import React, { useState, useEffect } from 'react'
import Styles from './AddAppChargingTokenDesignModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import IFButton from 'Components/IFButton/IFButton'
import { TextField, FormGroup } from '@mui/material'
import { connect } from 'react-redux'
import { IFText } from 'Components'
import AppChargingTokenDesignSelectors from 'Stores/AppChargingTokenDesign/Selectors'
import AppChargingTokenDesignActions from 'Stores/AppChargingTokenDesign/Actions'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
  width: '380px',
  '& .MuiInputBase-input': {
    height: '30px',
    padding: '10px',
  },
})

const AddAppChargingTokenDesignModal = ({
  addAppChargingTokenDesignRequestState,
  addAppChargingTokenDesign,
  editAppChargingTokenDesign,
  editAppChargingTokenDesignRequestState,
  appChargingTokenDesign,
  isEdit,
}) => {
  const { t } = useTranslation()
  const [designName, setDesignName] = useState('')
  const [designDescription, setDesignDescription] = useState('')
  const [nameError, setNameError] = useState('')
  const [designError, setDesignError] = useState('')

  useEffect(() => {
    if (isEdit && appChargingTokenDesign) {
      setDesignName(appChargingTokenDesign.name || '')
      setDesignDescription(appChargingTokenDesign.design || '')
    }
  }, [isEdit, appChargingTokenDesign])

  const handleInputChange = (setter, errorSetter, value) => {
    setter(value)
    if (value.trim() === '') {
      errorSetter(t('AppChargingTokenPage.Required'))
    } else if (value.trim().length < 3) {
      errorSetter(t('AppChargingTokenPage.MinThreeCharacters'))
    } else {
      errorSetter('')
    }
  }

  const handleBlur = (value, errorSetter) => {
    if (value.trim() === '') {
      errorSetter(t('AppChargingTokenPage.Required'))
    } else if (value.trim().length < 3) {
      errorSetter(t('AppChargingTokenPage.MinThreeCharacters'))
    } else {
      errorSetter('')
    }
  }

  const handleSubmit = () => {
    let hasError = false

    if (designName.trim() === '') {
      setNameError(t('AppChargingTokenPage.Required'))
      hasError = true
    } else if (designName.trim().length < 3) {
      setNameError(t('AppChargingTokenPage.MinThreeCharacters'))
      hasError = true
    }

    if (designDescription.trim() === '') {
      setDesignError(t('AppChargingTokenPage.Required'))
      hasError = true
    } else if (designDescription.trim().length < 3) {
      setDesignError(t('AppChargingTokenPage.MinThreeCharacters'))
      hasError = true
    }

    if (!hasError) {
      if (appChargingTokenDesign) {
        const updatedFields = {}
        if (
          designName.trim() !== '' &&
          designName !== appChargingTokenDesign.name
        ) {
          updatedFields.name = designName
        }
        if (
          designDescription.trim() !== '' &&
          designDescription !== appChargingTokenDesign.description
        ) {
          updatedFields.description = designDescription
        }

        if (Object.keys(updatedFields).length > 0) {
          editAppChargingTokenDesign(appChargingTokenDesign._id, updatedFields)
        }
      } else {
        addAppChargingTokenDesign({
          name: designName,
          description: designDescription,
        })
      }
    }
  }

  const isButtonDisabled =
    !designName.trim() ||
    designName.trim().length < 3 ||
    !designDescription.trim() ||
    designDescription.trim().length < 3 ||
    Boolean(nameError) ||
    Boolean(designError)

  return (
    <div className={Styles.Container}>
      <div className={Styles.FieldsDiv}>
        <div>
          <FormGroup className={Styles.InputWrappingDiv}>
            <IFText className={Styles.Labels}>
              {t('AppChargingTokenPage.DesignName')}
            </IFText>
            <StyledTextField
              value={designName}
              onChange={(e) =>
                handleInputChange(setDesignName, setNameError, e.target.value)
              }
              onBlur={() => handleBlur(designName, setNameError)}
              error={Boolean(nameError)}
            />
          </FormGroup>
          <div
            style={{
              height: '10px',
              fontSize: '0.75rem',
              color: 'red',
            }}
          >
            {nameError && nameError}
          </div>
        </div>

        <div>
          <FormGroup className={Styles.InputWrappingDiv}>
            <IFText className={Styles.Labels}>
              {t('AppChargingTokenPage.Design')}
            </IFText>
            <StyledTextField
              multiline
              maxRows={6}
              value={designDescription}
              onChange={(e) =>
                handleInputChange(
                  setDesignDescription,
                  setDesignError,
                  e.target.value,
                )
              }
              onBlur={() => handleBlur(designDescription, setDesignError)}
              error={Boolean(designError)}
            />
          </FormGroup>
          <div
            style={{
              height: '10px',
              fontSize: '0.75rem',
              color: 'red',
            }}
          >
            {designError && designError}
          </div>
        </div>
      </div>
      <IFButton
        size="sm"
        color={Colors.primary}
        isFill={true}
        text={
          isEdit
            ? t('AppChargingTokenPage.Edit')
            : t('AppChargingTokenPage.Save')
        }
        onClick={handleSubmit}
        className={Styles.SaveButton}
        isLoading={
          addAppChargingTokenDesignRequestState === RequestState.LOADING ||
          editAppChargingTokenDesignRequestState === RequestState.LOADING
        }
        isDead={isButtonDisabled}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  addAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getAddAppChargingTokenDesignRequestState(
      state,
    ),
  editAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getEditAppChargingTokenDesignRequestState(
      state,
    ),
})

const mapDispatchToProps = (dispatch) => ({
  addAppChargingTokenDesign: (design) =>
    dispatch(AppChargingTokenDesignActions.addAppChargingTokenDesign(design)),

  editAppChargingTokenDesign: (id, design) =>
    dispatch(
      AppChargingTokenDesignActions.editAppChargingTokenDesign(id, design),
    ),
})

AddAppChargingTokenDesignModal.propTypes = {
  addAppChargingTokenDesignRequestState: PropTypes.number,
  addAppChargingTokenDesign: PropTypes.func,
  editAppChargingTokenDesign: PropTypes.func,
  editAppChargingTokenDesignRequestState: PropTypes.number,
  appChargingTokenDesign: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAppChargingTokenDesignModal)
