import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  IFFilter,
  TransactionCard,
  EnergyConsumptionChart,
  FilterNoMatch,
} from 'Components'
import { TransactionsPageList } from 'Containers'
import Colors from 'Theme/Colors'
import Styles from './TransactionsPage.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import TransactionActions from 'Stores/Transaction/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { useLocation } from 'react-router-dom'
import history from 'history/browser'
import LoadingBar from 'react-top-loading-bar'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'

const TransactionsPage = ({
  admin,
  setTransactionsFilter,
  clearTransactions,
  transactionsFilter,
  fetchTransactionsListRequestState,
  fetchTransactionsList,
  fetchTransactionsMetrics,
  fetchTransactionsMetricsRequestState,
  metrics,
  energyConsumption,
  fetchEnergyConsumptionChart,
  fetchEnergyConsumptionChartRequestState,
  transactions,
  setDateInitialValue,
  clearTransactionsFilter,
}) => {
  const { t } = useTranslation()
  const tablesRef = useRef(null)
  const [tablesHeight, setTablesHeight] = React.useState(0)
  const [progress, setProgress] = useState(0)
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  useEffect(() => {
    if (
      fetchTransactionsListRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchTransactionsListRequestState === RequestState.SUCCEEDED)
      setProgress(100)
    if (
      fetchTransactionsListRequestState !== RequestState.LOADING &&
      fetchTransactionsListRequestState !== RequestState.UNINITIALIZED &&
      fetchTransactionsListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchTransactionsListRequestState])

  useEffect(() => {
    if (isFirstLoaded) {
      if (
        fetchTransactionsMetricsRequestState === RequestState.LOADING &&
        progress === 0
      )
        setProgress(progress + 10)
      if (fetchTransactionsMetricsRequestState === RequestState.SUCCEEDED) {
        setProgress(100)
        setIsFirstLoaded(false)
      }
    }
  }, [fetchTransactionsMetricsRequestState, isFirstLoaded])

  useEffect(() => {
    fetchTransactionsMetrics(transactionsFilter, 0)
    fetchEnergyConsumptionChart(transactionsFilter, 0)
  }, [transactionsFilter])

  useEffect(() => {
    const query = new URLSearchParams(history.location.search)
    let filterParam = query.get('filter')

    setDateInitialValue(admin, filterParam)
    setIsFirstLoaded(true)
    return () => {
      clearTransactionsFilter()
      clearTransactions()
    }
  }, [])
  const handleTransactionFilter = (newFilter) => {
    clearTransactions()
    history.push({
      pathname: '/transactions',
      search: `?filter=${encodeObjectToBase64(newFilter)}`,
    })
    fetchTransactionsList(newFilter, 0)
  }

  let location = useLocation()
  useEffect(() => {
    if (
      location.pathname === '/transactions' ||
      location.pathname === '/transactions/'
    ) {
      clearTransactions()
    }
  }, [location])

  useEffect(() => {
    fetchTransactionsList(transactionsFilter, 0)
  }, [clearTransactions])

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }
    const resizeObserver = new ResizeObserver(handleResize)
    if (tablesRef.current) {
      resizeObserver.observe(tablesRef.current)
    }
    return () => {
      if (tablesRef.current) {
        resizeObserver.unobserve(tablesRef.current)
      }
    }
  })

  const getDurationText = (duration) => {
    const seconds = Math.floor(duration / 1000)
    const hours = Math.floor(seconds / 3600)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)
    if (hours < 24) {
      return `prev. ${hours} hour(s)`
    } else if (days < 31) {
      return `prev. ${days} day(s)`
    } else {
      return `prev. ${months} month(s)`
    }
  }
  return (
    <div className={Styles.Container}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div className={Styles.Filter}>
        <IFFilter
          onFilterChange={(newFilter) => {
            handleTransactionFilter(newFilter)
          }}
          filters={transactionsFilter}
          disableFutureDates={true}
          textFieldPlaceholder={t(
            'TransactionsPage.TransactionsFilterPlaceholder',
          )}
          downloadTransactions={true}
        />
      </div>
      {fetchTransactionsListRequestState === RequestState.SUCCEEDED &&
      transactions.length === 0 ? (
        <div
          style={{
            height: '80vh',
          }}
        >
          <FilterNoMatch
            title={t('TransactionsPage.NoTransactions')}
            subtitle={t('TransactionsPage.filterNoTransaction')}
          />
        </div>
      ) : (
        <div className={Styles.Wrapper}>
          <div className={Styles.OuterContainer}>
            <div className={Styles.TotalsContainer}>
              <div style={{ flex: 1 }}>
                <TransactionCard
                  title={t('TransactionsPage.TotalMoneyCollected')}
                  data={metrics.totalMoneyCollected?.value}
                  percentageChange={
                    metrics.totalChargingBalance?.percentageChange
                  }
                  period={getDurationText(metrics?.transactionsDuration)}
                  metric={metrics?.transactionsCurrency}
                  style={Styles.TotalsCardChart}
                  isLoading={
                    fetchTransactionsMetricsRequestState ===
                    RequestState.LOADING
                  }
                />
                <div style={{ height: '16px' }} />
                <TransactionCard
                  title={t('TransactionsPage.TotalChargingBalance')}
                  data={metrics.totalChargingBalance?.value}
                  percentageChange={
                    metrics.totalChargingBalance?.percentageChange
                  }
                  period={getDurationText(metrics?.transactionsDuration)}
                  metric={metrics?.transactionsCurrency}
                  style={Styles.TotalsCardChart}
                  isLoading={
                    fetchTransactionsMetricsRequestState ===
                    RequestState.LOADING
                  }
                />
              </div>
              <div
                style={{
                  marginLeft: '16px',
                  flex: '1',
                }}
              >
                <TransactionCard
                  title={t('TransactionsPage.TotalEnergyConsumption')}
                  data={metrics.totalEnergyConsumption?.value}
                  percentageChange={
                    metrics.totalEnergyConsumption?.percentageChange
                  }
                  period={getDurationText(metrics?.transactionsDuration)}
                  metric={`kWh`}
                  style={Styles.TotalsCardChart}
                  isLoading={
                    fetchTransactionsMetricsRequestState ===
                    RequestState.LOADING
                  }
                />
                <div style={{ height: '16px' }} />

                <TransactionCard
                  title={t('TransactionsPage.TotalUsers')}
                  data={metrics.totalUsers?.value}
                  percentageChange={metrics.totalUsers?.percentageChange}
                  period={getDurationText(metrics?.transactionsDuration)}
                  style={Styles.TotalsCardChart}
                  isLoading={
                    fetchTransactionsMetricsRequestState ===
                    RequestState.LOADING
                  }
                />
              </div>
            </div>
            <div className={Styles.Chart}>
              <EnergyConsumptionChart
                totalDC={energyConsumption?.intervalsDC}
                totalAC={energyConsumption?.intervalsAC}
                startDates={energyConsumption?.startDates}
                height={200}
                dashboard={false}
                isLoading={
                  fetchEnergyConsumptionChartRequestState ===
                  RequestState.LOADING
                }
              />
            </div>
          </div>
          <div
            className={Styles.BottomContainer}
            style={{ backgroundColor: Colors.white }}
          >
            <div className={Styles.TransactionsContainer}>
              <div className={Styles.TransactionListContainer} ref={tablesRef}>
                <Scrollbars autoHide style={{ width: '100%', height: '100%' }}>
                  <TransactionsPageList tablesHeight={tablesHeight} />
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    setTransactionsFilter: (newFilter) =>
      dispatch(TransactionActions.setTransactionsFilter(newFilter)),
    clearTransactions: () => dispatch(TransactionActions.clearTransactions()),
    fetchTransactionsList: (filter, offset, shouldShowError) =>
      dispatch(
        TransactionActions.fetchTransactionsList(
          filter,
          offset,
          shouldShowError,
        ),
      ),
    fetchTransactionsMetrics: (filter, offset) =>
      dispatch(TransactionActions.fetchTransactionsMetrics(filter, offset)),
    fetchEnergyConsumptionChart: (filter, offset) =>
      dispatch(TransactionActions.fetchEnergyConsumptionChart(filter, offset)),
    setDateInitialValue: (admin, filterParam) =>
      dispatch(TransactionActions.setDateInitialValue(admin, filterParam)),
    clearTransactionsFilter: () =>
      dispatch(TransactionActions.clearTransactionsFilter()),
  }
}
const mapStateToProps = (state) => ({
  transactionsFilter: TransactionSelectors.getTransactionsFilter(state),
  fetchTransactionsListRequestState:
    TransactionSelectors.getFetchTransactionsListRequestState(state),
  fetchTransactionsMetricsRequestState:
    TransactionSelectors.getFetchTransactionsMetricsRequestState(state),
  metrics: TransactionSelectors.getMetrics(state),
  transactions: TransactionSelectors.getTransactionsList(state),
  energyConsumption: TransactionSelectors.getEnergyConsumption(state),
  fetchEnergyConsumptionChartRequestState:
    TransactionSelectors.getFetchEnergyConsumptionChartRequestState(state),
  transactions: TransactionSelectors.getTransactionsList(state),
  admin: AuthSelectors.getUser(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage)
