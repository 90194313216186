import PropTypes from 'prop-types'
import Styles from './AppChargingTokenDesignCard.module.css'
import { Colors } from 'Theme'
import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { Tooltip, IconButton, Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { IFText } from 'Components'

const AppChargingTokenDesignCard = ({
  imageData,
  designName,
  assignedDesign,
  canEdit,
  onEdit,
  onDelete,
  height,
}) => {
  const { t } = useTranslation()
  return (
    <div className={Styles.Container}>
      <Card sx={{ maxWidth: 390, backgroundColor: Colors.CardBackground }}>
        <CardMedia
          component="img"
          alt="Design"
          height={height}
          image={`data:image/png;base64,${imageData}`}
        />
        <div className={Styles.CardButtons}>
          <CardContent>
            <IFText className={Styles.TitleText}>{designName}</IFText>
          </CardContent>

          <div className={Styles.CardIcons}>
            {canEdit && !assignedDesign && (
              <div>
                <Tooltip
                  title={t('AppChargingTokenPage.deleteModalConfirm')}
                  className={Styles.Tooltip}
                >
                  <IconButton
                    onClick={() => {
                      onDelete()
                    }}
                  >
                    <div
                      className={Styles.DeleteIcon}
                      style={{ color: Colors.AdminListDeleteIcon }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </IconButton>
                </Tooltip>
              </div>
            )}
            {canEdit && (
              <div>
                <Tooltip
                  title={t('AppChargingTokenPage.Edit')}
                  className={Styles.Tooltip}
                >
                  <IconButton
                    onClick={() => {
                      onEdit()
                    }}
                  >
                    <EditIcon sx={{ color: Colors.gray }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  )
}
AppChargingTokenDesignCard.propTypes = {
  imageData: PropTypes.string,
  designName: PropTypes.string,
  assignedDesign: PropTypes.bool,
  canEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
}
export default AppChargingTokenDesignCard
