import { put, select, call } from 'redux-saga/effects'
import { appChargingTokenApiService, handleError } from 'Services/ApiService.js'
import appChargingTokenActions from 'Stores/AppChargingToken/Actions'
import AppChargingTokenSelectors from 'Stores/AppChargingToken/Selectors'
import history from 'history/browser'
import {
  encodeObjectToBase64,
  decodeBase64ToObject,
} from '../Utils/Base64Functions'
import i18n from 'i18next'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'

export function* fetchAppChargingTokens({ offset, shouldShowError }) {
  const query = new URLSearchParams(history.location.search)
  const slashArray = history.location.pathname.split('/')
  let filterParam = query.get('filter')
  let filterObject = []
  let defaultAppChargingTokenFilters = yield select(
    AppChargingTokenSelectors.getAppChargingTokenFilter,
  )
  try {
    if (filterParam === null) {
      filterObject = defaultAppChargingTokenFilters
      filterParam = encodeObjectToBase64(filterObject)
    } else {
      try {
        filterObject = decodeBase64ToObject(filterParam)
        for (let i = 0; i < filterObject.length; i++) {
          const { type: type1, title: title1 } = filterObject[i]
          const { type: type2, title: title2 } =
            defaultAppChargingTokenFilters[i]

          if (type1 !== type2 || title1 !== title2) {
            filterObject = defaultAppChargingTokenFilters
            filterParam = encodeObjectToBase64(filterObject)
          }
        }
      } catch (error) {
        filterObject = defaultAppChargingTokenFilters
        filterParam = encodeObjectToBase64(filterObject)
      }
    }
    if (offset === 0) {
      yield put(appChargingTokenActions.fetchAppChargingTokensLoading())
    }

    const { data } = yield call(appChargingTokenApiService.get, '/', {
      params: {
        filter: filterParam,
        offset: offset,
      },
    })
    yield put(
      appChargingTokenActions.fetchAppChargingTokensSuccess(
        offset,
        data.appChargingTokens,
        data.nextOffset,
        filterObject,
      ),
    )
  } catch (e) {
    yield put(appChargingTokenActions.fetchAppChargingTokensError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* editAppChargingToken({ appChargingTokenId, updatedValues }) {
  yield put(appChargingTokenActions.editAppChargingTokenLoading())
  try {
    const { data } = yield call(
      appChargingTokenApiService.patch,
      `/${appChargingTokenId}`,
      {
        uid: updatedValues.uid,
        visualNumber: updatedValues.visualNumber,
        design: updatedValues.design,
        reference: updatedValues.reference,
        parentId: updatedValues.parentId,
        voucherCode: updatedValues.voucherCode,
      },
    )
    yield put(appChargingTokenActions.editAppChargingTokenSuccess(data))
  } catch (e) {
    yield put(appChargingTokenActions.editAppChargingTokenError(e))
    handleError(e)
  }
}

export function* deleteAppChargingToken({ appChargingTokenId }) {
  yield put(appChargingTokenActions.deleteAppChargingTokenLoading())
  try {
    yield call(appChargingTokenApiService.delete, `/${appChargingTokenId}`)
    yield put(
      appChargingTokenActions.deleteAppChargingTokenSuccess(appChargingTokenId),
    )
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('AppChargingTokenPage.AppChargingTokenDeleted')}
      />,
    )
  } catch (e) {
    yield put(appChargingTokenActions.deleteAppChargingTokenError(e))
    handleError(e)
  }
}

export function* createAppChargingToken({ values }) {
  yield put(appChargingTokenActions.createAppChargingTokenLoading())
  try {
    const { data } = yield call(appChargingTokenApiService.post, '/', {
      uid: values.uid,
      visualNumber: values.visualNumber,
      design: values.design,
      reference: values.reference,
      voucherCode: values.voucherCode,
    })

    yield put(appChargingTokenActions.createAppChargingTokenSuccess(data))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('AppChargingTokenPage.AppChargingTokenCreated')}
      />,
    )
  } catch (e) {
    yield put(appChargingTokenActions.createAppChargingTokenError(e))
    handleError(e)
  }
}

export function* createAppChargingTokenBulk({ appChargingToken }) {
  yield put(appChargingTokenActions.createAppChargingTokenBulkLoading())
  try {
    const { data } = yield call(
      appChargingTokenApiService.post,
      '/appChargingTokenBulk',
      {
        appChargingToken: appChargingToken.appChargingToken,
      },
    )
    yield put(appChargingTokenActions.createAppChargingTokenBulkSuccess(data))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('AppChargingTokenPage.AppChargingTokenBulkCreated')}
      />,
    )
  } catch (e) {
    yield put(appChargingTokenActions.createAppChargingTokenBulkError(e))
    handleError(e)
  }
}

export function* downloadAppChargingTokenSampleFile() {
  yield put(appChargingTokenActions.downloadAppChargingTokenSampleFileLoading())
  try {
    const response = yield call(
      appChargingTokenApiService.get,
      '/downloadSamplefile',
      {
        responseType: 'blob',
      },
    )

    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = 'AppChargingTokenSample.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    yield put(
      appChargingTokenActions.downloadAppChargingTokenSampleFileSuccess(),
    )
  } catch (e) {
    yield put(
      appChargingTokenActions.downloadAppChargingTokenSampleFileError(e),
    )
    handleError(e)
  }
}
export function* getAppChargingTokensCount({}) {
  const query = new URLSearchParams(history.location.search)
  let filterParam = query.get('filter')
  let filterObject = []
  yield put(appChargingTokenActions.getAppChargingTokensCountLoading())
  try {
    const appChargingTokenFilter = yield select(
      AppChargingTokenSelectors.getAppChargingTokenFilter,
    )
    if (filterParam === null) {
      filterObject = appChargingTokenFilter
      filterParam = encodeObjectToBase64(filterObject)
    } else {
      try {
        filterObject = decodeBase64ToObject(filterParam)
        for (let i = 0; i < filterObject.length; i++) {
          const { type: type1, title: title1, data: data1 } = filterObject[i]
          const { type: type2, title: title2 } = appChargingTokenFilter[i]

          if (type1 !== type2 || title1 !== title2) {
            filterObject = appChargingTokenFilter
            filterParam = encodeObjectToBase64(filterObject)
          }
        }
      } catch (error) {
        filterObject = appChargingTokenFilter
        filterParam = encodeObjectToBase64(filterObject)
      }
    }
    const { data } = yield call(appChargingTokenApiService.get, '/count', {
      params: {
        filter: filterParam,
      },
    })
    yield put(
      appChargingTokenActions.getAppChargingTokensCountSuccess(
        data.count,
        filterObject,
      ),
    )
  } catch (e) {
    yield put(appChargingTokenActions.getAppChargingTokensCountError(e))
    handleError(e)
  }
}
