import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchAppChargingTokens: ['offset', 'shouldShowError'],
  fetchAppChargingTokensLoading: null,
  fetchAppChargingTokensSuccess: [
    'offset',
    'appChargingTokens',
    'nextOffset',
    'filterObject',
  ],
  fetchAppChargingTokensError: ['error'],

  editAppChargingToken: ['appChargingTokenId', 'updatedValues'],
  editAppChargingTokenLoading: null,
  editAppChargingTokenSuccess: ['appChargingToken'],
  editAppChargingTokenError: ['error'],

  deleteAppChargingToken: ['appChargingTokenId'],
  deleteAppChargingTokenLoading: null,
  deleteAppChargingTokenSuccess: ['appChargingTokenId'],
  deleteAppChargingTokenError: ['error'],

  createAppChargingToken: ['values'],
  createAppChargingTokenLoading: null,
  createAppChargingTokenSuccess: ['appChargingToken'],
  createAppChargingTokenError: ['error'],

  createAppChargingTokenBulk: ['appChargingToken'],
  createAppChargingTokenBulkLoading: null,
  createAppChargingTokenBulkSuccess: ['appChargingTokens'],
  createAppChargingTokenBulkError: ['error'],

  downloadAppChargingTokenSampleFile: null,
  downloadAppChargingTokenSampleFileLoading: null,
  downloadAppChargingTokenSampleFileSuccess: null,
  downloadAppChargingTokenSampleFileError: ['error'],

  getAppChargingTokensCount: null,
  getAppChargingTokensCountLoading: null,
  getAppChargingTokensCountSuccess: ['count'],
  getAppChargingTokensCountError: ['error'],

  clearAppChargingTokens: null,
  setDeleteAppChargingTokenRequestState: ['newState'],
})

export const AppChargingTokenTypes = Types
export default Creators
