import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IFButton, IFSkeleton, IFText, IFTextInput } from 'Components'
import Styles from './RoleDetails.module.css'
import { useTranslation } from 'react-i18next'
import { RolePermissionList, RoleAdminList } from 'Containers'
import { formatDate } from '../../Utils/StringFunctions'
import { Colors } from 'Theme'
import RoleActions from 'Stores/Role/Actions'
import RoleSelectors from 'Stores/Role/Selectors'
import { connect } from 'react-redux'
import RequestState from 'Enums/RequestState'
import AdminPermissions from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import { arraysAreEqual } from '../../Utils/StringFunctions'

const RoleDetails = ({
  role,
  isLoading,
  updateRole,
  updateRoleRequestState,
  fetchRoleAdminList,
  assignedAdmins,
  editingAdminRole,
  fetchEditingAdminRole,
  rolesWithAdmins,
}) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState(false)
  const [activePermissions, setActivePermissions] = useState([])
  const [nameEdit, setNameEdit] = useState('')
  const [resetPermissions, setResetPermissions] = useState(false)

  useEffect(() => {
    if (role) {
      const updatedRole =
        rolesWithAdmins.find((r) => r._id === role._id) || role
      setNameEdit(updatedRole.name)
      setActivePermissions([...updatedRole.permissions])
    }
  }, [role, rolesWithAdmins])

  useEffect(() => {
    fetchRoleAdminList(role._id, 0)
  }, [role._id, fetchRoleAdminList])

  useEffect(() => {
    if (updateRoleRequestState === RequestState.SUCCEEDED) {
      setIsEditing(false)
    }
    fetchEditingAdminRole()
  }, [updateRoleRequestState, fetchEditingAdminRole])

  const toggleEdit = () => {
    setIsEditing(!isEditing)
    setResetPermissions(false)
  }

  const handleCancel = () => {
    const updatedRole = rolesWithAdmins.find((r) => r._id === role._id) || role
    setNameEdit(updatedRole.name)
    setActivePermissions(updatedRole.permissions)
    setResetPermissions((prev) => !prev)
    setIsEditing(false)
  }

  const saveChanges = () => {
    const isNameChanged = role.name !== nameEdit
    const arePermissionsChanged = !arraysAreEqual(
      role.permissions,
      activePermissions,
    )
    if (!isNameChanged && !arePermissionsChanged) {
      setIsEditing(false)
    } else {
      updateRole(role._id, nameEdit, activePermissions)
    }
  }

  const handlePermissionsChange = (permissions) => {
    setActivePermissions(permissions)
  }

  const canEditRoles = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_EDIT_ROLES,
  )

  return (
    <div className={Styles.RoleDetailsContainer}>
      <div className={Styles.RoleDetails}>
        <div className={Styles.RoleDetailsDiv}>
          {isLoading ? (
            <>
              <IFSkeleton variant="text" width="150px" />
              <IFSkeleton variant="text" width="150px" />
            </>
          ) : (
            <>
              <IFText className={Styles.RoleHeaders}>
                {t('RoleDetails.Name')}
              </IFText>
              {isEditing ? (
                <IFTextInput
                  className={Styles.FieldText}
                  value={nameEdit}
                  onChange={(e) => setNameEdit(e.target.value)}
                  style={{
                    marginLeft: '-0.5rem',
                    color: Colors.text,
                  }}
                />
              ) : (
                <IFText className={Styles.RoleValues}>{nameEdit}</IFText>
              )}
            </>
          )}
        </div>

        <div className={Styles.RoleDetailsDiv}>
          {isLoading ? (
            <>
              <IFSkeleton variant="text" width="150px" />
              <IFSkeleton variant="text" width="150px" />
            </>
          ) : (
            <>
              <IFText className={Styles.RoleHeaders}>
                {t('RoleDetails.CreatedAt')}
              </IFText>
              <IFText className={Styles.RoleValues}>
                {formatDate(role.createdAt)}
              </IFText>
            </>
          )}
        </div>

        <div className={Styles.RoleDetailsDiv}>
          {isLoading ? (
            <>
              <IFSkeleton variant="text" width="150px" />
              <IFSkeleton variant="text" width="150px" />
            </>
          ) : (
            <>
              <IFText className={Styles.RoleHeaders}>
                {t('RoleDetails.UpdatedAt')}
              </IFText>
              <IFText className={Styles.RoleValues}>
                {formatDate(role.updatedAt)}
              </IFText>
            </>
          )}
        </div>

        <RoleAdminList
          role={role}
          assignedAdmins={assignedAdmins}
          isLoading={isLoading}
        />
      </div>
      {isLoading ? (
        <div className={Styles.PermissionsDivDivider}>
          <div className={Styles.PermissionsSkeleton}>
            <IFSkeleton variant="text" width="150px" />
            {[...Array(12)].map((e, index) => (
              <div key={index} className={Styles.PermissionsKeletonDiv}>
                <IFSkeleton variant="text" width="350px" />
                <IFSkeleton variant="text" width="40px" />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className={Styles.PermissionsDivDivider}>
          <div className={Styles.PermissionsDiv}>
            <RolePermissionList
              role={role}
              isLoading={isLoading}
              isEditing={isEditing}
              onPermissionsChange={handlePermissionsChange}
              resetPermissions={resetPermissions}
              titleFontSize={16}
              titleFontWeight={600}
            />
          </div>
        </div>
      )}
      {canEditRoles &&
        (isEditing ? (
          <div className={Styles.EditRoleButtonsDiv}>
            <IFButton
              size="sm"
              color={Colors.primary}
              isFill={true}
              isLoading={updateRoleRequestState === RequestState.LOADING}
              text={t('RoleDetails.Save')}
              onClick={saveChanges}
              className={Styles.saveButton}
            />
            <IFButton
              size="sm"
              color={Colors.primary}
              isFill={false}
              isLoading={false}
              text={t('RoleDetails.Cancel')}
              onClick={handleCancel}
              className={Styles.CancelButton}
            />
          </div>
        ) : isLoading ? (
          <IFSkeleton
            variant="text"
            width="4em"
            className={Styles.EditButton}
          />
        ) : (
          <IFButton
            size="sm"
            color={Colors.primary}
            isFill={false}
            isLoading={false}
            text={t('RoleDetails.Edit')}
            onClick={toggleEdit}
            className={Styles.EditButton}
          />
        ))}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateRole: (roleId, newRoleName, newRolePermissions) =>
      dispatch(RoleActions.updateRole(roleId, newRoleName, newRolePermissions)),
    fetchRoleAdminList: (roleId, offset) => {
      dispatch(RoleActions.fetchRoleAdminList(roleId, offset))
    },
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
  }
}

const mapStateToProps = (state) => ({
  assignedAdmins: RoleSelectors.getAssignedAdmins(state),
  updateRoleRequestState: RoleSelectors.getUpdateRoleRequestState(state),
  loggedInAdmin: AuthSelectors.getUser(state),
  assignedAdminsPaginationOffset:
    RoleSelectors.getAssignedAdminsPaginationOffset(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  rolesWithAdmins: RoleSelectors.getRolesWithAdmins(state),
})

RoleDetails.propTypes = {
  role: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateRole: PropTypes.func.isRequired,
  updateRoleRequestState: PropTypes.number,
  loggedInAdmin: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleDetails)
