import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchCountriesListCount: ['filter'],
  fetchCountriesListCountLoading: null,
  fetchCountriesListCountSuccess: ['countriesCount'],
  fetchCountriesListCountFail: ['error'],

  fetchCountriesList: ['filter', 'offset', 'shouldShowError'],
  fetchCountriesListLoading: null,
  fetchCountriesListSuccess: ['offset', 'countries', 'nextOffset'],
  fetchCountriesListFail: ['error'],

  fetchCurrenciesListCount: ['filter'],
  fetchCurrenciesListCountLoading: null,
  fetchCurrenciesListCountSuccess: ['currenciesCount'],
  fetchCurrenciesListCountFail: ['error'],

  fetchCurrenciesList: ['filter', 'offset', 'shouldShowError'],
  fetchCurrenciesListLoading: null,
  fetchCurrenciesListSuccess: ['offset', 'currencies', 'nextOffset'],
  fetchCurrenciesListFail: ['error'],

  setCountriesFilter: ['newFilter'],
  setCurrenciesFilter: ['newFilter'],
  clearCountriesFilter: null,
  clearCurrenciesFilter: null,
  clearCountries: null,

  fetchCountryBounds: ['countryName'],
  fetchCountryBoundsLoading: null,
  fetchCountryBoundsSuccess: ['bounds'],
  fetchCountryBoundsFail: ['error'],
  clearCountryBounds: null,

  updateCountry: ['countryId', 'newCountry', 'index', 'onResponse'],
  updateCountryLoading: null,
  updateCountrySuccess: ['index', 'newCountry'],
  updateCountryFail: ['error'],
  setUpdateCountryRequestState: ['requestState'],

  fetchAvailableCurrencies: null,
  fetchAvailableCurrenciesLoading: null,
  fetchAvailableCurrenciesSuccess: ['currencies'],
  fetchAvailableCurrenciesFail: ['error'],

  fetchCreateCountryBounds: ['countryName'],
  fetchCreateCountryBoundsLoading: null,
  fetchCreateCountryBoundsSuccess: ['bounds'],
  fetchCreateCountryBoundsFail: ['error'],
  clearCreateCountryBounds: null,

  addCountry: ['country'],
  addCountryLoading: null,
  addCountrySuccess: ['country'],
  addCountryFail: ['error'],
  updateCurrency: ['currencyId', 'newCurrency', 'index', 'onResponse'],
  updateCurrencyLoading: null,
  updateCurrencySuccess: ['index', 'newCurrency'],
  updateCurrencyFail: ['error'],
  setUpdateCurrencyRequestState: ['requestState'],

  addCurrency: ['currency'],
  addCurrencyLoading: null,
  addCurrencySuccess: ['currency'],
  addCurrencyFail: ['error'],
})

export const CountriesTypes = Types
export default Creators
