import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IFTable,
  IFText,
  IFsvg,
  IFDialog,
  IFButton,
  IFTooltipIconsLoading,
  AppChargingTokenItemDetails,
} from 'Components'
import AppChargingTokenSelectors from 'Stores/AppChargingToken/Selectors'
import AppChargingTokenActions from 'Stores/AppChargingToken/Actions'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import { Colors } from 'Theme'
import Styles from './AppChargingTokenList.module.css'
import { Tooltip, IconButton, Box } from '@mui/material'
import { capitalizeFirstLetter, formatDate } from '../../Utils/StringFunctions'
import ReportIcon from '@mui/icons-material/Report'
import EditIcon from '@mui/icons-material/Edit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfinityEnums from 'Enums/InfinityEnums'
import AdminPermissions from 'Enums/InfinityEnums'
import moment from 'moment'

const AppChargingTokenList = ({
  fetchAppChargingTokensRequestState,
  appChargingTokens,
  tablesHeight,
  paginationOffset,
  fetchAppChargingTokens,
  loggedInAdmin,
  editingAdminRole,
  allAppChargingTokenDesigns,
  deleteAppChargingTokenRequestState,
  deleteAppChargingToken,
  editAppChargingToken,
  editAppChargingTokenRequestState,
  setDeleteAppChargingTokenRequestState,
  allVouchers,
}) => {
  const [failedRequest, setFailedRequest] = useState(false)
  const [editDetails, setEditDetails] = useState({})
  const [editedDetails, setEditedDetails] = useState({})
  const [activeRowId, setActiveRowId] = useState(null)
  const [selectedAppChargingToken, setSelectedAppChargingToken] = useState()
  const deleteModalRef = useRef()
  const tableRef = useRef()
  const childResetRef = useRef(null)
  const uidValue = useRef()
  const visualNumberValue = useRef()
  const [table, setTable] = useState()
  const canEditAdmin = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_EDIT_APP_CHARGING_TOKENS,
  )
  const { t } = useTranslation()

  const loadMoreData = () => {
    if (
      fetchAppChargingTokensRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchAppChargingTokens(paginationOffset, failedRequest)
  }
  useEffect(() => {
    if (
      fetchAppChargingTokensRequestState === RequestState.LOADING &&
      tableRef.current
    ) {
      tableRef.current.resetExpandedRows()
    }
  }, [fetchAppChargingTokensRequestState])
  useEffect(() => {
    if (
      fetchAppChargingTokensRequestState === RequestState.ERROR_0_NETWORK ||
      fetchAppChargingTokensRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchAppChargingTokensRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchAppChargingTokensRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchAppChargingTokensRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchAppChargingTokensRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchAppChargingTokensRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchAppChargingTokensRequestState])

  useEffect(() => {
    if (editAppChargingTokenRequestState === RequestState.SUCCEEDED) {
      setActiveRowId(null)
    }
  }, [editAppChargingTokenRequestState])

  const handleRetry = () => {
    fetchAppChargingTokens(paginationOffset)
  }
  const handleCancel = () => {
    if (childResetRef.current) {
      childResetRef.current()
    }
  }

  const handleEdit = (rowId, table, row) => {
    uidValue.current = row.original.uid
    visualNumberValue.current = row.original.visualNumber
    if (activeRowId !== null && activeRowId !== rowId) {
      if (childResetRef.current) {
        childResetRef.current()
      }
      table?.setEditingRow(null)
      setEditDetails((prevState) => ({
        ...prevState,
        [activeRowId]: false,
      }))
    }

    if (activeRowId === rowId) {
      table?.setEditingRow(null)
      setActiveRowId(null)
      setEditDetails((prevState) => ({
        ...prevState,
        [rowId]: false,
      }))
      return
    }

    table?.setEditingRow(row)
    setActiveRowId(rowId)
    setEditDetails((prevState) => ({
      ...prevState,
      [rowId]: true,
    }))

    if (!row.getIsExpanded()) {
      row.toggleExpanded()
      tableRef.current.setExpandedRowExternal(row)
    }
  }

  const handleDetailsChange = (rowId, updatedDetails) => {
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [rowId]: updatedDetails,
    }))
  }
  const handleSaveRole = (table, row, values) => {
    const rowId = row.index
    const rowDetails = editedDetails[rowId] || {}

    const selectedDesign = rowDetails.design
      ? allAppChargingTokenDesigns.appChargingTokensDesigns.find(
          (design) => design.name === rowDetails.design,
        )
      : null

    const selectedVoucher = rowDetails.voucherCode
      ? allVouchers.find((voucher) => voucher.name === rowDetails.voucherCode)
      : null

    const updatedValues = {}

    if (values.uid !== row.original.uid) {
      updatedValues.uid = values.uid
    }
    if (values.visualNumber !== row.original.visualNumber) {
      updatedValues.visualNumber = values.visualNumber
    }
    if (selectedDesign && selectedDesign._id !== row.original.design?._id) {
      updatedValues.design = selectedDesign._id
    }
    if (
      rowDetails.reference &&
      rowDetails.reference !== row.original.reference
    ) {
      updatedValues.reference = rowDetails.reference
    }
    if (rowDetails.parentId && rowDetails.parentId !== row.original.parentId) {
      updatedValues.parentId = rowDetails.parentId
    }

    if (
      (!selectedVoucher && row.original.voucherCode) ||
      (selectedVoucher && selectedVoucher.code !== row.original.voucherCode)
    ) {
      updatedValues.voucherCode = selectedVoucher ? selectedVoucher.code : ''
    }

    if (Object.keys(updatedValues).length === 0) {
      setActiveRowId(null)
      table?.setEditingRow(null)
      return
    }

    setTable(table)
    editAppChargingToken(row.original._id, updatedValues)
  }

  const handleSaveRef = useRef(handleSaveRole)
  useEffect(() => {
    handleSaveRef.current = handleSaveRole
  })
  useEffect(() => {
    if (editAppChargingTokenRequestState === RequestState.SUCCEEDED) {
      table?.setEditingRow(null)
    }
  }, [editAppChargingTokenRequestState])

  const onDeleteClickHandler = (row, table) => {
    setSelectedAppChargingToken(row)
    if (activeRowId === row.index) {
      setActiveRowId(null)
      table?.setEditingRow(null)
    }

    deleteModalRef.current.show()
  }

  const handleDeleteAppChargingToken = () => {
    if (selectedAppChargingToken.original) {
      deleteAppChargingToken(selectedAppChargingToken.original._id)
    }
  }
  useEffect(() => {
    if (deleteAppChargingTokenRequestState === RequestState.SUCCEEDED) {
      deleteModalRef.current.dismiss()
      setDeleteAppChargingTokenRequestState(RequestState.UNINITIALIZED)
    }
  }, [
    deleteAppChargingTokenRequestState,
    activeRowId,
    selectedAppChargingToken,
  ])

  const appChargingTokenTableColumns = useMemo(
    () => [
      {
        accessorKey: 'uid',

        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            value: uidValue?.current,
            onChange: (event) => {
              uidValue.current = event.target.value
            },
            sx: {
              '& .MuiInputBase-root:hover::before': {
                borderBottom: `1px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-root.Mui-focused::after': {
                borderBottom: `2px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            },
          }
        },
        header:
          fetchAppChargingTokensRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('AppChargingTokenPage.Uid')
          ),
        Cell: ({ row }) => {
          return row.index < appChargingTokens.length ? (
            <div className={Styles.ColumnTitle}>
              <ExpandMoreIcon
                className={Styles.ExpandMoreIcon}
                style={{
                  transform: row.getIsExpanded()
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)',
                }}
              />
              <div className={Styles.UidText}>
                {row.original.uid ? (
                  <IFText>{capitalizeFirstLetter(row.original.uid)}</IFText>
                ) : (
                  <IFText>{t('AppChargingTokenPage.NotSet')}</IFText>
                )}
              </div>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'visualNumber',
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            value: visualNumberValue?.current,
            onChange: (event) => {
              visualNumberValue.current = event.target.value
            },
            sx: {
              '& .MuiInputBase-root:hover::before': {
                borderBottom: `1px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-root.Mui-focused::after': {
                borderBottom: `2px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
              },
            },
          }
        },
        header:
          fetchAppChargingTokensRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="55px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('AppChargingTokenPage.VisualNumber')
          ),
        Cell: ({ row }) => {
          return row.index < appChargingTokens.length ? (
            row.original.visualNumber ? (
              <IFText>
                {capitalizeFirstLetter(row.original.visualNumber)}
              </IFText>
            ) : (
              <IFText>{t('AppChargingTokenPage.NotSet')}</IFText>
            )
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'type',
        enableEditing: false,
        header:
          fetchAppChargingTokensRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AppChargingTokenPage.Type')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < appChargingTokens.length ? (
            row.original.type ? (
              <IFText>{capitalizeFirstLetter(row.original.type)}</IFText>
            ) : (
              <IFText>{t('AppChargingTokenPage.NotSet')}</IFText>
            )
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'status',
        enableEditing: false,
        header:
          fetchAppChargingTokensRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AppChargingTokenPage.Status')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < appChargingTokens.length ? (
            row.original.status ? (
              <IFText>{capitalizeFirstLetter(row.original.status)}</IFText>
            ) : (
              <IFText>{t('AppChargingTokenPage.NotSet')}</IFText>
            )
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'createdOn',
        enableEditing: false,
        header:
          fetchAppChargingTokensRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('AppChargingTokenPage.CreatedOn')
          ),
        Cell: ({ row }) => {
          return row.index < appChargingTokens.length ? (
            <IFText>
              {moment(row.original.createdAt).format('MMM DD, YYYY LTS')}
            </IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [t, fetchAppChargingTokensRequestState, failedRequest, appChargingTokens],
  )
  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const renderRowActions = ({ table, row }) => {
    const isCurrentAdmin = row.original.id === loggedInAdmin.id
    const canBeEdit =
      row.original.status === InfinityEnums.AppChargingTokenStatus.INACTIVE
    const canDelete = row.original.user === null || !row.original.user
    const rowId = row.original.id || row.index
    const isInEditMode = activeRowId === rowId

    if (Object.keys(row.original).length > 0 && !isCurrentAdmin) {
      return (
        <Box>
          {isInEditMode && row?.id === table.getState().editingRow?.id ? (
            <div className={Styles.AdminTableIcons}>
              <Tooltip title={t('AdminList.deleteModalCancel')}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleEdit(rowId, table, row)
                    table?.setEditingRow(null)
                    handleCancel()
                  }}
                >
                  <IFsvg.CloseBtn
                    height={24}
                    width={24}
                    fill={Colors.primary}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('AdminList.Save')}>
                <div className={Styles.CustomBackGround}>
                  <IFTooltipIconsLoading
                    isLoading={
                      editAppChargingTokenRequestState === RequestState.LOADING
                        ? true
                        : false
                    }
                    Icon={IFsvg.DoneBtn}
                    FilledIcon={IFsvg.DoneBtn}
                    size={26}
                    animationDisabled={false}
                    onClick={() => {
                      const values = row.getAllCells().reduce((acc, cell) => {
                        acc[cell.column.id] = cell.getValue()
                        return acc
                      }, {})
                      handleSaveRef.current(table, row, values)
                    }}
                  />
                </div>
              </Tooltip>

              {canDelete ? (
                <Tooltip title={t('AdminList.deleteModalConfirm')}>
                  <IconButton
                    onClick={(e) => {
                      onDeleteClickHandler(row, table)
                    }}
                  >
                    <div
                      className={Styles.DeleteIcon}
                      style={{ color: Colors.AdminListDeleteIcon }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </div>
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          ) : (
            canBeEdit && (
              <div className={Styles.AdminTableIcons}>
                <Tooltip title={t('AdminList.Edit')}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation()
                      handleEdit(rowId, table, row)
                    }}
                  >
                    <EditIcon sx={{ color: Colors.gray }} />
                  </IconButton>
                </Tooltip>
              </div>
            )
          )}
        </Box>
      )
    }

    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={handleRetry}
        />
      )
    }

    return null
  }

  const renderDetailPanel = ({ row }) => {
    const rowId = row.original.id || row.index

    return (
      <AppChargingTokenItemDetails
        row={row}
        editingAdminRole={editingAdminRole}
        editDetails={activeRowId === rowId}
        allAppChargingTokenDesigns={allAppChargingTokenDesigns}
        onDetailsChange={handleDetailsChange}
        onCancelEdit={(resetFn) => {
          if (activeRowId === rowId) {
            childResetRef.current = resetFn
          }
        }}
        appChargingTokens={appChargingTokens}
        allVouchers={allVouchers}
      />
    )
  }

  return (
    <>
      <IFTable
        ref={tableRef}
        columns={appChargingTokenTableColumns}
        data={appChargingTokens}
        tableMaxHeight={tablesHeight}
        paginationOffset={paginationOffset}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        tableSetHeight={'100%'}
        showSkeleton={
          fetchAppChargingTokensRequestState === RequestState.LOADING ||
          (fetchAppChargingTokensRequestState !== RequestState.SUCCEEDED &&
            paginationOffset === 0)
        }
        tableRequestState={fetchAppChargingTokensRequestState}
        enableEditing={canEditAdmin}
        renderRowActions={canEditAdmin && renderRowActions}
        renderDetailPanel={renderDetailPanel}
      />

      <IFDialog
        ref={deleteModalRef}
        style={{ margin: '15em' }}
        open={false}
        title={t('AppChargingTokenPage.deleteModalTitle')}
        bodyText={t('AppChargingTokenPage.deleteModalTextToken')}
        buttonAcceptonClick={handleDeleteAppChargingToken}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AppChargingTokenPage.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteAppChargingTokenRequestState === RequestState.LOADING}
        dismissOnAccept={false}
      />
    </>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchAppChargingTokens: (offset, shouldShowError) =>
      dispatch(
        AppChargingTokenActions.fetchAppChargingTokens(offset, shouldShowError),
      ),
    deleteAppChargingToken: (appChargingTokenId) =>
      dispatch(
        AppChargingTokenActions.deleteAppChargingToken(appChargingTokenId),
      ),
    editAppChargingToken: (appChargingTokenId, updatedValues) =>
      dispatch(
        AppChargingTokenActions.editAppChargingToken(
          appChargingTokenId,
          updatedValues,
        ),
      ),
    setDeleteAppChargingTokenRequestState: (newState) =>
      dispatch(
        AppChargingTokenActions.setDeleteAppChargingTokenRequestState(newState),
      ),
  }
}

const mapStateToProps = (state) => ({
  fetchAppChargingTokensRequestState:
    AppChargingTokenSelectors.getFetchAppChargingTokensRequestState(state),

  paginationOffset: AppChargingTokenSelectors.getPaginationOffset(state),

  deleteAppChargingTokenRequestState:
    AppChargingTokenSelectors.getDeleteAppChargingTokenRequestState(state),
  editAppChargingTokenRequestState:
    AppChargingTokenSelectors.getEditAppChargingTokenRequestState(state),
})

AppChargingTokenList.propTypes = {
  fetchAppChargingTokensRequestState: PropTypes.number,
  appChargingTokens: PropTypes.array,
  tablesHeight: PropTypes.number,
  paginationOffset: PropTypes.number,
  fetchAppChargingTokens: PropTypes.func,
  loggedInAdmin: PropTypes.object,
  editingAdminRole: PropTypes.object,
  allAppChargingTokenDesigns: PropTypes.object,
  deleteAppChargingTokenRequestState: PropTypes.number,
  deleteAppChargingToken: PropTypes.func,
  editAppChargingToken: PropTypes.func,
  editAppChargingTokenRequestState: PropTypes.number,
  setDeleteAppChargingTokenRequestState: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppChargingTokenList)
