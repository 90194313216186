import { connect } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CountriesSelectors from 'Stores/Countries-Currencies/Selectors'
import CountriesActions from 'Stores/Countries-Currencies/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { useTranslation } from 'react-i18next'
import {
  IFButton,
  IFsvg,
  IFTable,
  IFText,
  IFTooltipIconsLoading,
} from 'Components'
import {
  Box,
  IconButton,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import RequestState from 'Enums/RequestState'
import Styles from './CountriesList.module.css'
import { Colors } from 'Theme'
import ReactCountryFlag from 'react-country-flag'
import styled from 'styled-components'
import EditIcon from '@mui/icons-material/Edit'
import InfinityEnums from 'Enums/InfinityEnums'
import { areBoundsEqual } from 'Utils/StringFunctions'
const Countries = require('world-countries/countries.json')

const IconDivIFToolTip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.onHoverBackground};
  }
`
const CountriesList = ({
  countries,
  paginationOffset,
  countriesFilters,
  tablesHeight,
  fetchCountriesListRequestState,
  fetchCountriesList,
  editingAdminRole,
  fetchCountryBounds,
  countryBounds,
  countryBoundsRequestState,
  clearCountryBounds,
  updateCountry,
  updateCountryRequestState,
  setUpdateCountryRequestState,
  fetchAvailableCurrencies,
  availableCurrencies,
}) => {
  const { t } = useTranslation()
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    fetchAvailableCurrencies()
  }, [])
  useEffect(() => {
    if (
      fetchCountriesListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchCountriesListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchCountriesListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchCountriesListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchCountriesListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchCountriesListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchCountriesListRequestState === RequestState.SUCCEEDED) {
      setFailedRequest(false)
      setIsFirstLoaded(false)
    }
  }, [fetchCountriesListRequestState])
  const handleRetry = () => {
    fetchCountriesList(countriesFilters, paginationOffset)
  }
  const loadMoreData = () => {
    if (
      fetchCountriesListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchCountriesList(countriesFilters, paginationOffset, failedRequest)
  }

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const countryValue = useRef()
  const currencyValue = useRef()
  const [countryName, setCountryName] = useState()
  const boundsValue = useRef()

  const countriesTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        Edit: () => {
          return (
            <TextField
              id="standard-select-currency"
              select
              variant="standard"
              fullWidth
              value={countryValue?.current}
              onChange={(event) => {
                setCountryName(event.target.value)
                countryValue.current = event.target.value
              }}
              sx={{
                '& .MuiInputBase-root.Mui-focused': {
                  backgroundColor: 'transparent',
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '& .MuiInputBase-root:hover::before': {
                  borderBottom: `1px solid ${Colors.primary} !important`,
                },
                '& .MuiInputBase-root.Mui-focused::after': {
                  borderBottom: `2px solid ${Colors.primary} !important`,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 250,
                      maxWidth: 250,
                      overflow: 'scroll',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                },
              }}
            >
              {Countries.map((country) => (
                <MenuItem key={country.cca2} value={country.cca2}>
                  <div className={Styles.DropDownItem}>
                    <ReactCountryFlag
                      className={Styles.FlagItem}
                      countryCode={country.cca2}
                      svg
                    />
                    <IFText className={Styles.DropDownText}>
                      {country.name.common}
                    </IFText>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          )
        },
        header:
          fetchCountriesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('CountriesPage.CountryName')
          ),
        Cell: ({ row }) => {
          return row.index < countries.length ? (
            <div className={Styles.CountryItem}>
              <ReactCountryFlag
                className={Styles.FlagItem}
                countryCode={row.original.code}
                svg
              />
              <IFText>{row.original.name}</IFText>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'code',
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            disabled: true,
            value: countryValue?.current,
            sx: {
              '& .MuiInputBase-input.Mui-disabled': {
                cursor: 'default !important',
              },
            },
          }
        },
        header:
          fetchCountriesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('CountriesPage.CountryCode')
          ),
        Cell: ({ row }) => {
          return row.index < countries.length ? (
            <IFText>{row.original.code}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'currency',
        Edit: () => {
          return (
            <TextField
              id="standard-select-currency"
              select
              variant="standard"
              fullWidth
              value={currencyValue?.current}
              onChange={(event) => {
                currencyValue.current = event.target.value
              }}
              sx={{
                '& .MuiInputBase-root.Mui-focused': {
                  backgroundColor: 'transparent',
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '& .MuiInputBase-root:hover::before': {
                  borderBottom: `1px solid ${Colors.primary} !important`,
                },
                '& .MuiInputBase-root.Mui-focused::after': {
                  borderBottom: `2px solid ${Colors.primary} !important`,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 250,
                      maxWidth: 250,
                      overflow: 'scroll',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                },
              }}
            >
              {availableCurrencies?.map((currency) => (
                <MenuItem key={currency.name} value={currency.name}>
                  <IFText className={Styles.DropDownText}>
                    {currency.name}
                  </IFText>
                </MenuItem>
              ))}
            </TextField>
          )
        },
        header:
          fetchCountriesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('CountriesPage.Currency')
          ),
        Cell: ({ row }) => {
          return row.index < countries.length ? (
            <IFText>{row.original.currency.name}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'bounds',
        Edit: () => {
          return countryBoundsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="rectangular"
              width="100%"
              animation="none"
              height={24}
              sx={{ borderRadius: 1 }}
            />
          ) : (
            <TextField
              type="text"
              variant="standard"
              disabled
              value={
                countryBounds.length != 0 ? countryBounds : boundsValue.current
              }
              fullWidth
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  cursor: 'default !important',
                },
              }}
            />
          )
        },

        header:
          fetchCountriesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('CountriesPage.Bounds')
          ),
        Cell: ({ row }) => {
          return row.index < countries.length ? (
            <div className={Styles.Bounds}>
              <div>
                <IFText>
                  {t('CountriesPage.SW')}{' '}
                  {`{ ${t('CountriesPage.lat')}: ${row.original.bounds[0]}, ${t(
                    'CountriesPage.lon',
                  )}: ${row.original.bounds[2]} } `}
                </IFText>
              </div>
              <div>
                <IFText>
                  {t('CountriesPage.NE')}{' '}
                  {`{ ${t('CountriesPage.lat')}: ${row.original.bounds[1]}, ${t(
                    'CountriesPage.lon',
                  )}: ${row.original.bounds[3]} } `}{' '}
                </IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [
      countries,
      fetchCountriesListRequestState,
      isFirstLoaded,
      failedRequest,
      boundsValue,
      countryValue,
      countryBounds,
      countryBoundsRequestState,
      availableCurrencies,
      currencyValue,
    ],
  )
  const [table, setTable] = useState()
  useEffect(() => {
    if (updateCountryRequestState === RequestState.SUCCEEDED) {
      table?.setEditingRow(null)
    }
  }, [updateCountryRequestState])
  const handleSaveCountry = (table, row) => {
    const country = Countries.find(
      (country) => country.cca2 === countryValue.current,
    )
    const currency = availableCurrencies.find(
      (currency) => currency.name === currencyValue.current,
    )
    let values = {
      name: country.name.common,
      code: countryValue.current,
      bounds: countryBounds.length === 0 ? row.original.bounds : countryBounds,
      currency: currency?.id,
    }
    if (
      values.name === '' ||
      values.code === '' ||
      countryValue.current === '' ||
      (values.name === row.original.name &&
        values.code === row.original.code &&
        areBoundsEqual(values.bounds, row.original.bounds) &&
        values.currency === row.original.currency.id)
    ) {
      table?.setEditingRow(null)
    } else {
      setTable(table)
      updateCountry(row.original.id, values, row.index, () => {})
    }
  }
  const renderRowActionsCell = ({ table, row }) => {
    if (Object.keys(row.original).length > 0) {
      return row?.id === table.getState().editingRow?.id ? (
        <div className={Styles.RowActions}>
          <Tooltip title={t('CountriesPage.Cancel')}>
            <IconButton
              onClick={() => {
                clearCountryBounds()
                setCountryName()
                table?.setEditingRow(null)
              }}
            >
              <IFsvg.CloseBtn height={24} width={24} fill={Colors.primary} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('CountriesPage.Save')}>
            <IconDivIFToolTip>
              <IFTooltipIconsLoading
                isLoading={
                  updateCountryRequestState === RequestState.LOADING
                    ? true
                    : false
                }
                isDead={countryBoundsRequestState === RequestState.LOADING}
                Icon={IFsvg.DoneBtn}
                FilledIcon={IFsvg.DoneBtn}
                size={24}
                animationDisabled={false}
                onClick={() => {
                  handleSaveCountry(table, row)
                }}
              />
            </IconDivIFToolTip>
          </Tooltip>
        </div>
      ) : (
        <Box className={Styles.RowActions}>
          {editingAdminRole?.includes(
            InfinityEnums.AdminPermissions.CAN_EDIT_COUNTRIES,
          ) && (
            <Tooltip title={t('CountriesPage.Edit')}>
              <IconButton onClick={() => handleEdit({ row, table })}>
                <EditIcon sx={{ color: Colors.gray }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )
    }
    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={() => {
            handleRetry()
          }}
        />
      )
    }
  }
  const handleEdit = ({ row, table }) => {
    countryValue.current = row.original.code
    boundsValue.current = row.original.bounds
    currencyValue.current = row.original.currency.name
    setUpdateCountryRequestState(RequestState.UNINITIALIZED)
    clearCountryBounds()
    table?.setEditingRow(row)
  }
  useEffect(() => {
    if (countryName) {
      const country = Countries.find((country) => country.cca2 === countryName)
      if (country) {
        fetchCountryBounds(country.name.common)
      }
    }
  }, [countryName])

  return (
    <>
      <IFTable
        columns={countriesTableColumns}
        data={countries}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        paginationOffset={paginationOffset}
        enableEditing={true}
        filters={countriesFilters}
        showSkeleton={
          (fetchCountriesListRequestState === RequestState.LOADING &&
            paginationOffset === 0) ||
          (fetchCountriesListRequestState !== RequestState.SUCCEEDED &&
            !paginationOffset)
        }
        tableMaxHeight={tablesHeight - 16}
        tableSetHeight={'100%'}
        tableRequestState={fetchCountriesListRequestState}
        renderRowActionsCell={renderRowActionsCell}
      />
    </>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchCountriesList: (filter, offset, shouldShowError) =>
      dispatch(
        CountriesActions.fetchCountriesList(filter, offset, shouldShowError),
      ),
    fetchCountryBounds: (countryName) =>
      dispatch(CountriesActions.fetchCountryBounds(countryName)),
    clearCountryBounds: () => dispatch(CountriesActions.clearCountryBounds()),
    setUpdateCountryRequestState: (requestState) =>
      dispatch(CountriesActions.setUpdateCountryRequestState(requestState)),
    updateCountry: (countryId, newCountry, index, onResponse) =>
      dispatch(
        CountriesActions.updateCountry(
          countryId,
          newCountry,
          index,
          onResponse,
        ),
      ),
    fetchAvailableCurrencies: () =>
      dispatch(CountriesActions.fetchAvailableCurrencies()),
  }
}
const mapStateToProps = (state) => ({
  countries: CountriesSelectors.getCountriesList(state),
  countriesFilters: CountriesSelectors.getCountriesFilters(state),
  fetchCountriesListRequestState:
    CountriesSelectors.getFetchCountriesListRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  paginationOffset: CountriesSelectors.getCountriesListOffset(state),
  countryBounds: CountriesSelectors.getCountryBounds(state),
  countryBoundsRequestState:
    CountriesSelectors.getFetchCountryBoundstRequestState(state),
  updateCountryRequestState:
    CountriesSelectors.getUpdateCountryRequestState(state),
  availableCurrencies: CountriesSelectors.getAvailableCurrencies(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(CountriesList)
