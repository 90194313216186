import { INITIAL_STATE } from './initialState'
import { AppChargingTokenDesignTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'

export const fetchAppChargingTokenDesignsLoading = (state) => {
  return {
    ...state,
    fetchAppChargingTokenDesignsRequestState: RequestState.LOADING,
  }
}

export const fetchAppChargingTokenDesignsSuccess = (
  state,
  { offset, appChargingTokenDesigns, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchAppChargingTokenDesignsRequestState: RequestState.SUCCEEDED,
      appChargingTokenDesigns: appChargingTokenDesigns.map(
        (appChargingTokenDesigns) => ({
          ...appChargingTokenDesigns,
        }),
      ),
      paginationOffset: nextOffset,
    }
  } else {
    return {
      ...state,
      appChargingTokenDesigns: [
        ...state.appChargingTokenDesigns,
        ...appChargingTokenDesigns.map((appChargingTokenDesigns) => ({
          ...appChargingTokenDesigns,
        })),
      ],
      fetchAppChargingTokenDesignsRequestState: RequestState.SUCCEEDED,
      paginationOffset: nextOffset,
    }
  }
}

export const fetchAppChargingTokenDesignsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAppChargingTokenDesignsRequestState: requestState,
  }
}

export const fetchAllAppChargingTokenDesignsLoading = (state) => {
  return {
    ...state,
    fetchAllAppChargingTokenDesignsState: RequestState.LOADING,
  }
}
export const fetchAllAppChargingTokenDesignsSuccess = (
  state,
  { appChargingTokenDesigns },
) => {
  return {
    ...state,
    allAppChargingTokenDesigns: appChargingTokenDesigns,
    fetchAllAppChargingTokenDesignsState: RequestState.SUCCEEDED,
  }
}
export const fetchAllAppChargingTokenDesignsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAllAppChargingTokenDesignsState: requestState,
  }
}

export const addAppChargingTokenDesignLoading = (state) => {
  return {
    ...state,
    addAppChargingTokenDesignRequestState: RequestState.LOADING,
  }
}
export const addAppChargingTokenDesignSuccess = (state, { design }) => {
  return {
    ...state,
    addAppChargingTokenDesignRequestState: RequestState.SUCCEEDED,
    appChargingTokenDesigns: [design, ...state.appChargingTokenDesigns],
    count: state.count + 1,
  }
}

export const addAppChargingTokenDesignError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addAppChargingTokenDesignRequestState: requestState,
  }
}

export const editAppChargingTokenDesignLoading = (state) => {
  return {
    ...state,
    editAppChargingTokenDesignRequestState: RequestState.LOADING,
  }
}

export const editAppChargingTokenDesignSuccess = (state, { design }) => {
  const updatedDesign = state.appChargingTokenDesigns.map((oldDesign) => {
    const isMatch = oldDesign._id === design._id

    return isMatch
      ? {
          ...oldDesign,
          name: design.name,
          base64Image: design.design,
        }
      : oldDesign
  })

  return {
    ...state,
    appChargingTokenDesigns: updatedDesign,
    editAppChargingTokenDesignRequestState: RequestState.SUCCEEDED,
  }
}

export const editAppChargingTokenDesignError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    editAppChargingTokenDesignRequestState: requestState,
  }
}

export const clearDesigns = (state) => {
  return {
    ...state,
    appChargingTokenDesigns: [],
    paginationOffset: 0,
    fetchAppChargingTokenDesignsState: RequestState.UNINITIALIZED,
  }
}

export const deleteAppChargingTokenDesignLoading = (state) => {
  return {
    ...state,
    deleteAppChargingTokenDesignRequestState: RequestState.LOADING,
  }
}
export const deleteAppChargingTokenDesignSuccess = (state, { id }) => {
  const updatedDesigns = state.appChargingTokenDesigns.filter(
    (design) => design._id !== id,
  )

  return {
    ...state,
    appChargingTokenDesigns: updatedDesigns,
    count: state.count - 1 < 0 ? 0 : state.count - 1,
    deleteAppChargingTokenDesignRequestState: RequestState.SUCCEEDED,
  }
}
export const deleteAppChargingTokenDesignError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteAppChargingTokenDesignRequestState: requestState,
  }
}
export const getAppChargingTokenDesignsCountLoading = (state) => {
  return {
    ...state,
    getAppChargingTokenCountRequestState: RequestState.LOADING,
  }
}
export const getAppChargingTokenDesignsCountSuccess = (
  state,
  { appChargingTokenCount },
) => {
  return {
    ...state,
    count: appChargingTokenCount,
    getAppChargingTokenCountRequestState: RequestState.SUCCEEDED,
  }
}

export const getAppChargingTokenDesignsCountError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    getAppChargingTokenCountRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AppChargingTokenDesignTypes.FETCH_APP_CHARGING_TOKEN_DESIGNS_LOADING]:
    fetchAppChargingTokenDesignsLoading,
  [AppChargingTokenDesignTypes.FETCH_APP_CHARGING_TOKEN_DESIGNS_SUCCESS]:
    fetchAppChargingTokenDesignsSuccess,
  [AppChargingTokenDesignTypes.FETCH_APP_CHARGING_TOKEN_DESIGNS_ERROR]:
    fetchAppChargingTokenDesignsError,
  [AppChargingTokenDesignTypes.FETCH_ALL_APP_CHARGING_TOKEN_DESIGNS_LOADING]:
    fetchAllAppChargingTokenDesignsLoading,
  [AppChargingTokenDesignTypes.FETCH_ALL_APP_CHARGING_TOKEN_DESIGNS_SUCCESS]:
    fetchAllAppChargingTokenDesignsSuccess,
  [AppChargingTokenDesignTypes.FETCH_ALL_APP_CHARGING_TOKEN_DESIGNS_ERROR]:
    fetchAllAppChargingTokenDesignsError,
  [AppChargingTokenDesignTypes.ADD_APP_CHARGING_TOKEN_DESIGN_LOADING]:
    addAppChargingTokenDesignLoading,
  [AppChargingTokenDesignTypes.ADD_APP_CHARGING_TOKEN_DESIGN_SUCCESS]:
    addAppChargingTokenDesignSuccess,
  [AppChargingTokenDesignTypes.ADD_APP_CHARGING_TOKEN_DESIGN_ERROR]:
    addAppChargingTokenDesignError,
  [AppChargingTokenDesignTypes.EDIT_APP_CHARGING_TOKEN_DESIGN_LOADING]:
    editAppChargingTokenDesignLoading,
  [AppChargingTokenDesignTypes.EDIT_APP_CHARGING_TOKEN_DESIGN_SUCCESS]:
    editAppChargingTokenDesignSuccess,
  [AppChargingTokenDesignTypes.EDIT_APP_CHARGING_TOKEN_DESIGN_ERROR]:
    editAppChargingTokenDesignError,
  [AppChargingTokenDesignTypes.CLEAR_DESIGNS]: clearDesigns,
  [AppChargingTokenDesignTypes.DELETE_APP_CHARGING_TOKEN_DESIGN_LOADING]:
    deleteAppChargingTokenDesignLoading,
  [AppChargingTokenDesignTypes.DELETE_APP_CHARGING_TOKEN_DESIGN_SUCCESS]:
    deleteAppChargingTokenDesignSuccess,
  [AppChargingTokenDesignTypes.DELETE_APP_CHARGING_TOKEN_DESIGN_ERROR]:
    deleteAppChargingTokenDesignError,
  [AppChargingTokenDesignTypes.GET_APP_CHARGING_TOKEN_DESIGNS_COUNT_LOADING]:
    getAppChargingTokenDesignsCountLoading,
  [AppChargingTokenDesignTypes.GET_APP_CHARGING_TOKEN_DESIGNS_COUNT_SUCCESS]:
    getAppChargingTokenDesignsCountSuccess,
  [AppChargingTokenDesignTypes.GET_APP_CHARGING_TOKEN_DESIGNS_COUNT_ERROR]:
    getAppChargingTokenDesignsCountError,
})
