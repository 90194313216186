const getRolesCount = (state) => {
  return state.main.roleState.count
}
const getfetchRolesCountRequestState = (state) => {
  return state.main.roleState.fetchRolesCountRequestState
}
const getUpdateRoleRequestState = (state) => {
  return state.main.roleState.updateRoleRequestState
}
const getFetchRolesRequestState = (state) => {
  return state.main.roleState.fetchRolesRequestState
}
const getRoles = (state) => {
  return state.main.roleState.roles
}
const getCreateRoleRequestState = (state) => {
  return state.main.roleState.createRoleRequestState
}
const getDeleteRoleRequestState = (state) => {
  return state.main.roleState.deleteRoleRequestState
}

const getAssignedAdmins = (state) => {
  return state.main.roleState.assignedAdmins
}
const getFetchRoleAdminListRequestState = (state) => {
  return state.main.roleState.fetchRoleAdminListRequestState
}
const getAssignedAdminsPaginationOffset = (state) => {
  return state.main.roleState.assignedAdminsPaginationOffset
}
const getRolesWithAdmins = (state) => {
  return state.main.roleState.rolesWithAdmins
}
const getfetchRolesWithAdminsRequestState = (state) => {
  return state.main.roleState.fetchRolesWithAdminsRequestState
}
const getRolesPaginationOffset = (state) => {
  return state.main.roleState.rolesPaginationOffset
}
const RoleSelectors = {
  getfetchRolesCountRequestState,
  getRolesCount,
  getUpdateRoleRequestState,
  getFetchRolesRequestState,
  getRoles,
  getCreateRoleRequestState,
  getDeleteRoleRequestState,
  getAssignedAdmins,
  getFetchRoleAdminListRequestState,
  getAssignedAdminsPaginationOffset,
  getRolesWithAdmins,
  getfetchRolesWithAdminsRequestState,
  getRolesPaginationOffset,
}

export default RoleSelectors
