import { IFSkeleton, IFText } from 'Components'
import { Colors } from 'Theme'
import { formatNumber } from 'Utils/Calculations'
import Styles from './IFCount.module.css'

const IFCount = ({ color, isLoading, count }) => {
  return (
    <div
      className={Styles.CountContainer}
      style={{
        backgroundColor: isLoading ? Colors.SkeletonColor : Colors.primaryLight,
      }}
    >
      {isLoading ? (
        <IFSkeleton variant="circular" />
      ) : (
        <IFText className={Styles.Count} style={{ color: color }}>
          {formatNumber(count)}
        </IFText>
      )}
    </div>
  )
}

export default IFCount
