import { put, call, spawn } from 'redux-saga/effects'
import {
  vouchersApiService,
  handleError,
  handleSuccess,
} from 'Services/ApiService.js'
import voucherActions from 'Stores/Voucher/Actions'
import i18n from 'i18next'
import { encodeObjectToBase64 } from '../Utils/Base64Functions'

export function* fetchVouchers({ filter, offset, shouldShowError }) {
  yield put(voucherActions.fetchVouchersListLoading())
  try {
    if (filter !== null) {
      filter = encodeObjectToBase64(filter)
    }
    if (offset == 0) yield spawn(fetchVouchersCount, filter)
    const { data } = yield call(vouchersApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      voucherActions.fetchVouchersListSuccess(
        offset,
        data.vouchers,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(voucherActions.fetchVouchersListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* addVoucher({ voucher }) {
  yield put(voucherActions.addVoucherLoading())
  try {
    const { data } = yield call(vouchersApiService.post, '/', {
      voucherName: voucher.voucherName,
      voucherCode: voucher.voucherCode,
      voucherPoints: voucher.voucherPoints,
      voucherMaxRedeemed: voucher.voucherMaxRedeemed,
      voucherMaxRedeemCountPerUser: voucher.voucherMaxRedeemCountPerUser,
      voucherValidity: voucher.voucherValidity,
      voucherExpiry: voucher.voucherExpiry,
      country: voucher.country,
    })
    yield put(voucherActions.addVoucherSuccess(data))
    handleSuccess(i18n.t('VouchersPage.AddVoucherSuccessMessage'))
  } catch (e) {
    yield put(voucherActions.addVoucherFail(e))
    handleError(e)
  }
}
export function* deleteVoucher({ voucherId }) {
  yield put(voucherActions.deleteVoucherLoading())
  try {
    yield call(vouchersApiService.delete, `/${voucherId}`)
    yield put(voucherActions.deleteVoucherSuccess(voucherId))
    handleSuccess(i18n.t('VouchersPage.deleteVoucherSuccessMessage'))
  } catch (e) {
    yield put(voucherActions.deleteVoucherFail(e))
    handleError(e)
  }
}
export function* updateVoucher({ voucherId, newVoucher, index, onResponse }) {
  try {
    yield put(voucherActions.updateVoucherLoading())
    const { data } = yield call(vouchersApiService.patch, `/${voucherId}`, {
      ...newVoucher,
    })
    yield put(voucherActions.updateVoucherSuccess(index, data))
    onResponse(index)
  } catch (e) {
    yield put(voucherActions.updateVoucherFail(e))
    onResponse(index)
    handleError(e)
  }
}
export function* fetchVouchersCount(filter) {
  yield put(voucherActions.fetchVouchersCountLoading())
  try {
    const { data } = yield call(vouchersApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(voucherActions.fetchVouchersCountSuccess(data.vouchersCount))
  } catch (e) {
    yield put(voucherActions.fetchVouchersCountFail(e))
    handleError(e)
  }
}
export function* fetchAllVouchers() {
  yield put(voucherActions.fetchAllVouchersLoading())
  try {
    const { data } = yield call(vouchersApiService.get, `/allVouchers`)
    yield put(voucherActions.fetchAllVouchersSuccess(data))
  } catch (e) {
    yield put(voucherActions.fetchAllVouchersError(e))
    if (!shouldShowError) handleError(e)
  }
}
