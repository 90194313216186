import { takeLatest, all, takeEvery, take } from 'redux-saga/effects'
import { AuthTypes } from '../Stores/Auth/Actions'
import { AdminTypes } from '../Stores/Admin/Actions'
import { UserTypes } from '../Stores/User/Actions'
import { TransactionTypes } from 'Stores/Transaction/Actions'
import { NotificationTypes } from '../Stores/Notification/Actions'
import { VoucherTypes } from '../Stores/Voucher/Actions'
import { RoleTypes } from '../Stores/Role/Actions'
import { DashboardTypes } from 'Stores/Dashboard/Actions'
import { CountriesTypes } from 'Stores/Countries-Currencies/Actions'
import { AppChargingTokenTypes } from '../Stores/AppChargingToken/Actions'
import { AppChargingTokenDesignTypes } from '../Stores/AppChargingTokenDesign/Actions'

import {
  login,
  getUser,
  logout,
  fetchEditingAdminRole,
  changePassword,
  fetchAdminDetails,
  setCurrentUserNewName,
  fetchAdminActionsList,
  resetPasswordVerification,
  resetPassword,
} from './AuthSaga'

import {
  fetchAdminList,
  updateAdminRole,
  deleteAdmin,
  fetchAdminsCount,
  inviteAdmin,
  fetchInviteAdminList,
  deleteInviteAdmin,
  sendAdminEmail,
  fetchInviteAdmin,
  createAdmin,
  fetchAdminsInvitationsCount,
} from './AdminSaga'

import {
  fetchUserList,
  setCurrentUserTransactionRefund,
  setCurrentUserNewBalance,
  setCurrentUserChargingTokenActive,
  addCurrentUserNewCard,
  toggleCurrentUserIsActive,
  fetchUserDetails,
  fetchUserTransactionList,
  setCurrentUserNewPhone,
  reverseUserChargeTransaction,
  fetchUserPoints,
  fetchUserPaymentSessionList,
  deleteCurrentUserPaymentCard,
  fetchAdminActionsUsersList,
} from './UserSaga'

import {
  fetchNotificationGroups,
  fetchNotifications,
  editNotification,
  scheduleNotification,
  fetchGroupsCount,
  fetchNotificationsCount,
  fetchGroups,
  editGroup,
  deleteGroup,
  createGroup,
  fetchQueryCount,
} from './NotificationSaga'
import {
  fetchTransactionsList,
  fetchTransactionsMetrics,
  fetchEnergyConsumptionChart,
  fetchAllSubscriptions,
} from './TransactionSaga'
import {
  fetchVouchers,
  addVoucher,
  updateVoucher,
  deleteVoucher,
  fetchAllVouchers,
} from './VoucherSaga'

import { fetchDashboard } from './DashboardSaga'
import {
  fetchRolesCount,
  updateRole,
  fetchRoles,
  createRole,
  deleteRole,
  fetchRoleAdminList,
  fetchRolesWithAdmins,
} from './RoleSaga'

import {
  fetchAvailableCurrencies,
  fetchCountriesList,
  fetchCountriesListCount,
  fetchCountryBounds,
  fetchCurrenciesList,
  fetchCurrenciesListCount,
  updateCountry,
  fetchCreateCountryBounds,
  addCountry,
  updateCurrency,
  addCurrency,
} from './CountrySaga'

import {
  fetchAppChargingTokens,
  editAppChargingToken,
  deleteAppChargingToken,
  createAppChargingToken,
  createAppChargingTokenBulk,
  downloadAppChargingTokenSampleFile,
  getAppChargingTokensCount,
} from './AppChargingTokenSaga'

import {
  fetchAppChargingTokenDesigns,
  fetchAllAppChargingTokenDesigns,
  addAppChargingTokenDesign,
  editAppChargingTokenDesign,
  getAppChargingTokenDesignsCount,
  deleteAppChargingTokenDesign,
} from './AppChargingTokenDesignSaga'

export default function* root() {
  yield all([
    takeLatest(AuthTypes.FETCH_ADMIN_ACTIONS_LIST, fetchAdminActionsList),
  ])

  yield all([takeLatest(AuthTypes.LOGIN, login)])
  yield all([takeLatest(AuthTypes.GET_USER, getUser)])
  yield all([takeLatest(AuthTypes.LOGOUT, logout)])
  yield all([
    takeLatest(AuthTypes.FETCH_EDITING_ADMIN_ROLE, fetchEditingAdminRole),
  ])
  yield all([takeLatest(AuthTypes.CHANGE_PASSWORD, changePassword)])
  yield all([takeLatest(AuthTypes.FETCH_ADMIN_DETAILS, fetchAdminDetails)])
  yield all([
    takeLatest(AuthTypes.SET_CURRENT_USER_NEW_NAME, setCurrentUserNewName),
  ])
  yield all([
    takeLatest(
      AuthTypes.RESET_PASSWORD_VERIFICATION,
      resetPasswordVerification,
    ),
  ])
  yield all([takeLatest(AuthTypes.RESET_PASSWORD, resetPassword)])

  yield all([takeLatest(UserTypes.FETCH_USER_LIST, fetchUserList)])
  yield all([takeLatest(UserTypes.FETCH_USER_DETAILS, fetchUserDetails)])

  yield all([
    takeLatest(
      UserTypes.FETCH_ADMIN_ACTIONS_USERS_LIST,
      fetchAdminActionsUsersList,
    ),
  ])

  yield all([
    takeLatest(UserTypes.FETCH_USER_TRANSACTION_LIST, fetchUserTransactionList),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_CHARGING_TOKEN_ACTIVE,
      setCurrentUserChargingTokenActive,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_TRANSACTION_REFUND,
      setCurrentUserTransactionRefund,
    ),
  ])
  yield all([takeLatest(UserTypes.FETCH_USER_POINTS, fetchUserPoints)])

  yield all([takeLatest(AdminTypes.FETCH_ADMIN_LIST, fetchAdminList)])
  yield all([takeLatest(AdminTypes.FETCH_ADMINS_COUNT, fetchAdminsCount)])

  yield all([takeLatest(AdminTypes.UPDATE_ADMIN_ROLE, updateAdminRole)])
  yield all([takeLatest(AdminTypes.DELETE_ADMIN, deleteAdmin)])
  yield all([takeLatest(AdminTypes.INVITE_ADMIN, inviteAdmin)])
  yield all([takeLatest(AdminTypes.DELETE_INVITE_ADMIN, deleteInviteAdmin)])
  yield all([
    takeLatest(AdminTypes.FETCH_INVITE_ADMIN_LIST, fetchInviteAdminList),
  ])
  yield all([takeLatest(AdminTypes.SEND_ADMIN_EMAIL, sendAdminEmail)])
  yield all([takeLatest(AdminTypes.FETCH_INVITE_ADMIN, fetchInviteAdmin)])
  yield all([takeLatest(AdminTypes.CREATE_ADMIN, createAdmin)])
  yield all([
    takeLatest(
      AdminTypes.FETCH_ADMINS_INVITATIONS_COUNT,
      fetchAdminsInvitationsCount,
    ),
  ])

  yield all([takeLatest(RoleTypes.FETCH_ROLES_COUNT, fetchRolesCount)])
  yield all([takeLatest(RoleTypes.UPDATE_ROLE, updateRole)])
  yield all([takeLatest(RoleTypes.FETCH_ROLES, fetchRoles)])
  yield all([takeLatest(RoleTypes.CREATE_ROLE, createRole)])
  yield all([takeLatest(RoleTypes.DELETE_ROLE, deleteRole)])
  yield all([takeLatest(RoleTypes.FETCH_ROLE_ADMIN_LIST, fetchRoleAdminList)])
  yield all([
    takeLatest(RoleTypes.FETCH_ROLES_WITH_ADMINS, fetchRolesWithAdmins),
  ])

  yield all([
    takeLatest(UserTypes.ADD_CURRENT_USER_NEW_CARD, addCurrentUserNewCard),
  ])

  yield all([
    takeLatest(
      UserTypes.TOGGLE_CURRENT_USER_IS_ACTIVE,
      toggleCurrentUserIsActive,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.DELETE_CURRENT_USER_PAYMENT_CARD,
      deleteCurrentUserPaymentCard,
    ),
  ])
  yield all([
    takeLatest(
      UserTypes.SET_CURRENT_USER_NEW_BALANCE,
      setCurrentUserNewBalance,
    ),
  ])

  yield all([
    takeLatest(UserTypes.SET_CURRENT_USER_NEW_PHONE, setCurrentUserNewPhone),
  ])

  yield all([
    takeLatest(
      UserTypes.REVERSE_USER_CHARGE_TRANSACTION,
      reverseUserChargeTransaction,
    ),
  ])

  yield all([
    takeLatest(
      NotificationTypes.FETCH_NOTIFICATION_GROUPS,
      fetchNotificationGroups,
    ),
  ])
  yield all([
    takeLatest(NotificationTypes.FETCH_NOTIFICATIONS, fetchNotifications),
  ])
  yield all([takeLatest(NotificationTypes.EDIT_NOTIFICATION, editNotification)])
  yield all([
    takeLatest(NotificationTypes.SCHEDULE_NOTIFICATION, scheduleNotification),
  ])
  yield all([
    takeLatest(
      NotificationTypes.FETCH_NOTIFICATIONS_COUNT,
      fetchNotificationsCount,
    ),
  ])
  yield all([
    takeLatest(NotificationTypes.FETCH_GROUPS_COUNT, fetchGroupsCount),
  ])
  yield all([takeLatest(NotificationTypes.FETCH_GROUPS, fetchGroups)])
  yield all([takeLatest(NotificationTypes.EDIT_GROUP, editGroup)])
  yield all([takeLatest(NotificationTypes.DELETE_GROUP, deleteGroup)])
  yield all([takeLatest(NotificationTypes.CREATE_GROUP, createGroup)])
  yield all([takeLatest(NotificationTypes.FETCH_QUERY_COUNT, fetchQueryCount)])

  yield all([
    takeLatest(TransactionTypes.FETCH_TRANSACTIONS_LIST, fetchTransactionsList),
  ])

  yield all([
    takeLatest(
      TransactionTypes.FETCH_TRANSACTIONS_METRICS,
      fetchTransactionsMetrics,
    ),
  ])
  yield all([
    takeLatest(
      TransactionTypes.FETCH_ENERGY_CONSUMPTION_CHART,
      fetchEnergyConsumptionChart,
    ),
  ])

  yield all([takeLatest(VoucherTypes.FETCH_VOUCHERS, fetchVouchers)])

  yield all([takeLatest(VoucherTypes.ADD_VOUCHER, addVoucher)])

  yield all([takeEvery(VoucherTypes.UPDATE_VOUCHER, updateVoucher)])
  yield all([takeEvery(VoucherTypes.DELETE_VOUCHER, deleteVoucher)])
  yield all([takeLatest(VoucherTypes.FETCH_ALL_VOUCHERS, fetchAllVouchers)])

  yield all([
    takeLatest(
      UserTypes.FETCH_USER_PAYMENT_SESSION_LIST,
      fetchUserPaymentSessionList,
    ),
  ])
  yield all([takeLatest(DashboardTypes.FETCH_DASHBOARD, fetchDashboard)])

  yield all([
    takeLatest(
      CountriesTypes.FETCH_COUNTRIES_LIST_COUNT,
      fetchCountriesListCount,
    ),
  ])
  yield all([
    takeLatest(CountriesTypes.FETCH_COUNTRIES_LIST, fetchCountriesList),
  ])
  yield all([
    takeLatest(CountriesTypes.FETCH_CURRENCIES_LIST, fetchCurrenciesList),
  ])
  yield all([
    takeLatest(
      CountriesTypes.FETCH_CURRENCIES_LIST_COUNT,
      fetchCurrenciesListCount,
    ),
  ])
  yield all([
    takeLatest(CountriesTypes.FETCH_COUNTRY_BOUNDS, fetchCountryBounds),
  ])
  yield all([takeLatest(CountriesTypes.UPDATE_COUNTRY, updateCountry)])
  yield all([
    takeLatest(
      CountriesTypes.FETCH_AVAILABLE_CURRENCIES,
      fetchAvailableCurrencies,
    ),
  ])
  yield all([
    takeLatest(
      CountriesTypes.FETCH_CREATE_COUNTRY_BOUNDS,
      fetchCreateCountryBounds,
    ),
  ])
  yield all([takeLatest(CountriesTypes.ADD_COUNTRY, addCountry)])
  yield all([takeLatest(CountriesTypes.UPDATE_CURRENCY, updateCurrency)])
  yield all([takeLatest(CountriesTypes.ADD_CURRENCY, addCurrency)])

  yield all([
    takeLatest(TransactionTypes.FETCH_ALL_SUBSCRIPTIONS, fetchAllSubscriptions),
  ])

  yield all([
    takeLatest(
      AppChargingTokenTypes.FETCH_APP_CHARGING_TOKENS,
      fetchAppChargingTokens,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.EDIT_APP_CHARGING_TOKEN,
      editAppChargingToken,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.FETCH_APP_CHARGING_TOKEN_DESIGNS,
      fetchAppChargingTokenDesigns,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.FETCH_ALL_APP_CHARGING_TOKEN_DESIGNS,
      fetchAllAppChargingTokenDesigns,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.DELETE_APP_CHARGING_TOKEN,
      deleteAppChargingToken,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.ADD_APP_CHARGING_TOKEN_DESIGN,
      addAppChargingTokenDesign,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN,
      createAppChargingToken,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_BULK,
      createAppChargingTokenBulk,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.DOWNLOAD_APP_CHARGING_TOKEN_SAMPLE_FILE,
      downloadAppChargingTokenSampleFile,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenTypes.GET_APP_CHARGING_TOKENS_COUNT,
      getAppChargingTokensCount,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.DELETE_APP_CHARGING_TOKEN_DESIGN,
      deleteAppChargingTokenDesign,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.GET_APP_CHARGING_TOKEN_DESIGNS_COUNT,
      getAppChargingTokenDesignsCount,
    ),
  ])
  yield all([
    takeLatest(
      AppChargingTokenDesignTypes.EDIT_APP_CHARGING_TOKEN_DESIGN,
      editAppChargingTokenDesign,
    ),
  ])
}
