import Styles from './PushNotificationsPage.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { IFButton, IFText, DevicePreview, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useState, useEffect } from 'react'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import RequestState from 'Enums/RequestState'
import { splitCamelCase } from 'Utils/StringFunctions'
import PropTypes from 'prop-types'
import { styled as muiStyled } from '@mui/system'
import Checkbox from '@mui/material/Checkbox'
import { DatePicker } from 'rsuite'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

function pickerDate(inputDate) {
  const date = new Date(inputDate)

  const formattedDate = date.toLocaleDateString(undefined, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })

  const formattedTime = date.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return `${formattedDate} ${formattedTime}`
}

const MiddlePanel = styled.div`
  background-color: ${Colors.white};
`

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: Colors.primary,
  },
}))
const CssTextField = muiStyled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    height: '39px',
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
  },
}))
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .rs-picker-toggle-placeholder': {
    color: Colors.text,
  },
  '& .rs-picker-toggle.rs-btn': {
    borderColor: `#c4c4c4 !important`,
    borderRadius: '5px !important',
  },
  '& .rs-picker-toggle-value': { color: `${Colors.text} !important` },
  '& .rs-picker-toggle-textbox': {
    height: '39px',
  },
}))

const CssTextFieldTall = muiStyled(TextField)(({ theme }) => ({
  textAlign: 'left',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    height: '5rem',
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  color: Colors.text,
  '.Mui-focusVisible &': {
    outline: `2px auto ${Colors.primary}`,
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: Colors.primary,
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  color: Colors.text,
  '&::before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: Colors.primary,
  },
})
const PushNotificationsPage = ({
  notificationGroups,
  fetchNotificationGroups,
  fetchNotificationGroupsRequestState,
  isEdit,
  data,
  editNotification,
  editNotificationRequestState,
  scheduleNotification,
  scheduleNotificationRequestState,
}) => {
  const [isNow, setIsNow] = useState(true)
  const [isSchedule, setIsSchedule] = useState(false)
  const [isDisablePicker, setIsDisablePicker] = useState(true)

  const { t } = useTranslation()
  useEffect(() => {
    fetchNotificationGroups()
    if (isEdit) {
      setEnglishTitle(data.original.title.en)
      setEnglishBody(data.original.message.en)
      setArabicTitle(data.original.title.ar)
      setArabicBody(data.original.message.ar)
      setGroup(data.original.notificationGroups)
      setRedirectInput(
        data.original.redirectUrl ? data.original.redirectUrl : '',
      )
      setScheduledAt(data.original.scheduledAt)
    }
  }, [])

  useEffect(() => {
    if (scheduleNotificationRequestState === RequestState.SUCCEEDED) {
      setEnglishTitle('')
      setEnglishBody('')
      setArabicTitle('')
      setArabicBody('')
      setGroup([])
      setRedirectInput('')
    }
  }, [scheduleNotificationRequestState])

  const [englishTitle, setEnglishTitle] = useState('')
  const [englishTitleError, setEnglishTitleError] = useState('')
  const [englishBody, setEnglishBody] = useState('')
  const [englishBodyError, setEnglishBodyError] = useState('')
  const [arabicTitle, setArabicTitle] = useState('')
  const [arabicTitleError, setArabicTitleError] = useState('')
  const [arabicBody, setArabicBody] = useState('')
  const [arabicBodyError, setArabicBodyError] = useState('')
  const [group, setGroup] = useState([])
  const [groupError, setGroupError] = useState('')
  const [scheduledAt, setScheduledAt] = useState('')
  const [scheduledAtError, setScheduledAtError] = useState('')
  const [redirectInput, setRedirectInput] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [progress, setProgress] = useState(0)

  function isUrlValid(string) {
    try {
      new URL(string)
      return true
    } catch (err) {
      return false
    }
  }

  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    )
  }

  const editNotificationHandler = () => {
    const notification = {
      title: {
        en: englishTitle,
        ar: arabicTitle,
      },
      message: {
        en: englishBody,
        ar: arabicBody,
      },
      notificationGroups: group,
      scheduledAt: scheduledAt,
      ...(redirectInput
        ? isUrlValid(redirectInput)
          ? { redirectUrl: redirectInput }
          : { stationId: redirectInput }
        : {}),
    }
    editNotification(data.original._id, notification)
  }

  const scheduleNotificationHandler = () => {
    const time = isNow ? new Date().toISOString() : scheduledAt
    const notification = {
      notification: {
        title: {
          en: englishTitle,
          ar: arabicTitle,
        },
        message: {
          en: englishBody,
          ar: arabicBody,
        },
        ...(redirectInput
          ? isUrlValid(redirectInput)
            ? { redirectUrl: redirectInput }
            : { stationId: redirectInput }
          : {}),
      },
      notificationGroups: group,
      scheduledAt: time,
    }
    scheduleNotification(notification)
  }

  useEffect(() => {
    if (
      fetchNotificationGroupsRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)

    if (fetchNotificationGroupsRequestState === RequestState.SUCCEEDED)
      setProgress(100)
    if (
      fetchNotificationGroupsRequestState !== RequestState.LOADING &&
      fetchNotificationGroupsRequestState !== RequestState.UNINITIALIZED &&
      fetchNotificationGroupsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchNotificationGroupsRequestState])

  const handleRadioChange = (event) => {
    const selectedValue = event.target.value
    if (selectedValue === 'Now') {
      setIsNow(true)
      setIsSchedule(false)
      setIsDisablePicker(true)
    } else {
      setIsSchedule(true)
      setIsNow(false)
      setIsDisablePicker(false)
    }
  }
  return (
    <MiddlePanel
      className={Styles.MiddlePanel}
      style={{
        borderColor: Colors.white,
        height: isEdit ? '44rem' : '50rem',
      }}
    >
      <div className={Styles.BottomPanel}>
        <div className={Styles.Inputs}>
          <IFText
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
            className={Styles.InputTitle}
          >
            {t('PushNotificationsPanel.EnglishTitle')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'30px'} />
            </div>
          ) : (
            <CssTextField
              variant="outlined"
              value={englishTitle}
              inputProps={{
                maxLength: 65,
                style: { fontSize: '14px', fontFamily: 'ProximaNova' },
              }}
              onChange={(e) => {
                setEnglishTitle(e.target.value)

                e.target.value.length === 0
                  ? setEnglishTitleError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setEnglishTitleError(t('PushNotificationsPanel.MinTitle'))
                  : setEnglishTitleError('')

                const submit =
                  e.target.value.length > 2 &&
                  englishBody.length > 2 &&
                  arabicTitle.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setEnglishTitleError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setEnglishTitleError(t('PushNotificationsPanel.MinTitle'))
                  : setEnglishTitleError('')

                const submit =
                  e.target.value.length > 2 &&
                  englishBody.length > 2 &&
                  arabicTitle.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
            />
          )}
          <div className={Styles.ErrorContainer}>
            {englishTitleError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{englishTitleError}</IFText>
            ) : null}
          </div>

          <IFText
            className={Styles.InputTitle}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
          >
            {t('PushNotificationsPanel.EnglishBody')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'5rem'} />
            </div>
          ) : (
            <CssTextFieldTall
              variant="outlined"
              multiline
              rows={3}
              value={englishBody}
              onChange={(e) => {
                setEnglishBody(e.target.value)

                e.target.value.length === 0
                  ? setEnglishBodyError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setEnglishBodyError(t('PushNotificationsPanel.MinBody'))
                  : setEnglishBodyError('')

                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  arabicTitle.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setEnglishBodyError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setEnglishBodyError(t('PushNotificationsPanel.MinBody'))
                  : setEnglishBodyError('')
                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  arabicTitle.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              inputProps={{
                maxLength: 178,
                style: { fontSize: '14px', fontFamily: 'ProximaNova' },
              }}
            />
          )}
          <div className={Styles.ErrorContainer}>
            {englishBodyError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{englishBodyError}</IFText>
            ) : null}
          </div>

          <IFText
            className={Styles.InputTitle}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
          >
            {t('PushNotificationsPanel.ArabicTitle')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'30px'} />
            </div>
          ) : (
            <CssTextField
              variant="outlined"
              value={arabicTitle}
              onChange={(e) => {
                setArabicTitle(e.target.value)

                e.target.value.length === 0
                  ? setArabicTitleError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setArabicTitleError(t('PushNotificationsPanel.MinTitle'))
                  : setArabicTitleError('')
                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  englishBody.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setArabicTitleError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setArabicTitleError(t('PushNotificationsPanel.MinTitle'))
                  : setArabicTitleError('')
                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  englishBody.length > 2 &&
                  arabicBody.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              inputProps={{
                maxLength: 65,
                style: { fontSize: '14px', fontFamily: 'ProximaNova' },
              }}
            />
          )}
          <div className={Styles.ErrorContainer}>
            {arabicTitleError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{arabicTitleError}</IFText>
            ) : null}
          </div>

          <IFText
            className={Styles.InputTitle}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
          >
            {t('PushNotificationsPanel.ArabicBody')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'5rem'} />
            </div>
          ) : (
            <CssTextFieldTall
              variant="outlined"
              multiline
              rows={3}
              value={arabicBody}
              onChange={(e) => {
                setArabicBody(e.target.value)
                e.target.value.length === 0
                  ? setArabicBodyError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setArabicBodyError(t('PushNotificationsPanel.MinBody'))
                  : setArabicBodyError('')

                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  englishBody.length > 2 &&
                  arabicTitle.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setArabicBodyError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setArabicBodyError(t('PushNotificationsPanel.MinBody'))
                  : setArabicBodyError('')

                const submit =
                  e.target.value.length > 2 &&
                  englishTitle.length > 2 &&
                  englishBody.length > 2 &&
                  arabicTitle.length > 2 &&
                  group.length > 0
                setCanSubmit(submit)
              }}
              inputProps={{
                maxLength: 178,
                style: { fontSize: '14px', fontFamily: 'ProximaNova' },
              }}
            />
          )}
          <div className={Styles.ErrorContainer}>
            {arabicBodyError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{arabicBodyError}</IFText>
            ) : null}
          </div>

          <IFText
            className={Styles.InputTitle}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
          >
            {t('PushNotificationsPanel.Group')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'3.5rem'} />
            </div>
          ) : (
            <Box>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: Colors.primary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: Colors.primary,
                    },
                  },
                }}
              >
                <Select
                  multiple
                  variant="outlined"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={group}
                  style={{ fontSize: '14px' }}
                  onChange={(e) => {
                    setGroup(e.target.value)
                    e.target.value.length === 0
                      ? setGroupError(t('PushNotificationsPanel.Required'))
                      : setGroupError('')

                    const submit =
                      e.target.value.length > 0 &&
                      englishTitle.length > 2 &&
                      englishBody.length > 2 &&
                      arabicTitle.length > 2 &&
                      arabicBody.length > 2
                    setCanSubmit(submit)
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0
                      ? setGroupError(t('PushNotificationsPanel.Required'))
                      : setGroupError('')
                    const submit =
                      e.target.value.length > 0 &&
                      englishTitle.length > 2 &&
                      englishBody.length > 2 &&
                      arabicTitle.length > 2 &&
                      arabicBody.length > 2
                    setCanSubmit(submit)
                  }}
                  input={
                    <StyledOutlinedInput
                      name="group"
                      id="outlined-group-simple"
                      style={{ fontSize: '14px', color: Colors.text }}
                    />
                  }
                  renderValue={(selected) => selected.join(', ')}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  {notificationGroups.map((notifGroup) => (
                    <MenuItem key={notifGroup.name} value={notifGroup.name}>
                      <Checkbox checked={group.indexOf(notifGroup.name) > -1} />
                      <IFText className={Styles.MenuItemText}>
                        {splitCamelCase(notifGroup.name)}
                      </IFText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
          <div className={Styles.ErrorContainer}>
            {groupError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{groupError}</IFText>
            ) : null}
          </div>

          <IFText
            className={Styles.InputTitle}
            loadSkeleton={
              fetchNotificationGroupsRequestState === RequestState.LOADING
            }
            skeletonWidth={'7rem'}
          >
            {t('PushNotificationsPanel.StationId')}
          </IFText>
          {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'30px'} />
            </div>
          ) : (
            <CssTextField
              variant="outlined"
              value={redirectInput}
              onChange={(e) => {
                setRedirectInput(e.target.value)
                setCanSubmit(true)
              }}
              onBlur={(e) => {
                setCanSubmit(true)
              }}
              inputProps={{
                style: { fontSize: '14px', fontFamily: 'ProximaNova' },
              }}
            />
          )}
          {!isEdit && (
            <FormControl style={{ marginTop: '18px' }}>
              {fetchNotificationGroupsRequestState === RequestState.LOADING ? (
                <div className={Styles.Skeleton}>
                  <IFSkeleton variant="rectangular" height={'5rem'} />
                </div>
              ) : (
                <div>
                  <IFText className={Styles.InputTitle}>
                    {t('PushNotificationsPanel.sendButton')}
                  </IFText>
                  <RadioGroup
                    style={{
                      color: Colors.text,
                      fontSize: '14px',
                      marginLeft: '8px',
                      fontFamily: 'ProximaNova',
                    }}
                    defaultValue="Now"
                    aria-labelledby="demo-customized-radios"
                    name="customized-radios"
                    row
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="Now"
                      control={<BpRadio />}
                      label={
                        <IFText
                          style={{
                            color: Colors.text,
                            fontSize: '14px',
                          }}
                        >
                          {t('PushNotificationsPanel.sendNow')}
                        </IFText>
                      }
                    />
                    <FormControlLabel
                      value="Later"
                      control={<BpRadio />}
                      label={
                        <IFText
                          style={{
                            color: Colors.text,
                            fontSize: '14px',
                          }}
                        >
                          {t('PushNotificationsPanel.sendLater')}
                        </IFText>
                      }
                    />
                  </RadioGroup>
                </div>
              )}
            </FormControl>
          )}
          <div style={{ paddingTop: 16 }}>
            <IFText
              className={Styles.InputTitle}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.ScheduledAt')}
            </IFText>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: Colors.primary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: Colors.primary,
                    },
                  },
                }}
              >
                {fetchNotificationGroupsRequestState ===
                RequestState.LOADING ? (
                  <div className={Styles.Skeleton}>
                    <IFSkeleton variant="rectangular" height={'5rem'} />
                  </div>
                ) : (
                  <StyledDatePicker
                    className={Styles.Date}
                    initialValue={
                      isEdit
                        ? data.original.scheduledAt
                        : new Date().toISOString()
                    }
                    format="MM/dd/yyyy HH:mm"
                    placeholder={
                      isEdit
                        ? pickerDate(data.original.scheduledAt)
                        : pickerDate(new Date().toISOString())
                    }
                    placement="auto"
                    disabled={isDisablePicker && !isEdit}
                    shouldDisableDate={(currentDate) =>
                      currentDate && currentDate < new Date()
                    }
                    onSelect={(e) => {
                      if (!e) return
                      setScheduledAt(e)

                      if (!e) {
                        setScheduledAtError(
                          t('PushNotificationsPanel.Required'),
                        )
                      } else {
                        setScheduledAtError('')
                      }

                      const submit =
                        e &&
                        englishTitle.length > 2 &&
                        englishBody.length > 2 &&
                        arabicTitle.length > 2 &&
                        arabicBody.length > 2 &&
                        group.length > 0
                      setCanSubmit(submit)
                    }}
                    onBlur={(e) => {
                      if (!e) {
                        setScheduledAtError(
                          t('PushNotificationsPanel.Required'),
                        )
                      } else {
                        setScheduledAtError('')
                      }

                      const submit =
                        e &&
                        englishTitle.length > 2 &&
                        englishBody.length > 2 &&
                        arabicTitle.length > 2 &&
                        arabicBody.length > 2 &&
                        group.length > 0
                      setCanSubmit(submit)
                    }}
                    showMeridian={false}
                  />
                )}
              </FormControl>
            </Box>

            <div className={Styles.ErrorContainer}>
              {scheduledAtError.length > 0 ? (
                <IFText style={{ color: Colors.red }}>
                  {scheduledAtError}
                </IFText>
              ) : null}
            </div>
          </div>
        </div>

        <div className={Styles.DevicePreviewWrapper}>
          <div className={Styles.DevicePreviewTitle}>
            <IFText
              style={{ color: Colors.text }}
              className={Styles.DevicePreviewText}
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              skeletonWidth={'7rem'}
            >
              {t('PushNotificationsPanel.DevicePreviewTitle')}
            </IFText>
          </div>
          <div className={Styles.DevicePreview}>
            <DevicePreview
              title={englishTitle}
              subtitle={englishBody}
              locale="en"
            />
            <div className={Styles.ArabicDevicePreview}>
              <DevicePreview
                title={arabicTitle}
                subtitle={arabicBody}
                locale="ar"
              />
            </div>
          </div>
          <div className={Styles.ButtonWrapper}>
            <IFButton
              loadSkeleton={
                fetchNotificationGroupsRequestState === RequestState.LOADING
              }
              text={
                isEdit ? (
                  <IFText>{t('PushNotificationsPanel.submitButton')}</IFText>
                ) : (
                  <IFText>{t('PushNotificationsPanel.sendButton')}</IFText>
                )
              }
              isDead={
                !canSubmit ||
                editNotificationRequestState === RequestState.LOADING ||
                scheduleNotificationRequestState === RequestState.LOADING
              }
              color={Colors.primary}
              isFill={true}
              className={Styles.SendNotificationButton}
              onClick={
                isEdit ? editNotificationHandler : scheduleNotificationHandler
              }
              isLoading={
                isEdit
                  ? editNotificationRequestState === RequestState.LOADING
                  : scheduleNotificationRequestState === RequestState.LOADING
              }
            />
          </div>
        </div>
      </div>
    </MiddlePanel>
  )
}

PushNotificationsPage.propTypes = {
  notificationGroups: PropTypes.arrayOf(PropTypes.object),
  fetchNotificationGroups: PropTypes.func,
  fetchNotificationGroupsRequestState: PropTypes.number,
  isEdit: PropTypes.bool,
  data: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationGroups: () =>
      dispatch(NotificationActions.fetchNotificationGroups()),
    editNotification: (id, payload) =>
      dispatch(NotificationActions.editNotification(id, payload)),
    scheduleNotification: (payload) =>
      dispatch(NotificationActions.scheduleNotification(payload)),
  }
}

const mapStateToProps = (state) => ({
  notificationGroups: NotificationSelectors.getNotificationGroups(state),
  fetchNotificationGroupsRequestState:
    NotificationSelectors.getFetchNotificationGroupRequestState(state),
  editNotificationRequestState:
    NotificationSelectors.getEditNotificationRequestState(state),
  scheduleNotificationRequestState:
    NotificationSelectors.getScheduleNotificationRequestState(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PushNotificationsPage)
