import React, { useState, useRef, useImperativeHandle } from 'react'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { IFModal } from 'Components'
import { PushNotificationsPage } from 'Containers'
import PropTypes from 'prop-types'

const NotificationModal = React.forwardRef(({ title, isEdit }, ref) => {
  const { t } = useTranslation()
  const modalRef = useRef()
  const [data, setData] = useState([])
  const customDialogContentStyle = {
    height: '600px',
    width: '1000px',
  }

  const show = (row) => {
    if (isEdit) setData(row)
    modalRef.current.show()
  }

  const dismiss = () => {
    modalRef.current.dismiss()
  }

  useImperativeHandle(ref, () => ({
    show,
    dismiss,
  }))

  return (
    <IFModal
      title={title}
      ref={modalRef}
      maxWidth={60}
      dialogContentStyle={customDialogContentStyle}
    >
      <PushNotificationsPage isEdit={isEdit} data={data} />
    </IFModal>
  )
})

NotificationModal.propTypes = {
  title: PropTypes.string,
  isEdit: PropTypes.bool,
}

export default NotificationModal
