import { IFToastMessage } from 'Components'
import { put, call } from 'redux-saga/effects'
import { toaster } from 'rsuite'
import {
  notificationGroupsApiService,
  handleError,
  scheduledNotificationsApiService,
} from 'Services/ApiService.js'
import NotificationsActions from 'Stores/Notification/Actions'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'
import i18n from 'i18next'
import moment from 'moment'
import InfinityEnums from 'Enums/InfinityEnums'

export function* fetchNotificationGroups({}) {
  yield put(NotificationsActions.fetchNotificationGroupsLoading())
  try {
    const { data } = yield call(notificationGroupsApiService.get, '/', {})
    yield put(NotificationsActions.fetchNotificationGroupsSuccess(data))
  } catch (e) {
    yield put(NotificationsActions.fetchNotificationGroupsError(e))
    handleError(e)
  }
}

export function* fetchNotifications({ filter, offset, shouldShowError }) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (offset === 0) {
      yield put(NotificationsActions.fetchNotificationsLoading())
    }
    const { data } = yield call(scheduledNotificationsApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      NotificationsActions.fetchNotificationsSuccess(
        offset,
        data.scheduledNotifications,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(NotificationsActions.fetchNotificationsError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* editNotification({ id, payload }) {
  try {
    yield put(NotificationsActions.editNotificationLoading())
    const { data } = yield call(
      scheduledNotificationsApiService.patch,
      `/${id}`,
      payload,
    )
    yield put(NotificationsActions.editNotificationSuccess(data))

    const messageKey =
      payload.status === InfinityEnums.NotificationStatus.CANCELLED
        ? 'PushNotificationsPanel.cancelSuccess'
        : 'PushNotificationsPanel.editSuccess'

    toaster.push(<IFToastMessage type="success" text={i18n.t(messageKey)} />)
  } catch (e) {
    yield put(NotificationsActions.editNotificationFail(e))
    handleError(e)
  }
}

export function* scheduleNotification({ payload }) {
  try {
    yield put(NotificationsActions.scheduleNotificationLoading())
    const { data } = yield call(
      scheduledNotificationsApiService.post,
      '/',
      payload,
    )
    yield put(NotificationsActions.scheduleNotificationSuccess(data))

    const scheduledAt = moment(payload.scheduledAt)
    const now = moment()

    let messageKey = 'PushNotificationsPanel.SuccessMessage'

    if (scheduledAt.isAfter(now)) {
      messageKey = 'PushNotificationsPanel.scheduleSuccess'
    }

    toaster.push(<IFToastMessage type="success" text={i18n.t(messageKey)} />)
  } catch (e) {
    yield put(NotificationsActions.scheduleNotificationFail(e))
    handleError(e)
  }
}
export function* fetchNotificationsCount({ filter }) {
  yield put(NotificationsActions.fetchNotificationsCountLoading())
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    const { data } = yield call(
      scheduledNotificationsApiService.get,
      '/count',
      {
        params: {
          filter: filter,
        },
      },
    )
    yield put(NotificationsActions.fetchNotificationsCountSuccess(data.count))
  } catch (e) {
    yield put(NotificationsActions.fetchNotificationsCountError(e))
    handleError(e)
  }
}

export function* fetchGroupsCount({ filter }) {
  yield put(NotificationsActions.fetchGroupsCountLoading())
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    const { data } = yield call(notificationGroupsApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(NotificationsActions.fetchGroupsCountSuccess(data.count))
  } catch (e) {
    yield put(NotificationsActions.fetchGroupsCountError(e))
    handleError(e)
  }
}

export function* fetchGroups({ filter, offset, shouldShowError }) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (offset === 0) {
      yield put(NotificationsActions.fetchGroupsLoading())
    }
    const { data } = yield call(notificationGroupsApiService.get, '/groups', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      NotificationsActions.fetchGroupsSuccess(
        offset,
        data.groups,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(NotificationsActions.fetchGroupsError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* editGroup({ id, payload }) {
  try {
    yield put(NotificationsActions.editGroupLoading())
    const { data } = yield call(
      notificationGroupsApiService.patch,
      `/${id}`,
      payload,
    )
    yield put(NotificationsActions.editGroupSuccess(data))

    const messageKey = 'PushNotificationsPanel.editGroupSuccess'

    toaster.push(<IFToastMessage type="success" text={i18n.t(messageKey)} />)
  } catch (e) {
    yield put(NotificationsActions.editGroupFail(e))
    handleError(e)
  }
}
export function* deleteGroup({ id }) {
  yield put(NotificationsActions.deleteGroupLoading())
  try {
    yield call(notificationGroupsApiService.delete, `/${id}`)
    yield put(NotificationsActions.deleteGroupSuccess(id))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('PushNotificationsPanel.deleteGroupSuccess')}
      />,
    )
  } catch (e) {
    yield put(NotificationsActions.deleteGroupFail(e))
    handleError(e)
  }
}
export function* createGroup({ payload }) {
  try {
    yield put(NotificationsActions.createGroupLoading())
    const { data } = yield call(notificationGroupsApiService.post, '/', payload)
    yield put(NotificationsActions.createGroupSuccess(data))

    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('PushNotificationsPanel.createGroupSuccess')}
      />,
    )
  } catch (e) {
    yield put(NotificationsActions.createGroupFail(e))
    handleError(e)
  }
}

export function* fetchQueryCount({ payload }) {
  yield put(NotificationsActions.fetchQueryCountLoading())
  try {
    const queryParams = new URLSearchParams(payload).toString() // Convert payload to query params

    const { data } = yield call(
      notificationGroupsApiService.get,
      `/queryCount?${queryParams}`, // Append query params to the URL
    )

    yield put(NotificationsActions.fetchQueryCountSuccess(data.count))
  } catch (e) {
    yield put(NotificationsActions.fetchQueryCountError(e))
    if (e.details === 'Invalid ID format.') {
      yield put(NotificationsActions.setInvalidId(true))
    } else handleError(e)
  }
}
