import styles from './AppChargingTokenItemDetails.module.css'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import { FormControl, Select } from '@mui/material'

const AppChargingTokenItemDetails = ({
  row,
  editDetails,
  allAppChargingTokenDesigns,
  onDetailsChange,
  onCancelEdit,
  appChargingTokens,
  allVouchers,
}) => {
  const { t } = useTranslation()
  const latestTokenDetails = useMemo(() => {
    return (
      appChargingTokens.find((token) => token._id === row.original?._id) || {}
    )
  }, [appChargingTokens, row])

  const voucherName =
    allVouchers.find(
      (voucher) => voucher.code === latestTokenDetails?.voucherCode,
    )?.name || ''

  const [details, setDetails] = useState({
    reference: latestTokenDetails?.reference || '',
    parentId: latestTokenDetails?.parentId || '',
    design: latestTokenDetails?.design?.name || '',
    voucherCode: voucherName,
  })

  useEffect(() => {
    if (latestTokenDetails && Object.keys(latestTokenDetails).length > 0) {
      setDetails({
        reference: latestTokenDetails.reference || '',
        parentId: latestTokenDetails.parentId || '',
        design: latestTokenDetails.design?.name || '',
        voucherCode: voucherName,
      })
    }
  }, [latestTokenDetails])

  const resetDetails = () => {
    const voucherNameReset =
      allVouchers.find((voucher) => voucher.code === row.original?.voucherCode)
        ?.name || ''

    setDetails({
      reference: row.original?.reference || '',
      parentId: row.original?.parentId || '',
      voucherCode: voucherNameReset,
      design: row.original?.design?.name || '',
    })
  }

  useEffect(() => {
    if (onCancelEdit) {
      onCancelEdit(resetDetails)
    }
  }, [onCancelEdit])

  const handleInputChange = (key, value) => {
    const updatedDetails = {
      ...details,
      [key]: key === 'voucherCode' && value === '' ? '' : value,
    }
    setDetails(updatedDetails)

    if (onDetailsChange) {
      onDetailsChange(row.original?.id || row.index, updatedDetails)
    }
  }

  const AppChargingTokenDetails = [
    {
      key: 'reference',
      value: details.reference,
      translationKey: 'AppChargingTokenPage.Reference',
    },
    {
      key: 'parentId',
      value: details.parentId,
      translationKey: 'AppChargingTokenPage.ParentID',
    },
    {
      key: 'voucherCode',
      value: details.voucherCode,
      translationKey: 'AppChargingTokenPage.VoucherCode',
    },
    {
      key: 'design',
      value: details.design,
      translationKey: 'AppChargingTokenPage.Design',
    },
  ]

  return (
    <div
      className={styles.AppChargingTokenDetails}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.Line}>
        <div
          style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
          className={styles.Divider}
        />
        <div
          style={{ background: Colors.DividerColor }}
          className={styles.Circle}
        />
      </div>
      <div className={styles.Details}>
        <div className={styles.AppChargingTokenDetailsDiv}>
          <IFText className={styles.AppChargingTokenTitle}>
            {t('AppChargingTokenPage.Details')}
          </IFText>
          <div className={styles.AllDetails}>
            {AppChargingTokenDetails.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  {editDetails && item.key === 'design' ? (
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{
                          width: '12rem',
                          '& .MuiInputBase-input': {
                            fontSize: '14px',
                          },
                          '& .MuiInputBase-root': {
                            color: 'black',
                            '&:hover:not(.Mui-disabled):before': {
                              borderBottom: `1px solid ${Colors.primary} !important`,
                            },
                            '&.Mui-focused:after': {
                              borderBottom: `2px solid ${Colors.primary} !important`,
                            },
                          },
                          '& .MuiSelect-select': {
                            backgroundColor: 'transparent',
                            '&:focus': {
                              backgroundColor: 'transparent',
                            },
                          },
                          '& .MuiPaper-root': {
                            backgroundColor: 'white',
                          },
                          '& .MuiMenuItem-root': {
                            '&:hover': {
                              backgroundColor: `${Colors.primary} !important`,
                              color: 'white',
                            },
                          },
                        }}
                      >
                        <Select
                          value={details.design}
                          onChange={(e) =>
                            handleInputChange(item.key, e.target.value)
                          }
                          displayEmpty
                          variant="standard"
                        >
                          <MenuItem disabled value="">
                            <IFText className={styles.PlaceHolderText}>
                              {t('AppChargingTokenPage.Design')}
                            </IFText>
                          </MenuItem>
                          {allAppChargingTokenDesigns.appChargingTokensDesigns.map(
                            (design) => (
                              <MenuItem key={design.name} value={design.name}>
                                {design.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  ) : editDetails && item.key === 'reference' ? (
                    <TextField
                      id={`input-${item.key}`}
                      value={item.value}
                      onChange={(e) =>
                        handleInputChange(item.key, e.target.value)
                      }
                      variant="standard"
                      placeholder={t('AppChargingTokenPage.Reference')}
                      sx={{
                        width: '12rem',
                        '& .MuiInputBase-root:hover::before': {
                          borderBottom: `1px solid ${Colors.primary} !important`,
                        },
                        '& .MuiInputBase-root.Mui-focused::after': {
                          borderBottom: `2px solid ${Colors.primary} !important`,
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  ) : editDetails && item.key === 'parentId' ? (
                    <TextField
                      id={`input-${item.key}`}
                      value={item.value}
                      onChange={(e) =>
                        handleInputChange(item.key, e.target.value)
                      }
                      variant="standard"
                      placeholder={t('AppChargingTokenPage.ParentID')}
                      sx={{
                        width: '12rem',
                        '& .MuiInputBase-root:hover::before': {
                          borderBottom: `1px solid ${Colors.primary} !important`,
                        },
                        '& .MuiInputBase-root.Mui-focused::after': {
                          borderBottom: `2px solid ${Colors.primary} !important`,
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '14px',
                        },
                      }}
                    />
                  ) : editDetails && item.key === 'voucherCode' ? (
                    <div>
                      <FormControl
                        variant="standard"
                        sx={{
                          width: '12rem',
                          '& .MuiInputBase-input': {
                            fontSize: '14px',
                          },
                          '& .MuiInputBase-root': {
                            color: 'black',
                            '&:hover:not(.Mui-disabled):before': {
                              borderBottom: `1px solid ${Colors.primary} !important`,
                            },
                            '&.Mui-focused:after': {
                              borderBottom: `2px solid ${Colors.primary} !important`,
                            },
                          },
                          '& .MuiSelect-select': {
                            backgroundColor: 'transparent',
                            '&:focus': {
                              backgroundColor: 'transparent',
                            },
                          },
                          '& .MuiPaper-root': {
                            backgroundColor: 'white',
                          },
                          '& .MuiMenuItem-root': {
                            '&:hover': {
                              backgroundColor: `${Colors.primary} !important`,
                              color: 'white',
                            },
                          },
                        }}
                      >
                        <Select
                          value={details.voucherCode}
                          onChange={(e) =>
                            handleInputChange(item.key, e.target.value)
                          }
                          displayEmpty
                          variant="standard"
                          endAdornment={
                            details.voucherCode && (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    handleInputChange(item.key, '')
                                  }
                                  disableRipple
                                  disableFocusRipple
                                  sx={{
                                    pointerEvents: 'auto',
                                    marginRight: '1.5rem',
                                    backgroundColor: 'transparent',
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                  }}
                                >
                                  <ClearIcon sx={{ fontSize: '15px' }} />
                                </IconButton>
                              </InputAdornment>
                            )
                          }
                        >
                          <MenuItem disabled value="">
                            <IFText className={styles.PlaceHolderText}>
                              {t('AppChargingTokenPage.VoucherCode')}
                            </IFText>
                          </MenuItem>
                          {allVouchers.map((voucher) => (
                            <MenuItem key={voucher.name} value={voucher.name}>
                              {voucher.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : item.value ? (
                    <IFText className={styles.DetailText}>{item.value}</IFText>
                  ) : (
                    <IFText className={styles.DetailText}>
                      {t('AppChargingTokenPage.NotSet')}
                    </IFText>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

AppChargingTokenItemDetails.propTypes = {
  row: PropTypes.object,
  editDetails: PropTypes.bool,
  allAppChargingTokenDesigns: PropTypes.object,
  onDetailsChange: PropTypes.func,
  onCancelEdit: PropTypes.func,
  appChargingTokens: PropTypes.array,
  allVouchers: PropTypes.array,
}

export default AppChargingTokenItemDetails
