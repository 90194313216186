import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import InfinityEnums from 'Enums/InfinityEnums'

export const INITIAL_STATE = {
  invalidId: false,
  notificationsPaginationOffset: 0,
  groupsPaginationOffset: 0,
  notificationsCount: 0,
  groupsCounts: 0,
  queryCount: 0,
  notificationGroups: [],
  groups: [],
  notifications: [],
  fetchNotificationGroupRequestState: RequestState.UNINITIALIZED,
  fetchNotificationsRequestState: RequestState.UNINITIALIZED,
  editNotificationRequestState: RequestState.UNINITIALIZED,
  scheduleNotificationRequestState: RequestState.UNINITIALIZED,
  fetchNotificationsCountRequestState: RequestState.UNINITIALIZED,
  fetchGroupsCountRequestState: RequestState.UNINITIALIZED,
  fetchGroupsRequestState: RequestState.UNINITIALIZED,
  deleteGroupRequestState: RequestState.UNINITIALIZED,
  editGroupRequestState: RequestState.UNINITIALIZED,
  createGroupRequestState: RequestState.UNINITIALIZED,
  fetchQueryCountRequestState: RequestState.UNINITIALIZED,
  notificationsFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Notification Groups',
      data: [],
      initialValue: [],
      value: [],
      field: 'Groups',
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: [
        InfinityEnums.NotificationStatus.CANCELLED,
        InfinityEnums.NotificationStatus.SENT,
        InfinityEnums.NotificationStatus.SCHEDULED,
      ],
      initialValue: [],
      value: [],
      field: 'Status',
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Scheduled At',
      initialValue: [],
      value: [],
    },
  ],
  groupsFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.VALUE_RANGE,
      title: 'Number of Users',
      initialValue: [],
      value: [],
    },
  ],
}
