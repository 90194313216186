import moment from 'moment'

export const splitCamelCase = (string) => {
  let newString = string.replace(/([a-z])([A-Z])/g, '$1 $2')
  return newString[0].toUpperCase() + newString.substring(1)
}

export var getInitials = function (name) {
  var names = name.split(' ')
  var initials = names[0].substring(0, 1).toUpperCase()
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase()
  }
  return initials
}
export const formatDate = (dateTimeString) => {
  return moment(dateTimeString).format('MMM DD, YYYY HH:mm')
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}
export const formatPermissionName = (permissionName) => {
  return permissionName
    .split('_')
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(' ')
}
export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }
  const sortedArr1 = [...arr1].sort((a, b) => a - b)
  const sortedArr2 = [...arr2].sort((a, b) => a - b)

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false
    }
  }
  return true
}
export const areBoundsEqual = (bounds1, bounds2) => {
  if (bounds1.length !== bounds2.length) {
    return false
  }

  for (let i = 0; i < bounds1.length; i++) {
    const val1 = typeof bounds1[i] === 'string' ? bounds1[i].trim() : bounds1[i]
    const val2 = typeof bounds2[i] === 'string' ? bounds2[i].trim() : bounds2[i]

    if (val1 !== val2) {
      return false
    }
  }

  return true
}

// Format hours to 12-hour format with AM/PM
export const formatTime = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${hours}:${minutes}${ampm}`
}
