import { Dialog, DialogTitle, DialogContent } from '@mui/material'
import IFText from 'Components/IFText/IFText'
import Styles from './IFModal.module.css'
import React, { useState, useImperativeHandle } from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Colors } from 'Theme'
import { styled } from '@mui/system'

const DialogTitleStyled = styled(DialogTitle)({
  paddingLeft: '4px',
})

const DialogContentStyled = styled(DialogContent)({
  padding: '4px',
})

const IFModal = React.forwardRef(
  (
    {
      title,
      children,
      open,
      maxWidth,
      fullWidth = false,
      canDismiss = true,
      dialogContentStyle = {},
      onDismiss = () => {},
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(open)

    const show = () => {
      setIsOpen(true)
    }

    const dismiss = () => {
      if (canDismiss) setIsOpen(false)
      onDismiss()
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
    }))

    return (
      <Dialog
        open={isOpen}
        onClose={dismiss}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        PaperProps={{
          style: {
            padding: '16px',
          },
        }}
      >
        <div className={Styles.TitleContainer}>
          <DialogTitleStyled className={Styles.DialogTitle}>
            <IFText className={Styles.Title} style={{ color: Colors.text }}>
              {title}
            </IFText>
          </DialogTitleStyled>
          <div>
            <ClearIcon onClick={dismiss} className={Styles.ExitIcon} />
          </div>
        </div>
        <DialogContentStyled
          style={dialogContentStyle}
          className={Styles.DialogContent}
        >
          {children}
        </DialogContentStyled>
      </Dialog>
    )
  },
)

export default IFModal
