const getTransactionsList = (state) => {
  return state.main.transactionState.transactions
}

const getFetchTransactionsListRequestState = (state) => {
  return state.main.transactionState.fetchTransactionsListRequestState
}

const getPaginationOffset = (state) => {
  return state.main.transactionState.paginationOffset
}

const getTransactionsFilter = (state) => {
  return state.main.transactionState.transactionsFilter
}

const getFetchTransactionsMetricsRequestState = (state) => {
  return state.main.transactionState.fetchTransactionsMetricsRequestState
}
const getMetrics = (state) => {
  return state.main.transactionState.metrics
}

const getFetchEnergyConsumptionChartRequestState = (state) => {
  return state.main.transactionState.fetchEnergyConsumptionChartRequestState
}
const getEnergyConsumption = (state) => {
  return state.main.transactionState.energyConsumption
}

const getAllSubscriptions = (state) => {
  return state.main.transactionState.allSubscriptions
}

const TransactionSelectors = {
  getTransactionsList,
  getFetchTransactionsListRequestState,
  getPaginationOffset,
  getTransactionsFilter,
  getFetchTransactionsMetricsRequestState,
  getMetrics,
  getFetchEnergyConsumptionChartRequestState,
  getEnergyConsumption,
  getAllSubscriptions,
}

export default TransactionSelectors
