const getFetchDashboardRequestState = (state) => {
  return state.main.dashboardState.fetchDashboardRequestState
}

const getDashboardFilter = (state) => {
  return state.main.dashboardState.dashboardFilters
}
const getDashboard = (state) => {
  return state.main.dashboardState.metrics
}

const DashboardSelectors = {
  getFetchDashboardRequestState,
  getDashboardFilter,
  getDashboard,
}

export default DashboardSelectors
