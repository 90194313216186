import { LineChart } from '@mui/x-charts/LineChart'
import Styles from './EnergyConsumptionChart.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import dayjs from 'dayjs'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { IFText, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from 'Theme'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const BlurOverlayText = styled.div`
   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 600;
  background-color: ${Colors.white};
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
}
`

const formatStartDates = (startDates) => {
  if (!Array.isArray(startDates)) {
    return []
  }

  const allSameDay = startDates.every((date) =>
    dayjs(date).isSame(dayjs(startDates[0]), 'day'),
  )

  const minDate = dayjs(startDates[0])
  const maxDate = dayjs(startDates[startDates.length - 1])
  const daysDifference = maxDate.diff(minDate, 'day')

  if (allSameDay) {
    return startDates.map((date) => dayjs(date).format('HH:mm'))
  }

  if (daysDifference < 10) {
    return startDates.map((date) => dayjs(date).format('ddd, HH:mm'))
  }

  const allSameYear = startDates.every(
    (date) => dayjs(date).year() === dayjs(startDates[0]).year(),
  )

  return startDates.map((date) =>
    allSameYear ? dayjs(date).format('DD MMM') : dayjs(date).format('MMM YY'),
  )
}

const EnergyConsumptionChart = ({
  totalAC = [],
  totalDC = [],
  startDates = [],
  title,
  height,
  isLoading,
  dashboard,
}) => {
  const { t } = useTranslation()
  let formattedDates
  if (dashboard) {
    formattedDates = startDates
  } else {
    {
      formattedDates = Array.isArray(startDates)
        ? formatStartDates(startDates)
        : []
    }
  }

  const chartSetting = {
    yAxis: [
      {
        label: t('TransactionsPage.ChartYAxis'),
        labelStyle: {
          fontSize: '12px',
        },
      },
    ],
  }

  const isNoData =
    totalAC.every((value) => value === 0) &&
    totalDC.every((value) => value === 0)

  const emptySeries = {
    series: [],
    margin: { top: 10, left: 25, bottom: 25 },
  }

  const fixMargin = true
  const fixLabel = true

  return (
    <div>
      {isLoading ? (
        <Card>
          {title && <IFSkeleton variant="text" width="150px" height="16px" />}
          <div className={Styles.ChartContainer}>
            {title ? (
              <IFSkeleton
                variant="rectangular"
                height="262px"
                style={{ marginTop: 32 }}
              />
            ) : (
              <IFSkeleton
                variant="rectangular"
                height={height - 32}
                style={{ marginTop: 32 }}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card>
          {title && (
            <IFText className={Styles.Title}>
              {t('TransactionsPage.TransactionTableColumns.EnergyConsumption')}
            </IFText>
          )}
          <div className={Styles.ChartContainer}>
            {isNoData ? (
              <div>
                <BlurOverlayText>
                  <IFText>{t('TransactionsPage.ChartNoData')}</IFText>
                </BlurOverlayText>
                <LineChart
                  className={Styles.BlurBackground}
                  xAxis={[
                    {
                      scaleType: 'point',
                      data: formattedDates || [],
                    },
                  ]}
                  {...emptySeries}
                  height={height}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: 'translateX(-40px)',
                    },
                  }}
                />
              </div>
            ) : (
              <LineChart
                xAxis={[
                  {
                    scaleType: 'point',
                    data: formattedDates || [],
                  },
                ]}
                series={[
                  {
                    id: 'energy-series1',
                    data: totalAC || [],
                    label: 'AC',
                    color: Colors.primary,
                  },
                  {
                    id: 'energy-series2',
                    data: totalDC || [],
                    label: 'DC',
                    color: Colors.DCColor,
                  },
                ]}
                {...chartSetting}
                height={height}
                margin={
                  fixMargin ? { top: 35, bottom: 20, left: 100 } : undefined
                }
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'center' },
                    padding: 0,
                    itemMarkHeight: 14,
                    itemMarkWidth: 14,
                    itemGap: 8,
                    labelStyle: {
                      fontSize: 14,
                    },
                  },
                }}
                sx={
                  fixLabel
                    ? {
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: 'translateX(-40px)',
                        },
                      }
                    : {}
                }
              />
            )}
          </div>
        </Card>
      )}
    </div>
  )
}
EnergyConsumptionChart.propTypes = {
  totalAC: PropTypes.array,
  totalDC: PropTypes.array,
  startDates: PropTypes.array,
  title: PropTypes.string,
  heigth: PropTypes.number,
  isLoading: PropTypes.bool,
  dashboard: PropTypes.bool,
}

export default EnergyConsumptionChart
