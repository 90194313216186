import React, { useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material'
import { Colors } from 'Theme'
import { IFTabs } from 'Components'
import AppChargingTokenPageTabsSkeleton from './AppChargingTokenPageTabsSkeleton'
import InfinityEnums from 'Enums/InfinityEnums'

const tabTitles = {
  AppChargingTokens: 'Tokens',
  AppChargingTokenDesigns: 'Designs',
}

const AppChargingTokenPageTabs = React.forwardRef(
  (
    {
      editingAdminRole,
      onValueChange,
      isLoading,
      initialIndex,
      AppChargingTokenCount,
      AppChargingTokenDesignCount,
      fetchListRequestState,
    },
    ref,
  ) => {
    const canViewDesign = editingAdminRole?.includes(
      InfinityEnums.AdminPermissions.CAN_VIEW_APP_CHARGING_TOKENS_DESIGN,
    )
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)

    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    const tabsToRender = Object.entries(tabTitles).filter(([key]) => {
      if (key === 'AppChargingTokenDesigns' && !canViewDesign) {
        return false
      }
      return true
    })

    return (
      <div>
        {isLoading ? (
          <AppChargingTokenPageTabsSkeleton tabsCount={tabsToRender.length} />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {tabsToRender.map(([key, title], index) => (
              <Tab
                sx={{
                  padding: '0px',
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                key={index}
                label={
                  <IFTabs
                    title={title}
                    isSelected={selectedIndex === index}
                    fetchListRequestState={fetchListRequestState}
                    count={
                      key === 'AppChargingTokens'
                        ? AppChargingTokenCount
                        : AppChargingTokenDesignCount
                    }
                  />
                }
              />
            ))}
          </Tabs>
        )}
      </div>
    )
  },
)

AppChargingTokenPageTabs.propTypes = {
  editingAdminRole: PropTypes.array,
  onValueChange: PropTypes.func,
  isLoading: PropTypes.bool,
  initialIndex: PropTypes.number,
  AppChargingTokenCount: PropTypes.number,
  fetchListRequestState: PropTypes.string,
}

export default AppChargingTokenPageTabs
