import React, { useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import Styles from './AdminProfile.module.css'
import { Colors } from 'Theme'
import LoadingBar from 'react-top-loading-bar'
import {
  IFAvatar,
  IFButton,
  IFDialog,
  IFFilter,
  IFModal,
  IFSkeleton,
  IFText,
} from 'Components'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import moment from 'moment'
import ChangePasswordForm from './ChangePasswordForm'
import ReactCountryFlag from 'react-country-flag'
import RequestState from 'Enums/RequestState'
import { Edit } from '@mui/icons-material'
import { TextField } from '@mui/material'
import InfinityEnums from 'Enums/InfinityEnums'
import AdminActionList from 'Containers/AdminActionList/AdminActionList'

const AdminProfile = ({
  editingAdminRole,
  logout,
  user,
  changePassword,
  adminDetailsRequestState,
  ChangingPasswordRequestState,
  setCurrentUserNewName,
  ChangingUserNameRequestState,
  fetchAdminActionsList,
  adminActions,
  paginationOffset,
  fetchAdminActionsRequestState,
  setChangingPasswordRequestState,
  setChangingUserNameRequestState,
  adminActionsFilters,
  setAdminActionsFilter,
  clearAdminActions,
  clearAdminActionsFilter,
}) => {
  useEffect(() => {
    if (ChangingPasswordRequestState === RequestState.SUCCEEDED) {
      showDialogHandlerPassword.current.dismiss()
      setChangingPasswordRequestState(RequestState.UNINITIALIZED)
      adminActionsFilterRef?.current?.clearFilter()
      clearAdminActionsFilter()
      fetchAdminActionsList(adminActionsFilters, 0)
    }
  }, [ChangingPasswordRequestState])
  useEffect(() => {
    if (ChangingUserNameRequestState === RequestState.SUCCEEDED) {
      showDialogHandlerUserName.current.dismiss()
      setUserName('')
      setIsDeadButton(true)
      setChangingUserNameRequestState(RequestState.UNINITIALIZED)
      adminActionsFilterRef?.current?.clearFilter()
      clearAdminActionsFilter()
      fetchAdminActionsList(adminActionsFilters, 0)
    }
  }, [ChangingUserNameRequestState])
  const { t } = useTranslation()
  const logoutHandler = () => {
    logout()
  }
  const showDialogHandlerPassword = useRef(null)
  const showDialogHandlerUserName = useRef(null)
  const [progress, setProgress] = useState(0)
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [userName, setUserName] = useState()
  const [isDeadButton, setIsDeadButton] = useState(true)
  const onSubmitFormHandler = (passwords) => {
    changePassword(passwords)
  }
  useEffect(() => {
    if (isFirstLoaded) {
      if (adminDetailsRequestState === RequestState.LOADING && progress === 0)
        setProgress(progress + 10)

      if (adminDetailsRequestState === RequestState.SUCCEEDED) {
        setProgress(100)
        setIsFirstLoaded(false)
      }
      if (
        adminDetailsRequestState !== RequestState.LOADING &&
        adminDetailsRequestState !== RequestState.UNINITIALIZED &&
        adminDetailsRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [adminDetailsRequestState, isFirstLoaded])
  const handleOnChange = (event) => {
    setUserName(event.target.value)
    if (event.target.value.length > 0) setIsDeadButton(false)
    else setIsDeadButton(true)
  }
  useEffect(() => {
    adminActionsFilterRef?.current?.clearFilter()
    clearAdminActions()
    clearAdminActionsFilter()
    fetchAdminActionsList(adminActionsFilters, 0)
  }, [])

  const flattenedActions = useMemo(() => {
    return adminActions
      .map((action) => {
        return action.targets.map((target) => {
          let userAction
          let subject
          let country
          let model = ''
          let phrase = ''
          let verb = ''
          let createdAt = moment(action.createdAt).format('MMM DD, YYYY LTS')
          let attributes = ''

          if (user.id === action.adminUser._id) {
            userAction = t('AdminActions.EditingAdmin')
          } else {
            userAction = action.adminUser.name
          }

          if (user.id === target.id?.modelId) {
            subject = t('AdminActions.AdminPronoun')
          } else {
            subject = `${target.reference}`
          }

          if (target.id?.country && user.registeredCountries.length > 1) {
            country = target.id.country
          }

          if (
            target.model === InfinityEnums.Models.Admin &&
            subject === t('AdminActions.AdminPronoun')
          ) {
            model = ''
          } else if (target.model === InfinityEnums.Models.Wallet) {
            model = `${InfinityEnums.Models.User}:`
          } else {
            model = `${target.model}:`
          }

          if (action.action === InfinityEnums.AdminActions.Edit) {
            verb = t('AdminActions.Changed')
            if (user.id === target.id?.modelId) {
              subject = t('AdminActions.AdminPronoun')
            } else {
              subject = `${target.reference}'s`
            }

            const fields = target.attributes || []
            const formattedFields = fields
              .map((field) => {
                if (Array.isArray(field.newValue) || field.newValue === '') {
                  return field.name
                }
                if (typeof field.newValue === 'number') {
                  field.newValue = field.newValue.toFixed(2)
                }
                if (field.deletedValueReference) {
                  verb = t('AdminActions.Removed')
                  phrase = `${userAction} ${verb} ${
                    field.deletedValueReference
                  } ${t('AdminActions.from')} ${subject} ${field.name}`
                  return null
                }
                if (
                  typeof field.newValue === 'string' &&
                  /^[a-fA-F0-9]{24}$/.test(field.newValue)
                ) {
                  const association = action.associations.find(
                    (item) =>
                      item.id.modelId.toString() === field.newValue.toString(),
                  )
                  let newValue = association.reference
                  return `${field.name} to ${newValue}`
                }
                return `${field.name} to ${field.newValue}`
              })
              .join(', ')

            if (verb === t('AdminActions.Changed')) {
              phrase = `${userAction} ${verb} ${
                model ? model + ' ' : ''
              }${subject} ${formattedFields}`
            }

            return phrase
              ? {
                  phrase,
                  createdAt,
                  verb: action.action,
                  ...(country ? { country } : {}),
                }
              : null
          } else if (
            action.action === InfinityEnums.AdminActions.Create ||
            action.action === InfinityEnums.AdminActions.Invite
          ) {
            verb = t('AdminActions.Created')
            if (action.action === InfinityEnums.AdminActions.Invite)
              verb = t('AdminActions.Invited')
            attributes = target.attributes.map((field) => {
              let newValue = field.newValue
              if (Array.isArray(newValue)) {
                newValue = newValue.map((item) => {
                  if (
                    typeof item === 'string' &&
                    /^[a-fA-F0-9]{24}$/.test(item)
                  ) {
                    const association = action.associations.find(
                      (assoc) =>
                        assoc.id.modelId.toString() === item.toString(),
                    )
                    return association ? association.reference : item
                  }
                  return item
                })
              } else if (typeof newValue === 'object' && newValue !== null) {
                newValue = Object.entries(newValue).reduce(
                  (acc, [key, value]) => {
                    if (
                      typeof value === 'string' &&
                      /^[a-fA-F0-9]{24}$/.test(value)
                    ) {
                      const association = action.associations.find(
                        (assoc) =>
                          assoc.id.modelId.toString() === value.toString(),
                      )
                      acc[key] = association ? association.reference : value
                    } else {
                      acc[key] = value
                    }
                    return acc
                  },
                  {},
                )
              } else if (
                typeof newValue === 'string' &&
                moment(newValue, moment.ISO_8601, true).isValid()
              ) {
                newValue = moment(newValue).format('MMM DD, YYYY LTS')
              } else if (
                newValue &&
                typeof newValue === 'string' &&
                /^[a-fA-F0-9]{24}$/.test(field.newValue) &&
                action.associations
              ) {
                const association = action.associations.find(
                  (item) =>
                    item.id.modelId.toString() === field.newValue.toString(),
                )
                if (association) {
                  newValue = association.reference
                }
              }

              return {
                name: field.name,
                newValue: newValue,
              }
            })
          } else if (action.action === InfinityEnums.AdminActions.Delete) {
            verb = t('AdminActions.Deleted')
          } else if (action.action === InfinityEnums.AdminActions.Deactivate) {
            verb = t('AdminActions.Deactivated')
          } else if (action.action === InfinityEnums.AdminActions.Activate) {
            verb = t('AdminActions.Activated')
          }

          phrase = `${userAction} ${verb} ${model ? model + ' ' : ''}${subject}`
          return phrase
            ? {
                phrase,
                createdAt,
                verb: action.action,
                ...(country ? { country } : {}),
                ...(action.action === InfinityEnums.AdminActions.Create ||
                action.action === InfinityEnums.AdminActions.Invite
                  ? { attributes }
                  : {}),
              }
            : null
        })
      })
      .flat()
      .filter(Boolean)
  }, [user, adminActions])

  const adminActionsFilterRef = useRef(null)
  const [failedRequest, setFailedRequest] = useState(false)
  const handleRetry = () => {
    fetchAdminActionsList(adminActionsFilters, paginationOffset)
  }
  const loadMoreData = () => {
    if (
      fetchAdminActionsRequestState === RequestState.LOADING ||
      paginationOffset == null
    )
      return
    fetchAdminActionsList(adminActionsFilters, paginationOffset, failedRequest)
  }
  useEffect(() => {
    if (
      fetchAdminActionsRequestState === RequestState.ERROR_0_NETWORK ||
      fetchAdminActionsRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchAdminActionsRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchAdminActionsRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchAdminActionsRequestState])
  const handleAdminActionsFilter = (newFilter) => {
    clearAdminActions()
    setAdminActionsFilter(newFilter)
    fetchAdminActionsList(newFilter, 0)
  }
  const tablesRef = useRef()
  const [tablesHeight, setTablesHeight] = React.useState(0)
  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (tablesRef.current) {
      resizeObserver.observe(tablesRef.current)
    }

    return () => {
      if (tablesRef.current) {
        resizeObserver.unobserve(tablesRef.current)
      }
    }
  }, [])

  return (
    <div className={Styles.WrapperContainer}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div
        className={Styles.TopContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.UserDataContainer}>
          <div
            className={Styles.UserContainerDiv}
            style={{
              backgroundColor: Colors.white,
            }}
          >
            <div className={Styles.UserAvatarContainer}>
              <div className={Styles.AvatarContainer}>
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton variant="circular" width="4rem" height="4rem" />
                ) : (
                  <>
                    <IFAvatar name={user.name} size={64} />
                  </>
                )}
                <IFText
                  loadSkeleton={
                    adminDetailsRequestState === RequestState.LOADING ||
                    adminDetailsRequestState === RequestState.UNINITIALIZED
                  }
                  skeletonWidth="8rem"
                  skeletonHeight="1.75rem"
                  className={Styles.UserName}
                  style={{
                    color: Colors.UserPageTextColor,
                  }}
                >
                  {user.name}
                </IFText>
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <></>
                ) : (
                  <Edit
                    className={Styles.EditIcon}
                    fontSize={'small'}
                    onClick={() => {
                      setIsDeadButton(true)
                      setUserName()
                      showDialogHandlerUserName.current.show()
                    }}
                    style={{
                      color: Colors.PhoneEditIcon,
                    }}
                  />
                )}
              </div>

              <div className={Styles.ButtonsDiv}>
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton
                    variant="rectangular"
                    width="4.5rem"
                    height="2.5rem"
                  />
                ) : (
                  <IFButton
                    color={Colors.primary}
                    isFill={false}
                    isLoading={false}
                    onClick={() => {
                      showDialogHandlerPassword.current.show()
                    }}
                    text={t('AdminProfile.ChangePassword')}
                  />
                )}
                {adminDetailsRequestState === RequestState.LOADING ||
                adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                  <IFSkeleton
                    variant="rectangular"
                    width="4.5rem"
                    height="2.5rem"
                  />
                ) : (
                  <IFButton
                    color={Colors.red}
                    isFill={false}
                    isLoading={false}
                    onClick={logoutHandler}
                    text={t('AdminProfile.logout')}
                  />
                )}
              </div>
            </div>
          </div>
          <Scrollbars autoHide className={Styles.ScrollParent}>
            <div className={Styles.UserInfoContainer}>
              <div className={Styles.AllInfoContainer}>
                <div className={Styles.UserInfo}>
                  <div className={Styles.InfoText}>
                    <div className={Styles.Item}>
                      <div className={Styles.LabelTitle}>
                        <IFText
                          style={{ color: Colors.gray }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {t('AdminProfile.Email')}
                        </IFText>
                      </div>
                      <div className={Styles.LabelValue}>
                        <IFText
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                        >
                          {user.email}
                        </IFText>
                      </div>
                    </div>
                    <div className={Styles.Item}>
                      <div className={Styles.LabelTitle}>
                        <IFText
                          style={{ color: Colors.gray }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {t('AdminProfile.Role')}
                        </IFText>
                      </div>
                      <div className={Styles.LabelValue}>
                        <IFText
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                        >
                          {user.role.name}
                        </IFText>
                      </div>
                    </div>
                    <div className={Styles.Item}>
                      <div className={Styles.LabelTitle}>
                        <IFText
                          style={{ color: Colors.gray }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                          skeletonWidth="4rem"
                          skeletonHeight="1.5rem"
                        >
                          {t('AdminProfile.JoinedOn')}
                        </IFText>
                      </div>
                      <div className={Styles.LabelValue}>
                        <IFText
                          style={{
                            color: Colors.text,
                          }}
                          loadSkeleton={
                            adminDetailsRequestState === RequestState.LOADING ||
                            adminDetailsRequestState ===
                              RequestState.UNINITIALIZED
                          }
                        >
                          {moment(user.createdAt).format('MMM DD, YYYY LTS')}
                        </IFText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={Styles.CountryListDiv}>
              <IFText
                loadSkeleton={
                  adminDetailsRequestState === RequestState.LOADING ||
                  adminDetailsRequestState === RequestState.UNINITIALIZED
                }
                skeletonWidth="4rem"
                skeletonHeight="1.5rem"
                className={Styles.CountryName}
                style={{
                  color: Colors.text,
                  fontWeight: 600,
                }}
              >
                {t('AdminProfile.Countries')}
              </IFText>
              <div className={Styles.CountryList}>
                {user.registeredCountries.map((item, index) => (
                  <div className={Styles.CountryItem} key={index}>
                    {adminDetailsRequestState === RequestState.LOADING ||
                    adminDetailsRequestState === RequestState.UNINITIALIZED ? (
                      <IFSkeleton
                        variant="rectangular"
                        width="1.25rem"
                        height="1.25rem"
                      />
                    ) : (
                      <ReactCountryFlag
                        className={Styles.FlagItem}
                        countryCode={item.code}
                        svg
                      />
                    )}

                    <IFText
                      style={{
                        color: Colors.text,
                      }}
                      loadSkeleton={
                        adminDetailsRequestState === RequestState.LOADING ||
                        adminDetailsRequestState === RequestState.UNINITIALIZED
                      }
                      skeletonWidth="4rem"
                      skeletonHeight="1.5rem"
                    >
                      {item.name}
                    </IFText>
                  </div>
                ))}
              </div>
            </div>
            <div className={Styles.AdminActionsListDiv} ref={tablesRef}>
              <IFText
                loadSkeleton={
                  adminDetailsRequestState === RequestState.LOADING ||
                  adminDetailsRequestState === RequestState.UNINITIALIZED
                }
                skeletonWidth="4rem"
                skeletonHeight="1.5rem"
                className={Styles.CountryName}
                style={{
                  color: Colors.text,
                  fontWeight: 600,
                }}
              >
                {t('AdminProfile.AdminActions')}
              </IFText>
              <div className={Styles.Filter}>
                <IFFilter
                  isLoading={
                    adminDetailsRequestState === RequestState.LOADING ||
                    adminDetailsRequestState === RequestState.UNINITIALIZED
                  }
                  ref={adminActionsFilterRef}
                  onFilterChange={(newFilter) => {
                    handleAdminActionsFilter(newFilter)
                  }}
                  filters={adminActionsFilters}
                  textFieldPlaceholder={t(
                    'AdminProfile.AdminActionsFilterPlaceHolder',
                  )}
                  disableFutureDates={true}
                />
              </div>
              <div className={Styles.AdminListWrapper}>
                <AdminActionList
                  fetchAdminActionsRequestState={fetchAdminActionsRequestState}
                  paginationOffset={paginationOffset}
                  flattenedActions={flattenedActions}
                  tablesHeight={tablesHeight - 78}
                  loadMoreData={loadMoreData}
                  handleRetry={handleRetry}
                />
              </div>
            </div>
          </Scrollbars>
        </div>
        <IFModal
          title={t('AdminProfile.ChangePassword')}
          open={false}
          ref={showDialogHandlerPassword}
        >
          <ChangePasswordForm
            onSubmitForm={(values) => onSubmitFormHandler(values)}
          />
        </IFModal>
        <IFDialog
          ref={showDialogHandlerUserName}
          maxWidth={'sm'}
          fullWidth={true}
          open={false}
          dismissOnAccept={false}
          title={t('AdminProfile.EditUserNameTitle')}
          buttonAcceptonClick={() => {
            setCurrentUserNewName(userName)
          }}
          buttonDeadCondition={isDeadButton || userName === user.name}
          buttonCancelColor={Colors.UserPagePrimary}
          buttonAcceptColor={Colors.UserPagePrimary}
          buttonAcceptText={t('AdminProfile.EditUserNameConfirm')}
          buttonClassName={Styles.ButtonPhoneEditModal}
          contentClassName={Styles.contentClassName}
          isLoading={ChangingUserNameRequestState === RequestState.LOADING}
        >
          <div className={Styles.UserNameField}>
            <TextField
              defaultValue={user.name}
              id="outlined-basic"
              label={t('AdminProfile.EditUserNameLabel')}
              variant="outlined"
              sx={{
                width: '80%',
                '& label.Mui-focused': {
                  color: Colors.primary,
                },
                '& .MuiInputBase-sizeSmall': {
                  fontSize: '0.875rem',
                  fontFamily: 'ProximaNova',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: Colors.primary,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: Colors.primary,
                  },
                },
              }}
              className={Styles.ActionWidth}
              type="text"
              onChange={(event) => {
                handleOnChange(event)
              }}
            />
          </div>
        </IFDialog>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  user: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  adminDetailsRequestState: AuthSelectors.getAdminDetailsRequestState(state),
  ChangingPasswordRequestState:
    AuthSelectors.getChangingPasswordRequestState(state),
  ChangingUserNameRequestState:
    AuthSelectors.getChangingUserNameRequestState(state),
  adminActions: AuthSelectors.getAdminActionList(state),
  fetchAdminActionsRequestState:
    AuthSelectors.getfetchAdminActionsRequestState(state),
  paginationOffset: AuthSelectors.getAdminActionsListOffset(state),
  adminActionsFilters: AuthSelectors.getAdminActionsFilters(state),
})
function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthActions.logout()),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
    changePassword: (passwords) =>
      dispatch(AuthActions.changePassword(passwords)),
    setCurrentUserNewName: (userName) =>
      dispatch(AuthActions.setCurrentUserNewName(userName)),
    fetchAdminActionsList: (filter, offset, shouldShowError) =>
      dispatch(
        AuthActions.fetchAdminActionsList(filter, offset, shouldShowError),
      ),
    setChangingPasswordRequestState: (newRequestState) =>
      dispatch(AuthActions.setChangingPasswordRequestState(newRequestState)),
    setChangingUserNameRequestState: (newRequestState) =>
      dispatch(AuthActions.setChangingUserNameRequestState(newRequestState)),
    setAdminActionsFilter: (newFilter) =>
      dispatch(AuthActions.setAdminActionsFilter(newFilter)),
    clearAdminActions: () => dispatch(AuthActions.clearAdminActions()),
    clearAdminActionsFilter: () =>
      dispatch(AuthActions.clearAdminActionsFilter()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile)
