/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

const colors = {
  //common
  text: '#000000',
  primary: '#27a3ac',
  primaryLight: '#CEDADB',
  success: '#28a745',
  error: '#dc3545',
  white: '#FFFFFF',
  black: '#000000',
  red: '#D32F2F',
  green: '#008000',
  shadow: '#00287A',
  grey: '#e9e9e9',
  darkGrey: '#5A5A5A',
  gray: 'gray',
  transparent: 'transparent',

  //User List Item
  UserListItemSelectedBackground: '#d0f0f0',
  UserListItemNotSelectedBackground: '#ffffff',
  UserListItemActive: '#ffffff',
  UserListItemInactive: '#f74338',
  UserListItemHoverBackground: '#27a3ac1A',

  UserListItemPhoneAndLastSeen: '#00000099',

  //User List
  UserListTopBarBackground: '#f4fafb',
  ifIndicatorSecondary: '#F0F0F0',
  UserListBackground: '#ffffff',
  UserListHeaderText: '#000000',

  //Charging Tokens List Item
  ChargingTokenListItemIcon: '#000000',
  ChargingTokenListItemId: '#000000',
  ChargingTokenListItemDate: '#000000',
  ChargingTokenListItemBackgroundColor: '#FFFFF',
  ChargingTokenListItemShadow: '#0000001a',

  //Admin List Item
  AdminListItemEmail: '#00000099',
  AdminListPenIcon: '#818181',
  AdminListDeleteIcon: '#ff0000',

  //Admin List
  AdminListDeleteModalCancel: '#27a3ac',
  AdminListDeleteModalDelete: '#FF0000',
  DeadTooltip: '#A9A9A9',

  //Fund Transactions List Item
  FundTransactionListItemIcon: '#000000',
  FundTransactionListItemId: '#000000',
  FundTransactionListItemDate: '#000000',
  FundTransactionListItemAmount: '#000000',
  FundTransactionListItemBackgroundColor: '#FFFFF',
  FundTransactionListItemShadow: '#0000001a',

  //Charging Transaction List Item
  ChargingTransactionListItemShadow: '#0000001a',

  //filter
  FilterShadow: '#0000002f',
  InputsFilterBackground: '#27a3ac7a',
  RedCircle: '#ff0000Af',
  BlueCircle: '#0000ffAf',
  FilterClearButton: '#E1E1E1ff',
  FuilterSaveButton: '#158073ff',
  textFieldBoxShadow: '#ffffff00',
  verticalDivider: '#ffffff91',
  filterBackground: '#e8f6f7',
  filterIconEmpty: '#808080',
  filterIconFilled: '#000000',
  // Admin List Item
  AdminListItemShadow: '#0000001a',

  //IFToggle
  ToggleDisabled: '#c5c6c7',
  // Side Navigation Bar
  LogoBackground: '#3dacb4',
  NavigationBarBackground: '#27a3ac',
  IconHover: '#52b5bd',

  // Login page
  ForgotPassword: '#1672EC',
  LoginBackground: '#dce5e6',
  LoginGradient1: '#5cc0c9',
  LoginGradient2: '#147065',

  //Reset password
  resetPasswordBackgroundColor: '#fffffff2',
  resetPasswordBackgroundShadow: '#00000040',
  resetPasswordModalSubTitle: '#000000',
  pinFieldBackground: '#f8f9fa',
  pinFieldBorder: '#cccccc',
  pinFieldInvalidBorder: '#dc3545',
  pinFieldInvalidBoxShadow: '#dc354580',
  LoginBackgroundShadow: '#00000033',

  //UserPage
  ActionsBorder: '#27a3ac55',
  UserPageCancel: '#27a3ac',
  UserPageDeactivateButton: '#D32F2F',
  UserPageActivateButton: '#27a3ac',
  UserPageTextColor: '#000000',
  UserPageBackground: '#ffffff',
  UserPageBackgroundContainer: '#e9e9e9',
  UserPageBackgroundColor: '#f7f8fa',
  UserPagePrimary: '#27a3ac',
  PhoneEditIcon: '#b3b3b3',
  ProgressBar: '#e0e0de',
  UserActive: '#1F8B24',
  UserBlocked: '#D32F2F',
  HoverDeactivate: '#f0f0f0',

  //Payment Session List Item
  PaymentSessionListItemShadow: '#0000001a',

  // Transactions Page
  DividerColor: '#0000001a',
  CardShadow: '#e0e0e0',
  CardBackground: '#f1f1f1',

  //IFToastMessage
  ToastInfo: '#2196f3',
  ToastSuccess: '#4caf50',
  ToastError: '#f44336',
  ToastWarning: '#ffb300',
  HexTransparent: {
    T100: 'FF',
    T95: 'F2',
    T90: 'E6',
    T85: 'D9',
    T80: 'CC',
    T75: 'BF',
    T70: 'B3',
    T65: 'A6',
    T60: '99',
    T55: '8C',
    T50: '80',
    T45: '73',
    T40: '66',
    T35: '59',
    T30: '4D',
    T25: '40',
    T20: '33',
    T15: '26',
    T10: '1A',
    T5: '0D',
    T0: '00',
  },

  //TransactionList Detail Panel
  DetailPanel: '#818181',
  DividerColor: 'rgba(0, 0, 0, 0.3)',
  Status: 'rgba(0,0,0,0.05)',

  //IF Button
  transparencyOnHoverFill: 'E6',
  transparencyOnHoverNotFilled: '11',
  whitesmoke: 'whitesmoke',
  isDeadColor: '#e0e0e0',

  //SpinnerCircularFixed secondary color
  SpinnerSecondaryColor: 'ffffff4d',

  //Payment Pending status
  PaymentPendingStatus: '#fe9703',

  //VoucherPage
  SkeletonColor: '#0000001c',
  onHoverBackground: '#0000000a',

  //Dashboard
  DCColor: '#ac2765',
  SecondaryColor: '#ac8d27',
  ListBackground: '#f7f8fb',

  //AdminPage
  ActionDotBackground: '#d0f0f0',
  ActionLine: '#bdbdbd',

  NotSelectedText: '#b2b2b2',

  TariffListItem: {
    RemoveIcon: '#FF0000',
    AddIcon: '#1F8B24',
    gray: '#0000004d',
    black: '#000000',
    defaultSub: '#22BADB',
    DetailsGreyedOut: '#0000004D',
    NameGreyedOut: '#27a3ac4d',
    TooltipShadow: '#00000040',
    Tooltip: '#00000084',
  },
  // Upload Drop Zone
  DropZoneBackground: '#e1e1e1',
}

export default colors
