import { INITIAL_STATE } from './initialState'
import { RoleTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'

export const fetchRolesCountLoading = (state) => {
  return {
    ...state,
    fetchRolesCountRequestState: RequestState.LOADING,
  }
}

export const fetchRolesCountSuccess = (state, { count }) => {
  return {
    ...state,
    fetchRolesCountRequestState: RequestState.SUCCEEDED,
    count: count,
  }
}
export const fetchRolesCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRolesCountRequestState: requestState,
  }
}

export const updateRoleLoading = (state) => {
  return {
    ...state,
    updateRoleRequestState: RequestState.LOADING,
  }
}

export const updateRoleSuccess = (state, { role }) => {
  const updatedRolesWithAdmins = state.rolesWithAdmins.map((oldRole) => {
    const isMatch = oldRole._id === role.id

    return isMatch
      ? {
          ...oldRole,
          name: role.name,
          permissions: role.permissions,
          updatedAt: role.updatedAt,
          permissionsCount: role.permissions.length,
        }
      : oldRole
  })

  return {
    ...state,
    rolesWithAdmins: updatedRolesWithAdmins,
    updateRoleRequestState: RequestState.SUCCEEDED,
  }
}

export const updateRoleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateRoleRequestState: requestState,
  }
}

export const fetchRolesLoading = (state) => {
  return {
    ...state,
    fetchRolesRequestState: RequestState.LOADING,
  }
}

export const fetchRolesSuccess = (state, { roles }) => {
  return {
    ...state,
    fetchRolesRequestState: RequestState.SUCCEEDED,
    roles: roles,
  }
}

export const fetchRolesError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRolesRequestState: requestState,
  }
}
export const createRoleLoading = (state) => {
  return {
    ...state,
    createRoleRequestState: RequestState.LOADING,
  }
}
export const createRoleSuccess = (state, { role }) => {
  return {
    ...state,
    roles: [role, ...state.roles],
    rolesWithAdmins: [role, ...state.rolesWithAdmins],
    createRoleRequestState: RequestState.SUCCEEDED,
    count: state.count + 1,
  }
}
export const createRoleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createRoleRequestState: requestState,
  }
}

export const deleteRoleLoading = (state) => {
  return {
    ...state,
    deleteRoleRequestState: RequestState.LOADING,
  }
}

export const deleteRoleSuccess = (state, { roleId }) => {
  const updatedRoleList = state.rolesWithAdmins.filter(
    (role) => role._id !== roleId,
  )
  return {
    ...state,
    deleteRoleRequestState: RequestState.SUCCEEDED,
    rolesWithAdmins: updatedRoleList,
    count: state.count - 1 < 0 ? 0 : state.count - 1,
  }
}

export const deleteRoleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteRoleRequestState: requestState,
  }
}
export const fetchRolesWithAdminsLoading = (state) => {
  return {
    ...state,
    fetchRolesWithAdminsRequestState: RequestState.LOADING,
  }
}

export const fetchRolesWithAdminsSuccess = (
  state,
  { offset, rolesWithAdmins, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchRolesWithAdminsRequestState: RequestState.SUCCEEDED,
      rolesWithAdmins: rolesWithAdmins.map((rolesWithAdmin) => ({
        ...rolesWithAdmin,
      })),
      rolesPaginationOffset: nextOffset,
    }
  } else {
    return {
      ...state,
      rolesWithAdmins: [
        ...state.rolesWithAdmins,
        ...rolesWithAdmins.map((rolesWithAdmin) => ({
          ...rolesWithAdmin,
        })),
      ],
      fetchRolesWithAdminsRequestState: RequestState.SUCCEEDED,
      rolesPaginationOffset: nextOffset,
    }
  }
}

export const fetchRolesWithAdminsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRolesWithAdminsRequestState: requestState,
  }
}

export const fetchRoleAdminListLoading = (state) => {
  return {
    ...state,
    fetchRoleAdminListRequestState: RequestState.LOADING,
  }
}

export const fetchRoleAdminListSuccess = (
  state,
  { offset, admins, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchRoleAdminListRequestState: RequestState.SUCCEEDED,
      assignedAdmins: admins.map((admin) => ({
        ...admin,
      })),
      assignedAdminsPaginationOffset: nextOffset,
    }
  } else {
    return {
      ...state,
      assignedAdmins: [
        ...state.assignedAdmins,
        ...admins.map((admin) => ({
          ...admin,
        })),
      ],
      fetchRoleAdminListRequestState: RequestState.SUCCEEDED,
      assignedAdminsPaginationOffset: nextOffset,
    }
  }
}

export const fetchRoleAdminListFail = (state) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchRoleAdminListRequestState: requestState,
  }
}
export const clearAssignedRoleAdmins = (state) => {
  return {
    ...state,
    assignedAdmins: [],
    assignedAdminsPaginationOffset: 0,
    fetchRoleAdminListRequestState: RequestState.UNINITIALIZED,
  }
}

export const clearRoles = (state) => {
  return {
    ...state,
    fetchRolesWithAdminsRequestState: RequestState.UNINITIALIZED,
    rolesWithAdmins: [],
    rolesPaginationOffset: 0,
  }
}
export const reducer = createReducer(INITIAL_STATE, {
  [RoleTypes.FETCH_ROLES_COUNT_LOADING]: fetchRolesCountLoading,
  [RoleTypes.FETCH_ROLES_COUNT_SUCCESS]: fetchRolesCountSuccess,
  [RoleTypes.FETCH_ROLES_COUNT_FAIL]: fetchRolesCountFail,
  [RoleTypes.UPDATE_ROLE_LOADING]: updateRoleLoading,
  [RoleTypes.UPDATE_ROLE_SUCCESS]: updateRoleSuccess,
  [RoleTypes.UPDATE_ROLE_FAIL]: updateRoleFail,
  [RoleTypes.FETCH_ROLES_LOADING]: fetchRolesLoading,
  [RoleTypes.FETCH_ROLES_SUCCESS]: fetchRolesSuccess,
  [RoleTypes.FETCH_ROLES_ERROR]: fetchRolesError,
  [RoleTypes.CREATE_ROLE_LOADING]: createRoleLoading,
  [RoleTypes.CREATE_ROLE_SUCCESS]: createRoleSuccess,
  [RoleTypes.CREATE_ROLE_FAIL]: createRoleFail,
  [RoleTypes.DELETE_ROLE_LOADING]: deleteRoleLoading,
  [RoleTypes.DELETE_ROLE_SUCCESS]: deleteRoleSuccess,
  [RoleTypes.DELETE_ROLE_FAIL]: deleteRoleFail,
  [RoleTypes.FETCH_ROLE_ADMIN_LIST_LOADING]: fetchRoleAdminListLoading,
  [RoleTypes.FETCH_ROLE_ADMIN_LIST_SUCCESS]: fetchRoleAdminListSuccess,
  [RoleTypes.FETCH_ROLE_ADMIN_LIST_FAIL]: fetchRoleAdminListFail,
  [RoleTypes.FETCH_ROLES_WITH_ADMINS_LOADING]: fetchRolesWithAdminsLoading,
  [RoleTypes.FETCH_ROLES_WITH_ADMINS_SUCCESS]: fetchRolesWithAdminsSuccess,
  [RoleTypes.FETCH_ROLES_WITH_ADMINS_ERROR]: fetchRolesWithAdminsError,
  [RoleTypes.CLEAR_ASSIGNED_ROLE_ADMINS]: clearAssignedRoleAdmins,
  [RoleTypes.CLEAR_ROLES]: clearRoles,
})
