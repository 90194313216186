import Styles from './LoginPage.module.css'
import styled from 'styled-components'
import logo from 'Assets/Images/logo.png'
import { TextField, FormGroup } from '@mui/material'
import IFText from 'Components/IFText/IFText'
import IFButton from 'Components/IFButton/IFButton'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { Colors } from 'Theme'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AuthActions from 'Stores/Auth/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { styled as muiStyled } from '@mui/system'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  username: '',
  password: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`
const CssTextField = muiStyled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const LoginPage = ({ isLoginLoading, login }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      className={Styles.BackgroundDiv}
      style={{ backgroundColor: Colors.LoginBackground }}
    >
      <div
        className={Styles.Container}
        style={{
          backgroundColor: Colors.white,
          boxShadow: `0 0 20px 3px ${Colors.LoginBackgroundShadow}`,
        }}
      >
        <div
          className={Styles.WelcomeCard}
          style={{
            background: `linear-gradient(to bottom left,${Colors.LoginGradient1} ,  ${Colors.LoginGradient2} )`,
          }}
        >
          <IFText
            className={Styles.WelcomeTitle}
            style={{ color: Colors.white }}
          >
            {t('LoginPage.WelcomeTitle')}
          </IFText>
          <IFText
            className={Styles.WelcomeText}
            style={{ color: Colors.LoginBackground }}
          >
            {t('LoginPage.WelcomeText')}
          </IFText>
        </div>
        <div className={Styles.FormDiv}>
          <div className={Styles.LogoWrappingDiv}>
            <img className={Styles.Logo} src={logo} alt="logo" />
          </div>
          <FormContainer className={Styles.FormWrappingDiv}>
            <Formik
              validationSchema={object({
                username: string().required(t('LoginPage.Required')),
                password: string()
                  .required(t('LoginPage.Required'))
                  .min(8, t('LoginPage.ShortPassword')),
              })}
              initialValues={initialValues}
              onSubmit={(values) => {
                login(values.username, values.password)
              }} // TODO: submit values
            >
              {({ values, errors }) => (
                <Form>
                  <FormGroup className={Styles.InputWrappingDiv}>
                    <Field
                      sx={{
                        '& .MuiInputBase-input': {
                          fontFamily: 'ProximaNova',
                          fontSize: '14px',
                        },
                      }}
                      className={Styles.Input}
                      size="small"
                      name="username"
                      as={CssTextField}
                      variant="outlined"
                      label="Username"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: Colors.primary,
                          fontFamily: 'ProximaNova',
                        },
                      }}
                    />
                    <ErrorContainer className={Styles.ErrorStyling}>
                      <ErrorMessage name="username" />
                    </ErrorContainer>
                  </FormGroup>

                  <div
                    className={Styles.ForgotPasswordDiv}
                    onClick={() => {
                      navigate('/reset')
                    }}
                  >
                    <IFText
                      className={Styles.ForgotPassword}
                      style={{ color: Colors.ForgotPassword }}
                    >
                      {t('LoginPage.ForgotPassword')}
                    </IFText>
                  </div>
                  <FormGroup>
                    <Field
                      sx={{
                        '& .MuiInputBase-input': {
                          fontFamily: 'ProximaNova',
                          fontSize: '14px',
                        },
                      }}
                      as={CssTextField}
                      className={Styles.Input}
                      name="password"
                      type="password"
                      size="small"
                      variant="outlined"
                      label="Password"
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: Colors.primary,
                          fontFamily: 'ProximaNova',
                        },
                      }}
                    />
                    <ErrorContainer className={Styles.ErrorStyling}>
                      <ErrorMessage name="password" />
                    </ErrorContainer>
                  </FormGroup>

                  <IFButton
                    className={Styles.ButtonWrappingDiv}
                    color={Colors.primary}
                    isDead={
                      !(values.username && values.password) ||
                      (errors.password && errors.password.length > 0) ||
                      (errors.username && errors.username.length > 0)
                    }
                    isFill={true}
                    isLoading={isLoginLoading}
                    text={t('LoginPage.Login')}
                  />
                </Form>
              )}
            </Formik>
          </FormContainer>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(AuthActions.login(email, password)),
  }
}

const mapStateToProps = (state) => ({
  isLoginLoading: AuthSelectors.isLoginLoading(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
