import { INITIAL_STATE } from './initialState'
import { AdminTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import history from 'history/browser'
import { encodeObjectToBase64 } from '../../Utils/Base64Functions'
import InfinityEnums from 'Enums/InfinityEnums'

export const setFilterText = (state, { text }) => {
  return {
    ...state,
    filterText: text,
  }
}
export const setUpdateAdminRoleRequestState = (state, { newState }) => {
  return {
    ...state,
    updateAdminRoleRequestState: newState,
  }
}

export const fetchAdminListLoading = (state) => {
  return {
    ...state,
    fetchAdminListRequestState: RequestState.LOADING,
  }
}

export const fetchAdminListSuccess = (
  state,
  { offset, admins, nextOffset, filterObject, roles },
) => {
  let updatedFilters = filterObject
  if (offset == 0) {
    updatedFilters = filterObject.map((filter) => {
      if (
        filter.type === IFFIlterType.CHECK &&
        filter.title === InfinityEnums.IFFilterTitle.Role
      ) {
        return {
          ...filter,
          data: roles.map((role) => role.name),
        }
      }
      return filter
    })
  }
  const onAdminsPage =
    window.location.pathname.toLocaleLowerCase().indexOf('admin') ||
    history.location.pathname.indexOf('admin')

  if (onAdminsPage !== -1) {
    if (offset === 0) {
      history.push({
        search: `?filter=${encodeObjectToBase64(updatedFilters)}&tab=${0}`,
      })
      return {
        ...state,
        admins: admins.map((admin) => ({
          ...admin,
          role: admin.role || [],
        })),
        fetchAdminListRequestState: RequestState.SUCCEEDED,
        paginationOffset: nextOffset,
        adminsFilters: updatedFilters,
      }
    } else {
      return {
        ...state,
        admins: [
          ...state.admins,
          ...admins.map((admin) => ({
            ...admin,
            role: admin.role || [],
          })),
        ],
        fetchAdminListRequestState: RequestState.SUCCEEDED,
        paginationOffset: nextOffset,
      }
    }
  }
}
export const fetchAdminListError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminListRequestState: requestState,
  }
}

export const updateAdminRoleLoading = (state) => {
  return {
    ...state,
    updateAdminRoleRequestState: RequestState.LOADING,
  }
}

export const updateAdminRoleSuccess = (state, { admin }) => {
  const updatedAdmins = state.admins.map((oldAdmin) => {
    return oldAdmin.id === admin.id
      ? {
          ...oldAdmin,
          role: admin.role,
        }
      : oldAdmin
  })

  return {
    ...state,
    updateAdminRoleRequestState: RequestState.SUCCEEDED,
    admins: updatedAdmins,
  }
}

export const updateAdminRoleFail = (state) => {
  return {
    ...state,
    updateAdminRoleRequestState: RequestState.ERROR_UNKNOWN_OCCURRED,
  }
}

export const deleteAdminLoading = (state) => {
  return {
    ...state,
    deleteAdminRequestState: RequestState.LOADING,
  }
}

export const deleteAdminSuccess = (state, { adminId }) => {
  const updatedAdminList = state.admins.filter((admin) => admin.id !== adminId)
  return {
    ...state,
    deleteAdminRequestState: RequestState.SUCCEEDED,
    admins: updatedAdminList,
    count: state.count - 1 < 0 ? 0 : state.count - 1,
  }
}

export const deleteAdminFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteAdminRequestState: requestState,
  }
}
export const fetchAdminsCountLoading = (state) => {
  return {
    ...state,
    fetchAdminsCountRequestState: RequestState.LOADING,
  }
}
export const fetchAdminsCountSuccess = (state, { count }) => {
  return {
    ...state,
    fetchAdminsCountRequestState: RequestState.SUCCEEDED,
    count: count,
  }
}

export const fetchAdminsCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminsCountRequestState: requestState,
  }
}

export const inviteAdminLoading = (state) => {
  return {
    ...state,
    inviteAdminRequestState: RequestState.LOADING,
  }
}

export const inviteAdminSuccess = (state, { inviteAdminData }) => {
  return {
    ...state,
    inviteAdmins: [...state.inviteAdmins, inviteAdminData],
    adminsInvitationsCount: state.adminsInvitationsCount + 1,
    inviteAdminRequestState: RequestState.SUCCEEDED,
  }
}

export const inviteAdminFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    inviteAdminRequestState: requestState,
  }
}

export const fetchInviteAdminListLoading = (state) => {
  return {
    ...state,
    fetchInviteAdminListRequestState: RequestState.LOADING,
  }
}

export const fetchInviteAdminListSuccess = (
  state,
  { invitations, nextOffset, offset },
) => {
  if (offset === 0) {
    return {
      ...state,
      inviteAdmins: [...invitations],
      fetchInviteAdminListRequestState: RequestState.SUCCEEDED,
      inviteAdminPaginationOffset: nextOffset,
    }
  } else {
    return {
      ...state,
      inviteAdmins: [...state.inviteAdmins, ...invitations],
      fetchInviteAdminListRequestState: RequestState.SUCCEEDED,
      inviteAdminPaginationOffset: nextOffset,
    }
  }
}
export const fetchInviteAdminListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchInviteAdminListRequestState: requestState,
  }
}
export const deleteInviteAdminLoading = (state) => {
  return {
    ...state,
    deleteInviteAdminRequestState: RequestState.LOADING,
  }
}
export const deleteInviteAdminSuccess = (state, { inviteAdminId }) => {
  let newInviteAdmins = state.inviteAdmins.filter(
    (inviteAdmin) => inviteAdmin._id !== inviteAdminId,
  )

  return {
    ...state,
    inviteAdmins: [...newInviteAdmins],
    adminsInvitationsCount:
      state.adminsInvitationsCount - 1 < 0
        ? 0
        : state.adminsInvitationsCount - 1,
    deleteInviteAdminRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteInviteAdminListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteInviteAdminRequestState: requestState,
  }
}

export const sendAdminEmailLoading = (state) => {
  return {
    ...state,
    sendAdminEmailRequestState: RequestState.LOADING,
  }
}

export const sendAdminEmailSuccess = (state) => {
  return {
    ...state,
    sendAdminEmailRequestState: RequestState.SUCCEEDED,
  }
}
export const sendAdminEmailFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    sendAdminEmailRequestState: requestState,
  }
}
export const fetchInviteAdminLoading = (state) => {
  return {
    ...state,
    fetchInviteAdminRequestState: RequestState.LOADING,
  }
}

export const fetchInviteAdminSuccess = (state, { inviteAdmin }) => {
  return {
    ...state,
    inviteAdmin: { ...inviteAdmin },
    fetchInviteAdminRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchInviteAdminFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchInviteAdminRequestState: requestState,
  }
}
export const setInviteAdminStatus = (state, { status }) => {
  return {
    ...state,
    inviteAdminStatus: status,
  }
}

export const createAdminLoading = (state) => {
  return {
    ...state,
    createAdminRequestState: RequestState.LOADING,
  }
}

export const createAdminSuccess = (state) => {
  return {
    ...state,
    createAdminRequestState: RequestState.SUCCEEDED,
  }
}

export const createAdminFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createAdminRequestState: requestState,
  }
}

export const clearAdmins = (state) => {
  return {
    ...state,
    admins: [],
    paginationOffset: 0,
    fetchAdminListRequestState: RequestState.UNINITIALIZED,
  }
}

export const fetchAdminsInvitationsCountLoading = (state) => {
  return {
    ...state,
    fetchAdminsInvitationsCountRequestState: RequestState.LOADING,
  }
}
export const fetchAdminsInvitationsCountSuccess = (
  state,
  { adminsInvitationscount },
) => {
  return {
    ...state,
    fetchAdminsInvitationsCountRequestState: RequestState.SUCCEEDED,
    adminsInvitationsCount: adminsInvitationscount,
  }
}

export const fetchAdminsInvitationsCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAdminsInvitationsCountRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AdminTypes.SET_FILTER_TEXT]: setFilterText,
  [AdminTypes.SET_UPDATE_ADMIN_ROLE_REQUEST_STATE]:
    setUpdateAdminRoleRequestState,
  [AdminTypes.FETCH_ADMIN_LIST_LOADING]: fetchAdminListLoading,
  [AdminTypes.FETCH_ADMIN_LIST_SUCCESS]: fetchAdminListSuccess,
  [AdminTypes.FETCH_ADMIN_LIST_ERROR]: fetchAdminListError,
  [AdminTypes.UPDATE_ADMIN_ROLE_LOADING]: updateAdminRoleLoading,
  [AdminTypes.UPDATE_ADMIN_ROLE_SUCCESS]: updateAdminRoleSuccess,
  [AdminTypes.UPDATE_ADMIN_ROLE_FAIL]: updateAdminRoleFail,
  [AdminTypes.DELETE_ADMIN_LOADING]: deleteAdminLoading,
  [AdminTypes.DELETE_ADMIN_SUCCESS]: deleteAdminSuccess,
  [AdminTypes.DELETE_ADMIN_FAIL]: deleteAdminFail,
  [AdminTypes.FETCH_ADMINS_COUNT_LOADING]: fetchAdminsCountLoading,
  [AdminTypes.FETCH_ADMINS_COUNT_SUCCESS]: fetchAdminsCountSuccess,
  [AdminTypes.FETCH_ADMINS_COUNT_FAIL]: fetchAdminsCountFail,
  [AdminTypes.CLEAR_ADMINS]: clearAdmins,
  [AdminTypes.INVITE_ADMIN_LOADING]: inviteAdminLoading,
  [AdminTypes.INVITE_ADMIN_SUCCESS]: inviteAdminSuccess,
  [AdminTypes.INVITE_ADMIN_FAIL]: inviteAdminFail,
  [AdminTypes.FETCH_INVITE_ADMIN_LIST_LOADING]: fetchInviteAdminListLoading,
  [AdminTypes.FETCH_INVITE_ADMIN_LIST_SUCCESS]: fetchInviteAdminListSuccess,
  [AdminTypes.FETCH_INVITE_ADMIN_LIST_FAIL]: fetchInviteAdminListFail,
  [AdminTypes.DELETE_INVITE_ADMIN_LOADING]: deleteInviteAdminLoading,
  [AdminTypes.DELETE_INVITE_ADMIN_SUCCESS]: deleteInviteAdminSuccess,
  [AdminTypes.DELETE_INVITE_ADMIN_FAIL]: deleteInviteAdminListFail,
  [AdminTypes.SEND_ADMIN_EMAIL_LOADING]: sendAdminEmailLoading,
  [AdminTypes.SEND_ADMIN_EMAIL_SUCCESS]: sendAdminEmailSuccess,
  [AdminTypes.SEND_ADMIN_EMAIL_FAIL]: sendAdminEmailFail,
  [AdminTypes.FETCH_INVITE_ADMIN_LOADING]: fetchInviteAdminLoading,
  [AdminTypes.FETCH_INVITE_ADMIN_SUCCESS]: fetchInviteAdminSuccess,
  [AdminTypes.FETCH_INVITE_ADMIN_FAIL]: fetchInviteAdminFail,
  [AdminTypes.SET_INVITE_ADMIN_STATUS]: setInviteAdminStatus,
  [AdminTypes.CREATE_ADMIN_LOADING]: createAdminLoading,
  [AdminTypes.CREATE_ADMIN_SUCCESS]: createAdminSuccess,
  [AdminTypes.CREATE_ADMIN_FAIL]: createAdminFail,
  [AdminTypes.FETCH_ADMINS_INVITATIONS_COUNT_LOADING]:
    fetchAdminsInvitationsCountLoading,
  [AdminTypes.FETCH_ADMINS_INVITATIONS_COUNT_SUCCESS]:
    fetchAdminsInvitationsCountSuccess,
  [AdminTypes.FETCH_ADMINS_INVITATIONS_COUNT_FAIL]:
    fetchAdminsInvitationsCountFail,
})
