import RequestState from 'Enums/RequestState'
import { createReducer } from 'reduxsauce'
import { CountriesTypes } from './Actions'
import { INITIAL_STATE } from './initialState'
import IFFilterType from 'Enums/IFFilterType'

export const fetchCountriesListCountLoading = (state) => {
  return {
    ...state,
    fetchCountriesListCountRequestState: RequestState.LOADING,
  }
}
export const fetchCountriesListCountSuccess = (state, { countriesCount }) => {
  return {
    ...state,
    countriesCount: countriesCount,
    fetchCountriesListCountRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchCountriesListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCountriesListCountRequestState: requestState,
  }
}
export const fetchCountriesListLoading = (state) => {
  return {
    ...state,
    fetchCountriesRequestState: RequestState.LOADING,
  }
}

export const fetchCountriesListSuccess = (
  state,
  { offset, countries, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchCountriesRequestState: RequestState.SUCCEEDED,
      countriesPaginationOffset: nextOffset,
      countries: [...countries],
    }
  } else {
    return {
      ...state,
      fetchCountriesRequestState: RequestState.SUCCEEDED,
      countriesPaginationOffset: nextOffset,
      countries: [...state.countries, ...countries],
    }
  }
}

export const fetchCountriesListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCountriesRequestState: requestState,
  }
}

export const fetchCurrenciesListCountLoading = (state) => {
  return {
    ...state,
    fetchCurrenciesListCountRequestState: RequestState.LOADING,
  }
}
export const fetchCurrenciesListCountSuccess = (state, { currenciesCount }) => {
  return {
    ...state,
    currenciesCount: currenciesCount,
    fetchCurrenciesListCountRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchCurrenciesListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCurrenciesListCountRequestState: requestState,
  }
}
export const fetchCurrenciesListLoading = (state) => {
  return {
    ...state,
    fetchCurrenciesRequestState: RequestState.LOADING,
  }
}

export const fetchCurrenciesListSuccess = (
  state,
  { offset, currencies, nextOffset },
) => {
  if (offset === 0) {
    return {
      ...state,
      fetchCurrenciesRequestState: RequestState.SUCCEEDED,
      currenciesPaginationOffset: nextOffset,
      currencies: [...currencies],
    }
  } else {
    return {
      ...state,
      fetchCountriesRequestState: RequestState.SUCCEEDED,
      currenciesPaginationOffset: nextOffset,
      currencies: [...state.currencies, ...currencies],
    }
  }
}

export const fetchCurrenciesListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCurrenciesRequestState: requestState,
  }
}
export const setCountriesFilter = (state, { newFilter }) => {
  return {
    ...state,
    countriesFilters: newFilter,
  }
}
export const setCurrenciesFilter = (state, { newFilter }) => {
  return {
    ...state,
    currenciesFilters: newFilter,
  }
}
export const clearCountriesFilter = (state) => {
  return {
    ...state,
    countriesFilters: [
      {
        type: IFFilterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
    ],
  }
}
export const clearCountries = (state) => {
  return {
    ...state,
    countries: [],
  }
}
export const clearCurrenciesFilter = (state) => {
  return {
    ...state,
    currenciesFilters: [
      {
        type: IFFilterType.KEYWORD,
        title: null,
        data: null,
        initialValue: [],
        value: [],
      },
    ],
  }
}
export const fetchCountryBoundsLoading = (state) => {
  return {
    ...state,
    fetchCountryBoundsRequestState: RequestState.LOADING,
  }
}
export const fetchCountryBoundsSuccess = (state, { bounds }) => {
  return {
    ...state,
    bounds: bounds,
    fetchCountryBoundsRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchCountryBoundsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCountryBoundsRequestState: requestState,
  }
}
export const clearCountryBounds = (state) => {
  return {
    ...state,
    bounds: [],
  }
}
export const updateCountryLoading = (state) => {
  return {
    ...state,
    updateCountryRequestState: RequestState.LOADING,
  }
}

export const updateCountrySuccess = (state, { index, newCountry }) => {
  let newCountries = state.countries
  newCountries[index] = newCountry
  return {
    ...state,
    countries: [...newCountries],
    updateCountryRequestState: RequestState.SUCCEEDED,
  }
}
export const updateCountryFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateCountryRequestState: requestState,
  }
}
export const setUpdateCountryRequestState = (state, { requestState }) => {
  return {
    ...state,
    updateCountryRequestState: requestState,
  }
}
export const fetchAvailableCurrenciesLoading = (state) => {
  return {
    ...state,
    fetchAvailableCurrenciesRequestState: RequestState.LOADING,
  }
}

export const fetchAvailableCurrenciesSuccess = (state, { currencies }) => {
  return {
    ...state,
    fetchAvailableCurrenciesRequestState: RequestState.SUCCEEDED,
    availableCurrencies: [...currencies],
  }
}

export const fetchAvailableCurrenciesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAvailableCurrenciesRequestState: requestState,
  }
}
export const fetchCreateCountryBoundsLoading = (state) => {
  return {
    ...state,
    fetchCreateCountryBoundsRequestState: RequestState.LOADING,
  }
}
export const fetchCreateCountryBoundsSuccess = (state, { bounds }) => {
  return {
    ...state,
    createCountrybounds: bounds,
    fetchCreateCountryBoundsRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchCreateCountryBoundsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCreateCountryBoundsRequestState: requestState,
  }
}
export const updateCurrencyLoading = (state) => {
  return {
    ...state,
    updateCurrencyRequestState: RequestState.LOADING,
  }
}

export const updateCurrencySuccess = (state, { index, newCurrency }) => {
  let newCurrencies = state.currencies
  newCurrencies[index] = newCurrency
  return {
    ...state,
    currencies: [...newCurrencies],
    updateCurrencyRequestState: RequestState.SUCCEEDED,
  }
}
export const updateCurrencyFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateCurrencyRequestState: requestState,
  }
}
export const clearCreateCountryBounds = (state) => {
  return {
    ...state,
    fetchCreateCountryBoundsRequestState: RequestState.UNINITIALIZED,
    createCountrybounds: [],
  }
}
export const addCountrySuccess = (state, { country }) => {
  return {
    ...state,
    countries: [country, ...state.countries],
    countriesCount: state.countriesCount + 1,
    addCountryRequestState: RequestState.SUCCEEDED,
  }
}

export const addCountryLoading = (state) => {
  return {
    ...state,
    addCountryRequestState: RequestState.LOADING,
  }
}

export const addCountryFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCountryRequestState: requestState,
  }
}

export const setUpdateCurrencyRequestState = (state, { requestState }) => {
  return {
    ...state,
    updateCurrencyRequestState: requestState,
  }
}

export const addCurrencySuccess = (state, { currency }) => {
  return {
    ...state,
    currencies: [currency, ...state.currencies],
    currenciesCount: state.currenciesCount + 1,
    addCurrencyRequestState: RequestState.SUCCEEDED,
  }
}

export const addCurrencyLoading = (state) => {
  return {
    ...state,
    addCurrencyRequestState: RequestState.LOADING,
  }
}

export const addCurrencyFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCurrencyRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [CountriesTypes.FETCH_COUNTRIES_LIST_COUNT_LOADING]:
    fetchCountriesListCountLoading,
  [CountriesTypes.FETCH_COUNTRIES_LIST_COUNT_SUCCESS]:
    fetchCountriesListCountSuccess,
  [CountriesTypes.FETCH_COUNTRIES_LIST_COUNT_FAIL]: fetchCountriesListCountFail,
  [CountriesTypes.FETCH_COUNTRIES_LIST_LOADING]: fetchCountriesListLoading,
  [CountriesTypes.FETCH_COUNTRIES_LIST_SUCCESS]: fetchCountriesListSuccess,
  [CountriesTypes.FETCH_COUNTRIES_LIST_FAIL]: fetchCountriesListFail,

  [CountriesTypes.FETCH_CURRENCIES_LIST_COUNT_LOADING]:
    fetchCurrenciesListCountLoading,
  [CountriesTypes.FETCH_CURRENCIES_LIST_COUNT_SUCCESS]:
    fetchCurrenciesListCountSuccess,
  [CountriesTypes.FETCH_CURRENCIES_LIST_COUNT_FAIL]:
    fetchCurrenciesListCountFail,
  [CountriesTypes.FETCH_CURRENCIES_LIST_LOADING]: fetchCurrenciesListLoading,
  [CountriesTypes.FETCH_CURRENCIES_LIST_SUCCESS]: fetchCurrenciesListSuccess,
  [CountriesTypes.FETCH_CURRENCIES_LIST_FAIL]: fetchCurrenciesListFail,
  [CountriesTypes.SET_COUNTRIES_FILTER]: setCountriesFilter,
  [CountriesTypes.SET_CURRENCIES_FILTER]: setCurrenciesFilter,
  [CountriesTypes.CLEAR_COUNTRIES_FILTER]: clearCountriesFilter,
  [CountriesTypes.CLEAR_CURRENCIES_FILTER]: clearCurrenciesFilter,
  [CountriesTypes.CLEAR_COUNTRIES]: clearCountries,
  [CountriesTypes.FETCH_COUNTRY_BOUNDS_LOADING]: fetchCountryBoundsLoading,
  [CountriesTypes.FETCH_COUNTRY_BOUNDS_SUCCESS]: fetchCountryBoundsSuccess,
  [CountriesTypes.FETCH_COUNTRY_BOUNDS_FAIL]: fetchCountryBoundsFail,
  [CountriesTypes.CLEAR_COUNTRY_BOUNDS]: clearCountryBounds,
  [CountriesTypes.UPDATE_COUNTRY_LOADING]: updateCountryLoading,
  [CountriesTypes.UPDATE_COUNTRY_SUCCESS]: updateCountrySuccess,
  [CountriesTypes.UPDATE_COUNTRY_FAIL]: updateCountryFail,
  [CountriesTypes.SET_UPDATE_COUNTRY_REQUEST_STATE]:
    setUpdateCountryRequestState,
  [CountriesTypes.FETCH_AVAILABLE_CURRENCIES_LOADING]:
    fetchAvailableCurrenciesLoading,
  [CountriesTypes.FETCH_AVAILABLE_CURRENCIES_SUCCESS]:
    fetchAvailableCurrenciesSuccess,
  [CountriesTypes.FETCH_AVAILABLE_CURRENCIES_FAIL]:
    fetchAvailableCurrenciesFail,
  [CountriesTypes.FETCH_CREATE_COUNTRY_BOUNDS_LOADING]:
    fetchCreateCountryBoundsLoading,
  [CountriesTypes.FETCH_CREATE_COUNTRY_BOUNDS_SUCCESS]:
    fetchCreateCountryBoundsSuccess,
  [CountriesTypes.FETCH_CREATE_COUNTRY_BOUNDS_FAIL]:
    fetchCreateCountryBoundsFail,
  [CountriesTypes.CLEAR_CREATE_COUNTRY_BOUNDS]: clearCreateCountryBounds,
  [CountriesTypes.ADD_COUNTRY_SUCCESS]: addCountrySuccess,
  [CountriesTypes.ADD_COUNTRY_LOADING]: addCountryLoading,
  [CountriesTypes.ADD_COUNTRY_FAIL]: addCountryFail,
  [CountriesTypes.UPDATE_CURRENCY_LOADING]: updateCurrencyLoading,
  [CountriesTypes.UPDATE_CURRENCY_SUCCESS]: updateCurrencySuccess,
  [CountriesTypes.UPDATE_CURRENCY_FAIL]: updateCurrencyFail,
  [CountriesTypes.SET_UPDATE_CURRENCY_REQUEST_STATE]:
    setUpdateCurrencyRequestState,

  [CountriesTypes.ADD_CURRENCY_SUCCESS]: addCurrencySuccess,
  [CountriesTypes.ADD_CURRENCY_LOADING]: addCurrencyLoading,
  [CountriesTypes.ADD_CURRENCY_FAIL]: addCurrencyFail,
})
