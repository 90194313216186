import { INITIAL_STATE } from './initialState'
import { NotificationTypes } from './Actions'
import IFFIlterType from 'Enums/IFFilterType'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import {
  encodeObjectToBase64,
  decodeBase64ToObject,
} from '../../Utils/Base64Functions'
import InfinityEnums from 'Enums/InfinityEnums'
import { invalid } from 'moment/moment'

export const fetchNotificationGroupsLoading = (state) => {
  return {
    ...state,
    fetchNotificationGroupRequestState: RequestState.LOADING,
  }
}

export const fetchNotificationGroupsSuccess = (
  state,
  { notificationGroups },
) => {
  let result = []
  for (let j = 0; j < notificationGroups.length; j++) {
    if (
      !state.notificationGroups.some(
        (notifGroup) => notifGroup.id === notificationGroups[j].id,
      )
    ) {
      result.push(notificationGroups[j])
    }
  }
  return {
    ...state,
    notificationGroups: [...state.notificationGroups, ...result],
    fetchNotificationGroupRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchNotificationGroupsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchNotificationGroupRequestState: requestState,
  }
}

export const fetchNotificationsLoading = (state) => {
  return {
    ...state,
    fetchNotificationsRequestState: RequestState.LOADING,
  }
}
export const fetchNotificationsSuccess = (
  state,
  { offset, notifications, nextOffset },
) => {
  let result = []
  for (let j = 0; j < notifications.length; j++) {
    if (
      !state.notifications.some(
        (notification) => notification.id === notifications[j].id,
      )
    ) {
      result.push(notifications[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      fetchNotificationsRequestState: RequestState.SUCCEEDED,
      notificationsPaginationOffset: nextOffset,
      notifications: [...notifications],
    }
  } else {
    return {
      ...state,
      fetchNotificationsRequestState: RequestState.SUCCEEDED,
      notificationsPaginationOffset: nextOffset,
      notifications: [...state.notifications, ...notifications],
    }
  }
}

export const fetchNotificationsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchNotificationsRequestState: requestState,
  }
}

export const editNotificationLoading = (state) => {
  return {
    ...state,
    editNotificationRequestState: RequestState.LOADING,
  }
}
export const editNotificationSuccess = (state, { notification }) => {
  const updatedNotifications = state.notifications.map((n) =>
    n.id === notification.id ? notification : n,
  )
  return {
    ...state,
    notifications: updatedNotifications,
    editNotificationRequestState: RequestState.SUCCEEDED,
  }
}

export const editNotificationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    editNotificationRequestState: requestState,
  }
}

export const scheduleNotificationLoading = (state) => {
  return {
    ...state,
    scheduleNotificationRequestState: RequestState.LOADING,
  }
}
export const scheduleNotificationSuccess = (state, { notification }) => {
  return {
    ...state,
    notifications: [notification, ...state.notifications],
    notificationsCount: state.notificationsCount + 1,
    scheduleNotificationRequestState: RequestState.SUCCEEDED,
  }
}

export const scheduleNotificationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    scheduleNotificationRequestState: requestState,
  }
}

export const clearNotifications = (state) => {
  return {
    ...state,
    notifications: [],
    paginationOffset: 0,
    fetchNotificationsRequestState: RequestState.UNINITIALIZED,
  }
}

export const fetchNotificationsCountLoading = (state) => {
  return {
    ...state,
    fetchNotificationsCountRequestState: RequestState.LOADING,
  }
}
export const fetchNotificationsCountSuccess = (state, { count }) => {
  return {
    ...state,
    notificationsCount: count,
    fetchNotificationsCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchNotificationsCountError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchNotificationsCountRequestState: requestState,
  }
}

export const fetchGroupsCountLoading = (state) => {
  return {
    ...state,
    fetchGroupsCountRequestState: RequestState.LOADING,
  }
}
export const fetchGroupsCountSuccess = (state, { count }) => {
  return {
    ...state,
    groupsCount: count,
    fetchGroupsCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchGroupsCountError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchGroupsCountRequestState: requestState,
  }
}

export const setCountryFilterNoti = (state, { admin }) => {
  let newNotiFilters = state.notificationsFilter
  if (admin.registeredCountries.length > 1) {
    const countryNames = admin.registeredCountries.map(
      (country) => country.name,
    )
    let index = state.notificationsFilter.findIndex(
      (filter) =>
        filter.type === IFFIlterType.CHECK && filter.field === 'Country',
    )
    if (index === -1)
      newNotiFilters.push({
        type: IFFIlterType.CHECK,
        title: InfinityEnums.IFFilterTitle.Country,
        data: countryNames,
        initialValue: [],
        value: [],
        field: 'Country',
      })
  }
  return {
    ...state,
    notificationsFilter: [...newNotiFilters],
  }
}

export const setCheckFilter = (state, { names }) => {
  let newNotiFilters = state.notificationsFilter
  let index = state.notificationsFilter.findIndex(
    (filter) => filter.type === IFFIlterType.CHECK && filter.field === 'Groups',
  )
  newNotiFilters[index] = {
    ...newNotiFilters[index],
    data: [...names],
  }
  return {
    ...state,
    notificationsFilter: newNotiFilters,
  }
}

export const setNotificationsFilter = (state, { newFilter }) => {
  return {
    ...state,
    notificationsFilter: newFilter,
  }
}

export const fetchGroupsLoading = (state) => {
  return {
    ...state,
    fetchGroupsRequestState: RequestState.LOADING,
  }
}
export const fetchGroupsSuccess = (state, { offset, groups, nextOffset }) => {
  let result = []
  for (let j = 0; j < groups.length; j++) {
    if (!state.groups.some((group) => group.id === groups[j].id)) {
      result.push(groups[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      fetchGroupsRequestState: RequestState.SUCCEEDED,
      groupsPaginationOffset: nextOffset,
      groups: [...groups],
    }
  } else {
    return {
      ...state,
      fetchGroupsRequestState: RequestState.SUCCEEDED,
      groupsPaginationOffset: nextOffset,
      groups: [...state.groups, ...groups],
    }
  }
}

export const fetchGroupsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchGroupsRequestState: requestState,
  }
}

export const editGroupLoading = (state) => {
  return {
    ...state,
    editGroupRequestState: RequestState.LOADING,
  }
}
export const editGroupSuccess = (state, { group }) => {
  const updatedGroups = state.groups.map((n) => (n.id === group.id ? group : n))
  return {
    ...state,
    groups: updatedGroups,
    editGroupRequestState: RequestState.SUCCEEDED,
  }
}

export const editGroupFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    editGroupRequestState: requestState,
  }
}
export const deleteGroupLoading = (state) => {
  return {
    ...state,
    deleteGroupRequestState: RequestState.LOADING,
  }
}
export const deleteGroupSuccess = (state, { id }) => {
  const updatedGroups = state.groups.filter((group) => group.id !== id)
  return {
    ...state,
    groups: updatedGroups,
    deleteGroupRequestState: RequestState.SUCCEEDED,
    groupsCount: state.groupsCount - 1 < 0 ? 0 : state.groupsCount - 1,
  }
}
export const deleteGroupFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteGroupRequestState: requestState,
  }
}

export const createGroupLoading = (state) => {
  return {
    ...state,
    createGroupRequestState: RequestState.LOADING,
  }
}
export const createGroupSuccess = (state, { group }) => {
  return {
    ...state,
    groups: [group, ...state.groups],
    groupsCount: state.groupsCount + 1,
    createGroupRequestState: RequestState.SUCCEEDED,
  }
}

export const createGroupFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createGroupRequestState: requestState,
  }
}

export const fetchQueryCountLoading = (state) => {
  return {
    ...state,
    fetchQueryCountRequestState: RequestState.LOADING,
  }
}
export const fetchQueryCountSuccess = (state, { count }) => {
  return {
    ...state,
    queryCount: count,
    invalidId: false,
    fetchQueryCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchQueryCountError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchQueryCountRequestState: requestState,
  }
}

export const setInvalidId = (state, { bool }) => {
  return {
    ...state,
    invalidId: bool,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_LOADING]:
    fetchNotificationGroupsLoading,
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_SUCCESS]:
    fetchNotificationGroupsSuccess,
  [NotificationTypes.FETCH_NOTIFICATION_GROUPS_ERROR]:
    fetchNotificationGroupsError,
  [NotificationTypes.FETCH_NOTIFICATIONS_LOADING]: fetchNotificationsLoading,
  [NotificationTypes.FETCH_NOTIFICATIONS_SUCCESS]: fetchNotificationsSuccess,
  [NotificationTypes.FETCH_NOTIFICATIONS_ERROR]: fetchNotificationsError,
  [NotificationTypes.EDIT_NOTIFICATION_LOADING]: editNotificationLoading,
  [NotificationTypes.EDIT_NOTIFICATION_SUCCESS]: editNotificationSuccess,
  [NotificationTypes.EDIT_NOTIFICATION_FAIL]: editNotificationFail,
  [NotificationTypes.SCHEDULE_NOTIFICATION_LOADING]:
    scheduleNotificationLoading,
  [NotificationTypes.SCHEDULE_NOTIFICATION_SUCCESS]:
    scheduleNotificationSuccess,
  [NotificationTypes.SCHEDULE_NOTIFICATION_FAIL]: scheduleNotificationFail,
  [NotificationTypes.CLEAR_NOTIFICATIONS]: clearNotifications,
  [NotificationTypes.FETCH_NOTIFICATIONS_COUNT_LOADING]:
    fetchNotificationsCountLoading,
  [NotificationTypes.FETCH_NOTIFICATIONS_COUNT_SUCCESS]:
    fetchNotificationsCountSuccess,
  [NotificationTypes.FETCH_NOTIFICATIONS_COUNT_ERROR]:
    fetchNotificationsCountError,
  [NotificationTypes.FETCH_GROUPS_COUNT_LOADING]: fetchGroupsCountLoading,
  [NotificationTypes.FETCH_GROUPS_COUNT_SUCCESS]: fetchGroupsCountSuccess,
  [NotificationTypes.FETCH_GROUPS_COUNT_ERROR]: fetchGroupsCountError,
  [NotificationTypes.SET_COUNTRY_FILTER_NOTI]: setCountryFilterNoti,
  [NotificationTypes.SET_CHECK_FILTER]: setCheckFilter,
  [NotificationTypes.SET_NOTIFICATIONS_FILTER]: setNotificationsFilter,
  [NotificationTypes.FETCH_GROUPS_LOADING]: fetchGroupsLoading,
  [NotificationTypes.FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
  [NotificationTypes.FETCH_GROUPS_ERROR]: fetchGroupsError,
  [NotificationTypes.EDIT_GROUP_LOADING]: editGroupLoading,
  [NotificationTypes.EDIT_GROUP_SUCCESS]: editGroupSuccess,
  [NotificationTypes.EDIT_GROUP_FAIL]: editGroupFail,
  [NotificationTypes.DELETE_GROUP_LOADING]: deleteGroupLoading,
  [NotificationTypes.DELETE_GROUP_SUCCESS]: deleteGroupSuccess,
  [NotificationTypes.DELETE_GROUP_FAIL]: deleteGroupFail,
  [NotificationTypes.CREATE_GROUP_LOADING]: createGroupLoading,
  [NotificationTypes.CREATE_GROUP_SUCCESS]: createGroupSuccess,
  [NotificationTypes.CREATE_GROUP_FAIL]: createGroupFail,
  [NotificationTypes.FETCH_QUERY_COUNT_LOADING]: fetchQueryCountLoading,
  [NotificationTypes.FETCH_QUERY_COUNT_SUCCESS]: fetchQueryCountSuccess,
  [NotificationTypes.FETCH_QUERY_COUNT_ERROR]: fetchQueryCountError,
  [NotificationTypes.SET_INVALID_ID]: setInvalidId,
})
