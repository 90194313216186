import RequestState from 'Enums/RequestState'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchRolesCountRequestState: RequestState.UNINITIALIZED,
  updateRoleRequestState: RequestState.UNINITIALIZED,
  fetchRolesRequestState: RequestState.UNINITIALIZED,
  createRoleRequestState: RequestState.UNINITIALIZED,
  deleteRoleRequestState: RequestState.UNINITIALIZED,
  fetchRoleAdminListRequestState: RequestState.UNINITIALIZED,
  fetchRolesWithAdminsRequestState: RequestState.UNINITIALIZED,
  count: 0,
  rolesPaginationOffset: 0,
  assignedAdminsPaginationOffset: 0,
  assignedAdmins: [],
  roles: [],
  rolesWithAdmins: [],
}
