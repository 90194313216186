import { IFText, IFSkeleton } from 'Components'
import Styles from './TransactionCard.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import styled from 'styled-components'
import { Colors } from 'Theme'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: column;
`

const TransactionCard = ({
  title,
  data,
  percentageChange,
  period,
  metric,
  style,
  isLoading,
}) => {
  return (
    <div className={style}>
      {isLoading ? (
        <Card>
          <div className={Styles.CardHeader}>
            <IFSkeleton variant="rectangular" width="100px" height="14px" />
          </div>
          <div className={Styles.DPContainer} style={{ marginTop: '38px' }}>
            <IFSkeleton
              className={Styles.Data}
              variant="text"
              width="70px"
              height="30px"
            />
            <div>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <IFSkeleton
                  className={Styles.Percentage}
                  variant="text"
                  width="50px"
                  height="12px"
                />
              </div>
              <IFSkeleton
                className={Styles.Period}
                variant="text"
                width="50px"
                height="12px"
              />
            </div>
          </div>
        </Card>
      ) : (
        <Card>
          <IFText className={Styles.CardHeader} style={{ color: Colors.text }}>
            {metric ? `${title} (${metric})` : title}
          </IFText>
          <div className={Styles.DPContainer}>
            <IFText className={Styles.Data} style={{ color: Colors.text }}>
              {parseFloat(data?.toFixed(0)).toLocaleString()}
            </IFText>

            {percentageChange !== null && (
              <div>
                <div
                  style={{
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowOutwardIcon
                    sx={{
                      fontSize: '14px',
                      padding: 0,
                      marginRight: '4px',
                      color: percentageChange > 0 ? 'green' : 'red',
                      transform:
                        percentageChange > 0 ? 'none' : 'rotate(90deg)',
                    }}
                  />
                  <IFText
                    className={Styles.Percentage}
                    style={{ color: percentageChange > 0 ? 'green' : 'red' }}
                  >
                    {`${Math.abs(
                      parseFloat(percentageChange?.toFixed(1)),
                    ).toLocaleString()}%`}
                  </IFText>
                </div>
                <IFText className={Styles.Period}>{period}</IFText>
              </div>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

TransactionCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.number,
  percentageChange: PropTypes.number,
  period: PropTypes.string,
  metric: PropTypes.string,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default TransactionCard
