const getCountriesCount = (state) => {
  return state.main.countriesState.countriesCount
}
const getFetchCountriesListCountRequestState = (state) => {
  return state.main.countriesState.fetchCountriesListCountRequestState
}
const getFetchCountriesListRequestState = (state) => {
  return state.main.countriesState.fetchCountriesRequestState
}
const getCountriesFilters = (state) => {
  return state.main.countriesState.countriesFilters
}
const getCountriesList = (state) => {
  return state.main.countriesState.countries
}
const getCountriesListOffset = (state) => {
  return state.main.countriesState.countriesPaginationOffset
}

const getCurrenciesCount = (state) => {
  return state.main.countriesState.currenciesCount
}
const getFetchCurrenciesListCountRequestState = (state) => {
  return state.main.countriesState.fetchCurrenciesListCountRequestState
}
const getFetchCurrenciesListRequestState = (state) => {
  return state.main.countriesState.fetchCurrenciesRequestState
}
const getCurrenciesFilters = (state) => {
  return state.main.countriesState.currenciesFilters
}
const getCurrenciesList = (state) => {
  return state.main.countriesState.currencies
}
const getCurrenciesListOffset = (state) => {
  return state.main.countriesState.currenciesPaginationOffset
}
const getFetchCountryBoundstRequestState = (state) => {
  return state.main.countriesState.fetchCountryBoundsRequestState
}
const getCountryBounds = (state) => {
  return state.main.countriesState.bounds
}
const getUpdateCountryRequestState = (state) => {
  return state.main.countriesState.updateCountryRequestState
}
const getAvailableCurrencies = (state) => {
  return state.main.countriesState.availableCurrencies
}
const getAvailableCurrenciesRequestState = (state) => {
  return state.main.countriesState.fetchAvailableCurrenciesRequestState
}
const getFetchCreateCountryBoundstRequestState = (state) => {
  return state.main.countriesState.fetchCreateCountryBoundsRequestState
}
const getCreateCountryBounds = (state) => {
  return state.main.countriesState.createCountrybounds
}
const getAddCountryRequestState = (state) => {
  return state.main.countriesState.addCountryRequestState
}
const getUpdateCurrencyRequestState = (state) => {
  return state.main.countriesState.updateCurrencyRequestState
}
const getAddCurrencyRequestState = (state) => {
  return state.main.countriesState.addCurrencyRequestState
}

const CountriesSelectors = {
  getCountriesCount,
  getFetchCountriesListCountRequestState,
  getCountriesFilters,
  getCountriesList,
  getCountriesListOffset,
  getFetchCountriesListRequestState,
  getFetchCountryBoundstRequestState,
  getCountryBounds,
  getUpdateCountryRequestState,
  getCreateCountryBounds,
  getFetchCreateCountryBoundstRequestState,
  getAddCountryRequestState,

  getCurrenciesCount,
  getFetchCurrenciesListCountRequestState,
  getCurrenciesFilters,
  getCurrenciesList,
  getCurrenciesListOffset,
  getFetchCurrenciesListRequestState,
  getAvailableCurrencies,
  getAvailableCurrenciesRequestState,
  getUpdateCurrencyRequestState,
  getAddCurrencyRequestState,
}

export default CountriesSelectors
