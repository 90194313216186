import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchDashboardMetricsLoading: null,
  fetchDashboard: ['filter'],
  fetchDashboardMetricsSuccess: ['metrics'],
  fetchDashboardMetricsError: ['error'],
  setCountryFilter: ['admin'],
  setDashboardFilter: ['newFilter'],
  clearDashboardFilter: null,
})

export const DashboardTypes = Types
export default Creators
