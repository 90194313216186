import numeral from 'numeral'

// Makes sure only thing changed in filter is the city
export function getTextWidth(txt, font) {
  let element = document.createElement('canvas')
  let context = element.getContext('2d')
  context.font = font
  return context.measureText(txt).width + 16
}

export function increaseBrightness(hex, percent) {
  // strip the leading # if it's there
  hex = hex.replace(/^\s*#|\s*$/g, '')

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1')
  }

  var r = parseInt(hex.substr(0, 2), 16),
    g = parseInt(hex.substr(2, 2), 16),
    b = parseInt(hex.substr(4, 2), 16)

  return (
    '#' +
    (0 | ((1 << 8) + r + ((256 - r) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + g + ((256 - g) * percent) / 100)).toString(16).substr(1) +
    (0 | ((1 << 8) + b + ((256 - b) * percent) / 100)).toString(16).substr(1)
  )
}

export function isOverflowActive(event) {
  return event
    ? event.offsetHeight < event.scrollHeight ||
        event.offsetWidth < event.scrollWidth
    : false
}

export function strToRGB(str) {
  if (!str) return '000000'
  var hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  var color = (hash & 0x00ffffff).toString(16).toUpperCase()
  return '000000'.substring(0, 6 - color.length) + color
}

export function formatNumber(number) {
  return numeral(number)
    .format('0.0a')
    .replace(/\.0(?=[kmbt]?$)/, '')
    .replace('m', 'M')
    .replace('b', 'B')
}
