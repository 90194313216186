import { IFAddButton, IFText, TariffListItem } from 'Components'
import React, { useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import Styles from './TariffList.module.css'
import { Virtuoso } from 'react-virtuoso'
import { useTranslation } from 'react-i18next'
import { Colors } from 'Theme'

const TariffList = React.forwardRef(
  (
    {
      displayedList = [],
      dropdownList = [],
      isEditing,
      onSubmit = () => {},
      onDataChange = () => {},
      hasPoints,
    },
    ref,
  ) => {
    const [deleteTariffs, setDeleteTariffs] = useState([])
    const [addTariffs, setAddTariffs] = useState([])
    const [filteredTariffs, setFilteredTariffs] = useState([])
    const [combinedTariffs, setCombinedTariffs] = useState(displayedList)
    const [finalTariffs, setFinalTariffs] = useState({
      add: [],
      delete: [],
      edit: [],
    })
    const { t } = useTranslation()

    // Work around for displayedList firing useEffect without it's value changing
    const [newDisplayedList, setNewDisplayedList] = useState(displayedList)
    useEffect(() => {
      if (JSON.stringify(displayedList) !== JSON.stringify(newDisplayedList)) {
        setNewDisplayedList(displayedList)
      }
    }, [displayedList])

    const handleEnergyConsumptionChange = (_id, energyConsumed) => {
      const index = combinedTariffs.findIndex(
        (tariff) => tariff._id.tariffId === _id.tariffId,
      )
      if (index > -1) {
        let newCombinedTariffs = combinedTariffs
        newCombinedTariffs[index].energyConsumed = energyConsumed
        setCombinedTariffs(newCombinedTariffs)
        onChange(addTariffs, deleteTariffs, newCombinedTariffs)
      }
    }

    useEffect(() => {
      const formattedTariffs = newDisplayedList?.map((tariff) => {
        return {
          ...tariff,
          origin: 'original',
        }
      })
      setCombinedTariffs(formattedTariffs)
    }, [newDisplayedList])

    useEffect(() => {
      setDeleteTariffs([])
      setAddTariffs([])
    }, [isEditing])

    useEffect(() => {
      let difference = dropdownList.filter((sub) => {
        const found = newDisplayedList.filter((generalSub) => {
          return generalSub._id.tariffId === sub._id.tariffId
        })
        return found.length === 0
      })
      setFilteredTariffs(difference)
    }, [dropdownList, newDisplayedList, isEditing])

    const handleTariffAdd = (_id) => {
      const index = addTariffs.findIndex((id) => id.tariffId === _id.tariffId)
      if (index === -1) {
        setAddTariffs((current) => [...current, _id])
        onChange([...addTariffs, _id], deleteTariffs, combinedTariffs)
      } else {
        let newAddTariffs = addTariffs.filter(
          (id) => id.tariffId !== _id.tariffId,
        )
        setAddTariffs(newAddTariffs)
        onChange(newAddTariffs, deleteTariffs, combinedTariffs)
      }
    }

    const handleRemove = (_id, toBeRemoved) => {
      if (toBeRemoved) {
        const index = deleteTariffs.findIndex(
          (id) => id.tariffId === _id.tariffId,
        )
        if (index === -1) {
          setDeleteTariffs([...deleteTariffs, _id])
          onChange(addTariffs, [...deleteTariffs, _id], combinedTariffs)
        }
      } else {
        const index = deleteTariffs.findIndex(
          (id) => id.tariffId === _id.tariffId,
        )
        if (index > -1) {
          let newDeleteTariffs = deleteTariffs.filter(
            (id) => id.tariffId !== _id.tariffId,
          )
          setDeleteTariffs(newDeleteTariffs)
          onChange(addTariffs, newDeleteTariffs, combinedTariffs)
        }
      }
    }
    const handleAdd = (_id) => {
      let index = addTariffs.findIndex((id) => id.tariffId === _id.tariffId)
      if (index === -1) {
        setAddTariffs([...addTariffs, _id])
      }

      index = dropdownList.findIndex((sub) => sub._id.tariffId === _id.tariffId)
      if (index > -1) {
        const alreadyExists = combinedTariffs.some((sub) => {
          return sub._id.tariffId === _id.tariffId
        })
        if (!alreadyExists) {
          const formattedNewTariff = {
            ...dropdownList[index],
            origin: 'new',
          }
          setCombinedTariffs((prevState) => {
            return [...prevState, formattedNewTariff]
          })
          onChange([...addTariffs, _id], deleteTariffs, [
            ...combinedTariffs,
            formattedNewTariff,
          ])
        }
      }

      let newFilteredSubs = filteredTariffs.filter((sub) => {
        return sub._id.tariffId !== _id.tariffId
      })
      setFilteredTariffs(newFilteredSubs)
    }

    const handleSubmit = () => {
      onSubmit(finalTariffs)
    }

    const onChange = (addTariffs, deleteTariffs, combinedTariffs) => {
      let finalTariffs = { add: [], delete: [], edit: [] }

      let tempCombinedTariffs = combinedTariffs
      let newTariffs = tempCombinedTariffs.filter(
        (combTariff) => combTariff.origin === 'new',
      )

      for (const newTariff of newTariffs) {
        const index = addTariffs.findIndex(
          (addId) => addId.tariffId === newTariff._id.tariffId,
        )
        if (index > -1) {
          finalTariffs.add.push({
            _id: newTariff._id.tariffId,
            subId: newTariff._id.subId,
            energyConsumed: newTariff.energyConsumed,
          })
        }
      }

      let originalTariffs = tempCombinedTariffs.filter(
        (combTariff) => combTariff.origin === 'original',
      )

      for (const origTariff of originalTariffs) {
        const index = deleteTariffs.findIndex(
          (deleteId) => deleteId.tariffId === origTariff._id.tariffId,
        )

        if (index > -1) {
          finalTariffs.delete.push({
            _id: origTariff._id.tariffId,
            subId: origTariff._id.subId,
          })
        } else {
          const displayedListIndex = displayedList.findIndex(
            (listTariff) => listTariff._id.tariffId === origTariff._id.tariffId,
          )
          if (displayedListIndex > -1) {
            if (
              displayedList[displayedListIndex].energyConsumed !==
              origTariff.energyConsumed
            ) {
              finalTariffs.edit.push({
                _id: origTariff._id.tariffId,
                subId: origTariff._id.subId,
                energyConsumed: origTariff.energyConsumed,
              })
            }
          }
        }
      }

      setFinalTariffs(finalTariffs)
      if (
        finalTariffs.add.some((tariff) => tariff.energyConsumed === 0) ||
        (finalTariffs.add.length === 0 &&
          finalTariffs.edit.length === 0 &&
          (finalTariffs.delete.length === originalTariffs.length ||
            finalTariffs.delete.length === 0))
      )
        onDataChange(false)
      else onDataChange(true)
    }

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }))

    return (
      <div
        className={
          isEditing
            ? Styles.SubscriptionListWrapperEditing
            : Styles.SubscriptionListWrapper
        }
      >
        {combinedTariffs?.length === 0 ? (
          <div className={Styles.EmptyContainer}>
            <div>{t('Tariffs.NoTariffs')}</div>
          </div>
        ) : (
          <div className={Styles.ListWrapper}>
            <Virtuoso
              data={combinedTariffs}
              increaseViewportBy={480}
              itemContent={(index, sub) => {
                if (sub && sub.origin === 'original') {
                  return (
                    <div style={{ marginBottom: '0.5rem' }}>
                      <TariffListItem
                        _id={sub._id}
                        subscriptionName={sub.subscriptionName}
                        subscriptionTime={sub.subscriptionTime}
                        tariffName={sub.tariffName}
                        currentType={sub.currentType}
                        moneyTariff={sub.moneyTariff}
                        pointsTariff={sub.pointsTariff}
                        maxPower={sub.maxPower}
                        energyConsumed={
                          sub.energyConsumed ? sub.energyConsumed / 1000 : 0
                        }
                        isEditing={isEditing}
                        onIconClick={(tariffId, toBeRemoved) => {
                          handleRemove(tariffId, toBeRemoved)
                        }}
                        onEnergyConsumedChange={(_id, value) => {
                          handleEnergyConsumptionChange(_id, value)
                        }}
                        hasPoints={hasPoints}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div style={{ marginTop: '0.5rem' }}>
                      <TariffListItem
                        _id={sub._id}
                        subscriptionName={sub.subscriptionName}
                        subscriptionTime={sub.subscriptionTime}
                        tariffName={sub.tariffName}
                        currentType={sub.currentType}
                        moneyTariff={sub.moneyTariff}
                        pointsTariff={sub.pointsTariff}
                        maxPower={sub.maxPower}
                        energyConsumed={
                          sub.energyConsumed ? sub.energyConsumed / 1000 : 0
                        }
                        isEditing={isEditing}
                        onIconClick={() => {
                          handleTariffAdd(sub._id)
                        }}
                        onEnergyConsumedChange={(_id, value) =>
                          handleEnergyConsumptionChange(_id, value)
                        }
                        hasPoints={hasPoints}
                      />
                    </div>
                  )
                }
              }}
            />
          </div>
        )}
        {isEditing && (
          <div className={Styles.ChargingSpecsAdd}>
            <IFAddButton
              data={filteredTariffs}
              onSelect={(value) => {
                handleAdd(value)
              }}
              RenderMenuItem={TariffListItem}
              RenderMenuItemkeys={[
                '_id',
                'subscriptionName',
                'subscriptionTime',
                'tariffName',
                'currentType',
                'moneyTariff',
                'pointsTariff',
                'maxPower',
                'energyConsumed',
                'isEditing',
                'onIconClick',
                'onEnergyConsumedChange',
                'hasPoints',
              ]}
              headers={
                <div className={Styles.HeaderRowContainer}>
                  <div className={Styles.HeaderNameContainer}>
                    <IFText
                      className={Styles.HeaderText}
                      style={{ color: Colors.text }}
                    >
                      {t('Tariffs.Subscriptions')}
                    </IFText>
                  </div>
                  <div className={Styles.HeaderContainer}>
                    <IFText
                      className={Styles.HeaderText}
                      style={{ color: Colors.text }}
                    >
                      {t('Tariffs.CurrentType')}
                    </IFText>
                  </div>
                  <div className={Styles.HeaderContainer}>
                    <IFText
                      className={Styles.HeaderText}
                      style={{ color: Colors.text }}
                    >
                      {t('Tariffs.MoneyTariff')}
                    </IFText>
                  </div>
                  {hasPoints && (
                    <div className={Styles.HeaderContainer}>
                      <IFText
                        className={Styles.HeaderText}
                        style={{ color: Colors.text }}
                      >
                        {t('Tariffs.PointsTariff')}
                      </IFText>
                    </div>
                  )}
                </div>
              }
            />
          </div>
        )}
      </div>
    )
  },
)

TariffList.propTypes = {
  displayedList: PropTypes.array,
  dropdownList: PropTypes.array,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDataChange: PropTypes.func,
  hasPoints: PropTypes.bool,
}

export default TariffList
