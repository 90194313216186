import RequestState from 'Enums/RequestState'
import IFFilterType from 'Enums/IFFilterType'

export const INITIAL_STATE = {
  countries: [],
  fetchCountriesRequestState: RequestState.UNINITIALIZED,
  fetchCountriesListCountRequestState: RequestState.UNINITIALIZED,
  countriesCount: 0,
  countriesPaginationOffset: 0,
  countriesFilters: [
    {
      type: IFFilterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
  ],
  currencies: [],
  fetchCurrenciesRequestState: RequestState.UNINITIALIZED,
  fetchCurrenciesListCountRequestState: RequestState.UNINITIALIZED,
  currenciesCount: 0,
  currenciesPaginationOffset: 0,
  currenciesFilters: [
    {
      type: IFFilterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
  ],
  fetchCountryBoundsRequestState: RequestState.UNINITIALIZED,
  bounds: [],
  updateCountryRequestState: RequestState.UNINITIALIZED,
  availableCurrencies: [],
  fetchAvailableCurrenciesRequestState: RequestState.UNINITIALIZED,
  createCountrybounds: [],
  fetchCreateCountryBoundsRequestState: RequestState.UNINITIALIZED,
  addCountryRequestState: RequestState.UNINITIALIZED,
  updateCurrencyRequestState: RequestState.UNINITIALIZED,
  addCurrencyRequestState: RequestState.UNINITIALIZED,
}
