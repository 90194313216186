import PropTypes from 'prop-types'
import Styles from './IFAddButton.module.css'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { SelectPicker } from 'rsuite'
import AddIcon from '@mui/icons-material/Add'
import React, { useRef, useState } from 'react'

const StyledDiv = styled.div`
  &:hover {
    color: whitesmoke;
  }
`
const IFAddButton = ({
  data = [],
  onClick = () => {},
  onSelect = () => {},
  RenderMenuItem,
  RenderMenuItemkeys = [],
  listProps = {},
  headers = null,
}) => {
  const [selectPickerPlacement, setSelectPickerPlacement] =
    useState('bottomStart')
  const options = []
  const selectPickerRef = useRef()
  const buttonRef = useRef()

  data.forEach((element) => {
    options.push({
      label: element.name,
      value: element._id,
    })
  })

  return (
    <div className={Styles.Container}>
      <button
        className={Styles.AddButton}
        style={{ color: Colors.primary }}
        onClick={() => {
          if (
            buttonRef.current.getBoundingClientRect().y + 286 >
            window.innerHeight
          ) {
            setSelectPickerPlacement('topStart')
          } else {
            setSelectPickerPlacement('bottomStart')
          }
          selectPickerRef.current.open()
          onClick()
        }}
        ref={buttonRef}
      >
        <StyledDiv
          className={Styles.IconContainer}
          style={{ color: Colors.primary }}
        >
          <AddIcon />
        </StyledDiv>
      </button>
      <SelectPicker
        style={{
          marginTop: selectPickerPlacement === 'bottomStart' ? '-42px' : '42px',
        }}
        ref={selectPickerRef}
        placement={selectPickerPlacement}
        className={Styles.SelectPicker}
        data={options}
        onSelect={(value) => {
          onSelect(value)
        }}
        virtualized
        value={null}
        renderMenuItem={(value) => {
          let element = data.find((el) => {
            return el.name === value
          })
          let values = {}
          if (element)
            RenderMenuItemkeys.forEach((key) => {
              values[key] = element[key]
            })

          return (
            <div>
              {element && <RenderMenuItem key={`RenderMenuItem`} {...values} />}
            </div>
          )
        }}
        renderMenu={(menu) => (
          <div>
            {headers ? headers : null}
            {menu}
          </div>
        )}
        preventOverflow={true}
        menuMaxHeight={220}
        menuStyle={{
          flexDirection:
            selectPickerPlacement === 'bottomStart'
              ? 'column'
              : 'column-reverse',

          zIndex: 10,
          maxWidth: '18.75rem',
        }}
        listProps={listProps}
      />
    </div>
  )
}

IFAddButton.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  RenderMenuItem: PropTypes.element,
  RenderMenuItemkeys: PropTypes.array,
}

export default IFAddButton
