import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  AdminListItem,
  AdminListItemSkeleton,
  IFSkeleton,
  IFText,
} from 'Components'
import Styles from './RoleAdminList.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import { formatNumber } from '../../Utils/Calculations'
import { connect } from 'react-redux'
import RequestState from 'Enums/RequestState'
import RoleSelectors from 'Stores/Role/Selectors'
import RoleActions from 'Stores/Role/Actions'

const RoleAdminList = ({
  role,
  assignedAdmins,
  isLoading,
  assignedAdminsPaginationOffset,
  fetchRoleAdminList,
  fetchRoleAdminListRequestState,
}) => {
  const { t } = useTranslation()
  const [loadingMore, setLoadingMore] = useState(false)

  const loadMoreData = useCallback(() => {
    if (
      fetchRoleAdminListRequestState !== RequestState.LOADING &&
      assignedAdminsPaginationOffset !== null
    ) {
      setLoadingMore(true)
      fetchRoleAdminList(role._id, assignedAdminsPaginationOffset)
    }
  }, [
    fetchRoleAdminListRequestState,
    assignedAdminsPaginationOffset,
    fetchRoleAdminList,
    role._id,
  ])

  useEffect(() => {
    if (fetchRoleAdminListRequestState !== RequestState.LOADING) {
      setLoadingMore(false)
    }
  }, [fetchRoleAdminListRequestState])
  return (
    <div className={Styles.AdminListDiv}>
      <div className={Styles.Container}>
        {isLoading ? (
          <IFSkeleton variant="text" width="150px" />
        ) : (
          <>
            <IFText className={Styles.AdminListTitle}>
              {t('RoleDetails.Admins')}
            </IFText>
            <div
              className={Styles.CountDiv}
              style={{
                backgroundColor: Colors.primaryLight,
              }}
            >
              <IFText className={Styles.CountText}>
                {formatNumber(role.adminsCount)}
              </IFText>
            </div>
          </>
        )}
      </div>
      {role.adminsCount === 0 ? (
        <IFText className={Styles.NoAdminsAssignedText}>
          {t('RoleDetails.NoAdminsAssigned')}
        </IFText>
      ) : (
        <Scrollbars
          className={Styles.AdminsContainerDiv}
          style={{ height: 402 }}
          onScrollFrame={({ scrollHeight, scrollTop, clientHeight }) => {
            if (scrollHeight - scrollTop - clientHeight < 10) {
              loadMoreData()
            }
          }}
        >
          <div className={Styles.AdminsDetailsDivContainer}>
            {fetchRoleAdminListRequestState === RequestState.LOADING &&
            assignedAdmins.length === 0
              ? [...Array(5)].map((e, index) => (
                  <div key={index} className={Styles.AdminDetailsDiv}>
                    <AdminListItemSkeleton />
                  </div>
                ))
              : assignedAdmins.map((admin) => (
                  <div key={admin.email} className={Styles.AdminDetailsDiv}>
                    <AdminListItem name={admin.name} email={admin.email} />
                  </div>
                ))}
            {loadingMore &&
              fetchRoleAdminListRequestState === RequestState.LOADING &&
              [...Array(2)].map((e, index) => (
                <div
                  key={`loading-${index}`}
                  className={Styles.AdminDetailsDiv}
                >
                  <AdminListItemSkeleton />
                </div>
              ))}
          </div>
        </Scrollbars>
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRoleAdminList: (roleId, offset) => {
      dispatch(RoleActions.fetchRoleAdminList(roleId, offset))
    },
  }
}

const mapStateToProps = (state) => ({
  assignedAdminsPaginationOffset:
    RoleSelectors.getAssignedAdminsPaginationOffset(state),
  fetchRoleAdminListRequestState:
    RoleSelectors.getFetchRoleAdminListRequestState(state),
})

RoleAdminList.propTypes = {
  role: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  assignedAdmins: PropTypes.array.isRequired,
  assignedAdminsPaginationOffset: PropTypes.number,
  fetchRoleAdminList: PropTypes.func.isRequired,
  fetchRoleAdminListRequestState: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdminList)
