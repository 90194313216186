import { put, call } from 'redux-saga/effects'
import { transactionsApiService, handleError } from 'Services/ApiService.js'
import transactionActions from 'Stores/Transaction/Actions'
import history from 'history/browser'
import {
  encodeObjectToBase64,
  decodeBase64ToObject,
} from '../Utils/Base64Functions'

export function* fetchTransactionsList({ filter, offset, shouldShowError }) {
  const query = new URLSearchParams(history.location.search)
  let filterParam = query.get('filter')
  let filterObject = []
  let defaultTransactionFilters = filter
  if (filterParam === null) {
    filterObject = defaultTransactionFilters
    filterParam = encodeObjectToBase64(filterObject)
  } else {
    try {
      filterObject = decodeBase64ToObject(filterParam)
      for (let i = 0; i < filterObject.length; i++) {
        const { type: type1, title: title1, data: data1 } = filterObject[i]
        const {
          type: type2,
          title: title2,
          data: data2,
        } = defaultTransactionFilters[i]
        if (
          type1 !== type2 ||
          title1 !== title2 ||
          JSON.stringify(data1) !== JSON.stringify(data2)
        ) {
          filterObject = defaultTransactionFilters
          filterParam = encodeObjectToBase64(filterObject)
        }
      }
    } catch (error) {
      filterObject = defaultTransactionFilters
      filterParam = encodeObjectToBase64(filterObject)
    }
  }
  if (offset === 0) {
    yield put(transactionActions.fetchTransactionsListLoading())
  }
  try {
    const { data } = yield call(transactionsApiService.get, '/', {
      params: {
        filter: filterParam,
        offset: offset,
      },
    })
    yield put(
      transactionActions.fetchTransactionsListSuccess(
        offset,
        data.transactions,
        data.nextOffset,
        filterObject,
      ),
    )
  } catch (e) {
    yield put(transactionActions.fetchTransactionsListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchTransactionsMetrics({ filter, offset }) {
  yield put(transactionActions.fetchTransactionsMetricsLoading())
  try {
    if (filter !== null) {
      filter = encodeObjectToBase64(filter)
    }
    const { data } = yield call(transactionsApiService.get, '/metrics', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(transactionActions.fetchTransactionsMetricsSuccess(data.metrics))
  } catch (e) {
    yield put(transactionActions.fetchTransactionsMetricsError(e))
    handleError(e)
  }
}

export function* fetchEnergyConsumptionChart({ filter, offset }) {
  yield put(transactionActions.fetchEnergyConsumptionChartLoading())
  try {
    if (filter !== null) {
      filter = encodeObjectToBase64(filter)
    }
    const { data } = yield call(transactionsApiService.get, '/chart', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      transactionActions.fetchEnergyConsumptionChartSuccess(
        data.energyConsumption,
      ),
    )
  } catch (e) {
    yield put(transactionActions.fetchEnergyConsumptionChartError(e))
    handleError(e)
  }
}

export function* fetchAllSubscriptions({ country }) {
  yield put(transactionActions.fetchAllSubscriptionsLoading())
  try {
    const { data } = yield call(
      transactionsApiService.get,
      '/allSubscriptions',
      {
        params: {
          country: country,
        },
      },
    )
    yield put(transactionActions.fetchAllSubscriptionsSuccess(data))
  } catch (e) {
    yield put(transactionActions.fetchAllSubscriptionsError(e))
    handleError(e)
  }
}
