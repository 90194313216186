import PropTypes from 'prop-types'
import { Colors } from 'Theme'

const Done = ({
  className,
  height = 36,
  width = 36,
  fill = Colors.primary,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48" // size of image before scaling
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
      </svg>
    </div>
  )
}

const Expand = ({
  className,
  height = 36,
  width = 36,
  fill = Colors.primary,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48" // size of image before scaling
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="m24 31.05-12.3-12.3 2.45-2.45L24 26.2l9.85-9.85 2.45 2.45Z" />
      </svg>
    </div>
  )
}

const ArrowUpSort = ({ height = 36, width = 36, fill = Colors.primary }) => {
  return (
    <div style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512" // size of image before scaling
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="M151.6 42.4C145.5 35.8 137 32 128 32s-17.5 3.8-23.6 10.4l-88 96c-11.9 13-11.1 33.3 2 45.2s33.3 11.1 45.2-2L96 146.3V448c0 17.7 14.3 32 32 32s32-14.3 32-32V146.3l32.4 35.4c11.9 13 32.2 13.9 45.2 2s13.9-32.2 2-45.2l-88-96zM320 480h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32zm0-128H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H320c-17.7 0-32 14.3-32 32s14.3 32 32 32z" />
      </svg>
    </div>
  )
}

const ArrowDownSort = ({ height = 36, width = 36, fill = Colors.primary }) => {
  return (
    <div style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512" // size of image before scaling
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="M151.6 469.6C145.5 476.2 137 480 128 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L96 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 480c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H320zm0-128c-17.7 0-32-14.3-32-32s14.3-32 32-32H544c17.7 0 32 14.3 32 32s-14.3 32-32 32H320z" />
      </svg>
    </div>
  )
}

const ArrowUp = ({
  className,
  height = 36,
  width = 36,
  fill = Colors.primary,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="m282-204.651-55.566-56.131L480-514.348l253.566 253.566L678-204.651 480-403.216 282-204.651Zm0-266.001-55.566-55.566L480-780.349l253.566 254.131L678-470.652 480-669.217 282-470.652Z" />
      </svg>
    </div>
  )
}

const DoneBtn = ({
  className,
  height = 24,
  width = 24,
  fill = Colors.primary,
  onClick,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        viewBox="0 -960 960 960"
        width="100%"
        fill={fill}
        onClick={onClick}
      >
        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
      </svg>
    </div>
  )
}

const CloseBtn = ({
  className,
  height = 24,
  width = 24,
  fill = Colors.red,
  onClick,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill={fill}
        onClick={onClick}
      >
        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
      </svg>
    </div>
  )
}

const MoreInfo = ({
  className,
  height = 24,
  width = 24,
  fill = Colors.primary,
  onClick,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={fill}
        onClick={onClick}
      >
        <path d="M249.23-420q-24.75 0-42.37-17.63-17.63-17.62-17.63-42.37 0-24.75 17.63-42.37Q224.48-540 249.23-540q24.75 0 42.38 17.63 17.62 17.62 17.62 42.37 0 24.75-17.62 42.37Q273.98-420 249.23-420ZM480-420q-24.75 0-42.37-17.63Q420-455.25 420-480q0-24.75 17.63-42.37Q455.25-540 480-540q24.75 0 42.37 17.63Q540-504.75 540-480q0 24.75-17.63 42.37Q504.75-420 480-420Zm230.77 0q-24.75 0-42.38-17.63-17.62-17.62-17.62-42.37 0-24.75 17.62-42.37Q686.02-540 710.77-540q24.75 0 42.37 17.63 17.63 17.62 17.63 42.37 0 24.75-17.63 42.37Q735.52-420 710.77-420Z" />
      </svg>
    </div>
  )
}

const ResendEmailIcon = ({ className, height = 24, width = 24, onClick }) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 -960 960 960"
        width="24px"
        fill={Colors.gray}
        onClick={onClick}
      >
        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
      </svg>
    </div>
  )
}

const refresh = ({
  className,
  height = 36,
  width = 36,
  fill = Colors.primary,
}) => {
  return (
    <div className={className} style={{ height: height, width: width }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        width="100%"
        height="100%"
        fill={fill}
      >
        <path d="M480-160q-133 0-226.5-93.5T160-480q0-133 93.5-226.5T480-800q85 0 149 34.5T740-671v-129h60v254H546v-60h168q-38-60-97-97t-137-37q-109 0-184.5 75.5T220-480q0 109 75.5 184.5T480-220q83 0 152-47.5T728-393h62q-29 105-115 169t-195 64Z" />
      </svg>
    </div>
  )
}

const componentPropTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
}

const components = [
  CloseBtn,
  DoneBtn,
  Done,
  Expand,
  ArrowUpSort,
  ArrowDownSort,
  ArrowUp,
  MoreInfo,
  ResendEmailIcon,
  refresh,
]

components.forEach((component) => {
  component.propTypes = componentPropTypes
})

export default {
  CloseBtn,
  DoneBtn,
  Done,
  Expand,
  ArrowUpSort,
  ArrowDownSort,
  ArrowUp,
  MoreInfo,
  ResendEmailIcon,
  refresh,
}
