import React, { useState } from 'react'
import Styles from './InviteAdminModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import IFButton from 'Components/IFButton/IFButton'
import {
  TextField,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import AuthSelectors from 'Stores/Auth/Selectors'
import { connect } from 'react-redux'
import { IFText } from 'Components'
import RequestState from 'Enums/RequestState'
import PropTypes from 'prop-types'
import AdminSelectors from 'Stores/Admin/Selectors'

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
  width: '17.5rem',
  '& .MuiInputBase-input': {
    height: '30px',
    padding: '10px',
  },
})
const StyledSelect = styled(Select)`
  && {
    font-family: 'ProximaNova';
    font-size: 14px;
    color: ${Colors.text};
    height: 50px;
    width: 17.5rem;
  }
`
const StyledFormControl = styled(FormControl)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
  width: '100%',
})

const InviteAdminModal = ({
  loggedInAdmin,
  roles,
  onSubmitForm,
  inviteAdminRequestState,
}) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedRole, setSelectedRole] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState('')
  const [countryError, setCountryError] = useState(false)
  const [countryErrorText, setCountryErrorText] = useState('')
  const [roleError, setRoleError] = useState(false)
  const [roleErrorText, setRoleErrorText] = useState('')

  const handleEmailChange = (event) => {
    const value = event.target.value
    setEmail(value)

    if (!value) {
      setEmailError(true)
      setEmailErrorText(t('AdminPage.FieldRequired'))
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      setEmailError(true)
      setEmailErrorText(t('AdminPage.InvalidEmail'))
    } else {
      setEmailError(false)
      setEmailErrorText('')
    }
  }

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError(true)
      setEmailErrorText(t('AdminPage.FieldRequired'))
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError(true)
      setEmailErrorText(t('AdminPage.InvalidEmail'))
    } else {
      setEmailError(false)
      setEmailErrorText('')
    }
  }

  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value)
    setCountryError(false)
    setCountryErrorText('')
  }
  const handleCountryBlur = () => {
    if (
      loggedInAdmin.registeredCountries.length > 1 &&
      selectedCountries.length === 0
    ) {
      setCountryError(true)
      setCountryErrorText(t('AdminPage.FieldRequired'))
    }
  }

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value)
    setRoleError(false)
    setRoleErrorText('')
  }

  const handleRoleBlur = () => {
    if (!selectedRole) {
      setRoleError(true)
      setRoleErrorText(t('AdminPage.FieldRequired'))
    }
  }

  const handleSubmit = () => {
    if (!email) {
      setEmailError(true)
      setEmailErrorText(t('AdminPage.FieldRequired'))
      return
    }
    if (emailError) return

    onSubmitForm({
      email,
      countries: selectedCountries,
      role: selectedRole,
    })
  }

  const isButtonDisabled =
    !email ||
    emailError ||
    (loggedInAdmin.registeredCountries.length > 1 &&
      selectedCountries.length === 0) ||
    !selectedRole

  return (
    <div className={Styles.InviteAdminContainer}>
      <div>
        <FormGroup className={Styles.InputWrappingDiv}>
          <IFText>{t('AdminPage.InviteAdminEmail')}</IFText>
          <StyledTextField
            value={email}
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            error={emailError}
          />
        </FormGroup>
        <div
          style={{
            height: '10px',
            fontSize: '0.75rem',
            color: Colors.red,
          }}
        >
          {emailError && emailErrorText}
        </div>
      </div>

      {loggedInAdmin.registeredCountries &&
        loggedInAdmin.registeredCountries.length > 1 && (
          <FormGroup className={Styles.InputWrappingDivCountry}>
            <StyledFormControl>
              <IFText>{t('AdminPage.InviteAdminCountry')}</IFText>
              <StyledSelect
                value={selectedCountries}
                onChange={handleCountryChange}
                onBlur={handleCountryBlur}
                multiple
                labelId="country-select-label"
                renderValue={(selected) =>
                  selected
                    .map(
                      (countryId) =>
                        loggedInAdmin.registeredCountries.find(
                          (country) => country.id === countryId,
                        )?.name,
                    )
                    .join(', ')
                }
              >
                {loggedInAdmin.registeredCountries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
            {countryError && (
              <div
                style={{
                  height: '10px',
                  fontSize: '0.75rem',
                  color: Colors.red,
                }}
              >
                {countryErrorText}
              </div>
            )}
          </FormGroup>
        )}

      <StyledFormControl>
        <IFText>{t('AdminPage.InviteAdminRole')}</IFText>
        <StyledSelect
          value={selectedRole}
          onChange={handleRoleChange}
          onBlur={handleRoleBlur}
          labelId="role-select-label"
        >
          {roles.map((filteredRole) => (
            <MenuItem key={filteredRole.id} value={filteredRole.id}>
              {filteredRole.name}
            </MenuItem>
          ))}
        </StyledSelect>
        {roleError && (
          <div
            style={{
              height: '10px',
              fontSize: '0.75rem',
              color: Colors.red,
            }}
          >
            {roleErrorText}
          </div>
        )}
      </StyledFormControl>

      <IFButton
        size="sm"
        color={Colors.primary}
        isFill={true}
        text={t('AdminPage.InviteAdminButton')}
        className={Styles.SaveButton}
        onClick={handleSubmit}
        isDead={isButtonDisabled}
        isLoading={inviteAdminRequestState === RequestState.LOADING}
      />
    </div>
  )
}
const mapStateToProps = (state) => ({
  loggedInAdmin: AuthSelectors.getUser(state),
  inviteAdminRequestState: AdminSelectors.getInviteAdminRequestState(state),
})
InviteAdminModal.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  loggedInAdmin: PropTypes.object,
  roles: PropTypes.array,
  inviteAdminRequestState: PropTypes.number,
}
export default connect(mapStateToProps)(InviteAdminModal)
