import React from 'react'
import styles from './AppChargingTokenDesignCardSkeleton.module.css'
import { IFSkeleton } from 'Components'
import { Colors } from 'Theme'

const AppChargingTokenDesignCardSkeleton = () => {
  return (
    <div
      className={styles.CardDiv}
      style={{ backgroundColor: Colors.CardBackground }}
    >
      <IFSkeleton variant="rectangular" width={'100%'} height={220} />

      <div className={styles.CardButtons}>
        <IFSkeleton variant="text" width="30%" />

        <div className={styles.CardIcons}>
          <IFSkeleton variant="circular" width={24} height={24} />
          <IFSkeleton variant="circular" width={24} height={24} />
        </div>
      </div>
    </div>
  )
}

export default AppChargingTokenDesignCardSkeleton
