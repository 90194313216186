import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Styles from './RolePermissionList.module.css'
import { IFText, RolesPermissionsListItem } from 'Components'
import { useTranslation } from 'react-i18next'
import InfinityEnums from '../../Enums/InfinityEnums'
import { formatPermissionName } from 'Utils/StringFunctions'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import RoleSelectors from 'Stores/Role/Selectors'
const RolePermissionList = ({
  role = null,
  isEditing = false,
  onPermissionsChange = () => {},
  resetPermissions = false,
  titleFontSize = '16px',
  titleFontWeight = 'normal',
  rolesWithAdmins,
}) => {
  const { t } = useTranslation()
  const [selectedPermissions, setSelectedPermissions] = useState([])
  useEffect(() => {
    if (role) {
      const updatedRole =
        rolesWithAdmins.find((r) => r._id === role._id) || role
      const permissions = Object.values(InfinityEnums.AdminPermissions)
      const initialSelected = permissions.filter((permission) =>
        updatedRole.permissions.includes(permission),
      )
      setSelectedPermissions(initialSelected)
    } else {
      setSelectedPermissions([])
    }
  }, [role])

  useEffect(() => {
    onPermissionsChange(selectedPermissions)
  }, [selectedPermissions])

  useEffect(() => {
    if (resetPermissions) {
      if (role) {
        const updatedRole =
          rolesWithAdmins.find((r) => r._id === role._id) || role
        const permissions = Object.values(InfinityEnums.AdminPermissions)
        const initialSelected = permissions.filter((permission) =>
          updatedRole.permissions.includes(permission),
        )
        setSelectedPermissions(initialSelected)
      } else {
        setSelectedPermissions([])
      }
    }
  }, [resetPermissions, role])

  const togglePermission = (value) => {
    setSelectedPermissions((prev) =>
      prev.includes(value)
        ? prev.filter((perm) => perm !== value)
        : [...prev, value],
    )
  }

  return (
    <div>
      <IFText style={{ fontSize: titleFontSize, fontWeight: titleFontWeight }}>
        {t('RoleDetails.Permissions')}
      </IFText>
      <Scrollbars
        className={Styles.PermissionContainerDiv}
        style={{ height: 508 }}
      >
        <div className={Styles.PermissionContainerDiv}>
          {Object.entries(InfinityEnums.AdminPermissions).map(
            ([permissionName, permissionValue]) => {
              const isChecked = selectedPermissions.includes(permissionValue)
              return (
                <RolesPermissionsListItem
                  key={permissionValue}
                  title={formatPermissionName(permissionName)}
                  isSelected={isChecked}
                  isEditing={isEditing}
                  onToggle={() => togglePermission(permissionValue)}
                />
              )
            },
          )}
        </div>
      </Scrollbars>
    </div>
  )
}

const mapStateToProps = (state) => ({
  rolesWithAdmins: RoleSelectors.getRolesWithAdmins(state),
})

RolePermissionList.propTypes = {
  role: PropTypes.object,
  isEditing: PropTypes.bool,
  onPermissionsChange: PropTypes.func,
  resetPermissions: PropTypes.bool,
  titleFontSize: PropTypes.string,
  titleFontWeight: PropTypes.string,
  rolesWithAdmins: PropTypes.array,
}

export default connect(mapStateToProps)(RolePermissionList)
