import PropTypes from 'prop-types'
import Styles from './IFTabs.module.css'
import { IFCount, IFText } from 'Components'
import { Colors } from 'Theme'
import RequestState from 'Enums/RequestState'
const IFTab = ({ title, count, isSelected, fetchListRequestState }) => {
  return (
    <div className={Styles.Container}>
      <IFText
        style={{ color: isSelected ? Colors.text : Colors.gray }}
        className={Styles.IFTabTitle}
      >
        {title}
      </IFText>
      <div className={Styles.CountDiv}>
        <IFCount
          count={count}
          isLoading={
            fetchListRequestState === RequestState.LOADING && isSelected
          }
          color={isSelected ? Colors.text : Colors.gray}
        />
      </div>
    </div>
  )
}
IFTab.propTypes = {
  title: PropTypes.string,
  count: PropTypes.string,
  isSelected: PropTypes.bool,
}
export default IFTab
