import { connect } from 'react-redux'
import Styles from './CountriesPage.module.css'
import { Colors } from 'Theme'
import { CountriesPageTabs, CurrenciesList } from 'Containers'
import React, { useEffect, useRef, useState } from 'react'
import CountriesSelectors from 'Stores/Countries-Currencies/Selectors'
import CountriesActions from 'Stores/Countries-Currencies/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'
import Scrollbars from 'react-custom-scrollbars'
import { CountriesList } from 'Containers'
import LoadingBar from 'react-top-loading-bar'
import AddCountryForm from './AddCountryForm'
import AddCurrencyForm from './AddCurrencyForm'
const CountriesPage = ({
  CountriesCount,
  fetchCountriesList,
  countriesFilters,
  CurrenciesCount,
  fetchCurrenciesList,
  currenciesFilters,
  fetchCurrenciesListCount,
  editingAdminRole,
  fetchCountriesListRequestState,
  fetchCurrenciesListRequestState,
  setCountriesFilter,
  setCurrenciesFilter,
  clearCurrenciesFilter,
  clearCountriesFilter,
  clearCountries,
  clearCreateCountryBounds,
  addCountryRequestState,
  addCountry,
  addCurrencyRequestState,
  addCurrency,
}) => {
  const { t } = useTranslation()
  const countriesFilterRef = useRef(null)
  const currenciesFilterRef = useRef(null)
  const tablesRef = useRef(null)
  const [tablesHeight, setTablesHeight] = React.useState(0)
  const showCountryDialogHandler = useRef()
  const showCurrencyDialogHandler = useRef()

  useEffect(() => {
    return () => {
      clearCountries()
      clearCountriesFilter()
      setFetchListLoadingState(true)
      countriesFilterRef?.current?.clearFilter()
      currenciesFilterRef?.current?.clearFilter()
    }
  }, [])
  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (tablesRef.current) {
      resizeObserver.observe(tablesRef.current)
    }

    return () => {
      if (tablesRef.current) {
        resizeObserver.unobserve(tablesRef.current)
      }
    }
  }, [])
  const [fetchListLoadingState, setFetchListLoadingState] = useState(true)
  useEffect(() => {
    if (
      fetchCountriesListRequestState === RequestState.SUCCEEDED ||
      fetchCurrenciesListRequestState === RequestState.SUCCEEDED
    ) {
      setFetchListLoadingState(false)
    }
  }, [fetchCurrenciesListRequestState, fetchCountriesListRequestState])
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const handleTabChange = (newValue) => {
    setSelectedTabIndex(newValue)
  }
  useEffect(() => {
    if (fetchListLoadingState) fetchCurrenciesListCount(currenciesFilters)
  }, [currenciesFilters, fetchListLoadingState])
  useEffect(() => {
    if (selectedTabIndex === 0) {
      clearCurrenciesFilter()
    } else if (selectedTabIndex === 1) {
      clearCountriesFilter()
    }
  }, [selectedTabIndex])
  useEffect(() => {
    if (selectedTabIndex === 0) {
      fetchCountriesList(countriesFilters, 0)
    } else if (selectedTabIndex === 1) {
      fetchCurrenciesList(currenciesFilters, 0)
      fetchCurrenciesListCount(currenciesFilters)
    }
  }, [selectedTabIndex, countriesFilters, currenciesFilters])

  const handleCountriesFilterChange = (newFilter) => {
    fetchCountriesList(newFilter, 0)
    setCountriesFilter(newFilter)
  }
  const handleCurrenciesFilterChange = (newFilter) => {
    fetchCurrenciesList(newFilter, 0)
    setCurrenciesFilter(newFilter)
  }
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    if (fetchListLoadingState) {
      if (
        fetchCountriesListRequestState === RequestState.LOADING &&
        progress === 0
      )
        setProgress(progress + 10)

      if (fetchCountriesListRequestState === RequestState.SUCCEEDED)
        setProgress(100)

      if (
        fetchCountriesListRequestState !== RequestState.LOADING &&
        fetchCountriesListRequestState !== RequestState.UNINITIALIZED &&
        fetchCountriesListRequestState !== RequestState.SUCCEEDED
      )
        setProgress(100)
    }
  }, [fetchCountriesListRequestState])
  useEffect(() => {
    if (addCountryRequestState === RequestState.SUCCEEDED)
      showCountryDialogHandler.current.dismiss()
  }, [addCountryRequestState])
  const onSubmitCountryFormHandler = (country) => {
    addCountry(country)
  }
  useEffect(() => {
    if (addCurrencyRequestState === RequestState.SUCCEEDED)
      showCurrencyDialogHandler.current.dismiss()
  }, [addCurrencyRequestState])
  const onSubmitCurrencyFormHandler = (currency) => {
    addCurrency(currency)
  }
  return (
    <div className={Styles.WrapperContainer}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div
        className={Styles.TopContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.UserDataContainer}>
          <div className={Styles.InnerContainer}>
            <div className={Styles.TabButtonDiv}>
              <CountriesPageTabs
                onValueChange={handleTabChange}
                initialIndex={selectedTabIndex}
                isLoading={
                  fetchListLoadingState &&
                  (fetchCountriesListRequestState === RequestState.LOADING ||
                    fetchCurrenciesListRequestState === RequestState.LOADING)
                }
                CountriesCount={CountriesCount}
                fetchListRequestState={
                  selectedTabIndex == 0
                    ? fetchCountriesListRequestState
                    : fetchCurrenciesListRequestState
                }
                CurrenciesCount={CurrenciesCount}
              />
              {editingAdminRole?.includes(
                InfinityEnums.AdminPermissions.CAN_EDIT_COUNTRIES,
              ) && (
                <div>
                  {fetchListLoadingState &&
                  fetchCountriesListRequestState === RequestState.LOADING ? (
                    <IFSkeleton variant="text" width="100px" />
                  ) : (
                    <>
                      {selectedTabIndex === 0 && (
                        <IFButton
                          size="sm"
                          color={Colors.primary}
                          isFill={false}
                          isLoading={false}
                          className={Styles.CreateButton}
                          onClick={() => {
                            showCountryDialogHandler.current.show()
                            clearCreateCountryBounds()
                          }}
                          text={`+ ${t('CountriesPage.Add')}`}
                          loadSkeleton={
                            fetchCountriesListRequestState ===
                              RequestState.LOADING && fetchListLoadingState
                          }
                        />
                      )}
                      {selectedTabIndex === 1 && (
                        <IFButton
                          size="sm"
                          color={Colors.primary}
                          isFill={false}
                          isLoading={false}
                          text={`+ ${t('CountriesPage.Add')}`}
                          className={Styles.CreateButton}
                          onClick={() => {
                            showCurrencyDialogHandler.current.show()
                          }}
                          loadSkeleton={
                            fetchCurrenciesListRequestState ===
                              RequestState.LOADING && fetchListLoadingState
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className={Styles.Filter}>
              {selectedTabIndex === 0 && (
                <IFFilter
                  ref={countriesFilterRef}
                  onFilterChange={(newFilter) => {
                    handleCountriesFilterChange(newFilter)
                  }}
                  isLoading={
                    fetchCountriesListRequestState === RequestState.LOADING &&
                    fetchListLoadingState
                  }
                  filters={countriesFilters}
                  textFieldPlaceholder={t(
                    'CountriesPage.CountriesFilterPlaceholder',
                  )}
                />
              )}
              {selectedTabIndex === 1 && (
                <IFFilter
                  ref={currenciesFilterRef}
                  onFilterChange={(newFilter) => {
                    handleCurrenciesFilterChange(newFilter)
                  }}
                  isLoading={
                    fetchCurrenciesListRequestState === RequestState.LOADING &&
                    fetchListLoadingState
                  }
                  filters={currenciesFilters}
                  textFieldPlaceholder={t(
                    'CountriesPage.CurrenciesFilterPlaceholder',
                  )}
                />
              )}
            </div>
          </div>
          <Scrollbars autoHide>
            <div className={Styles.Tables} ref={tablesRef}>
              {selectedTabIndex === 0 && (
                <CountriesList tablesHeight={tablesHeight} />
              )}
              {selectedTabIndex === 1 && (
                <CurrenciesList tablesHeight={tablesHeight} />
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
      <IFModal
        title={t('CountriesPage.AddNewCountry')}
        open={false}
        ref={showCountryDialogHandler}
      >
        <AddCountryForm
          onSubmitForm={(values) => onSubmitCountryFormHandler(values)}
        />
      </IFModal>
      <IFModal
        title={t('CountriesPage.AddNewCurrency')}
        open={false}
        ref={showCurrencyDialogHandler}
      >
        <AddCurrencyForm
          onSubmitForm={(values) => onSubmitCurrencyFormHandler(values)}
        />
      </IFModal>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchCountriesList: (filter, offset, shouldShowError) =>
      dispatch(
        CountriesActions.fetchCountriesList(filter, offset, shouldShowError),
      ),
    fetchCurrenciesList: (filter, offset, shouldShowError) =>
      dispatch(
        CountriesActions.fetchCurrenciesList(filter, offset, shouldShowError),
      ),
    fetchCurrenciesListCount: (filter) =>
      dispatch(CountriesActions.fetchCurrenciesListCount(filter)),
    setCountriesFilter: (newFilter) =>
      dispatch(CountriesActions.setCountriesFilter(newFilter)),
    setCurrenciesFilter: (newFilter) =>
      dispatch(CountriesActions.setCurrenciesFilter(newFilter)),
    clearCurrenciesFilter: () =>
      dispatch(CountriesActions.clearCurrenciesFilter()),
    clearCountriesFilter: () =>
      dispatch(CountriesActions.clearCountriesFilter()),
    clearCountries: () => dispatch(CountriesActions.clearCountries()),
    clearCreateCountryBounds: () =>
      dispatch(CountriesActions.clearCreateCountryBounds()),
    addCountry: (country) => dispatch(CountriesActions.addCountry(country)),
    addCurrency: (currency) => dispatch(CountriesActions.addCurrency(currency)),
  }
}
const mapStateToProps = (state) => ({
  CountriesCount: CountriesSelectors.getCountriesCount(state),
  countriesFilters: CountriesSelectors.getCountriesFilters(state),
  CurrenciesCount: CountriesSelectors.getCurrenciesCount(state),
  currenciesFilters: CountriesSelectors.getCurrenciesFilters(state),
  fetchCurrenciesListRequestState:
    CountriesSelectors.getFetchCurrenciesListRequestState(state),
  fetchCountriesListRequestState:
    CountriesSelectors.getFetchCountriesListRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  addCountryRequestState: CountriesSelectors.getAddCountryRequestState(state),
  addCurrencyRequestState: CountriesSelectors.getAddCurrencyRequestState(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(CountriesPage)
