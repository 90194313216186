import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  AppChargingTokenDesignCard,
  AppChargingTokenDesignCardSkeleton,
  IFDialog,
  IFModal,
  IFText,
} from 'Components'
import { AddAppChargingTokenDesignModal } from 'Containers'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'
import Styles from './AppChargingTokenDesignGrid.module.css'
import AuthSelectors from 'Stores/Auth/Selectors'
import AppChargingTokenDesignActions from 'Stores/AppChargingTokenDesign/Actions'
import AppChargingTokenDesignSelectors from 'Stores/AppChargingTokenDesign/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import RequestState from 'Enums/RequestState'
import { Colors } from 'Theme'

const AppChargingTokenDesignGrid = ({
  appChargingTokenDesigns,
  fetchAppChargingTokenDesigns,
  fetchAppChargingTokenDesignsState,
  paginationOffset,
  editingAdminRole,
  editAppChargingTokenDesignRequestState,
  deleteAppChargingTokenDesign,
  deleteAppChargingTokenDesignRequestState,
}) => {
  const { t } = useTranslation()
  const [loadingMore, setLoadingMore] = useState(false)
  const canEditToken = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_APP_CHARGING_TOKENS_DESIGN,
  )
  const isLoading = fetchAppChargingTokenDesignsState === RequestState.LOADING
  const editDesignModalRef = useRef()
  const confirmDeleteDialog = useRef()
  const [selectedDesign, setSelectedDesign] = useState(null)
  const [deleteDesignId, setDeleteDesignId] = useState(null)

  const handleEditDesign = (design) => {
    setSelectedDesign(design)
    editDesignModalRef.current.show()
  }
  const onDeleteClickHandler = (id) => {
    setDeleteDesignId(id)
    confirmDeleteDialog.current.show()
  }
  const handleDeleteAdmin = () => {
    deleteAppChargingTokenDesign(deleteDesignId)
  }

  const loadMoreData = useCallback(() => {
    if (
      fetchAppChargingTokenDesignsState !== RequestState.LOADING &&
      paginationOffset !== null
    ) {
      setLoadingMore(true)
      fetchAppChargingTokenDesigns(paginationOffset)
    }
  }, [fetchAppChargingTokenDesignsState, paginationOffset])

  useEffect(() => {
    if (editAppChargingTokenDesignRequestState === RequestState.SUCCEEDED) {
      editDesignModalRef.current.dismiss()
    }
  }, [editAppChargingTokenDesignRequestState])

  useEffect(() => {
    if (fetchAppChargingTokenDesignsState !== RequestState.LOADING) {
      setLoadingMore(false)
    }
  }, [fetchAppChargingTokenDesignsState])
  useEffect(() => {
    if (deleteAppChargingTokenDesignRequestState === RequestState.SUCCEEDED) {
      confirmDeleteDialog.current.dismiss()
    }
  }, [deleteAppChargingTokenDesignRequestState])
  useEffect(() => {
    if (appChargingTokenDesigns.length < 10 && paginationOffset !== null) {
      loadMoreData()
    }
  }, [appChargingTokenDesigns.length, paginationOffset, loadMoreData])

  return (
    <div className={Styles.ScrollBarContainer}>
      {appChargingTokenDesigns.length === 0 && !isLoading && (
        <div className={Styles.noDesignsFound}>
          <IFText>{t('AppChargingTokenPage.NoDesignsFound')}</IFText>
        </div>
      )}

      <Scrollbars
        onScrollFrame={({ scrollHeight, scrollTop, clientHeight }) => {
          if (scrollHeight - scrollTop - clientHeight < 10 && !loadingMore) {
            loadMoreData()
          }
        }}
      >
        <div className={Styles.gridContainer}>
          {isLoading && appChargingTokenDesigns.length === 0
            ? Array.from({ length: 4 }).map((_, index) => (
                <div key={index}>
                  <AppChargingTokenDesignCardSkeleton />
                </div>
              ))
            : appChargingTokenDesigns.map((design) => (
                <AppChargingTokenDesignCard
                  key={design._id}
                  imageData={design.base64Image}
                  designName={design.name}
                  canEdit={canEditToken}
                  assignedDesign={design.assignedToToken}
                  onEdit={() => handleEditDesign(design)}
                  onDelete={() => onDeleteClickHandler(design._id)}
                  height={250}
                />
              ))}

          {loadingMore &&
            fetchAppChargingTokenDesignsState === RequestState.LOADING &&
            [...Array(1)].map((_, index) => (
              <div key={`loading-${index}`}>
                <AppChargingTokenDesignCardSkeleton />
              </div>
            ))}
        </div>
      </Scrollbars>

      <IFModal
        title={t('AppChargingTokenPage.EditDesign')}
        ref={editDesignModalRef}
      >
        <AddAppChargingTokenDesignModal
          isEdit={canEditToken}
          appChargingTokenDesign={selectedDesign}
        />
      </IFModal>

      <IFDialog
        ref={confirmDeleteDialog}
        style={{ margin: '15em' }}
        open={false}
        title={t('AppChargingTokenPage.deleteModalTitle')}
        bodyText={t('AppChargingTokenPage.deleteModalText')}
        buttonAcceptonClick={handleDeleteAdmin}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AppChargingTokenPage.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={
          deleteAppChargingTokenDesignRequestState === RequestState.LOADING
        }
        dismissOnAccept={false}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  paginationOffset: AppChargingTokenDesignSelectors.getPaginationOffset(state),
  editAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getEditAppChargingTokenDesignRequestState(
      state,
    ),
  deleteAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getDeleteAppChargingTokenDesignRequestState(
      state,
    ),
})

const mapDispatchToProps = (dispatch) => ({
  fetchAppChargingTokenDesigns: (offset) => {
    dispatch(AppChargingTokenDesignActions.fetchAppChargingTokenDesigns(offset))
  },
  deleteAppChargingTokenDesign: (id) => {
    dispatch(AppChargingTokenDesignActions.deleteAppChargingTokenDesign(id))
  },
})

AppChargingTokenDesignGrid.propTypes = {
  appChargingTokenDesigns: PropTypes.array.isRequired,
  fetchAppChargingTokenDesignsState: PropTypes.string.isRequired,
  fetchAppChargingTokenDesigns: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppChargingTokenDesignGrid)
