import { useEffect, useRef, useState, useMemo } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box } from '@mui/system'
import { Colors } from 'Theme'
import styles from './IFTable.module.css'
import IFText from 'Components/IFText/IFText'
import { useTranslation } from 'react-i18next'
import { IFsvg } from 'Components'
import RequestState from 'Enums/RequestState'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'

const icons = {
  CancelIcon: () => (
    <IFsvg.CloseBtn height={24} width={24} fill={Colors.primary} />
  ),
  SaveIcon: () => (
    <IFsvg.DoneBtn height={26} width={26} fill={Colors.primary} />
  ),
}
const IFTable = ({
  columns,
  data,
  showSkeleton,
  filters = undefined,
  paginationOffset = null,
  fetchMoreOnBottomReached,
  renderDetailPanel,
  enableToolBar = false,
  enableFullScreen = false,
  tableMaxHeight = 400,
  renderToolbar,
  tableSetHeight = undefined,
  enableHover = true,
  onSaveAction = undefined,
  isSaving = false,
  enableEditing = false,
  renderRowActions,
  hideColumnQuery = '',
  tableRequestState,
  renderRowActionsCell,
  editDisplayMode = 'row',
}) => {
  const [expandedRows, setExpandedRows] = useState({})
  const [failedRequest, setFailedRequest] = useState(false)
  const tableContainerRef = useRef()
  const { t } = useTranslation()
  /**
   * This `useEffect` hook hides error overlays related to ResizeObserver issues
   * (e.g., 'ResizeObserver loop completed with undelivered notifications')
   * that might appear during development with webpack-dev-server.
   * It does this by setting the display style of the error overlay element
   * to 'none'.
   * Link: https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
   */
  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications' ||
            'ResizeObserver loop limit exceeded',
        )
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  useEffect(() => {
    if (filters) {
      setExpandedRows({})
      table.setExpanded({})
    }
  }, [filters])
  useEffect(() => {
    if (
      tableRequestState === RequestState.ERROR_0_NETWORK ||
      tableRequestState === RequestState.ERROR_400_OCCURRED ||
      tableRequestState === RequestState.ERROR_401_OCCURRED ||
      tableRequestState === RequestState.ERROR_403_OCCURRED ||
      tableRequestState === RequestState.ERROR_409_OCCURRED ||
      tableRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (tableRequestState === RequestState.SUCCEEDED) setFailedRequest(false)
  }, [tableRequestState])
  useEffect(() => {
    if (filters) {
      const isEmpty = checkFilterEmpty(filters)
      if (!isEmpty) {
        tableContainerRef?.current?.scrollTo(0, 0)
      }
    }
  }, [filters])
  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) return true
    else return false
  }
  const handleScroll = (event) => {
    if (paginationOffset) {
      const container = event.target
      const isAtBottom =
        container.scrollHeight - container.scrollTop <=
        container.clientHeight + 60
      if (isAtBottom) {
        fetchMoreOnBottomReached(event.target)
      }
    }
  }
  useEffect(() => {
    if (
      paginationOffset &&
      fetchMoreOnBottomReached &&
      tableContainerRef &&
      tableContainerRef.current.scrollHeight - 62 * 2 <
        tableContainerRef.current.clientHeight
    ) {
      if (tableRequestState !== RequestState.LOADING) {
        fetchMoreOnBottomReached(tableContainerRef.current)
      }
    }
  }, [paginationOffset, fetchMoreOnBottomReached, tableContainerRef])

  const memoizedData = useMemo(() => {
    return paginationOffset === null || showSkeleton
      ? showSkeleton
        ? Array.from({ length: tableMaxHeight / 62 }, () => ({}))
        : data
      : failedRequest
      ? [...data, {}]
      : [...data, {}, {}] || []
  }, [paginationOffset, showSkeleton, tableMaxHeight, data, failedRequest])

  const options = {
    scrollbars: {
      visibility: 'auto',
      autoHide: 'move',
      autoHideDelay: 1000,
    },
  }
  const rootRef = useRef(null)
  const [initialize, osInstance] = useOverlayScrollbars({
    options,
    defer: true,
  })

  useEffect(() => {
    const root = rootRef.current?.querySelector('.MuiPaper-root')
    if (tableContainerRef && root) {
      initialize({
        target: root,
        elements: {
          viewport: tableContainerRef.current,
        },
      })
    }
    return () => osInstance()?.destroy()
  }, [tableContainerRef, initialize, osInstance])

  const table = useMaterialReactTable({
    columns: columns,
    data: memoizedData,
    defaultColumn: {
      minSize: 40,
      maxSize: 1000,
      size: 140,
    },
    enableEditing: enableEditing,
    editDisplayMode: editDisplayMode,
    getRowId: (row) => row.id,
    icons: icons,
    enableExpandAll: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableSorting: false,
    enableBottomToolbar: false,
    enableFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
    enableFullScreenToggle: showSkeleton ? false : enableFullScreen,
    enableTopToolbar: enableToolBar,
    enableToolbarInternalActions: enableToolBar,
    enableRowVirtualization: true,
    enableKeyboardShortcuts: false,
    rowVirtualizerOptions: { overscan: 5 },
    positionActionsColumn: 'last',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 120,
        Cell: renderRowActionsCell ? renderRowActionsCell : renderRowActions,
        muiTableBodyCellProps: {
          sx: {
            '& .MuiBox-root': {
              gap: '0px !important',
            },
            '& .MuiIconButton-root': {
              width: '40px',
            },
          },
        },
      },
    },
    muiTableContainerProps: {
      ref: tableContainerRef,
      sx: {
        maxHeight: tableMaxHeight,
      },
      onScroll: handleScroll,
    },
    state: {
      showSkeletons: showSkeleton,
      isSaving: isSaving,
      columnVisibility: {
        ...hideColumnQuery,
        'mrt-row-expand': false,
      },
    },
    initialState: {
      columnVisibility: { 'mrt-row-expand': false },
    },
    muiSkeletonProps: {
      animation: 'none',
      variant: 'text',
      width: '60%',
    },
    onEditingRowCancel: () => {},
    onEditingRowSave: onSaveAction,
    renderRowActions: renderRowActions,
    renderEmptyRowsFallback: () => (
      <>
        {tableRequestState === RequestState.SUCCEEDED && (
          <div className={styles.NoRecord}>
            <IFText>{t('UserPage.TransactionNoRecord')}</IFText>
          </div>
        )}
      </>
    ),
    muiTableBodyCellProps: () => ({
      sx: {
        height: '62px',
      },
    }),
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        border: '1px solid rgba(0, 0, 0, 0.1)',
        paddingTop: '0px',
        cursor: 'default',
        backgroundColor:
          theme.palette.mode === 'dark'
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
      }),
    }),
    muiTableBodyRowProps: ({ row, table }) => ({
      hover: enableHover,
      onClick: () => {
        if (Object.keys(row.original).length > 0) {
          const newExpandedRows = {
            ...expandedRows,
            [row.id]: !row.getIsExpanded(),
          }
          setExpandedRows(newExpandedRows)
          table.setExpanded(newExpandedRows)
        }
      },
      sx: {
        cursor: renderDetailPanel ? 'pointer' : 'default',
      },
    }),
    muiTablePaperProps: ({ table }) => ({
      style: {
        padding: table.getState().isFullScreen ? '64px' : '0',
        transition: 'none',
      },
    }),
    renderTopToolbarCustomActions: renderToolbar,
    renderDetailPanel: renderDetailPanel,
  })
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && table?.getState().isFullScreen) {
        table.setIsFullScreen(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [table])

  useEffect(() => {
    const handlePopState = (event) => {
      if (table?.getState()?.isFullScreen) {
        table.setIsFullScreen(false)
        event.preventDefault()
        history.go(1)
      }
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [table])

  return (
    <Box
      ref={rootRef}
      data-overlayscrollbars=""
      sx={{
        height: tableSetHeight,
        borderCollapse: 'collapse',
        '& th, & td': {
          border: 'none',
        },
        '& th': {
          fontFamily: 'ProximaNova',
          fontWeight: '500',
          padding: '8px',
          paddingRight: '2px',
        },
        '& td': {
          paddingLeft: '8px',
        },
        '& .MuiTableRow-root': {
          boxShadow: 'none',
        },
        '& .MuiTableBody-root .MuiTableRow-root:hover td:after': {
          backgroundColor: `${Colors.UserListItemHoverBackground} `,
          transition: 'none',
        },
        '& .MuiTableBody-root': {
          minHeight: '72px',
        },
        '& .MuiTableHead-root': {
          opacity: '1',
          backgroundColor: Colors.white,
        },
        '& .MuiPaper-root': {
          boxShadow: 'none',
        },
        '& .MuiBox-root': {
          padding: '0px',
          alignItems: 'center',
        },
        ' & .MuiPaper-root .os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless':
          {
            marginTop: enableToolBar ? '96px !important' : '40px !important',
          },
      }}
    >
      <MaterialReactTable table={table} />
    </Box>
  )
}

export default IFTable
