import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchAdminListRequestState: RequestState.UNINITIALIZED,
  fetchAdminsCountRequestState: RequestState.UNINITIALIZED,
  updateAdminRoleRequestState: RequestState.UNINITIALIZED,
  deleteAdminRequestState: RequestState.UNINITIALIZED,
  inviteAdminRequestState: RequestState.UNINITIALIZED,
  fetchInviteAdminListRequestState: RequestState.UNINITIALIZED,
  deleteInviteAdminRequestState: RequestState.UNINITIALIZED,
  sendAdminEmailRequestState: RequestState.UNINITIALIZED,
  fetchInviteAdminRequestState: RequestState.UNINITIALIZED,
  createAdminRequestState: RequestState.UNINITIALIZED,
  fetchAdminsInvitationsCountRequestState: RequestState.UNINITIALIZED,
  filterText: '',
  inviteAdminStatus: '',
  paginationOffset: 0,
  count: 0,
  adminsInvitationsCount: 0,
  admins: [],
  roles: [],
  inviteAdmins: [],
  inviteAdminPaginationOffset: 0,
  inviteAdmin: {},
  defaultAdminsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Role',
      data: [],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
  adminsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Role',
      data: [],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
}
