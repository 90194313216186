const getNotificationGroups = (state) => {
  return state.main.notificationState.notificationGroups
}

const getFetchNotificationGroupRequestState = (state) => {
  return state.main.notificationState.fetchNotificationGroupRequestState
}

const getFetchNotificationsRequestState = (state) => {
  return state.main.notificationState.fetchNotificationsRequestState
}
const getNotifications = (state) => {
  return state.main.notificationState.notifications
}

const getNotificationsFilter = (state) => {
  return state.main.notificationState.notificationsFilter
}
const getNotificationsPaginationOffset = (state) => {
  return state.main.notificationState.notificationsPaginationOffset
}
const getEditNotificationRequestState = (state) => {
  return state.main.notificationState.editNotificationRequestState
}
const getScheduleNotificationRequestState = (state) => {
  return state.main.notificationState.scheduleNotificationRequestState
}
const getNotificationsCountRequestState = (state) => {
  return state.main.notificationState.fetchNotificationsCountRequestState
}

const getGroupsCountRequestState = (state) => {
  return state.main.notificationState.fetchGroupsCountRequestState
}
const getGroupsCount = (state) => {
  return state.main.notificationState.groupsCount
}
const getNotificationsCount = (state) => {
  return state.main.notificationState.notificationsCount
}

const getGroupsFilter = (state) => {
  return state.main.notificationState.groupsFilter
}
const getGroups = (state) => {
  return state.main.notificationState.groups
}
const getFetchGroupsRequestState = (state) => {
  return state.main.notificationState.fetchGroupsRequestState
}
const getGroupsPaginationOffset = (state) => {
  return state.main.notificationState.groupsPaginationOffset
}
const getDeleteGroupRequestState = (state) => {
  return state.main.notificationState.deleteGroupRequestState
}
const getEditGroupRequestState = (state) => {
  return state.main.notificationState.editGroupRequestState
}
const getCreateGroupRequestState = (state) => {
  return state.main.notificationState.createGroupRequestState
}

const getQueryCountRequestState = (state) => {
  return state.main.notificationState.fetchQueryCountRequestState
}
const getQueryCount = (state) => {
  return state.main.notificationState.queryCount
}
const getInvalidId = (state) => {
  return state.main.notificationState.invalidId
}

const NotificationSelectors = {
  getNotificationGroups,
  getFetchNotificationGroupRequestState,
  getFetchNotificationsRequestState,
  getNotifications,
  getNotificationsFilter,
  getNotificationsPaginationOffset,
  getEditNotificationRequestState,
  getScheduleNotificationRequestState,
  getNotificationsCountRequestState,
  getGroupsCountRequestState,
  getGroupsCount,
  getNotificationsCount,
  getGroupsFilter,
  getGroups,
  getFetchGroupsRequestState,
  getGroupsPaginationOffset,
  getDeleteGroupRequestState,
  getEditGroupRequestState,
  getCreateGroupRequestState,
  getQueryCountRequestState,
  getQueryCount,
  getInvalidId,
}

export default NotificationSelectors
