import React, { useState, useEffect } from 'react'
import Styles from './CreateAdminAccountpage.module.css'
import styled from 'styled-components'
import logo from 'Assets/Images/logo.png'
import { TextField, FormGroup } from '@mui/material'
import IFText from 'Components/IFText/IFText'
import IFButton from 'Components/IFButton/IFButton'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { Colors } from 'Theme'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import * as Yup from 'yup'
import history from 'history/browser'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'
import { IFLoadingIndicator } from 'Components'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'

const initialValues = {
  username: '',
  password: '',
  confirmPassword: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`
const CssTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: ${Colors.text};
    }
    & .MuiOutlinedInput-root {
      ${({ InputProps }) =>
        InputProps?.readOnly &&
        `
        background-color: ${Colors.grey}; 
        color: ${Colors.gray}; 
      `}

      &:hover fieldset {
        border-color: ${Colors.primary};
      }

      &.Mui-focused fieldset {
        border-color: ${Colors.primary};
      }

      & .MuiInputBase-input {
        ${({ InputProps }) =>
          InputProps?.readOnly &&
          `
          color: ${Colors.gray}; 
        `}
      }
    }
  }
`
const CreateAdminAccountPage = ({
  fetchInviteAdmin,
  inviteAdmin,
  createAdmin,
  fetchInviteAdminRequestState,
  inviteAdminStatus,
  createAdminRequestState,
}) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleRegister = (values) => {
    const admin = {
      email: inviteAdmin.email,
      name: values.username,
      password: values.password,
      role: inviteAdmin.role,
      inviteId: inviteAdmin._id,
    }
    createAdmin(admin)
  }
  useEffect(() => {
    if (createAdminRequestState === RequestState.SUCCEEDED) {
      navigate('/login')
    }
  }, [createAdminRequestState, navigate])

  useEffect(() => {
    const slashArray = history.location.pathname.split('/')
    const inviteAdminId = slashArray[slashArray.length - 1]
    if (inviteAdminId && inviteAdminId.match(/^[0-9a-fA-F]{24}$/)) {
      fetchInviteAdmin(inviteAdminId)
    }
  }, [])

  useEffect(() => {
    if (inviteAdmin?.email) {
      setEmail(inviteAdmin.email)
    }
  }, [inviteAdmin])

  return (
    <div
      className={Styles.BackgroundDiv}
      style={{ backgroundColor: Colors.LoginBackground }}
    >
      <div
        className={Styles.Container}
        style={{
          backgroundColor: Colors.white,
          boxShadow: `0 0 20px 3px ${Colors.LoginBackgroundShadow}`,
        }}
      >
        <div
          className={Styles.WelcomeCard}
          style={{
            background: `linear-gradient(to bottom left, ${Colors.LoginGradient1}, ${Colors.LoginGradient2})`,
          }}
        >
          <IFText
            className={Styles.WelcomeTitle}
            style={{ color: Colors.white }}
          >
            {t('CreateAdminAccountPage.WelcomeTitle')}
          </IFText>
          <IFText
            className={Styles.WelcomeText}
            style={{ color: Colors.LoginBackground }}
          >
            {t('CreateAdminAccountPage.CreateAccount')}
          </IFText>
        </div>

        <div className={Styles.FormDiv}>
          <div className={Styles.LogoWrappingDiv}>
            <img className={Styles.Logo} src={logo} alt="logo" />
          </div>

          {fetchInviteAdminRequestState === RequestState.LOADING ? (
            <div className={Styles.FormWrappingDiv}>
              <IFLoadingIndicator />
            </div>
          ) : inviteAdminStatus === InfinityEnums.InvitationStatus.PENDING ? (
            <FormContainer className={Styles.FormWrappingDiv}>
              <Formik
                validationSchema={object({
                  username: string().required(
                    t('CreateAdminAccountPage.Required'),
                  ),
                  password: Yup.string()
                    .required(t('CreateAdminAccountPage.Required'))
                    .min(8, t('CreateAdminAccountPage.ShortPassword')),
                  confirmPassword: Yup.string()
                    .required(t('CreateAdminAccountPage.Required'))
                    .min(8, t('CreateAdminAccountPage.ShortPassword'))
                    .oneOf(
                      [Yup.ref('password'), null],
                      t('CreateAdminAccountPage.PasswordsMustMatch'),
                    ),
                })}
                initialValues={{
                  ...initialValues,
                  email: email || '',
                }}
                enableReinitialize
                onSubmit={handleRegister}
              >
                {({ values, errors }) => (
                  <Form className={Styles.InputDiv}>
                    <FormGroup className={Styles.InputWrappingDiv}>
                      <Field
                        className={Styles.Input}
                        size="small"
                        name="email"
                        InputProps={{ readOnly: true }}
                        as={CssTextField}
                        variant="outlined"
                        label={t('CreateAdminAccountPage.Email')}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: Colors.primary },
                        }}
                      />
                    </FormGroup>
                    <FormGroup className={Styles.InputWrappingDiv}>
                      <Field
                        className={Styles.Input}
                        size="small"
                        name="username"
                        as={CssTextField}
                        variant="outlined"
                        label={t('CreateAdminAccountPage.Name')}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: Colors.primary },
                        }}
                      />
                      <ErrorContainer>
                        <ErrorMessage name="username" />
                      </ErrorContainer>
                    </FormGroup>

                    <FormGroup className={Styles.InputWrappingDiv}>
                      <Field
                        as={CssTextField}
                        className={Styles.Input}
                        name="password"
                        type="password"
                        size="small"
                        variant="outlined"
                        label={t('CreateAdminAccountPage.Password')}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: Colors.primary },
                        }}
                      />
                      <ErrorContainer>
                        <ErrorMessage name="password" />
                      </ErrorContainer>
                    </FormGroup>

                    <FormGroup className={Styles.InputWrappingDiv}>
                      <Field
                        as={CssTextField}
                        className={Styles.Input}
                        name="confirmPassword"
                        type="password"
                        size="small"
                        variant="outlined"
                        label={t('CreateAdminAccountPage.ConfirmPassword')}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: Colors.primary },
                        }}
                      />
                      <ErrorContainer>
                        <ErrorMessage name="confirmPassword" />
                      </ErrorContainer>
                    </FormGroup>

                    <IFButton
                      className={Styles.ButtonWrappingDiv}
                      color={Colors.primary}
                      isDead={
                        !(
                          values.username &&
                          values.password &&
                          values.confirmPassword
                        ) ||
                        errors.password ||
                        errors.username ||
                        errors.confirmPassword
                      }
                      isFill={true}
                      isLoading={
                        createAdminRequestState === RequestState.LOADING
                      }
                      text={t('CreateAdminAccountPage.Register')}
                    />
                  </Form>
                )}
              </Formik>
            </FormContainer>
          ) : inviteAdminStatus === InfinityEnums.InvitationStatus.ACCEPTED ? (
            <div className={Styles.AcceptedText}>
              <div>
                <IFText style={{ color: Colors.text }}>
                  {t('CreateAdminAccountPage.AcceptedInvite')}
                </IFText>
              </div>
              <div className={Styles.ClickHere}>
                <IFText
                  className={Styles.ClickHereText}
                  style={{ color: Colors.text }}
                >
                  {t('CreateAdminAccountPage.ToLogin')}
                </IFText>
                <Link
                  href="/login"
                  variant="body2"
                  style={{
                    color: Colors.linkColor,
                    fontSize: '1.1rem',
                  }}
                  className={Styles.link}
                >
                  <IFText className={Styles.ClickHereText}>
                    {t('CreateAdminAccountPage.Here')}
                  </IFText>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  fetchInviteAdmin: (inviteAdminId) =>
    dispatch(AdminActions.fetchInviteAdmin(inviteAdminId)),
  createAdmin: (admin) => dispatch(AdminActions.createAdmin(admin)),
})

const mapStateToProps = (state) => ({
  isLoginLoading: AuthSelectors.isLoginLoading(state),
  inviteAdmin: AdminSelectors.getInviteAdmin(state),
  fetchInviteAdminRequestState:
    AdminSelectors.getFetchInviteAdminRequestState(state),
  inviteAdminStatus: AdminSelectors.getInviteAdminStatus(state),
  createAdminRequestState: AdminSelectors.getCreateAdminRequestState(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAdminAccountPage)
