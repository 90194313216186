import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import images from 'Theme/Images'
import GridLayout, { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {
  EnergyConsumptionChart,
  DashboardCard,
  DashboardPieChart,
  Barchart,
  HorizontalBarchart,
  DashboardList,
  IFFilter,
} from 'Components'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import TransactionActions from 'Stores/Transaction/Actions'
import Colors from 'Theme/Colors'
import Styles from './DashboardPage.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import { Scrollbars } from 'react-custom-scrollbars'
import LoadingBar from 'react-top-loading-bar'
import AuthSelectors from 'Stores/Auth/Selectors'
import DashboardSelectors from 'Stores/Dashboard/Selectors'
import DashboardActions from 'Stores/Dashboard/Actions'
import { formatNumber } from 'Utils/Calculations'
import styled from 'styled-components'

const WhiteBackground = styled.div`
  position: absolute;
  top: 0;
  border-radius: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${Colors.ListBackground};
  z-index: 1000;
`

const ResponsiveGridLayout = WidthProvider(Responsive)

const layouts = {
  lg: [
    { i: 'SalesChart', x: 0, y: 0, w: 4, h: 2, static: true },
    { i: 'UsersList', x: 4, y: 0, w: 2, h: 2, static: true },
    { i: 'Wallets', x: 6, y: 0, w: 2, h: 1, static: true },
    { i: 'AddedMoney', x: 6, y: 1, w: 1, h: 1, static: true },
    { i: 'ChargingMoney', x: 7, y: 1, w: 1, h: 1, static: true },
    { i: 'ActiveUsers', x: 0, y: 2, w: 2, h: 1, static: true },
    { i: 'VouchersList', x: 0, y: 3, w: 2, h: 2, static: true },
    { i: 'TotalUsers', x: 2, y: 2, w: 2, h: 1, static: true },
    { i: 'EnergyPie', x: 4, y: 2, w: 2, h: 1, static: true },
    { i: 'TransCount', x: 7, y: 2, w: 1, h: 1, static: true },
    { i: 'EnergyChart', x: 2, y: 3, w: 4, h: 2, static: true },
    { i: 'EnergyCard', x: 6, y: 2, w: 1, h: 1, static: true },
  ],
  md: [
    { i: 'SalesChart', x: 0, y: 0, w: 4, h: 2, static: true },
    { i: 'Wallets', x: 4, y: 2, w: 2, h: 1, static: true },
    { i: 'ChargingMoney', x: 4, y: 1, w: 1, h: 1, static: true },
    { i: 'AddedMoney', x: 5, y: 1, w: 1, h: 1, static: true },
    { i: 'UsersList', x: 4, y: 0, w: 2, h: 2, static: true },
    { i: 'Wallets', x: 0, y: 2, w: 2, h: 1, static: true },
    { i: 'AddedMoney', x: 1, y: 4, w: 1, h: 1, static: true },
    { i: 'ChargingMoney', x: 2, y: 4, w: 1, h: 1, static: true },
    { i: 'ActiveUsers', x: 2, y: 2, w: 2, h: 1, static: true },
    { i: 'VouchersList', x: 0, y: 2, w: 2, h: 2, static: true },
    { i: 'TotalUsers', x: 2, y: 3, w: 2, h: 1, static: true },
    { i: 'EnergyPie', x: 4, y: 4, w: 2, h: 1, static: true },
    { i: 'TransCount', x: 4, y: 5, w: 1, h: 1, static: true },
    { i: 'EnergyChart', x: 0, y: 4, w: 4, h: 2, static: true },
    { i: 'EnergyCard', x: 5, y: 5, w: 1, h: 1, static: true },
  ],
  sm: [
    { i: 'SalesChart', x: 0, y: 0, w: 4, h: 2, static: true },
    { i: 'UsersList', x: 0, y: 2, w: 2, h: 2, static: true },
    { i: 'Wallets', x: 2, y: 2, w: 2, h: 1, static: true },
    { i: 'AddedMoney', x: 0, y: 7, w: 1, h: 1, static: true },
    { i: 'ChargingMoney', x: 0, y: 3, w: 1, h: 1, static: true },
    { i: 'ActiveUsers', x: 1, y: 3, w: 2, h: 1, static: true },
    { i: 'VouchersList', x: 3, y: 3, w: 2, h: 2, static: true },
    { i: 'TotalUsers', x: 0, y: 4, w: 2, h: 1, static: true },
    { i: 'EnergyPie', x: 2, y: 7, w: 2, h: 1, static: true },
    { i: 'TransCount', x: 3, y: 4, w: 1, h: 1, static: true },
    { i: 'EnergyChart', x: 0, y: 5, w: 4, h: 2, static: true },
    { i: 'EnergyCard', x: 1, y: 7, w: 1, h: 1, static: true },
  ],
  xs: [
    { i: 'SalesChart', x: 0, y: 0, w: 4, h: 2, static: true },
    { i: 'UsersList', x: 0, y: 5, w: 2, h: 2, static: true },
    { i: 'Wallets', x: 0, y: 1, w: 2, h: 1, static: true },
    { i: 'AddedMoney', x: 0, y: 4, w: 1, h: 1, static: true },
    { i: 'ChargingMoney', x: 1, y: 9, w: 1, h: 1, static: true },
    { i: 'ActiveUsers', x: 0, y: 6, w: 2, h: 1, static: true },
    { i: 'VouchersList', x: 0, y: 7, w: 2, h: 2, static: true },
    { i: 'TotalUsers', x: 0, y: 2, w: 2, h: 1, static: true },
    { i: 'EnergyPie', x: 0, y: 8, w: 2, h: 1, static: true },
    { i: 'TransCount', x: 0, y: 9, w: 1, h: 1, static: true },
    { i: 'EnergyChart', x: 0, y: 3, w: 4, h: 2, static: true },
    { i: 'EnergyCard', x: 1, y: 4, w: 1, h: 1, static: true },
  ],
}

const DashboardPage = ({
  metrics,
  energyConsumption,
  fetchEnergyConsumptionChart,
  admin,
  setDashboardFilter,
  clearDashboardFilter,
  dashboardFilters,
  fetchDashboardRequestState,
  setCountryFilter,
  fetchDashboard,
}) => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState(0)
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [dateFilter, setDateFilter] = useState(false)
  const [showWhiteContainer, setShowWhiteContainer] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWhiteContainer(false)
    }, 300)
    return () => clearTimeout(timer)
  }, [])

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  useEffect(() => {
    fetchEnergyConsumptionChart(dashboardFilters, 0)
    fetchDashboard(dashboardFilters)
  }, [])
  useEffect(() => {
    setCountryFilter(admin)
    setIsFirstLoaded(true)
    return () => {
      clearDashboardFilter()
    }
  }, [])

  useEffect(() => {
    if (fetchDashboardRequestState === RequestState.LOADING && progress === 0)
      setProgress(progress + 10)
    if (fetchDashboardRequestState === RequestState.SUCCEEDED) {
      setProgress(100)
      setIsFirstLoaded(false)
    }
    if (
      fetchDashboardRequestState !== RequestState.LOADING &&
      fetchDashboardRequestState !== RequestState.UNINITIALIZED &&
      fetchDashboardRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchDashboardRequestState, isFirstLoaded])

  const handleTransactionFilter = (newFilter) => {
    setDashboardFilter(newFilter)
    fetchDashboard(newFilter)
    fetchEnergyConsumptionChart(newFilter)
    newFilter[0].value === '' ? setDateFilter(false) : setDateFilter(true)
  }

  useEffect(() => {
    const errorHandler = (e) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications' ||
            'ResizeObserver loop limit exceeded',
        )
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none'
        }
      }
    }
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  return (
    <div className={Styles.Container}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div className={Styles.TopContainer}>
        <div className={Styles.GridContainer}>
          <div className={Styles.Filter}>
            <IFFilter
              filters={dashboardFilters}
              onFilterChange={(newFilter) => {
                handleTransactionFilter(newFilter)
              }}
              textFieldPlaceholder={t('Dashboard.DashboardFilterPlaceholder')}
            />
          </div>
          <Scrollbars autoHide style={{ height: '100%' }}>
            <div>
              {showWhiteContainer && <WhiteBackground />}
              <ResponsiveGridLayout
                layouts={layouts}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 576, xxs: 0 }}
                cols={{ lg: 8, md: 6, sm: 4, xs: 2, xxs: 1 }}
                rowHeight={168}
                autoSize={true}
                margin={[8, 8]}
                containerPadding={[0, 0]}
                isResizable={true}
                isDraggable={false}
              >
                <div key="TransCount">
                  <DashboardCard
                    title={t('Dashboard.TotalChargingTransactions')}
                    icon={images.charge}
                    data={
                      metrics?.totalChargeTransactions
                        ? metrics?.totalChargeTransactions
                        : 0
                    }
                    height={'100%'}
                    width={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="AddedMoney">
                  <DashboardCard
                    title={t('Dashboard.TotalAddedMoney')}
                    metric={metrics?.transactionsCurrency}
                    icon={images.money}
                    data={
                      metrics?.totalMoneyCollected
                        ? metrics?.totalMoneyCollected
                        : 0
                    }
                    height={'100%'}
                    width={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="EnergyCard">
                  <DashboardCard
                    title={t('TransactionsPage.TotalEnergyConsumption')}
                    metric="kWh"
                    icon={images.station}
                    data={
                      metrics?.totalEnergyConsumption
                        ? metrics?.totalEnergyConsumption
                        : 0
                    }
                    height={'100%'}
                    width={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="ChargingMoney">
                  <DashboardCard
                    title={t('Dashboard.TotalChargingMoney')}
                    metric={metrics?.transactionsCurrency}
                    icon={images.spark}
                    data={
                      metrics?.totalChargingMoney
                        ? metrics?.totalChargingMoney
                        : 0
                    }
                    height={'100%'}
                    width={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="Wallets">
                  <DashboardPieChart
                    values={[
                      metrics?.totalNegativeWallets
                        ? metrics?.totalNegativeWallets
                        : 0,
                      metrics?.totalPositiveWallets
                        ? metrics?.totalPositiveWallets
                        : 0,
                    ]}
                    labels={[
                      `${t('Dashboard.NegativeWallets')} (${formatNumber(
                        metrics?.totalNegativeWallets
                          ? metrics?.totalNegativeWallets
                          : 0,
                      )})`,
                      `${t('Dashboard.PositiveWallets')} (${formatNumber(
                        metrics?.totalPositiveWallets
                          ? metrics?.totalPositiveWallets
                          : 0,
                      )})`,
                    ]}
                    title={t('Dashboard.Wallets')}
                    chartColors={[Colors.red, Colors.primary]}
                    pieInnerRadius={40}
                    pieOuterRadius={60}
                    height={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="TotalUsers">
                  <DashboardPieChart
                    values={[
                      metrics?.activeUsers ? metrics?.activeUsers : 0,
                      metrics?.inactiveUsers ? metrics?.inactiveUsers : 0,
                    ]}
                    labels={[
                      `${t('Dashboard.ActiveUsers')} (${formatNumber(
                        metrics?.activeUsers ? metrics?.activeUsers : 0,
                      )})`,
                      `${t('Dashboard.InactiveUsers')} (${formatNumber(
                        metrics?.inactiveUsers ? metrics?.inactiveUsers : 0,
                      )})`,
                    ]}
                    total={metrics?.totalUsers ? metrics?.totalUsers : 0}
                    title={t('Dashboard.TotalUsers')}
                    chartColors={[Colors.SecondaryColor, Colors.primary]}
                    pieInnerRadius={40}
                    pieOuterRadius={60}
                    height={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="EnergyPie">
                  <DashboardPieChart
                    values={[
                      metrics?.totalDCConsumption
                        ? metrics?.totalDCConsumption
                        : 0,
                      metrics?.totalACConsumption
                        ? metrics?.totalACConsumption
                        : 0,
                    ]}
                    labels={[
                      `${'DC'} (${formatNumber(
                        metrics?.totalDCConsumption
                          ? metrics?.totalDCConsumption
                          : 0,
                      )})`,
                      `${'AC'} (${formatNumber(
                        metrics?.totalACConsumption
                          ? metrics?.totalACConsumption
                          : 0,
                      )})`,
                    ]}
                    title={t('TransactionsPage.TotalEnergyConsumption')}
                    chartColors={[Colors.DCColor, Colors.primary]}
                    pieInnerRadius={40}
                    pieOuterRadius={60}
                    height={'100%'}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>

                <div key="ActiveUsers">
                  <HorizontalBarchart
                    values={[
                      metrics?.newUsersCount ? metrics?.newUsersCount : 0,
                      metrics?.activeUsers ? metrics?.activeUsers : 0,
                    ]}
                    labels={[
                      t('Dashboard.NewUsers'),
                      t('Dashboard.ReturningUsers'),
                    ]}
                    title={t('Dashboard.ActiveUsers')}
                    chartColors={[Colors.SecondaryColor, Colors.primary]}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="UsersList">
                  <DashboardList
                    data={metrics?.usersList ? metrics?.usersList : []}
                    title={t('Dashboard.TopUsers')}
                    keyProp="Name"
                    labelProp="energy"
                    isAvatar={true}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="VouchersList">
                  <DashboardList
                    data={metrics?.vouchersList ? metrics?.vouchersList : []}
                    title={t('Dashboard.TopVouchers')}
                    keyProp="Name"
                    labelProp="redeemCount"
                    isAvatar={false}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="SalesChart">
                  <Barchart
                    values={[
                      metrics?.sales?.thisYear ? metrics?.sales?.thisYear : [],
                      metrics?.sales?.lastYear ? metrics?.sales?.lastYear : [],
                    ]}
                    labels={[
                      t('Dashboard.ThisYear'),
                      t('Dashboard.PreviousYear'),
                    ]}
                    startDates={months}
                    title={t('Dashboard.TotalSales')}
                    metric={`Money Earned (${metrics?.transactionsCurrency})`}
                    height={271}
                    chartColors={[Colors.SecondaryColor, Colors.primary]}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                  />
                </div>
                <div key="EnergyChart">
                  <EnergyConsumptionChart
                    title={t('TransactionsPage.EnergyConsumption')}
                    totalDC={energyConsumption?.intervalsDC}
                    totalAC={energyConsumption?.intervalsAC}
                    startDates={
                      dateFilter ? months : energyConsumption?.startDates
                    }
                    height={271}
                    isLoading={
                      fetchDashboardRequestState === RequestState.LOADING
                    }
                    dashboard={dateFilter}
                  />
                </div>
              </ResponsiveGridLayout>
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEnergyConsumptionChart: (filter, offset) =>
      dispatch(TransactionActions.fetchEnergyConsumptionChart(filter, offset)),
    setCountryFilter: (admin) =>
      dispatch(DashboardActions.setCountryFilter(admin)),
    setDashboardFilter: (newFilter) =>
      dispatch(DashboardActions.setDashboardFilter(newFilter)),
    clearDashboardFilter: () =>
      dispatch(DashboardActions.clearDashboardFilter()),
    fetchDashboard: (filter) =>
      dispatch(DashboardActions.fetchDashboard(filter)),
  }
}
const mapStateToProps = (state) => ({
  energyConsumption: TransactionSelectors.getEnergyConsumption(state),
  admin: AuthSelectors.getUser(state),
  dashboardFilters: DashboardSelectors.getDashboardFilter(state),
  fetchDashboardRequestState:
    DashboardSelectors.getFetchDashboardRequestState(state),
  metrics: DashboardSelectors.getDashboard(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
