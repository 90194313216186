import React, { useState, useEffect, useRef } from 'react'
import NotificationPageTabs from 'Containers/NotificationPageTabs/NotificationPageTabs'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import Styles from './NotificationsPage.module.css'
import Colors from 'Theme/Colors'
import InfinityEnums from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import {
  NotificationModal,
  NotificationsList,
  NotificationGroupsList,
  CreateNotificationGroupModal,
} from 'Containers'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'

const NotificationsPage = ({
  notificationsCount,
  groupsCount,
  fetchNotificationsCountRequestState,
  fetchGroupsCountRequestState,
  fetchNotificationsCount,
  fetchGroupsCount,
  clearNotifications,
  notificationsFilter,
  fetchNotificationsRequestState,
  fetchNotifications,
  groupsFilter,
  editingAdminRole,
  admin,
  setCountryFilterNoti,
  notificationGroups,
  fetchNotificationGroups,
  setCheckFilter,
  setNotificationsFilter,
  editNotificationRequestState,
  scheduleNotificationRequestState,
  fetchGroupsRequestState,
  createGroupRequestState,
}) => {
  const { t } = useTranslation()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [tablesHeight, setTablesHeight] = useState(0)
  const notificationModalRef = useRef()
  const groupModalRef = useRef()
  const notificationsTableRef = useRef(null)
  const notiFilterRef = useRef(null)

  const canEditNotification = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATIONS,
  )
  const canViewGroups = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATION_GROUPS,
  )
  const canViewNotifications = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
  )
  const canEditGroups = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATION_GROUPS,
  )
  useEffect(() => {
    fetchNotificationGroups()
  }, [])
  useEffect(() => {
    if (scheduleNotificationRequestState === RequestState.SUCCEEDED)
      notificationModalRef.current.dismiss()
    fetchNotifications(notificationsFilter, 0)
  }, [scheduleNotificationRequestState])

  useEffect(() => {
    if (createGroupRequestState === RequestState.SUCCEEDED)
      groupModalRef.current.dismiss()
  }, [createGroupRequestState])

  useEffect(() => {
    setCountryFilterNoti(admin)
    const names = notificationGroups.map((item) => item.name)
    setCheckFilter(names)
    setFirstLoad(true)

    return () => {}
  }, [notificationGroups])

  const handleTabChange = (newValue) => {
    setSelectedTabIndex(newValue)
  }

  useEffect(() => {
    if (canViewNotifications) fetchNotificationsCount(notificationsFilter)
    if (canViewGroups) fetchGroupsCount(groupsFilter)
  }, [])
  useEffect(() => {
    if (
      fetchNotificationsRequestState === RequestState.SUCCEEDED &&
      fetchNotificationsCountRequestState === RequestState.SUCCEEDED &&
      firstLoad
    ) {
      setFirstLoad(false)
    }
  }, [
    fetchNotificationsRequestState,
    fetchNotificationsCountRequestState,
    firstLoad,
  ])
  useEffect(() => {
    fetchNotifications(notificationsFilter, 0)
  }, [])

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }
    const resizeObserver = new ResizeObserver(handleResize)
    if (notificationsTableRef.current) {
      resizeObserver.observe(notificationsTableRef.current)
    }
    return () => {
      if (notificationsTableRef.current) {
        resizeObserver.unobserve(notificationsTableRef.current)
      }
    }
  })
  useEffect(() => {
    if (
      firstLoad &&
      fetchNotificationsRequestState === RequestState.LOADING &&
      progress === 0
    ) {
      setProgress(progress + 10)
    }

    if (
      firstLoad &&
      fetchNotificationsRequestState === RequestState.SUCCEEDED
    ) {
      setProgress(100)
    }
    if (
      firstLoad &&
      fetchNotificationsRequestState !== RequestState.LOADING &&
      fetchNotificationsRequestState !== RequestState.UNINITIALIZED &&
      fetchNotificationsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchNotificationsRequestState])

  const handleSendButton = () => {
    notificationModalRef.current.show()
  }
  const handleCreate = () => {
    groupModalRef.current.show()
    groupModalRef.current.resetValues()
  }
  const handleFilterChange = (newFilter) => {
    clearNotifications()
    setNotificationsFilter(newFilter)

    fetchNotifications(newFilter, 0)
    fetchNotificationsCount(newFilter)
  }
  return (
    <div className={Styles.Wrapper}>
      <div
        className={Styles.NotificationsContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.TabButtonDiv}>
          <NotificationPageTabs
            onValueChange={handleTabChange}
            initialIndex={selectedTabIndex}
            isLoading={
              fetchNotificationsRequestState &&
              (fetchNotificationsRequestState === RequestState.LOADING ||
                fetchNotificationsCountRequestState === RequestState.LOADING)
            }
            notificationsFilter={notificationsFilter}
            notificationsCount={notificationsCount}
            groupsCount={groupsCount}
            canViewGroups={canViewGroups}
            canViewNotifications={canViewNotifications}
          />
          {canEditNotification && (
            <div className={Styles.InviteAdminDiv}>
              {firstLoad &&
              fetchNotificationsRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <>
                  {selectedTabIndex === 0 && (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('PushNotificationsPanel.sendButton')}
                      className={Styles.InviteAdminButton}
                      onClick={handleSendButton}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {canEditGroups && (
            <div className={Styles.InviteAdminDiv}>
              {firstLoad && fetchGroupsRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <>
                  {selectedTabIndex === 1 && (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('PushNotificationsPanel.createButton')}
                      className={Styles.InviteAdminButton}
                      onClick={handleCreate}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {selectedTabIndex === 0 && (
          <div className={Styles.AdminFilterDiv}>
            <IFFilter
              ref={notiFilterRef}
              onFilterChange={(newFilter) => handleFilterChange(newFilter)}
              filters={notificationsFilter}
              textFieldPlaceholder={t(
                'PushNotificationsPanel.NotiFilterPlaceholder',
              )}
              isLoading={
                fetchNotificationsRequestState === RequestState.LOADING
              }
            />
          </div>
        )}
        <Scrollbars className={Styles.ScrollParent}>
          {selectedTabIndex === 0 && (
            <div className={Styles.Tables} ref={notificationsTableRef}>
              <NotificationsList tablesHeight={tablesHeight} />
            </div>
          )}
          {selectedTabIndex === 1 && (
            <div className={Styles.Tables} ref={notificationsTableRef}>
              <NotificationGroupsList tablesHeight={tablesHeight} />
            </div>
          )}
        </Scrollbars>
      </div>
      <NotificationModal
        ref={notificationModalRef}
        title={t('PushNotificationsPanel.PanelTitleSend')}
        isEdit={false}
      />
      <CreateNotificationGroupModal
        ref={groupModalRef}
        title={t('PushNotificationsPanel.PanelTitleGroupCreate')}
        isEdit={false}
        admin={admin}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationsCount: (filter) =>
      dispatch(NotificationActions.fetchNotificationsCount(filter)),
    fetchGroupsCount: (filter) =>
      dispatch(NotificationActions.fetchGroupsCount(filter)),
    fetchNotifications: (filter, offset, shouldShowError) =>
      dispatch(
        NotificationActions.fetchNotifications(filter, offset, shouldShowError),
      ),
    setCountryFilterNoti: (admin) =>
      dispatch(NotificationActions.setCountryFilterNoti(admin)),
    clearNotifications: () =>
      dispatch(NotificationActions.clearNotifications()),
    fetchNotificationGroups: () =>
      dispatch(NotificationActions.fetchNotificationGroups()),
    setCheckFilter: (names) =>
      dispatch(NotificationActions.setCheckFilter(names)),
    setNotificationsFilter: (newFilter) =>
      dispatch(NotificationActions.setNotificationsFilter(newFilter)),
  }
}

const mapStateToProps = (state) => ({
  notificationsCount: NotificationSelectors.getNotificationsCount(state),
  groupsCount: NotificationSelectors.getGroupsCount(state),
  fetchNotificationsCountRequestState:
    NotificationSelectors.getNotificationsCountRequestState(state),
  fetchGroupsCountRequestState:
    NotificationSelectors.getGroupsCountRequestState(state),
  notificationsFilter: NotificationSelectors.getNotificationsFilter(state),
  groupsFilter: NotificationSelectors.getGroupsFilter(state),
  fetchNotificationsRequestState:
    NotificationSelectors.getFetchNotificationsRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  admin: AuthSelectors.getUser(state),
  notificationGroups: NotificationSelectors.getNotificationGroups(state),
  editNotificationRequestState:
    NotificationSelectors.getEditNotificationRequestState(state),
  scheduleNotificationRequestState:
    NotificationSelectors.getScheduleNotificationRequestState(state),
  fetchGroupsRequestState:
    NotificationSelectors.getFetchGroupsRequestState(state),
  createGroupRequestState:
    NotificationSelectors.getCreateGroupRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage)
