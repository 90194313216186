import { put, call, select, spawn } from 'redux-saga/effects'
import { adminsApiService, handleError } from 'Services/ApiService.js'
import AdminActions from 'Stores/Admin/Actions'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'
import i18n from 'i18next'
import history from 'history/browser'
import AdminSelectors from 'Stores/Admin/Selectors'
import {
  encodeObjectToBase64,
  decodeBase64ToObject,
} from '../Utils/Base64Functions'
import { fetchRoles } from './RoleSaga'
import RoleSelectors from 'Stores/Role/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import IFFilterType from 'Enums/IFFilterType'

export function* fetchAdminList({ offset, shouldShowError }) {
  const query = new URLSearchParams(history.location.search)
  const slashArray = history.location.pathname.split('/')
  let filterParam = query.get('filter')
  let filterObject = []
  let defaultAdminsFilters = yield select(
    AdminSelectors.getDefaultAdminsFilters,
  )
  try {
    const admin = yield select(AuthSelectors.getUser)
    if (admin.registeredCountries.length > 1) {
      const countryNames = admin.registeredCountries.map(
        (country) => country.name,
      )
      let index = defaultAdminsFilters.findIndex(
        (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
      )
      if (index === -1) {
        defaultAdminsFilters.push({
          type: IFFilterType.CHECK,
          title: InfinityEnums.IFFilterTitle.Country,
          data: countryNames,
          initialValue: [],
          value: [],
        })
      }
    }
    if (filterParam === null) {
      filterObject = defaultAdminsFilters
      filterParam = encodeObjectToBase64(filterObject)
    } else {
      try {
        filterObject = decodeBase64ToObject(filterParam)
        if (admin.registeredCountries.length > 1) {
          const countryNames = admin.registeredCountries.map(
            (country) => country.name,
          )
          let index = filterObject.findIndex(
            (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
          )
          if (index === -1) {
            filterObject.push({
              type: IFFilterType.CHECK,
              title: InfinityEnums.IFFilterTitle.Country,
              data: countryNames,
              initialValue: [],
              value: [],
            })
          }
        }
        for (let i = 0; i < filterObject.length; i++) {
          const { type: type1, title: title1, data: data1 } = filterObject[i]
          const {
            type: type2,
            title: title2,
            data: data2,
          } = defaultAdminsFilters[i]

          if (type1 !== type2 || title1 !== title2) {
            filterObject = defaultAdminsFilters
            filterParam = encodeObjectToBase64(filterObject)
          }
        }
      } catch (error) {
        filterObject = defaultAdminsFilters
        filterParam = encodeObjectToBase64(filterObject)
      }
    }

    if (offset === 0) {
      yield put(AdminActions.fetchAdminListLoading())
      yield* fetchRoles({})
    }
    const roles = yield select(RoleSelectors.getRoles)
    const { data } = yield call(adminsApiService.get, '/', {
      params: {
        filter: filterParam,
        offset: offset,
      },
    })
    yield put(
      AdminActions.fetchAdminListSuccess(
        offset,
        data.admins,
        data.nextOffset,
        filterObject,
        roles,
      ),
    )
  } catch (e) {
    yield put(AdminActions.fetchAdminListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchAdminsCount({}) {
  const query = new URLSearchParams(history.location.search)
  let filterParam = query.get('filter')
  let filterObject = []
  yield put(AdminActions.fetchAdminsCountLoading())
  try {
    const adminFilter = yield select(AdminSelectors.getAdminsFilters)
    if (filterParam === null) {
      filterObject = adminFilter
      filterParam = encodeObjectToBase64(filterObject)
    } else {
      try {
        filterObject = decodeBase64ToObject(filterParam)
        for (let i = 0; i < filterObject.length; i++) {
          const { type: type1, title: title1, data: data1 } = filterObject[i]
          const { type: type2, title: title2 } = adminFilter[i]

          if (type1 !== type2 || title1 !== title2) {
            filterObject = adminFilter
            filterParam = encodeObjectToBase64(filterObject)
          }
        }
      } catch (error) {
        filterObject = adminFilter
        filterParam = encodeObjectToBase64(filterObject)
      }
    }
    const { data } = yield call(adminsApiService.get, '/count', {
      params: {
        filter: filterParam,
      },
    })
    yield put(AdminActions.fetchAdminsCountSuccess(data.count, filterObject))
  } catch (e) {
    yield put(AdminActions.fetchAdminsCountFail(e))
    handleError(e)
  }
}

export function* updateAdminRole({ adminId, newRoleValue }) {
  yield put(AdminActions.updateAdminRoleLoading(adminId))

  try {
    const { data } = yield call(adminsApiService.patch, `/${adminId}`, {
      newRoleId: newRoleValue,
    })

    yield put(AdminActions.updateAdminRoleSuccess(data))
  } catch (e) {
    yield put(AdminActions.updateAdminRoleFail(adminId))

    handleError(e)
  }
}

export function* deleteAdmin({ adminId }) {
  yield put(AdminActions.deleteAdminLoading(adminId))

  try {
    yield call(adminsApiService.delete, `/${adminId}`)
    yield put(AdminActions.deleteAdminSuccess(adminId))
    toaster.push(
      <IFToastMessage type="success" text={i18n.t('AdminList.AdminDeleted')} />,
    )
  } catch (e) {
    yield put(AdminActions.deleteAdminFail(adminId))
    handleError(e)
  }
}

export function* inviteAdmin({ inviteAdminData }) {
  yield put(AdminActions.inviteAdminLoading())
  try {
    const { data } = yield call(adminsApiService.post, '/inviteAdmin', {
      email: inviteAdminData.email,
      role: inviteAdminData.role,
      country: inviteAdminData.countries,
    })

    yield put(AdminActions.inviteAdminSuccess(data))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('CreateAdminAccountPage.InvitationSent')}
      />,
    )
  } catch (e) {
    yield put(AdminActions.inviteAdminFail(e))
    handleError(e)
  }
}

export function* fetchInviteAdminList({ offset, shouldShowError }) {
  try {
    if (offset === 0) yield put(AdminActions.fetchInviteAdminListLoading())
    const { data } = yield call(adminsApiService.get, '/inviteAdmin', {
      params: {
        offset: offset,
      },
    })

    yield put(
      AdminActions.fetchInviteAdminListSuccess(
        data.invitations,
        data.nextOffset,
        offset,
      ),
    )
  } catch (e) {
    yield put(AdminActions.fetchInviteAdminListFail(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* deleteInviteAdmin({ inviteAdminId }) {
  yield put(AdminActions.deleteInviteAdminLoading())
  try {
    yield call(adminsApiService.delete, `/inviteAdmin/${inviteAdminId}`)

    yield put(AdminActions.deleteInviteAdminSuccess(inviteAdminId))
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('InvitationsList.InvitationDeleted')}
      />,
    )
  } catch (e) {
    yield put(AdminActions.deleteInviteAdminFail(e))
    handleError(e)
  }
}
export function* sendAdminEmail({ email }) {
  yield put(AdminActions.sendAdminEmailLoading())
  try {
    const { data } = yield call(adminsApiService.post, `/sendAdminEmail`, {
      email: email,
    })
    yield put(AdminActions.sendAdminEmailSuccess())
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('CreateAdminAccountPage.InvitationSent')}
      />,
    )
  } catch (e) {
    yield put(AdminActions.sendAdminEmailFail(e))
    handleError(e)
  }
}

export function* fetchInviteAdmin({ inviteAdminId }) {
  yield put(AdminActions.fetchInviteAdminLoading())
  try {
    const { data } = yield call(
      adminsApiService.get,
      `/inviteAdmin/${inviteAdminId}`,
    )

    yield put(AdminActions.setInviteAdminStatus(data.status))
    yield put(AdminActions.fetchInviteAdminSuccess(data))
  } catch (e) {
    yield put(AdminActions.fetchInviteAdminFail(e))
    handleError(e)
  }
}

export function* createAdmin({ admin }) {
  yield put(AdminActions.createAdminLoading())
  try {
    const { data } = yield call(adminsApiService.post, '/', {
      ...admin,
    })
    yield put(AdminActions.createAdminSuccess())
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('CreateAdminAccountPage.AccountCreated')}
      />,
    )
  } catch (e) {
    yield put(AdminActions.createAdminFail(e))
    handleError(e)
  }
}
export function* fetchAdminsInvitationsCount({}) {
  yield put(AdminActions.fetchAdminsInvitationsCountLoading())
  try {
    const { data } = yield call(adminsApiService.get, '/invitationsCount')

    yield put(AdminActions.fetchAdminsInvitationsCountSuccess(data.invitations))
  } catch (e) {
    yield put(AdminActions.fetchAdminsInvitationsCountFail(e))
    handleError(e)
  }
}
