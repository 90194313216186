import React, { useEffect, useRef, useState } from 'react'
import { PieChart } from '@mui/x-charts/PieChart'
import Styles from './DashboardPieChart.module.css'
import PropTypes from 'prop-types'
import { IFText, IFSkeleton } from 'Components'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { formatNumber } from '../../Utils/Calculations'
import { debounce } from 'lodash'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const ColorBox = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background-color: ${(props) => props.color};
`

const DashboardPieChart = ({
  values,
  labels,
  total,
  title,
  chartColors,
  pieInnerRadius,
  pieOuterRadius,
  height,
  isLoading,
}) => {
  const chartContainerRef = useRef(null)
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const updateContainerWidth = () => {
      if (chartContainerRef.current) {
        setContainerWidth(chartContainerRef.current.offsetWidth + 10)
      }
    }
    const debouncedResizeHandler = debounce(updateContainerWidth, 200)
    updateContainerWidth()
    window.addEventListener('resize', debouncedResizeHandler)
    return () => {
      window.removeEventListener('resize', debouncedResizeHandler)
      debouncedResizeHandler.cancel()
    }
  }, [])
  const formattedData = total !== undefined ? formatNumber(total) : null

  const chartData = values.map((value, index) => ({
    id: index,
    value,
    color: chartColors[index % chartColors.length],
  }))

  return (
    <Card style={{ height: height }}>
      <div style={{ flex: 1 }}>
        <div className={Styles.Header}>
          {isLoading ? (
            <IFSkeleton variant="text" width="130px" height="20px" />
          ) : (
            <IFText style={{ fontSize: 16 }}>{title}</IFText>
          )}
          {isLoading && formattedData ? (
            <IFSkeleton
              variant="text"
              width="50px"
              height="20px"
              style={{ marginLeft: 8 }}
            />
          ) : (
            formattedData && (
              <div className={Styles.TotalDiv}>
                <IFText className={Styles.total}>{formattedData}</IFText>
              </div>
            )
          )}
        </div>

        <div className={Styles.LegendContainer}>
          {isLoading
            ? Array.from({ length: labels.length || 4 }).map((_, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <IFSkeleton variant="text" width="100px" height="14px" />
                </div>
              ))
            : labels.map((label, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <ColorBox color={chartColors[index % chartColors.length]} />
                  <IFText style={{ fontSize: 14 }}>{label}</IFText>
                </div>
              ))}
        </div>
      </div>

      <div ref={chartContainerRef} className={Styles.ChartContainer}>
        {isLoading ? (
          <IFSkeleton
            variant="circular"
            width={pieOuterRadius * 1.75}
            height={pieOuterRadius * 1.75}
            style={{ margin: 'auto' }}
          />
        ) : (
          <PieChart
            series={[
              {
                data: chartData,
                innerRadius: pieInnerRadius,
                outerRadius: pieOuterRadius,
                cx: containerWidth - pieOuterRadius - 20,
              },
            ]}
          />
        )}
      </div>
    </Card>
  )
}

DashboardPieChart.propTypes = {
  values: PropTypes.array,
  labels: PropTypes.array,
  total: PropTypes.number,
  title: PropTypes.string,
  chartColors: PropTypes.array,
  pieInnerRadius: PropTypes.number,
  pieOuterRadius: PropTypes.number,
  height: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default DashboardPieChart
