import { read, utils } from 'xlsx'
import Papa from 'papaparse'

export function xlsxToJson(file) {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    reader.onload = (event) => {
      const data = event.target.result
      const workbook = read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const json = utils.sheet_to_json(worksheet)
      resolve(json)
    }
  })
  return promise
}

export function csvToJson(file) {
  const promise = new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      complete: (parsedFile) => resolve(parsedFile),
    })
  })
  return promise
}
