import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Styles from './AppChargingTokenPage.module.css'
import {
  AppChargingTokenList,
  AppChargingTokenPageTabs,
  AppChargingTokenDesignGrid,
} from 'Containers'
import AppChargingTokenActions from 'Stores/AppChargingToken/Actions'
import AppChargingTokenSelectors from 'Stores/AppChargingToken/Selectors'
import AppChargingTokenDesignSelectors from 'Stores/AppChargingTokenDesign/Selectors'
import AppChargingTokenDesignActions from 'Stores/AppChargingTokenDesign/Actions'
import RequestState from 'Enums/RequestState'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import Colors from 'Theme/Colors'
import AuthSelectors from 'Stores/Auth/Selectors'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import history from 'history/browser'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'
import AddAppChargingTokenDesignModal from './AddAppChargingTokenDesignModal'
import CreateAppChargingToken from './CreateAppChargingToken'
import VoucherSelectors from 'Stores/Voucher/Selectors'
import VoucherActions from 'Stores/Voucher/Actions'
import InfinityEnums from 'Enums/InfinityEnums'
import LoadingBar from 'react-top-loading-bar'

const AppChargingTokenPage = ({
  fetchAppChargingTokens,
  appChargingTokens,
  fetchAppChargingTokensRequestState,
  loggedInAdmin,
  editingAdminRole,
  appChargingTokenFilter,
  allAppChargingTokenDesigns,
  fetchAllAppChargingTokenDesigns,
  clearAppChargingTokens,
  allVouchers,
  fetchAllVouchers,
  AppChargingTokenCount,
  getAppChargingTokensCountRequestState,
  appChargingTokenDesign,
  addAppChargingTokenDesignRequestState,
  fetchAppChargingTokenDesigns,
  fetchAppChargingTokenDesignsState,
  appChargingTokenDesignCount,
  clearDesigns,
  getAppChargingTokenDesignsCount,
  editAppChargingTokenDesignRequestState,
  getAppChargingTokensCount,
}) => {
  const { t } = useTranslation()
  const appChargingTokenFilterRef = useRef(null)
  const [tablesHeight, setTablesHeight] = useState(0)
  const appChargingTokentablesRef = useRef(null)
  const createRoleModalRef = useRef()
  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const tabParam = parseInt(searchParams.get('tab'), 10)
    return !isNaN(tabParam) && (tabParam === 0 || tabParam === 1) ? tabParam : 0
  })
  const canAddToken = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_APP_CHARGING_TOKENS,
  )
  const [firstLoad, setFirstLoad] = useState(true)
  const [progress, setProgress] = useState(0)
  const addDesignModalRef = useRef()

  useEffect(() => {
    if (
      addAppChargingTokenDesignRequestState === RequestState.SUCCEEDED ||
      editAppChargingTokenDesignRequestState === RequestState.SUCCEEDED
    ) {
      if (addDesignModalRef.current) {
        addDesignModalRef.current.dismiss()
      }
    }
  }, [
    addAppChargingTokenDesignRequestState,
    editAppChargingTokenDesignRequestState,
  ])

  useEffect(() => {
    if (selectedTabIndex === 0) {
      fetchAppChargingTokens(0, false)
      fetchAllVouchers()
      fetchAllVouchers()
      getAppChargingTokensCount()
      if (canAddToken) fetchAllAppChargingTokenDesigns()
      clearDesigns()
      getAppChargingTokenDesignsCount()
    } else if (selectedTabIndex === 1) {
      fetchAppChargingTokenDesigns(0)
      getAppChargingTokensCount()
      getAppChargingTokenDesignsCount()
    }
  }, [selectedTabIndex])

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (appChargingTokentablesRef.current) {
      resizeObserver.observe(appChargingTokentablesRef.current)
    }

    return () => {
      if (appChargingTokentablesRef.current) {
        resizeObserver.unobserve(appChargingTokentablesRef.current)
      }
    }
  })
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', selectedTabIndex)

    history.push({
      pathname: '/appChargingTokens',
      search: searchParams.toString(),
    })
  }, [selectedTabIndex])

  useEffect(() => {
    if (
      fetchAppChargingTokensRequestState === RequestState.SUCCEEDED &&
      firstLoad
    ) {
      setFirstLoad(false)
    }
  }, [fetchAppChargingTokensRequestState, firstLoad])

  useEffect(() => {
    if (
      firstLoad &&
      fetchAppChargingTokensRequestState === RequestState.LOADING &&
      progress === 0
    ) {
      setProgress(progress + 10)
    }

    if (
      firstLoad &&
      fetchAppChargingTokensRequestState === RequestState.SUCCEEDED
    ) {
      setProgress(100)
    }
    if (
      firstLoad &&
      fetchAppChargingTokensRequestState !== RequestState.LOADING &&
      fetchAppChargingTokensRequestState !== RequestState.UNINITIALIZED &&
      fetchAppChargingTokensRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchAppChargingTokensRequestState])

  const handleCreateNewTokenClick = () => {
    createRoleModalRef.current.show()
  }
  const handleCreateNewDesignClick = () => {
    addDesignModalRef.current.show()
  }

  const handleFilterChange = (newFilter) => {
    history.push({
      pathname: '/appChargingTokens',
      search: `?filter=${encodeObjectToBase64(newFilter)}`,
    })
    clearAppChargingTokens()
    fetchAppChargingTokens(0)
    getAppChargingTokensCount()
  }
  const handleTabChange = (newValue) => {
    setSelectedTabIndex(newValue)
  }

  return (
    <div className={Styles.Wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div
        className={Styles.AppChargingTokenContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.TabButtonDiv}>
          <AppChargingTokenPageTabs
            editingAdminRole={editingAdminRole}
            onValueChange={handleTabChange}
            initialIndex={selectedTabIndex}
            isLoading={
              (fetchAppChargingTokensRequestState === RequestState.LOADING ||
                fetchAppChargingTokenDesignsState === RequestState.LOADING) &&
              firstLoad
            }
            AppChargingTokenCount={AppChargingTokenCount}
            AppChargingTokenDesignCount={appChargingTokenDesignCount}
            fetchListRequestState={getAppChargingTokensCountRequestState}
          />

          {canAddToken && (
            <div className={Styles.AddButtonDiv}>
              {firstLoad &&
              fetchAppChargingTokensRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <>
                  {selectedTabIndex === 0 ? (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('AppChargingTokenPage.CreateAppChargingToken')}
                      className={Styles.AddTokenButton}
                      onClick={handleCreateNewTokenClick}
                    />
                  ) : (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('AppChargingTokenPage.CreateDesign')}
                      className={Styles.AddTokenButton}
                      onClick={handleCreateNewDesignClick}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {selectedTabIndex === 0 && (
          <div className={Styles.FilterDiv}>
            <IFFilter
              ref={appChargingTokenFilterRef}
              onFilterChange={(newFilter) => handleFilterChange(newFilter)}
              filters={appChargingTokenFilter}
              textFieldPlaceholder={t(
                'AppChargingTokenPage.AppChargingTokenFilterPlaceholder',
              )}
              isLoading={
                fetchAppChargingTokensRequestState === RequestState.LOADING
              }
            />
          </div>
        )}

        <Scrollbars className={Styles.ScrollParent}>
          {selectedTabIndex === 0 ? (
            <div className={Styles.Tables} ref={appChargingTokentablesRef}>
              <AppChargingTokenList
                tablesHeight={tablesHeight}
                appChargingTokens={appChargingTokens}
                loggedInAdmin={loggedInAdmin}
                editingAdminRole={editingAdminRole}
                allAppChargingTokenDesigns={allAppChargingTokenDesigns}
                allVouchers={allVouchers}
              />
            </div>
          ) : (
            <>
              <AppChargingTokenDesignGrid
                appChargingTokenDesigns={appChargingTokenDesign}
                fetchAppChargingTokenDesignsState={
                  fetchAppChargingTokenDesignsState
                }
              />
            </>
          )}
        </Scrollbars>

        <CreateAppChargingToken
          ref={createRoleModalRef}
          allAppChargingTokenDesigns={allAppChargingTokenDesigns}
          allVouchers={allVouchers}
        />
      </div>
      <IFModal
        title={t('AppChargingTokenPage.AddAppChargingTokenDesign')}
        ref={addDesignModalRef}
      >
        <AddAppChargingTokenDesignModal />
      </IFModal>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAppChargingTokens: (offset, shouldShowError) =>
      dispatch(
        AppChargingTokenActions.fetchAppChargingTokens(offset, shouldShowError),
      ),
    fetchAllAppChargingTokenDesigns: () =>
      dispatch(AppChargingTokenDesignActions.fetchAllAppChargingTokenDesigns()),
    clearAppChargingTokens: () =>
      dispatch(AppChargingTokenActions.clearAppChargingTokens()),
    fetchAppChargingTokenDesigns: (offset) =>
      dispatch(
        AppChargingTokenDesignActions.fetchAppChargingTokenDesigns(offset),
      ),
    fetchAllVouchers: () => dispatch(VoucherActions.fetchAllVouchers()),
    getAppChargingTokensCount: () =>
      dispatch(AppChargingTokenActions.getAppChargingTokensCount()),
    getAppChargingTokenDesignsCount: () =>
      dispatch(AppChargingTokenDesignActions.getAppChargingTokenDesignsCount()),
    clearDesigns: () => dispatch(AppChargingTokenDesignActions.clearDesigns()),
  }
}

const mapStateToProps = (state) => ({
  fetchAppChargingTokensRequestState:
    AppChargingTokenSelectors.getFetchAppChargingTokensRequestState(state),
  appChargingTokens: AppChargingTokenSelectors.getAppChargingTokens(state),
  paginationOffset: AppChargingTokenSelectors.getPaginationOffset(state),
  loggedInAdmin: AuthSelectors.getUser(state),
  appChargingTokenFilter:
    AppChargingTokenSelectors.getAppChargingTokenFilter(state),
  allAppChargingTokenDesigns:
    AppChargingTokenDesignSelectors.getAllAppChargingTokenDesigns(state),
  allVouchers: VoucherSelectors.getAllVouchers(state),
  AppChargingTokenCount:
    AppChargingTokenSelectors.getAppChargingTokenCount(state),
  getAppChargingTokensCountRequestState:
    AppChargingTokenSelectors.getAppChargingTokensCountRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  addAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getAddAppChargingTokenDesignRequestState(
      state,
    ),
  editAppChargingTokenDesignRequestState:
    AppChargingTokenDesignSelectors.getEditAppChargingTokenDesignRequestState(
      state,
    ),
  appChargingTokenDesign:
    AppChargingTokenDesignSelectors.getAppChargingTokenDesigns(state),
  fetchAppChargingTokenDesignsState:
    AppChargingTokenDesignSelectors.getFetchAppChargingTokenDesignsRequestState(
      state,
    ),
  appChargingTokenDesignCount:
    AppChargingTokenDesignSelectors.getAppChargingDesignsTokenCount(state),
  getAppChargingTokenCountRequestState:
    AppChargingTokenDesignSelectors.getGetAppChargingTokenCountRequestState(
      state,
    ),
})

AppChargingTokenPage.propTypes = {
  fetchAppChargingTokens: PropTypes.func,
  appChargingTokens: PropTypes.array,
  fetchAppChargingTokensRequestState: PropTypes.string,
  loggedInAdmin: PropTypes.object,
  editingAdminRole: PropTypes.array,
  appChargingTokenFilter: PropTypes.object,
  allAppChargingTokenDesigns: PropTypes.array,
  fetchAllAppChargingTokenDesigns: PropTypes.func,
  clearAppChargingTokens: PropTypes.func,
  allVouchers: PropTypes.array,
  fetchAllVouchers: PropTypes.func,
  AppChargingTokenCount: PropTypes.number,
  getAppChargingTokensCountRequestState: PropTypes.string,
  appChargingTokenDesign: PropTypes.array,
  addAppChargingTokenDesignRequestState: PropTypes.string,
  fetchAppChargingTokenDesigns: PropTypes.func,
  fetchAppChargingTokenDesignsState: PropTypes.string,
  appChargingTokenDesignCount: PropTypes.number,
  getAppChargingTokenDesignsCount: PropTypes.func,
  clearDesigns: PropTypes.func,
  editAppChargingTokenDesignRequestState: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppChargingTokenPage)
