import { put, call } from 'redux-saga/effects'
import { dashboardApiService, handleError } from 'Services/ApiService.js'
import dashboardActions from 'Stores/Dashboard/Actions'
var base64 = require('base-64')

export function* fetchDashboard({ filter }) {
  yield put(dashboardActions.fetchDashboardMetricsLoading())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(dashboardApiService.get, '/metrics', {
      params: {
        filter: filter,
      },
    })
    yield put(dashboardActions.fetchDashboardMetricsSuccess(data.metrics))
  } catch (e) {
    yield put(dashboardActions.fetchDashboardMetricsError(e))
    handleError(e)
  }
}
