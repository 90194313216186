import React, { useState, Fragment, useRef } from 'react'
import Styles from './ResetPassword.module.css'
import styled from 'styled-components'
import logo from 'Assets/Images/logo.png'
import { TextField, FormGroup } from '@mui/material'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { Colors } from 'Theme'
import { object, string } from 'yup'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { IFModal, IFText, IFButton, IFToastMessage } from 'Components'
import AuthActions from 'Stores/Auth/Actions'
import ReactPinField from 'react-pin-field'
import { styled as muiStyled } from '@mui/system'
import AuthSelectors from 'Stores/Auth/Selectors'
import RequestState from 'Enums/RequestState'
import { useNavigate } from 'react-router-dom'
import { toaster } from 'rsuite'
import PropTypes from 'prop-types'

const initialValues = {
  email: '',
  password: '',
  confirmPassword: '',
}

const StyledPinField = muiStyled(ReactPinField)(({ theme }) => ({
  backgroundColor: `${Colors.pinFieldBackground}`,
  border: `1px solid ${Colors.pinFieldBorder}`,
  borderRadius: '0.3rem',
  fontSize: '2rem',
  margin: '0.25rem',
  height: '3.5rem',
  outline: 'none',
  textAlign: 'center',
  transitionDuration: '250ms',
  transitionProperty: 'background, color, border, box-shadow, transform',
  width: '3rem',
  '&:focus': {
    borderColor: `${Colors.primary}`,
    outline: 'none',
    transform: 'scale(1.05)',
  },
  '&:invalid': {
    animation: 'shake 3 linear 75ms',
    borderColor: `${Colors.pinFieldInvalidBorder}`,
    boxShadow: `0 0 0.25rem ${Colors.pinFieldInvalidBoxShadow}`,
  },
}))

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`
const CssTextField = styled(TextField)`
  && {
    & label.Mui-focused {
      color: ${Colors.text};
    }
    & .MuiOutlinedInput-root {
      ${({ InputProps }) =>
        InputProps?.readOnly &&
        `
        background-color: ${Colors.grey}; 
        color: ${Colors.gray}; 
      `}
      &:hover fieldset {
        border-color: ${Colors.primary};
      }
      &.Mui-focused fieldset {
        border-color: ${Colors.primary};
      }
      & .MuiInputBase-input {
        ${({ InputProps }) =>
          InputProps?.readOnly &&
          `
          color: ${Colors.gray}; 
        `}
      }
    }
  }
`
const ResetPassword = ({
  resetPasswordVerification,
  emailVerificationId,
  resetPasswordVerificationRequestState,
  resetPasswordRequestState,
  resetPassword,
}) => {
  const { t } = useTranslation()
  const confirmResetDialog = useRef()
  const [confirmPin, setConfirmPin] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const handleRegister = (values) => {
    setEmail(values.email)
    setPassword(values.password)
    resetPasswordVerification(values.email)
    confirmResetDialog.current.show()
  }

  const customDialogContentStyle = {
    width: '480px',
    height: '220px',
  }

  return (
    <div
      className={Styles.BackgroundDiv}
      style={{ backgroundColor: Colors.LoginBackground }}
    >
      <div
        className={Styles.Container}
        style={{
          backgroundColor: Colors.white,
          boxShadow: `0 0 20px 3px ${Colors.LoginBackgroundShadow}`,
        }}
      >
        <div
          className={Styles.WelcomeCard}
          style={{
            background: `linear-gradient(to bottom left, ${Colors.LoginGradient1}, ${Colors.LoginGradient2})`,
          }}
        >
          <IFText
            className={Styles.WelcomeTitle}
            style={{ color: Colors.white }}
          >
            {t('ResetPassword.Title')}
          </IFText>
        </div>

        <div className={Styles.FormDiv}>
          <div className={Styles.LogoWrappingDiv}>
            <img className={Styles.Logo} src={logo} alt="logo" />
          </div>

          <FormContainer className={Styles.FormWrappingDiv}>
            <Formik
              validationSchema={object({
                email: string().required(t('ResetPassword.Required')),
                password: Yup.string()
                  .required(t('ResetPassword.Required'))
                  .min(8, t('ResetPassword.ShortPassword')),
                confirmPassword: Yup.string()
                  .required(t('ResetPassword.Required'))
                  .min(8, t('ResetPassword.ShortPassword'))
                  .oneOf(
                    [Yup.ref('password'), null],
                    t('ResetPassword.PasswordsMustMatch'),
                  ),
              })}
              initialValues={{
                ...initialValues,
              }}
              enableReinitialize
              onSubmit={handleRegister}
            >
              {({ values, errors }) => (
                <Form className={Styles.InputDiv}>
                  <FormGroup className={Styles.InputWrappingDiv}>
                    <Field
                      className={Styles.Input}
                      size="small"
                      name="email"
                      as={CssTextField}
                      variant="outlined"
                      label={t('ResetPassword.Email')}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: Colors.primary },
                      }}
                    />
                    <ErrorContainer>
                      <ErrorMessage name="email" />
                    </ErrorContainer>
                  </FormGroup>

                  <FormGroup className={Styles.InputWrappingDiv}>
                    <Field
                      as={CssTextField}
                      className={Styles.Input}
                      name="password"
                      type="password"
                      size="small"
                      variant="outlined"
                      label={t('ResetPassword.Password')}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: Colors.primary },
                      }}
                    />
                    <ErrorContainer>
                      <ErrorMessage name="password" />
                    </ErrorContainer>
                  </FormGroup>

                  <FormGroup className={Styles.InputWrappingDiv}>
                    <Field
                      as={CssTextField}
                      className={Styles.Input}
                      name="confirmPassword"
                      type="password"
                      size="small"
                      variant="outlined"
                      label={t('ResetPassword.ConfirmPassword')}
                      InputLabelProps={{
                        shrink: true,
                        style: { color: Colors.primary },
                      }}
                    />
                    <ErrorContainer>
                      <ErrorMessage name="confirmPassword" />
                    </ErrorContainer>
                  </FormGroup>

                  <IFButton
                    className={Styles.ButtonWrappingDiv}
                    color={Colors.primary}
                    isDead={
                      !(
                        values.email &&
                        values.password &&
                        values.confirmPassword
                      ) ||
                      errors.email ||
                      errors.password ||
                      errors.confirmPassword
                    }
                    isLoading={
                      resetPasswordVerificationRequestState ===
                      RequestState.LOADING
                    }
                    isFill={true}
                    text={t('ResetPassword.Reset')}
                  />
                </Form>
              )}
            </Formik>
          </FormContainer>
        </div>
      </div>
      <IFModal
        ref={confirmResetDialog}
        title={t('ResetPassword.ModalTitle')}
        width={'30rem'}
        centerTitle={true}
        dialogContentStyle={customDialogContentStyle}
      >
        <Fragment>
          <IFText
            className={Styles.SubTitle}
            style={{ color: Colors.resetPasswordModalSubTitle }}
          >
            {t('ResetPassword.ModalSubTitle')}
            <br />
            {email}
          </IFText>
          <div className={Styles.PinFieldContainer}>
            <StyledPinField
              length={6}
              validate={t('ResetPassword.PinValidationString')}
              onChange={(value) => {
                setConfirmPin(value)
              }}
            />
          </div>
          <IFButton
            text={t('ResetPassword.ModalButtonText')}
            size="sm"
            color={Colors.primary}
            isFill={true}
            className={Styles.ConfirmButton}
            isLoading={resetPasswordRequestState === RequestState.LOADING}
            onClick={() => {
              resetPassword(password, confirmPin, emailVerificationId, () => {
                confirmResetDialog.current.dismiss()
                toaster.push(
                  <IFToastMessage
                    type="success"
                    text={t('ResetPassword.ResetPasswordSuccess')}
                  />,

                  navigate('/login'),
                )
              })
            }}
          />
        </Fragment>
      </IFModal>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  resetPasswordVerification: (email) =>
    dispatch(AuthActions.resetPasswordVerification(email)),
  resetPassword: (
    newPassword,
    emailVerificationCode,
    emailVerificationId,
    onSuccess,
  ) =>
    dispatch(
      AuthActions.resetPassword(
        newPassword,
        emailVerificationCode,
        emailVerificationId,
        onSuccess,
      ),
    ),
})

const mapStateToProps = (state) => ({
  emailVerificationId: AuthSelectors.getResetPasswordEmailID(state),
  resetPasswordRequestState: AuthSelectors.getResetPasswordRequestState(state),
  resetPasswordVerificationRequestState:
    AuthSelectors.getResetPasswordVerificationRequestState(state),
  resetPasswordRequestState: AuthSelectors.getResetPasswordRequestState(state),
})
ResetPassword.propTypes = {
  resetPasswordVerification: PropTypes.func,
  emailVerificationId: PropTypes.string,
  resetPasswordVerificationRequestState: PropTypes.string,
  resetPasswordRequestState: PropTypes.string,
  resetPassword: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
