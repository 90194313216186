const getAppChargingTokenDesigns = (state) => {
  return state.main.appChargingTokenDesignState.appChargingTokenDesigns
}
const getFetchAppChargingTokenDesignsRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .fetchAppChargingTokenDesignsRequestState
}
const getFetchAllAppChargingTokenDesignsRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .fetchAllAppChargingTokenDesignsRequestState
}
const getPaginationOffset = (state) => {
  return state.main.appChargingTokenDesignState.paginationOffset
}
const getAllAppChargingTokenDesigns = (state) => {
  return state.main.appChargingTokenDesignState.allAppChargingTokenDesigns
}

const getAddAppChargingTokenDesignRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .addAppChargingTokenDesignRequestState
}
const getEditAppChargingTokenDesignRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .editAppChargingTokenDesignRequestState
}
const getAppChargingDesignsTokenCount = (state) => {
  return state.main.appChargingTokenDesignState.count
}
const getGetAppChargingTokenCountRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .getAppChargingTokenCountRequestState
}
const getDeleteAppChargingTokenDesignRequestState = (state) => {
  return state.main.appChargingTokenDesignState
    .deleteAppChargingTokenDesignRequestState
}

const AppChargingTokenDesignSelectors = {
  getAppChargingTokenDesigns,
  getFetchAppChargingTokenDesignsRequestState,
  getPaginationOffset,
  getAllAppChargingTokenDesigns,
  getAddAppChargingTokenDesignRequestState,
  getEditAppChargingTokenDesignRequestState,
  getFetchAllAppChargingTokenDesignsRequestState,
  getAppChargingDesignsTokenCount,
  getGetAppChargingTokenCountRequestState,
  getDeleteAppChargingTokenDesignRequestState,
}

export default AppChargingTokenDesignSelectors
