import { BarChart } from '@mui/x-charts/BarChart'
import Styles from './Barchart.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import dayjs from 'dayjs'
import { axisClasses } from '@mui/x-charts/ChartsAxis'
import { IFText, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from 'Theme'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const BlurOverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 600;
  background-color: ${Colors.white};
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
`

const Barchart = ({
  values = [],
  labels = [],
  startDates = [],
  title,
  height,
  metric,
  chartColors,
  width,
  isLoading,
}) => {
  const { t } = useTranslation()
  const isNoData = values.every((array) => array.every((value) => value === 0))
  const chartSetting = {
    yAxis: [
      {
        label: metric,
        labelStyle: {
          fontSize: '12px',
        },
      },
    ],
  }

  const emptySeries = {
    series: [],
    margin: { top: 10, left: 25, bottom: 25 },
  }

  const fixMargin = true
  const fixLabel = true

  const chartData = values.map((value, index) => ({
    id: `series-${index}`,
    data: value,
    label: labels[index],
    color: chartColors[index],
  }))

  return (
    <div>
      {isLoading ? (
        <Card>
          <IFSkeleton variant="text" width="150px" height="16px" />

          <div className={Styles.ChartContainer}>
            <IFSkeleton
              variant="rectangular"
              height="262px"
              style={{ marginTop: 32 }}
            />
          </div>
        </Card>
      ) : (
        <Card>
          <IFText className={Styles.Title}>{title}</IFText>

          <div className={Styles.ChartContainer}>
            {isNoData ? (
              <div>
                <BlurOverlayText>
                  <IFText>{t('TransactionsPage.ChartNoData')}</IFText>
                </BlurOverlayText>
                <BarChart
                  className={Styles.BlurBackground}
                  xAxis={[
                    {
                      scaleType: 'band',
                      data: startDates || [],
                    },
                  ]}
                  {...emptySeries}
                  height={height}
                  width={width}
                  sx={{
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: 'translateX(-40px)',
                    },
                  }}
                />
              </div>
            ) : (
              <BarChart
                xAxis={[
                  {
                    scaleType: 'band',
                    data: startDates || [],
                  },
                ]}
                series={chartData}
                {...chartSetting}
                height={height}
                width={width}
                margin={
                  fixMargin ? { top: 35, bottom: 20, left: 100 } : undefined
                }
                sx={
                  fixLabel
                    ? {
                        [`.${axisClasses.left} .${axisClasses.label}`]: {
                          transform: 'translateX(-40px)',
                        },
                      }
                    : {}
                }
                slotProps={{
                  legend: {
                    direction: 'row',
                    position: { vertical: 'top', horizontal: 'center' },
                    padding: 0,
                    itemMarkHeight: 14,
                    itemMarkWidth: 14,
                    itemGap: 8,
                    labelStyle: {
                      fontSize: 14,
                    },
                  },
                }}
              />
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

Barchart.propTypes = {
  values: PropTypes.array,
  labels: PropTypes.array,
  startDates: PropTypes.array,
  title: PropTypes.string,
  height: PropTypes.number,
  metric: PropTypes.string,
  chartColors: PropTypes.array,
  width: PropTypes.number,
  isLoading: PropTypes.bool,
}

export default Barchart
