import { put, call, select, spawn } from 'redux-saga/effects'
import {
  usersApiService,
  transactionsApiService,
  handleError,
  handleSuccess,
  pointsApiService,
  adminActionsApiService,
} from 'Services/ApiService.js'
import UserActions from 'Stores/User/Actions'
import history from 'history/browser'
import UserSelectors from 'Stores/User/Selectors'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'
import i18n from 'i18next'
import AuthSelectors from 'Stores/Auth/Selectors'
import IFFilterType from 'Enums/IFFilterType'
import InfinityEnums from 'Enums/InfinityEnums'
import {
  encodeObjectToBase64,
  decodeBase64ToObject,
} from '../Utils/Base64Functions'

export function* fetchUserList({ offset, onError, shouldShowError }) {
  const query = new URLSearchParams(history.location.search)
  const slashArray = history.location.pathname.split('/')
  const userIdParam = slashArray[2]
  let filterParam = query.get('filter')
  let filterObject = []
  let defaultUsersFilters = yield select(UserSelectors.getDefaultUsersFilters)
  try {
    const admin = yield select(AuthSelectors.getUser)
    if (admin.registeredCountries.length > 1) {
      const countryNames = admin.registeredCountries.map(
        (country) => country.name,
      )
      let index = defaultUsersFilters.findIndex(
        (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
      )
      if (index === -1) {
        defaultUsersFilters.push({
          type: IFFilterType.CHECK,
          title: InfinityEnums.IFFilterTitle.Country,
          data: countryNames,
          initialValue: [],
          value: [],
        })
      }
    }
    if (filterParam === null) {
      filterObject = defaultUsersFilters
      filterParam = encodeObjectToBase64(filterObject)
    } else {
      try {
        filterObject = decodeBase64ToObject(filterParam)
        if (admin.registeredCountries.length > 1) {
          const countryNames = admin.registeredCountries.map(
            (country) => country.name,
          )
          let index = filterObject.findIndex(
            (filter) => filter.title === InfinityEnums.IFFilterTitle.Country,
          )
          if (index === -1) {
            filterObject.push({
              type: IFFilterType.CHECK,
              title: InfinityEnums.IFFilterTitle.Country,
              data: countryNames,
              initialValue: [],
              value: [],
            })
          }
        }
        for (let i = 0; i < filterObject.length; i++) {
          const { type: type1, title: title1, data: data1 } = filterObject[i]
          const {
            type: type2,
            title: title2,
            data: data2,
          } = defaultUsersFilters[i]

          if (
            type1 !== type2 ||
            title1 !== title2 ||
            JSON.stringify(data1) !== JSON.stringify(data2)
          ) {
            filterObject = defaultUsersFilters
            filterParam = encodeObjectToBase64(filterObject)
          }
        }
      } catch (error) {
        filterObject = defaultUsersFilters
        filterParam = encodeObjectToBase64(filterObject)
      }
    }
    if (offset === 0) {
      yield spawn(fetchUserListCount, filterParam)
      yield put(UserActions.fetchUserListLoading())
    }
    const { data } = yield call(usersApiService.get, '/', {
      params: {
        filter: filterParam,
        offset: offset,
      },
    })
    if (userIdParam && userIdParam.match(/^[0-9a-fA-F]{24}$/) && offset === 0) {
      const index = data.users.findIndex((user) => user.id === userIdParam)
      if (index !== -1) {
        yield put(
          UserActions.fetchUserListSuccess(
            offset,
            data.users,
            data.nextOffset,
            null,
            data.usersCount,
            index,
            filterObject,
          ),
        )
      } else {
        const Userdata = yield call(usersApiService.get, `/${userIdParam}`)
        if (!Userdata.data) {
          return
        }
        yield put(
          UserActions.fetchUserListSuccess(
            offset,
            data.users,
            data.nextOffset,
            Userdata.data,
            data.usersCount,
            data.users.length - 1,
            filterObject,
          ),
        )
      }
    } else {
      yield put(
        UserActions.fetchUserListSuccess(
          offset,
          data.users,
          data.nextOffset,
          null,
          data.usersCount,
          0,
          filterObject,
        ),
      )
    }
  } catch (e) {
    onError(e.details)
    yield put(UserActions.fetchUserListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchUserDetails({ userId, disableSkeleton }) {
  if (!disableSkeleton) yield put(UserActions.fetchUserDetailsLoading())
  try {
    const { data } = yield call(usersApiService.get, `/${userId}`)
    yield put(UserActions.fetchUserDetailsSuccess(data))
    return data
  } catch (e) {
    yield put(UserActions.fetchUserDetailsFail(e))
    handleError(e)
  }
}

export function* setCurrentUserChargingTokenActive({
  userId,
  chargingTokenId,
  index,
}) {
  yield put(UserActions.setCurrentUserChargingTokenDeactiveLoading(index))
  try {
    yield call(
      usersApiService.patch,
      `${userId}/chargingTokens/${chargingTokenId}/deactivate`,
    )
    yield put(UserActions.setCurrentUserChargingTokenDeactiveSuccess(index))
  } catch (e) {
    yield put(UserActions.setCurrentUserChargingTokenDeactiveFail(index))
    handleError(e)
  }
}

export function* setCurrentUserTransactionRefund({ id, index }) {
  yield put(UserActions.setCurrentUserTransactionRefundLoading(index))
  try {
    yield call(transactionsApiService.post, `/paymobRefund`, {
      id: id,
    })
    yield put(UserActions.setCurrentUserTransactionRefundSuccess(index))
  } catch (e) {
    yield put(UserActions.setCurrentUserTransactionRefundFail(index))
    handleError(e)
  }
}

export function* addCurrentUserNewCard({ id, serialNumbers, onError }) {
  yield put(UserActions.addCurrentUserNewCardLoading())
  try {
    const { data } = yield call(
      usersApiService.post,
      `/${id}/chargingTokens/activate`,
      {
        chargingTokenVisualNumber: serialNumbers,
      },
    )
    yield spawn(fetchUserDetails, { userId: id, disableSkeleton: true })
    yield put(UserActions.addCurrentUserNewCardSuccess(data))
    handleSuccess(i18n.t('UserPage.AddCardSuccessMessage'))
  } catch (e) {
    onError(e.details)
    yield put(UserActions.addCurrentUserNewCardFail())
    handleError(e)
  }
}

export function* toggleCurrentUserIsActive({ id, setIsActive }) {
  yield put(UserActions.toggleCurrentUserIsActiveLoading())
  try {
    yield call(
      usersApiService.patch,
      `/${id}/${setIsActive ? 'activate' : 'deactivate'}`,
    )
    yield put(UserActions.toggleCurrentUserIsActiveSuccess(id, setIsActive))
  } catch (e) {
    yield put(UserActions.toggleCurrentUserIsActiveFail())
    handleError(e)
  }
}

export function* setCurrentUserNewBalance({ id, amount, country }) {
  yield put(UserActions.setCurrentUserNewBalanceLoading())
  try {
    const { data } = yield call(transactionsApiService.post, '/', {
      userId: id,
      amount: amount,
      country: country,
    })
    yield put(UserActions.setCurrentUserNewBalanceSuccess(data))
    handleSuccess(i18n.t('UserPage.WalletSuccessMessage'))
  } catch (e) {
    yield put(UserActions.setCurrentUserNewBalanceFail())
    handleError(e)
  }
}

export function* fetchUserTransactionList({
  userId,
  filter,
  offset,
  shouldShowError,
}) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (userId) {
      if (offset === 0) yield put(UserActions.fetchUserTransactionListLoading())
      const { data } = yield call(
        usersApiService.get,
        `/${userId}/transactions`,
        {
          params: {
            filter: filter,
            offset: offset,
          },
        },
      )
      yield put(
        UserActions.fetchUserTransactionListSuccess(
          offset,
          data.transactions,
          data.nextOffset,
        ),
      )
    }
  } catch (e) {
    yield put(UserActions.fetchUserTransactionListFail(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* setCurrentUserNewPhone({ id, newPhone }) {
  yield put(UserActions.setCurrentUserNewPhoneLoading())
  try {
    const { data } = yield call(usersApiService.patch, `/${id}/changePhone`, {
      phone: newPhone,
    })
    yield put(UserActions.setCurrentUserNewPhoneSuccess(id, newPhone))
    handleSuccess(i18n.t('UserPage.ChangePhoneSuccessMessage'))
  } catch (e) {
    yield put(UserActions.setCurrentUserNewPhoneFail(id))
    handleError(e)
  }
}

export function* reverseUserChargeTransaction({
  values,
  transactionData,
  onResponse,
}) {
  yield put(
    UserActions.reverseUserChargeTransactionLoading(transactionData.index),
  )
  try {
    const modifiedValues = {
      ...values,
      tariffs: {
        add: values.tariffs.add.map((tariff) => ({
          ...tariff,
          energyConsumed: tariff.energyConsumed * 1000,
        })),
        delete: [...values.tariffs.delete],
        edit: values.tariffs.edit.map((tariff) => ({
          ...tariff,
          energyConsumed: tariff.energyConsumed * 1000,
        })),
      },
    }
    const { data } = yield call(
      transactionsApiService.post,
      `/${transactionData.id}/reverse`,
      modifiedValues,
    )
    yield put(
      UserActions.reverseUserChargeTransactionSuccess(transactionData.index),
    )
    onResponse()
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('ReverseTransactionModal.SuccessMessage')}
      />,
    )
  } catch (e) {
    yield put(
      UserActions.reverseUserChargeTransactionError(transactionData.index, e),
    )
    handleError(e)
    onResponse()
  }
}

export function* fetchUserPoints({ id, offset, country }) {
  yield put(UserActions.fetchUserPointsLoading())
  try {
    const { data } = yield call(pointsApiService.get, `/user/${id}`, {
      params: {
        offset: offset,
        country: country,
      },
    })
    yield put(
      UserActions.fetchUserPointsSuccess(offset, data.points, data.nextOffset),
    )
  } catch (e) {
    yield put(UserActions.fetchUserPointsError())
    handleError(e)
  }
}

export function* fetchUserPaymentSessionList({
  userId,
  filter,
  offset,
  shouldShowError,
}) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (userId) {
      if (offset === 0)
        yield put(UserActions.fetchUserPaymentSessionListLoading())
      const { data } = yield call(usersApiService.get, `/${userId}/sessions`, {
        params: {
          filter: filter,
          offset: offset,
        },
      })
      yield put(
        UserActions.fetchUserPaymentSessionListSuccess(
          offset,
          data.paymentSessions,
          data.nextOffset,
        ),
      )
    }
  } catch (e) {
    yield put(UserActions.fetchUserPaymentSessionListError(e))
    if (!shouldShowError) handleError(e)
  }
}

export function* fetchUserListCount(filter) {
  yield put(UserActions.fetchUserListCountLoading())
  try {
    const { data } = yield call(usersApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(UserActions.fetchUserListCountSuccess(data.usersCount))
  } catch (e) {
    yield put(UserActions.fetchUserListCountFail(e))
    handleError(e)
  }
}

export function* deleteCurrentUserPaymentCard({
  userId,
  paymentCardId,
  index,
}) {
  yield put(UserActions.deleteCurrentUserPaymentCardLoading(index))
  try {
    yield call(
      usersApiService.delete,
      `${userId}/paymentCards/${paymentCardId}`,
    )
    yield put(UserActions.deleteCurrentUserPaymentCardSuccess(index))
  } catch (e) {
    yield put(UserActions.deleteCurrentUserPaymentCardFail(index))
    handleError(e)
  }
}

export function* fetchAdminActionsUsersList({
  userId,
  filter,
  offset,
  shouldShowError,
}) {
  if (userId) {
    if (offset === 0) {
      yield put(UserActions.fetchAdminActionsLoading())
    }
    try {
      if (filter != null) {
        filter = encodeObjectToBase64(filter)
      }

      const { data } = yield call(
        adminActionsApiService.get,
        `/user/${userId}`,
        {
          params: {
            filter: filter,
            offset: offset,
          },
        },
      )
      yield put(
        UserActions.fetchAdminActionsSuccess(
          offset,
          data.results,
          data.nextOffset,
        ),
      )
    } catch (e) {
      yield put(UserActions.fetchAdminActionsError(e))
      if (!shouldShowError) handleError(e)
    }
  }
}
