import { React, useState, useEffect } from 'react'
import Styles from './DevicePreview.module.css'
import { IFText } from 'Components'
import images from 'Theme/Images'
import { Colors } from 'Theme'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'

const BubbleContainer = styled.div`
  background-color: ${Colors.white};
`

const DevicePreview = ({ title, subtitle, locale }) => {
  const chosenTitle =
    locale === InfinityEnums.Locale.EN ? 'Notification Title' : 'عنوان الاشعار'
  const chosenSubtitle =
    locale === InfinityEnums.Locale.EN ? 'Notification Text' : 'نص الاشعار'
  const [innerTitle, setInnerTitle] = useState(chosenTitle)
  const [innerSubtitle, setInnerSubtitle] = useState(chosenSubtitle)

  useEffect(() => {
    if (title.length === 0) {
      setInnerTitle(chosenTitle)
    } else setInnerTitle(title)
  }, [title])

  useEffect(() => {
    if (subtitle.length === 0) setInnerSubtitle(chosenSubtitle)
    else setInnerSubtitle(subtitle)
  }, [subtitle])

  return (
    <div className={Styles.Wrapper}>
      <div style={{ position: 'relative' }}>
        <img className={Styles.IphoneImg} src={images.iphone} alt="" />
        <BubbleContainer
          className={
            locale === InfinityEnums.Locale.EN
              ? Styles.BubbleContainer
              : Styles.BubbleContainerArabic
          }
        >
          <div className={Styles.LogoContainer}>
            <img className={Styles.Logo} src={images.verticalLogo} alt="" />
          </div>
          <div className={Styles.TextContainer}>
            <div className={Styles.TitleContainer}>
              <IFText
                className={
                  locale === InfinityEnums.Locale.EN
                    ? Styles.TitleText
                    : [Styles.TitleText, Styles.ArabicText].join(' ')
                }
                style={{ color: Colors.text }}
              >
                {innerTitle}
              </IFText>
            </div>
            <div className={Styles.SubtitleContainer}>
              <IFText
                className={
                  locale === InfinityEnums.Locale.EN
                    ? Styles.SubtitleText
                    : [Styles.SubtitleText, Styles.ArabicText].join(' ')
                }
                style={{ color: Colors.text }}
              >
                {innerSubtitle}
              </IFText>
            </div>
          </div>
        </BubbleContainer>
      </div>
    </div>
  )
}

DevicePreview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  locale: PropTypes.string,
}

export default DevicePreview
