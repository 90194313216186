import React, { useEffect, useRef, useState } from 'react'
import Divider from '@mui/material/Divider'
import {
  UserListItem,
  IFAvatar,
  IFText,
  UserListItemSkeleton,
  IFButton,
} from 'Components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import styles from './UserList.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RequestState from 'Enums/RequestState'
import { Waypoint } from 'react-waypoint'
import history from 'history/browser'
import { Virtuoso } from 'react-virtuoso'
import ReportIcon from '@mui/icons-material/Report'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import 'overlayscrollbars/overlayscrollbars.css'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'

const Container = styled.div`
  color: ${Colors.text};
  background-color: ${Colors.UserListBackground};
`
const UserList = ({
  users,
  selectedUserIndex,
  fetchUserListRequestState,
  fetchUserListCountRequestState,
  usersCount,
  paginationOffset,
  fetchUserList,
  usersFilter,
  setSelectedUserIndex,
  setUsersFilter,
  reference,
}) => {
  const loadMoreData = () => {
    if (
      fetchUserListRequestState === RequestState.LOADING ||
      paginationOffset == null
    )
      return
    setUsersFilter(usersFilter)
    fetchUserList(paginationOffset, () => {}, failedRequest)
  }
  const { t } = useTranslation()
  const handleClick = (index) => {
    setSelectedUserIndex(index)
    if (index > -1 && users[index] && index !== selectedUserIndex) {
      const selectedUser = users[index]
      if (selectedUser) {
        history.push({
          pathname: `/user/${selectedUser.id}`,
          search: `?filter=${encodeObjectToBase64(usersFilter)}`,
        })
      }
    }
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchUserListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchUserListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchUserListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchUserListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchUserListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchUserListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchUserListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchUserListRequestState])
  const handleRetry = () => {
    fetchUserList(paginationOffset, () => {})
  }
  const Footer = () => {
    return paginationOffset && users && users.length !== 0 ? (
      <div className={styles.WaypointContainer}>
        <Waypoint onEnter={loadMoreData} />
        {failedRequest ? (
          <div className={styles.IconDiv}>
            <div className={styles.ReportIconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
            <div className={styles.TextDiv}>
              <IFButton
                size="sm"
                color={Colors.red}
                text={t('IFTable.TryAgain')}
                onClick={() => {
                  handleRetry()
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.LoadingData}>
            <UserListItemSkeleton />
            <Divider />
            <UserListItemSkeleton />
          </div>
        )}
      </div>
    ) : null
  }
  const options = {
    scrollbars: {
      visibility: 'auto',
      autoHide: 'move',
      autoHideDelay: 1000,
    },
  }
  const rootRef = useRef(null)
  const [scroller, setScroller] = useState(null)
  const [initialize, osInstance] = useOverlayScrollbars({
    options,
    defer: true,
  })

  useEffect(() => {
    const { current: root } = rootRef
    if (scroller && root) {
      initialize({
        target: root,
        elements: {
          viewport: scroller,
        },
      })
    }
    return () => osInstance()?.destroy()
  }, [scroller, initialize, osInstance])

  return (
    <Container className={styles.Container}>
      {fetchUserListRequestState === RequestState.LOADING ? (
        <>
          {[...Array(12)].map(() => (
            <>
              <UserListItemSkeleton />
              <Divider />
            </>
          ))}
        </>
      ) : fetchUserListRequestState === RequestState.SUCCEEDED &&
        fetchUserListCountRequestState === RequestState.SUCCEEDED &&
        usersCount === 0 ? (
        <div className={styles.NoRecord}>
          <IFText>{t('UserPage.TransactionNoRecord')}</IFText>
        </div>
      ) : (
        <div
          ref={rootRef}
          data-overlayscrollbars=""
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Virtuoso
            ref={reference}
            style={{
              overflow: 'hidden',
            }}
            data={users}
            endReached={
              paginationOffset && users.length !== 0 ? loadMoreData : () => {}
            }
            increaseViewportBy={480}
            itemContent={(index, user) => (
              <div key={`UserListItem ${index}`}>
                <UserListItem
                  onClick={() => handleClick(index)}
                  user={user}
                  avatar={
                    <IFAvatar
                      imageURL={user.avatar}
                      size={42}
                      name={user.name}
                    />
                  }
                  isSelected={index === selectedUserIndex}
                />
                <Divider />
              </div>
            )}
            scrollerRef={setScroller}
            components={{ Footer: Footer }}
          />
        </div>
      )}
    </Container>
  )
}

UserList.propTypes = {
  userList: PropTypes.array,
  selectedIndex: PropTypes.number,
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserList: (paginationOffset, onError, shouldShowError) =>
      dispatch(
        UserActions.fetchUserList(paginationOffset, onError, shouldShowError),
      ),
    setUsersFilter: (newFilter) =>
      dispatch(UserActions.setUsersFilter(newFilter)),
    setSelectedUserIndex: (index) =>
      dispatch(UserActions.setSelectedUserIndex(index)),
  }
}

const mapStateToProps = (state) => ({
  users: UserSelectors.getUserList(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  paginationOffset: UserSelectors.getUserListOffset(state),
  usersFilter: UserSelectors.getUsersFilters(state),
  fetchUserListCountRequestState:
    UserSelectors.getFetchUserListCountRequestState(state),
  usersCount: UserSelectors.getUsersCount(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
