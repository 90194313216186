import React from 'react'
import { Box, Stack } from '@mui/material'
import { IFSkeleton } from 'Components'
import Styles from './AdminActionSkeleton.module.css'

const AdminActionSkeleton = () => {
  return (
    <>
      <Box className={Styles.Container}>
        <Box className={Styles.ContainerDiv}>
          <Box className={Styles.CircularSkeleton}>
            <IFSkeleton variant="circular" width={28} height={28} />
          </Box>

          <Stack className={Styles.BodySkeleton}>
            <IFSkeleton variant="text" width="20%" height={15} />
            <IFSkeleton variant="text" width="15%" height={15} />
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default AdminActionSkeleton
