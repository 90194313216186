import Styles from './CreateAppChargingToken.module.css'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import InfinityEnums from 'Enums/InfinityEnums'
import { IFModal, IFButton, IFText, IFToastMessage } from 'Components'
import React, { useState, useEffect, useRef } from 'react'
import RequestState from 'Enums/RequestState'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import Dropzone from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { xlsxToJson, csvToJson } from '../../Utils/DataConversion'
import { toaster } from 'rsuite'
import Icon from '@mui/material/Icon'
import CancelIcon from '@mui/icons-material/Cancel'
import styled from 'styled-components'
import {
  TextField,
  FormGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material'
import AppChargingTokenDesignActions from 'Stores/AppChargingTokenDesign/Actions'
import AppChargingTokenActions from 'Stores/AppChargingToken/Actions'
import AppChargingTokenSelectors from 'Stores/AppChargingToken/Selectors'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
  width: '230px',
  '& .MuiInputBase-input': {
    height: '30px',
    padding: '10px',
  },
})
const StyledSelect = styled(Select)`
  && {
    font-family: 'ProximaNova';
    color: ${Colors.text};
    height: 50px;
    width: 230px;
  }
`
const CreateAppChargingToken = React.forwardRef(
  (
    {
      createAppChargingToken,
      createAppChargingTokenBulk,
      allAppChargingTokenDesigns,
      createAppChargingTokenRequestState,
      createAppChargingTokenBulkRequestState,
      downloadAppChargingTokenSampleFile,
      downloadAppChargingTokenSampleFileRequestState,
      allVouchers,
    },
    ref,
  ) => {
    const [visualNumber, setVisualNumber] = useState('')
    const [reference, setReference] = useState('')
    const [uid, setUid] = useState('')
    const [visualNumberError, setVisualNumberError] = useState('')
    const [uidError, setUidError] = useState('')
    const [design, setDesign] = useState('')
    const [designError, setDesignError] = useState('')
    const [voucherName, setVoucherName] = useState('')
    const [uploadedFile, setUploadedFile] = useState(null)
    const [jsonFile, setJsonFile] = useState({})
    const [addedFile, setAddedFile] = useState(false)
    const [typedText, setTypedText] = useState(false)
    const [clickedNext, setClickedNext] = useState(false)
    const [canSubmit, setCanSubmit] = useState(false)
    let errorFlag = false

    const { t } = useTranslation()

    const resetErrors = () => {
      setVisualNumberError('')
      setUidError('')
      setDesignError('')
    }

    const handleModalClose = () => {
      setVisualNumber('')
      setReference('')
      setUid('')
      setDesign('')
      setVoucherName('')
      resetErrors()
      setAddedFile(false)
      setTypedText(false)
      setUploadedFile(null)
    }

    const handleXlsx = (file) => {
      const json = xlsxToJson(file)
      json.then((data) => setJsonFile(data))
    }

    const handleCsv = (file) => {
      const json = csvToJson(file)
      json.then((parsedFile) => setJsonFile(parsedFile.data))
    }

    const handleOnDrop = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (acceptedFiles[0].name.includes('csv')) {
          handleCsv(acceptedFiles[0])
        } else if (acceptedFiles[0].name.includes('xlsx')) {
          handleXlsx(acceptedFiles[0])
        } else {
          toaster.push(
            <IFToastMessage
              type={'error'}
              text={t('AddChargingTokensModal.FileNotSupported')}
            />,
            { placement: 'topEnd' },
          )
          errorFlag = true
        }
        setUploadedFile(acceptedFiles)
        setAddedFile(true)

        resetErrors()
      }
    }

    const handleSubmit = () => {
      if (visualNumber.trim().length < 3 && !addedFile) {
        setVisualNumberError(t('AppChargingTokenPage.Min3Chars'))
        return
      }
      if (uid.trim().length < 3 && !addedFile) {
        setUidError(t('AppChargingTokenPage.Min3Chars'))
        return
      }
      if (!design.trim() && !addedFile) {
        setDesignError(t('AppChargingTokenPage.Required'))
        return
      }

      const designId =
        allAppChargingTokenDesigns?.appChargingTokensDesigns?.find(
          (item) => item.name === design,
        )?._id
      const voucherCode = allVouchers?.find(
        (item) => item.name === voucherName,
      )?.code
      const data = {
        visualNumber: visualNumber,
        uid: uid,
        design: designId,
      }

      if (reference) {
        data.reference = reference
      }

      if (voucherName) {
        data.voucherCode = voucherCode
      }
      if (typedText) {
        createAppChargingToken(data)
      } else if (addedFile && !errorFlag) {
        createAppChargingTokenBulk({ appChargingToken: jsonFile })
      }
    }

    const handleDownloadSampleFile = () => {
      downloadAppChargingTokenSampleFile()
    }

    useEffect(() => {
      if (
        createAppChargingTokenRequestState === RequestState.SUCCEEDED ||
        createAppChargingTokenBulkRequestState === RequestState.SUCCEEDED
      ) {
        ref.current.dismiss()
      }
    }, [
      createAppChargingTokenRequestState,
      createAppChargingTokenBulkRequestState,
    ])

    useEffect(() => {
      const isFormFilled =
        visualNumber.trim().length >= 3 &&
        uid.trim().length >= 3 &&
        design.trim()
      setCanSubmit(isFormFilled || addedFile)
    }, [visualNumber, uid, design, addedFile])

    return (
      <IFModal
        onDismiss={handleModalClose}
        title={t('AppChargingTokenPage.AddNewCard')}
        ref={ref}
        canGoBack={clickedNext}
        onBackClicked={() => setClickedNext(false)}
      >
        <div className={Styles.CreateAppChargingTokenContainer}>
          <div className={addedFile ? Styles.FadeOut : Styles.FadeIn}>
            <div className={Styles.FieldsDiv}>
              <div className={Styles.Row}>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <IFText className={Styles.Labels}>
                    {t('AppChargingTokenPage.Uid')}
                  </IFText>
                  <StyledTextField
                    value={uid}
                    onChange={(e) => {
                      const value = e.target.value
                      setUid(value)

                      setTypedText(
                        value.length > 0 ||
                          visualNumber.length > 0 ||
                          reference.length > 0 ||
                          design.length > 0 ||
                          voucherName.length > 0,
                      )
                      setUidError(
                        value.length === 0
                          ? t('AppChargingTokenPage.Required')
                          : value.length < 3
                          ? t('AppChargingTokenPage.MinThreeCharacters')
                          : '',
                      )
                    }}
                    onBlur={(e) => {
                      const value = e.target.value.trim()
                      setUidError(
                        value.length === 0
                          ? t('AppChargingTokenPage.Required')
                          : value.length < 3
                          ? t('AppChargingTokenPage.MinThreeCharacters')
                          : '',
                      )
                    }}
                    error={!!uidError}
                  />
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDivCountry}>
                  <FormControl
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: Colors.primary,
                        },
                        '& fieldset': {
                          borderColor: Colors.grey,
                          borderWidth: 1,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Colors.primary,
                        },
                      },
                    }}
                  >
                    <IFText className={Styles.Labels}>
                      {t('AppChargingTokenPage.Type')}
                    </IFText>
                    <StyledSelect
                      labelId="role-select-label"
                      value={InfinityEnums.ChargingTokenType.RFID}
                      disabled
                    >
                      <MenuItem value={InfinityEnums.ChargingTokenType.RFID}>
                        {InfinityEnums.ChargingTokenType.RFID}
                      </MenuItem>
                    </StyledSelect>
                  </FormControl>
                </FormGroup>
              </div>
              <div
                style={{
                  height: '10px',
                  marginTop: '1px',
                  fontSize: '0.75rem',
                  color: Colors.red,
                }}
              >
                {uidError && uidError}
              </div>

              <div className={Styles.Row}>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <IFText className={Styles.Labels}>
                    {t('AppChargingTokenPage.VisualNumber')}
                  </IFText>
                  <StyledTextField
                    value={visualNumber}
                    onChange={(e) => {
                      const value = e.target.value
                      setVisualNumber(value)

                      setTypedText(
                        uid.length > 0 ||
                          value.length > 0 ||
                          reference.length > 0 ||
                          design.length > 0 ||
                          voucherName.length > 0,
                      )
                      setVisualNumberError(
                        value.length === 0
                          ? t('AppChargingTokenPage.Required')
                          : value.length < 3
                          ? t('AppChargingTokenPage.MinThreeCharacters')
                          : '',
                      )
                    }}
                    onBlur={(e) => {
                      const value = e.target.value.trim()
                      setVisualNumberError(
                        value.length === 0
                          ? t('AppChargingTokenPage.Required')
                          : value.length < 3
                          ? t('AppChargingTokenPage.MinThreeCharacters')
                          : '',
                      )
                    }}
                    error={!!visualNumberError}
                  />
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDivCountry}>
                  <FormControl
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: Colors.primary,
                        },
                        '& fieldset': {
                          borderColor: Colors.grey,
                          borderWidth: 1,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Colors.primary,
                        },
                      },
                    }}
                  >
                    <IFText className={Styles.Labels}>
                      {t('AppChargingTokenPage.Status')}
                    </IFText>
                    <StyledSelect
                      labelId="role-select-label"
                      value={InfinityEnums.AppChargingTokenStatus.INACTIVE}
                      disabled
                    >
                      <MenuItem
                        value={InfinityEnums.AppChargingTokenStatus.INACTIVE}
                      >
                        {InfinityEnums.AppChargingTokenStatus.INACTIVE}
                      </MenuItem>
                    </StyledSelect>
                  </FormControl>
                </FormGroup>
              </div>
              <div
                style={{
                  height: '10px',
                  marginTop: '1px',
                  fontSize: '0.75rem',
                  color: Colors.red,
                }}
              >
                {visualNumberError && visualNumberError}
              </div>
              <div className={Styles.Row}>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <IFText className={Styles.Labels}>
                    {t('AppChargingTokenPage.Reference')}
                  </IFText>
                  <StyledTextField
                    value={reference}
                    onChange={(e) => {
                      setReference(e.target.value)
                      if (
                        visualNumber.length > 0 ||
                        e.target.value.length > 0 ||
                        uid.length > 0 ||
                        design.length > 0 ||
                        voucherName.length > 0
                      ) {
                        setTypedText(true)
                      } else setTypedText(false)
                    }}
                  />
                </FormGroup>
                <FormGroup className={Styles.InputWrappingDivCountry}>
                  <FormControl
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: Colors.primary,
                        },
                        '& fieldset': {
                          borderColor: Colors.grey,
                          borderWidth: 1,
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: Colors.primary,
                        },
                      },
                    }}
                  >
                    <IFText className={Styles.Labels}>
                      {t('AppChargingTokenPage.Design')}
                    </IFText>
                    <StyledSelect
                      labelId="role-select-label"
                      value={design}
                      onChange={(e) => {
                        setDesign(e.target.value)
                        setDesignError('')

                        if (
                          visualNumber.length > 0 ||
                          e.target.value.length > 0 ||
                          uid.length > 0 ||
                          reference.length > 0 ||
                          voucherName.length > 0
                        ) {
                          setTypedText(true)
                        } else {
                          setTypedText(false)
                        }
                      }}
                      onBlur={() => {
                        if (!design.trim()) {
                          setDesignError(t('AppChargingTokenPage.Required'))
                        }
                      }}
                      endAdornment={
                        design.length > 0 && (
                          <InputAdornment
                            position="end"
                            sx={{
                              pointerEvents: 'none',
                              width: '2px',
                              marginRight: '30px',
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setDesign('')
                                setDesignError(
                                  t('AppChargingTokenPage.Required'),
                                )
                                if (
                                  visualNumber.length > 0 ||
                                  uid.length > 0 ||
                                  reference.length > 0 ||
                                  voucherName.length > 0
                                ) {
                                  setTypedText(true)
                                } else {
                                  setTypedText(false)
                                }
                              }}
                              disableRipple
                              disableFocusRipple
                              sx={{
                                pointerEvents: 'auto',
                                marginRight: '28px',
                                backgroundColor: 'transparent',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              <ClearIcon sx={{ fontSize: '15px' }} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    >
                      {allAppChargingTokenDesigns?.appChargingTokensDesigns?.map(
                        (designItem) => (
                          <MenuItem
                            key={designItem.id || designItem.name}
                            value={designItem.name}
                          >
                            {designItem.name}
                          </MenuItem>
                        ),
                      )}
                    </StyledSelect>
                  </FormControl>
                </FormGroup>
              </div>
              {designError && (
                <div
                  style={{
                    height: '10px',
                    marginTop: '1px',
                    marginLeft: '15rem',
                    fontSize: '0.75rem',
                    color: Colors.red,
                  }}
                >
                  {designError}
                </div>
              )}
              <FormGroup className={Styles.InputWrappingCenterDiv}>
                <FormControl
                  fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: Colors.primary,
                      },
                      '& fieldset': {
                        borderColor: Colors.grey,
                        borderWidth: 1,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: Colors.primary,
                      },
                    },
                  }}
                >
                  <IFText className={Styles.Labels}>
                    {t('AppChargingTokenPage.VoucherCode')}
                  </IFText>
                  <StyledSelect
                    labelId="role-select-label"
                    value={voucherName}
                    onChange={(e) => {
                      setVoucherName(e.target.value)
                      if (
                        visualNumber.length > 0 ||
                        e.target.value.length > 0 ||
                        uid.length > 0 ||
                        reference.length > 0 ||
                        design.length > 0
                      ) {
                        setTypedText(true)
                      } else setTypedText(false)
                    }}
                    endAdornment={
                      voucherName.length > 0 && (
                        <InputAdornment
                          position="end"
                          sx={{
                            pointerEvents: 'none',
                            width: '2px',
                            marginRight: '30px',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setVoucherName('')
                              if (
                                visualNumber.length > 0 ||
                                uid.length > 0 ||
                                reference.length > 0 ||
                                design.length > 0
                              ) {
                                setTypedText(true)
                              } else {
                                setTypedText(false)
                              }
                            }}
                            disableRipple
                            disableFocusRipple
                            sx={{
                              pointerEvents: 'auto',
                              marginRight: '28px',
                              backgroundColor: 'transparent',
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            }}
                          >
                            <ClearIcon sx={{ fontSize: '15px' }} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {allVouchers?.map((voucher) => (
                      <MenuItem
                        key={voucher.id || voucher.name}
                        value={voucher.name}
                      >
                        {voucher.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </FormGroup>
            </div>
          </div>
          <div
            className={
              typedText || addedFile
                ? [Styles.DividerWrapper, Styles.FadeOut].join(' ')
                : [Styles.DividerWrapper, Styles.FadeIn].join(' ')
            }
          >
            <div className={Styles.DividerContainer}>
              <Divider />
            </div>
            <div className={Styles.DividerText}>
              <IFText style={{ color: Colors.text }}>
                {t('AppChargingTokenPage.Or')}
              </IFText>
            </div>
            <div className={Styles.DividerContainer}>
              <Divider />
            </div>
          </div>

          <div className={typedText ? Styles.FadeOut : Styles.FadeIn}>
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      className={
                        addedFile ? Styles.DropZoneSelected : Styles.DropZone
                      }
                      style={{ backgroundColor: Colors.DropZoneBackground }}
                    >
                      {uploadedFile == null ? (
                        <>
                          <FileUploadIcon />

                          <IFText>
                            {t('AppChargingTokenPage.DragAndDrop')}
                          </IFText>
                        </>
                      ) : (
                        <>
                          <InsertDriveFileIcon fontSize="large" />
                          <div
                            className={Styles.CloseIconContainer}
                            onClick={(e) => {
                              e.stopPropagation()
                              setUploadedFile(null)
                              setAddedFile(false)
                            }}
                          >
                            <Icon
                              className={Styles.CloseIcon}
                              component={CancelIcon}
                            />
                          </div>
                          <IFText>{uploadedFile[0].name}</IFText>
                        </>
                      )}
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          <div className={Styles.Submit}>
            {!typedText ? (
              <IFButton
                text={t('AppChargingTokenPage.DownloadSampleCSV')}
                color={Colors.primary}
                isFill={true}
                size="sm"
                isLoading={
                  downloadAppChargingTokenSampleFileRequestState ===
                  RequestState.LOADING
                    ? true
                    : false
                }
                onClick={handleDownloadSampleFile}
              />
            ) : null}
            <IFButton
              className={Styles.SubmitButton}
              text={t('AppChargingTokenPage.Submit')}
              color={Colors.primary}
              isFill={true}
              isLoading={
                createAppChargingTokenBulkRequestState ===
                  RequestState.LOADING ||
                createAppChargingTokenRequestState === RequestState.LOADING
                  ? true
                  : false
              }
              size="sm"
              onClick={handleSubmit}
              isDead={!canSubmit}
            />
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    createAppChargingTokenBulk: (appChargingToken) =>
      dispatch(
        AppChargingTokenActions.createAppChargingTokenBulk(appChargingToken),
      ),

    createAppChargingToken: (values) =>
      dispatch(AppChargingTokenActions.createAppChargingToken(values)),
    fetchAllAppChargingTokenDesigns: () =>
      dispatch(AppChargingTokenDesignActions.fetchAllAppChargingTokenDesigns()),

    downloadAppChargingTokenSampleFile: () =>
      dispatch(AppChargingTokenActions.downloadAppChargingTokenSampleFile()),
  }
}

const mapStateToProps = (state) => ({
  createAppChargingTokenRequestState:
    AppChargingTokenSelectors.getCreateAppChargingTokenRequestState(state),

  createAppChargingTokenBulkRequestState:
    AppChargingTokenSelectors.getCreateAppChargingTokenBulkRequestState(state),
  downloadAppChargingTokenSampleFileRequestState:
    AppChargingTokenSelectors.getDownloadAppChargingTokenSampleFileRequestState(
      state,
    ),
})

CreateAppChargingToken.propTypes = {
  addChargingTokens: PropTypes.func,
  addChargingTokensRequestState: PropTypes.number,
  addChargingTokensBulk: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CreateAppChargingToken)
