import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setFilterText: ['text'],
  fetchUserList: ['offset', 'onError', 'shouldShowError'],
  fetchUserListSuccess: [
    'offset',
    'users',
    'nextOffset',
    'newUser',
    'usersCount',
    'index',
    'filter',
  ],
  setUserListSelectedIndex: ['index'],
  fetchUserListError: ['error'],
  fetchUserListLoading: null,
  clearUsers: null,
  setUsersFilter: ['newFilter'],
  setSelectedCountry: ['newCountry'],
  fetchUserTransactionList: ['userId', 'filter', 'offset', 'shouldShowError'],
  fetchUserTransactionListLoading: null,
  fetchUserTransactionListSuccess: ['offset', 'transactions', 'nextOffset'],
  fetchUserTransactionListFail: ['error'],
  clearTransactions: null,

  fetchUserPaymentSessionList: [
    'userId',
    'filter',
    'offset',
    'shouldShowError',
  ],
  fetchUserPaymentSessionListLoading: null,
  fetchUserPaymentSessionListSuccess: [
    'offset',
    'paymentSessions',
    'nextOffset',
  ],
  fetchUserPaymentSessionListError: ['error'],
  clearPaymentSessions: null,

  setUserTransactionsFilter: ['newFilter'],
  clearUserTransactionsFilter: ['admin'],

  setUserPaymentsFilter: ['newFilter'],
  clearUserPaymentsFilter: ['admin'],

  fetchUserDetails: ['userId', 'disableSkeleton'],
  fetchUserDetailsSuccess: ['user'],
  fetchUserDetailsFail: ['error'],
  fetchUserDetailsLoading: null,
  clearCurrentUser: null,

  setCurrentUserChargingTokenDeactiveLoading: ['index'],
  setCurrentUserChargingTokenActive: ['userId', 'chargingTokenId', 'index'],
  setCurrentUserChargingTokenDeactiveSuccess: ['index'],
  setCurrentUserChargingTokenDeactiveFail: ['index'],

  setUserSortingAsc: ['isAsc'],
  setSelectedUserIndex: ['index'],

  setCurrentUserTransactionRefundLoading: ['index'],
  setCurrentUserTransactionRefund: ['id', 'index'],
  setCurrentUserTransactionRefundSuccess: ['index'],
  setCurrentUserTransactionRefundFail: ['index'],

  setCurrentUserNewBalance: ['id', 'amount', 'country'],
  setCurrentUserNewBalanceSuccess: ['transaction'],
  setCurrentUserNewBalanceFail: null,
  setCurrentUserNewBalanceLoading: null,

  addCurrentUserNewCard: ['id', 'serialNumbers', 'onError'],
  addCurrentUserNewCardSuccess: ['chargingToken'],
  addCurrentUserNewCardFail: ['error'],
  addCurrentUserNewCardLoading: null,

  toggleCurrentUserIsActive: ['id', 'setIsActive'],
  toggleCurrentUserIsActiveFail: null,
  toggleCurrentUserIsActiveLoading: null,
  toggleCurrentUserIsActiveSuccess: ['id', 'setIsActive'],

  setCurrentUserNewPhone: ['id', 'newPhone'],
  setCurrentUserNewPhoneSuccess: ['id', 'newPhone'],
  setCurrentUserNewPhoneFail: ['id'],
  setCurrentUserNewPhoneLoading: null,

  reverseUserChargeTransaction: ['values', 'transactionData', 'onResponse'],
  reverseUserChargeTransactionLoading: ['index'],
  reverseUserChargeTransactionSuccess: ['index'],
  reverseUserChargeTransactionError: ['index', 'error'],

  fetchUserPoints: ['id', 'offset', 'country'],
  fetchUserPointsError: null,
  fetchUserPointsLoading: null,
  fetchUserPointsSuccess: ['offset', 'points', 'nextOffset'],
  clearPoints: null,

  fetchUserListCount: ['filter'],
  fetchUserListCountLoading: null,
  fetchUserListCountSuccess: ['usersCount'],
  fetchUserListCountFail: ['error'],

  deleteCurrentUserPaymentCard: ['userId', 'paymentCardId', 'index'],
  deleteCurrentUserPaymentCardLoading: ['index'],
  deleteCurrentUserPaymentCardSuccess: ['index'],
  deleteCurrentUserPaymentCardFail: ['index'],

  fetchAdminActionsUsersList: ['userId', 'filter', 'offset', 'shouldShowError'],
  fetchAdminActionsLoading: null,
  fetchAdminActionsSuccess: ['offset', 'adminActions', 'nextOffset'],
  fetchAdminActionsError: ['error'],

  clearAdminActionsFilter: null,
  setAdminActionsFilter: ['newFilter'],
  clearAdminActions: null,
  setAdminActionsPaginationOffset: ['newOffset'],
  setUserTransactionsPaginationOffset: ['newOffset'],
  setUserPaymentsPaginationOffset: ['newOffset'],
})

export const UserTypes = Types
export default Creators
