import { INITIAL_STATE } from './initialState'
import { DashboardTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import InfinityEnums from 'Enums/InfinityEnums'
var base64 = require('base-64')

export const fetchDashboardMetricsLoading = (state) => {
  return {
    ...state,
    fetchDashboardRequestState: RequestState.LOADING,
  }
}

export const fetchDashboardMetricsSuccess = (state, { metrics }) => {
  return {
    ...state,
    metrics,
    fetchDashboardRequestState: RequestState.SUCCEEDED,
  }
}
export const fetchDashboardMetricsError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchDashboardRequestState: requestState,
  }
}

export const setCountryFilter = (state, { admin }) => {
  let newDashboardFilters = state.dashboardFilters
  if (admin.registeredCountries.length > 1) {
    const countryNames = admin.registeredCountries.map(
      (country) => country.name,
    )
    let initialCountry = admin.registeredCountries[0].name
    let index = state.dashboardFilters.findIndex(
      (filter) =>
        filter.type === IFFIlterType.SELECT && filter.field === 'Country',
    )
    if (index === -1)
      newDashboardFilters.push({
        type: IFFIlterType.SELECT,
        title: InfinityEnums.IFFilterTitle.Country,
        data: countryNames,
        initialValue: initialCountry,
        value: initialCountry,
        canClear: false,
        field: 'Country',
      })
  }
  return {
    ...state,
    dashboardFilters: [...newDashboardFilters],
  }
}

export const clearDashboardFilter = (state) => {
  const startYear = 2021
  const currentYear = new Date().getFullYear()
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) =>
    (startYear + i).toString(),
  )

  return {
    ...state,
    dashboardFilters: [
      {
        type: IFFIlterType.SELECT,
        title: 'Year',
        data: years,
        initialValue: '',
        value: '',
        canClear: true,
        field: 'Date',
      },
    ],
  }
}

export const setDashboardFilter = (state, { newFilter }) => {
  return {
    ...state,
    dashboardFilters: newFilter,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [DashboardTypes.FETCH_DASHBOARD_METRICS_LOADING]:
    fetchDashboardMetricsLoading,
  [DashboardTypes.FETCH_DASHBOARD_METRICS_SUCCESS]:
    fetchDashboardMetricsSuccess,
  [DashboardTypes.FETCH_DASHBOARD_METRICS_ERROR]: fetchDashboardMetricsError,
  [DashboardTypes.SET_DASHBOARD_FILTER]: setDashboardFilter,
  [DashboardTypes.CLEAR_DASHBOARD_FILTER]: clearDashboardFilter,
  [DashboardTypes.SET_COUNTRY_FILTER]: setCountryFilter,
})
