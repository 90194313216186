import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import subDays from 'date-fns/subDays'

export const INITIAL_STATE = {
  paginationOffset: 0,
  transactions: [],
  metrics: {},
  energyConsumption: {},
  fetchTransactionsListRequestState: RequestState.UNINITIALIZED,
  fetchTransactionsMetricsRequestState: RequestState.UNINITIALIZED,
  fetchEnergyConsumptionChartRequestState: RequestState.UNINITIALIZED,
  allSubscriptions: [],
  fetchAllSubscriptionsRequestState: RequestState.UNINITIALIZED,

  transactionsFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Type',
      data: ['addMoney', 'charge', 'refund'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.VALUE_RANGE_DECIMAL,
      title: 'Amount',
      initialValue: [],
      value: [],
      field: 'amount',
    },
    {
      type: IFFIlterType.VALUE_RANGE_DECIMAL,
      title: 'Energy Consumption',
      initialValue: [],
      value: [],
      field: 'energyConsumption',
    },
  ],
}
