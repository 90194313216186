import { put, call } from 'redux-saga/effects'
import {
  appChargingTokenDesignApiService,
  handleError,
} from 'Services/ApiService.js'
import appChargingTokenDesignActions from 'Stores/AppChargingTokenDesign/Actions'
import i18n from 'i18next'
import { IFToastMessage } from 'Components'
import { toaster } from 'rsuite'

export function* fetchAppChargingTokenDesigns({ offset }) {
  yield put(appChargingTokenDesignActions.fetchAppChargingTokenDesignsLoading())

  try {
    const { data } = yield call(appChargingTokenDesignApiService.get, '/', {
      params: {
        offset: offset,
      },
    })
    yield put(
      appChargingTokenDesignActions.fetchAppChargingTokenDesignsSuccess(
        offset,
        data.designs,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(
      appChargingTokenDesignActions.fetchAppChargingTokenDesignsError(e),
    )
    handleError(e)
  }
}

export function* fetchAllAppChargingTokenDesigns() {
  yield put(
    appChargingTokenDesignActions.fetchAllAppChargingTokenDesignsLoading(),
  )

  try {
    const { data } = yield call(
      appChargingTokenDesignApiService.get,
      '/allDesigns',
    )
    yield put(
      appChargingTokenDesignActions.fetchAllAppChargingTokenDesignsSuccess(
        data,
      ),
    )
  } catch (e) {
    yield put(
      appChargingTokenDesignActions.fetchAllAppChargingTokenDesignsError(e),
    )
    handleError(e)
  }
}
export function* addAppChargingTokenDesign({ design }) {
  yield put(appChargingTokenDesignActions.addAppChargingTokenDesignLoading())
  try {
    const { data } = yield call(appChargingTokenDesignApiService.post, '/', {
      name: design.name,
      design: design.description,
    })

    yield put(
      appChargingTokenDesignActions.addAppChargingTokenDesignSuccess(data),
    )
    toaster.push(
      <IFToastMessage
        type="success"
        text={i18n.t('AppChargingTokenPage.AppChargingTokenDesignAdded')}
      />,
    )
  } catch (e) {
    yield put(appChargingTokenDesignActions.addAppChargingTokenDesignError(e))
    handleError(e)
  }
}

export function* editAppChargingTokenDesign({ id, design }) {
  yield put(appChargingTokenDesignActions.editAppChargingTokenDesignLoading())
  try {
    const { data } = yield call(
      appChargingTokenDesignApiService.patch,
      `/${id}`,
      {
        name: design.name,
        design: design.description,
      },
    )

    yield put(
      appChargingTokenDesignActions.editAppChargingTokenDesignSuccess(data),
    )
  } catch (e) {
    yield put(appChargingTokenDesignActions.editAppChargingTokenDesignError(e))
    handleError(e)
  }
}

export function* getAppChargingTokenDesignsCount() {
  yield put(
    appChargingTokenDesignActions.getAppChargingTokenDesignsCountLoading(),
  )
  try {
    const { data } = yield call(appChargingTokenDesignApiService.get, '/count')
    yield put(
      appChargingTokenDesignActions.getAppChargingTokenDesignsCountSuccess(
        data.count,
      ),
    )
  } catch (e) {
    yield put(
      appChargingTokenDesignActions.getAppChargingTokenDesignsCountError(e),
    )
    handleError(e)
  }
}
export function* deleteAppChargingTokenDesign({ id }) {
  yield put(appChargingTokenDesignActions.deleteAppChargingTokenDesignLoading())
  try {
    yield call(appChargingTokenDesignApiService.delete, `/${id}`)
    yield put(
      appChargingTokenDesignActions.deleteAppChargingTokenDesignSuccess(id),
    )
  } catch (e) {
    yield put(
      appChargingTokenDesignActions.deleteAppChargingTokenDesignError(e),
    )
    handleError(e)
  }
}
