const InfinityEnums = {
  Locale: {
    EN: 'en',
    AR: 'ar',
  },
  AdminPermissions: {
    CAN_REFUND: 1,
    CAN_TOGGLE_USER_ACTIVATION: 2,
    CAN_ADJUST_BALANCE: 3,
    CAN_ADD_CHARGING_TOKEN: 4,
    CAN_TOGGLE_CARD_ACTIVATION: 5,
    CAN_EDIT_ADMINS: 6,
    CAN_EDIT_CARD_REQUEST_STATUS: 7,
    CAN_EDIT_NOTIFICATIONS: 8,
    CAN_VIEW_TRANSACTIONS: 10,
    CAN_VIEW_USERS: 11,
    CAN_ADD_POINTS: 13,
    CAN_GENERATE_CODE: 14,
    CAN_VIEW_ADMINS: 15,
    CAN_VIEW_NOTIFICATIONS: 16,
    CAN_EDIT_USERS: 17,
    CAN_REVERSE_TRANSACTION: 18,
    CAN_VIEW_VOUCHERS: 19,
    CAN_EDIT_ROLES: 20,
    CAN_VIEW_USER_PAYMENT_CARDS: 22,
    CAN_EDIT_USER_PAYMENT_CARDS: 23,
    CAN_CREATE_ROLES: 24,
    CAN_INVITE_ADMIN: 25,
    CAN_VIEW_POINTS: 26,
    CAN_EDIT_VOUCHERS: 27,
    CAN_VIEW_DASHBOARD: 28,
    CAN_VIEW_ADMIN_ACTIONS: 29,
    CAN_VIEW_NOTIFICATION_GROUPS: 30,
    CAN_VIEW_COUNTRIES: 31,
    CAN_EDIT_COUNTRIES: 32,
    CAN_EDIT_NOTIFICATION_GROUPS: 33,
    CAN_VIEW_APP_CHARGING_TOKENS: 34,
    CAN_EDIT_APP_CHARGING_TOKENS: 35,
    CAN_VIEW_APP_CHARGING_TOKENS_DESIGN: 36,
    CAN_EDIT_APP_CHARGING_TOKENS_DESIGN: 37,
  },
  ChargingTokenType: {
    RFID: 'Rfid',
    VIRTUAL: 'Virtual',
  },
  InvitationStatus: {
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    PENDING: 'Pending',
  },

  AppChargingTokenStatus: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    DEACTIVATED: 'Deactivated',
  },
  ChargingLevel: {
    LEVEL1: 'Level 1',
    LEVEL2: 'Level 2',
    LEVEL3: 'Level 3',
  },
  TransactionStatus: {
    REVERSED: 'Reversed',
    CORRECTED: 'Corrected',
    NORMAL: 'Normal',
    VOID: 'Void',
  },
  TransactionReverseType: {
    REVERSE: 'Reverse',
    CORRECT: 'Correct',
  },
  PaymentStatus: {
    SUCCEED: 'success',
    FAILED: 'fail',
    PENDING: 'pending',
  },
  AdminActions: {
    Edit: 'Edit',
    Create: 'Create',
    Delete: 'Delete',
    Activate: 'Activate',
    Deactivate: 'Deactivate',
    Invite: 'Invite',
  },
  Models: {
    Admin: 'Admin',
    AdminRole: 'AdminRole',
    Country: 'Country',
    Currency: 'Currency',
    ExternalClient: 'ExternalClient',
    InviteUser: 'InviteUser',
    Notification: 'Notification',
    NotificationGroup: 'NotificationGroup',
    PaymentSession: 'PaymentSession',
    Point: 'Point',
    Transaction: 'Transaction',
    User: 'User',
    Voucher: 'Voucher',
    Wallet: 'Wallet',
    AppChargingToken: 'AppChargingToken',
  },
  IFFilterTitle: {
    Country: 'Country',
    Role: 'Role',
  },
  NotificationStatus: {
    SENT: 'Sent',
    SCHEDULED: 'Scheduled',
    CANCELLED: 'Cancelled',
  },
}

export default InfinityEnums
