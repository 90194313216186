import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  fetchAppChargingTokensRequestState: RequestState.UNINITIALIZED,
  editAppChargingTokenRequestState: RequestState.UNINITIALIZED,
  deleteAppChargingTokenRequestState: RequestState.UNINITIALIZED,
  createAppChargingTokenRequestState: RequestState.UNINITIALIZED,
  createAppChargingTokenBulkRequestState: RequestState.UNINITIALIZED,
  downloadAppChargingTokenSampleFileRequestState: RequestState.UNINITIALIZED,
  getAppChargingTokensCountRequestState: RequestState.UNINITIALIZED,
  filterText: '',
  appChargingTokens: [],
  paginationOffset: 0,
  count: 0,
  appChargingTokenFilter: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['Active', 'Inactive', 'Deactivated'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Type',
      data: ['Rfid', 'Virtual'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
}
