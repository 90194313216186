import React, { useState, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from '@mui/material'
import { Colors } from 'Theme'
import { IFTabs } from 'Components'
import NotificationPageTabsSkeleton from './NotificationPageTabsSkeleton'
import { useTranslation } from 'react-i18next'

const NotificationPageTabs = React.forwardRef(
  (
    {
      onValueChange,
      isLoading,
      initialIndex,
      notificationsCount,
      groupsCount,
      canViewGroups,
      canViewNotifications,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)
    const tabTitles = {}
    if (canViewNotifications) {
      tabTitles.Notifications = t('PushNotificationsPanel.notificationsTab')
    }
    if (canViewGroups) {
      tabTitles.Groups = t('PushNotificationsPanel.groupsTab')
    }
    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    return (
      <div>
        {isLoading ? (
          <NotificationPageTabsSkeleton
            tabsCount={Object.keys(tabTitles).length}
          />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {Object.entries(tabTitles).map(([key, title], index) => (
              <Tab
                sx={{
                  padding: '0px',
                  minWidth: 'fit-content',
                  maxWidth: 'fit-content',
                }}
                key={index}
                label={
                  <IFTabs
                    title={title}
                    isSelected={selectedIndex === index}
                    count={
                      key === 'Notifications' ? notificationsCount : groupsCount
                    }
                  />
                }
              />
            ))}
          </Tabs>
        )}
      </div>
    )
  },
)

NotificationPageTabs.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  initialIndex: PropTypes.number,
  notificationsCount: PropTypes.number,
  groupsCount: PropTypes.number,
  isLoading: PropTypes.bool,
  canViewGroups: PropTypes.bool,
  canViewNotifications: PropTypes.bool,
}

export default NotificationPageTabs
