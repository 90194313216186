/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

const images = {
  logo: require('Assets/Images/logo.png'),
  iphone: require('Assets/Images/iphone.png'),
  verticalLogo: require('Assets/Images/newLogo.png'),
  noSitesFilter: require('../Assets/Images/noSitesFilter.png'),
  charge: require('../Assets/Images/charge.png'),
  spark: require('../Assets/Images/spark.png'),
  money: require('../Assets/Images/money.png'),
  station: require('../Assets/Images/station.png'),
}

export default images
