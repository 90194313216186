import Styles from './AddForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField, FormGroup, MenuItem } from '@mui/material'
import { object, string } from 'yup'
import { IFLoadingIndicator, IFButton, IFText } from 'Components'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { styled as muiStyled } from '@mui/system'
import CountriesSelectors from 'Stores/Countries-Currencies/Selectors'
import RequestState from 'Enums/RequestState'

const currencyCodes = require('iso4217')

const StyledTextField = muiStyled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
})

const initialValues = {
  currencyName: '',
  currencyCode: '',
  currencySymbol: '',
  currencyDecimalPlaces: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const AddCurrencyForm = ({ onSubmitForm, addCurrencyRequestState }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState()

  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div>
        <Formik
          validationSchema={object({
            currencyName: Yup.string().required(t('CountriesPage.Required')),
            currencyCode: string().required(t('CountriesPage.Required')),
            currencySymbol: string().required(t('CountriesPage.Required')),
            currencyDecimalPlaces: string().required(
              t('CountriesPage.Required'),
            ),
          })}
          initialValues={initialValues}
          onSubmit={(values) => {
            const updatedValues = {
              ...values,
              currencyName: selected.Currency,
            }
            onSubmitForm(updatedValues)
          }}
        >
          {({ values, errors, setFieldValue }) => {
            useEffect(() => {
              if (selected) setFieldValue('currencyName', selected.Code)
            }, [selected])
            return (
              <Form>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="currencyName">
                    {t('CountriesPage.CountryCurrencyForm')}
                  </label>
                  <Field
                    className={Styles.Input}
                    id="standard-select-currency"
                    select
                    fullWidth
                    as={StyledTextField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      const { value } = event.target
                      const currency = Object.values(currencyCodes).find(
                        (currency) => currency.Code === value,
                      )
                      setSelected(currency)
                      setFieldValue('currencyName', value)
                      setFieldValue('currencyCode', value)
                      setFieldValue(
                        'currencySymbol',
                        currency.Symbol ? currency.Symbol : currency.Code,
                      )
                      setFieldValue(
                        'currencyDecimalPlaces',
                        currency.Fraction ? currency.Fraction : 0,
                      )
                    }}
                    size="small"
                    name="currencyName"
                    sx={{
                      '& .MuiInputBase-root.Mui-focused': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiSelect-select:focus': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiInputBase-root:hover::before': {
                        borderBottom: `1px solid ${Colors.primary} !important`,
                      },
                      '& .MuiInputBase-root.Mui-focused::after': {
                        borderBottom: `2px solid ${Colors.primary} !important`,
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 250,
                            maxWidth: 250,
                            overflow: 'scroll',
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      },
                    }}
                  >
                    {Object.values(currencyCodes)
                      .filter(
                        (currency) =>
                          currency.Location && currency.Location.trim() !== '',
                      )
                      .map((currency) => (
                        <MenuItem key={currency.Code} value={currency.Code}>
                          <div className={Styles.DropDownItem}>
                            <IFText className={Styles.DropDownText}>
                              {currency.Currency}
                            </IFText>
                          </div>
                        </MenuItem>
                      ))}
                  </Field>
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="currencyName" />
                  </ErrorContainer>
                </FormGroup>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="currencyCode">
                    {t('CountriesPage.CurrencyCodeForm')}
                  </label>
                  <TextField
                    disabled={true}
                    className={Styles.Input}
                    fullWidth
                    value={values.currencyCode}
                    name="currencyCode"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        cursor: 'default !important',
                      },
                    }}
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="currencyCode" />
                  </ErrorContainer>
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="currencySymbol">
                    {t('CountriesPage.CurrencySymbolForm')}
                  </label>
                  <TextField
                    type="text"
                    disabled={true}
                    fullWidth
                    name="currencySymbol"
                    size="small"
                    value={values.currencySymbol}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        cursor: 'default !important',
                      },
                    }}
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="currencySymbol" />
                  </ErrorContainer>
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="currencyDecimalPlaces">
                    {t('CountriesPage.CurrencyDecimalForm')}
                  </label>
                  <TextField
                    type="text"
                    disabled={true}
                    fullWidth
                    name="currencyDecimalPlaces"
                    size="small"
                    value={values.currencyDecimalPlaces}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        cursor: 'default !important',
                      },
                    }}
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="currencyDecimalPlaces" />
                  </ErrorContainer>
                </FormGroup>

                <div className={Styles.ButtonDiv}>
                  <IFButton
                    className={Styles.ButtonWrappingDiv}
                    type="submit"
                    color={Colors.primary}
                    isDead={
                      !(
                        values.currencyName &&
                        values.currencyCode &&
                        values.currencySymbol &&
                        values.currencyDecimalPlaces >= 0
                      ) || errors.currencyName
                    }
                    isFill={true}
                    isLoading={false}
                    text={
                      addCurrencyRequestState === RequestState.LOADING ? (
                        <div className={Styles.LoadingIndicator}>
                          <IFLoadingIndicator size={'2em'} />
                        </div>
                      ) : (
                        t('CountriesPage.Submit')
                      )
                    }
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  addCurrencyRequestState: CountriesSelectors.getAddCurrencyRequestState(state),
})

export default connect(mapStateToProps)(AddCurrencyForm)
