import { connect } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CountriesSelectors from 'Stores/Countries-Currencies/Selectors'
import CountriesActions from 'Stores/Countries-Currencies/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { useTranslation } from 'react-i18next'
import {
  IFButton,
  IFSkeleton,
  IFsvg,
  IFTable,
  IFText,
  IFTooltipIconsLoading,
} from 'Components'
import { Box, IconButton, MenuItem, TextField, Tooltip } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import RequestState from 'Enums/RequestState'
import Styles from './CurrenciesList.module.css'
import { Colors } from 'Theme'
import styled from 'styled-components'
import EditIcon from '@mui/icons-material/Edit'
import InfinityEnums from 'Enums/InfinityEnums'
const currencyCodes = require('iso4217')
const IconDivIFToolTip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.onHoverBackground};
  }
`
const CurrenciesList = ({
  currencies,
  paginationOffset,
  currenciesFilters,
  tablesHeight,
  fetchCurrenciesListRequestState,
  fetchCurrenciesList,
  editingAdminRole,
  updateCurrency,
  updateCurrencyRequestState,
  setUpdateCurrencyRequestState,
}) => {
  const { t } = useTranslation()
  const [isFirstLoaded, setIsFirstLoaded] = useState(true)
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchCurrenciesListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchCurrenciesListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchCurrenciesListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchCurrenciesListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchCurrenciesListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchCurrenciesListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchCurrenciesListRequestState === RequestState.SUCCEEDED) {
      setFailedRequest(false)
      setIsFirstLoaded(false)
    }
  }, [fetchCurrenciesListRequestState])
  const handleRetry = () => {
    fetchCurrenciesList(currenciesFilters, paginationOffset)
  }
  const loadMoreData = () => {
    if (
      fetchCurrenciesListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchCurrenciesList(currenciesFilters, paginationOffset, failedRequest)
  }

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  const currencyValue = useRef()

  const currenciesTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        Edit: () => {
          return (
            <TextField
              id="standard-select-currency"
              select
              variant="standard"
              fullWidth
              defaultValue="here"
              value={currencyValue?.current.Code}
              onChange={(event) => {
                const selectedRow = Object.values(currencyCodes).find(
                  (currency) => currency.Code === event.target.value,
                )
                currencyValue.current = selectedRow
              }}
              sx={{
                '& .MuiInputBase-root.Mui-focused': {
                  backgroundColor: 'transparent',
                },
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '& .MuiInputBase-root:hover::before': {
                  borderBottom: `1px solid ${Colors.primary} !important`,
                },
                '& .MuiInputBase-root.Mui-focused::after': {
                  borderBottom: `2px solid ${Colors.primary} !important`,
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: 250,
                      maxWidth: 250,
                      overflow: 'scroll',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                },
              }}
            >
              {Object.values(currencyCodes)
                .filter(
                  (currency) =>
                    currency.Location && currency.Location.trim() !== '',
                )
                .map((currency) => (
                  <MenuItem key={currency.Code} value={currency.Code}>
                    <div className={Styles.DropDownItem}>
                      <IFText className={Styles.DropDownText}>
                        {currency.Currency}
                      </IFText>
                    </div>
                  </MenuItem>
                ))}
            </TextField>
          )
        },
        header:
          fetchCurrenciesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <IFSkeleton variant="text" width="6.875rem" height="1.25rem" />
          ) : (
            t('CountriesPage.CountryName')
          ),
        Cell: ({ row }) => {
          return row.index < currencies.length ? (
            <IFText>{row.original.name}</IFText>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <IFSkeleton variant="text" width="60%" height="1.25rem" />
          )
        },
      },
      {
        accessorKey: 'code',
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            disabled: true,
            value: currencyValue?.current.Code,
            sx: {
              '& .MuiInputBase-input.Mui-disabled': {
                cursor: 'default !important',
              },
            },
          }
        },
        header:
          fetchCurrenciesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <IFSkeleton variant="text" width="6.875rem" height="1.25rem" />
          ) : (
            t('CountriesPage.CountryCode')
          ),
        Cell: ({ row }) => {
          return row.index < currencies.length ? (
            <IFText>{row.original.code}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <IFSkeleton variant="text" width="60%" height="1.25rem" />
          )
        },
      },
      {
        accessorKey: 'symbol',
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            disabled: true,
            value: currencyValue?.current.Symbol
              ? currencyValue?.current.Symbol
              : currencyValue?.current.Code,
            sx: {
              '& .MuiInputBase-input.Mui-disabled': {
                cursor: 'default !important',
              },
            },
          }
        },
        header:
          fetchCurrenciesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <IFSkeleton variant="text" width="6.875rem" height="1.25rem" />
          ) : (
            t('CountriesPage.Symbol')
          ),
        Cell: ({ row }) => {
          return row.index < currencies.length ? (
            <IFText>{row.original.symbol}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <IFSkeleton variant="text" width="60%" height="1.25rem" />
          )
        },
      },
      {
        accessorKey: 'decimalPlaces',
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            disabled: true,
            value: currencyValue?.current.Fraction
              ? currencyValue?.current.Fraction
              : 0,
            sx: {
              '& .MuiInputBase-input.Mui-disabled': {
                cursor: 'default !important',
              },
            },
          }
        },

        header:
          fetchCurrenciesListRequestState === RequestState.LOADING &&
          isFirstLoaded ? (
            <IFSkeleton variant="text" width="6.875rem" height="1.25rem" />
          ) : (
            t('CountriesPage.DecimalPlaces')
          ),
        Cell: ({ row }) => {
          return row.index < currencies.length ? (
            <IFText>{row.original.decimalPlaces}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <IFSkeleton variant="text" width="60%" height="1.25rem" />
          )
        },
      },
    ],
    [
      currencyValue,
      currencyCodes,
      currencies,
      fetchCurrenciesListRequestState,
      isFirstLoaded,
      failedRequest,
    ],
  )
  const [table, setTable] = useState()
  useEffect(() => {
    if (updateCurrencyRequestState === RequestState.SUCCEEDED) {
      table?.setEditingRow(null)
    }
  }, [updateCurrencyRequestState])
  const handleSaveCurrency = (table, row) => {
    let values = {
      name: currencyValue.current.Currency,
      code: currencyValue.current.Code,
      symbol: currencyValue.current.Symbol
        ? currencyValue.current.Symbol
        : currencyValue.current.Code,
      decimalPlaces: currencyValue.current.Fraction
        ? currencyValue.current.Fraction
        : 0,
    }
    if (
      values.name === '' ||
      values.code === '' ||
      values.symbol === '' ||
      values.decimalPlaces === '' ||
      (values.name === row.original.name &&
        values.code === row.original.code &&
        values.symbol === row.original.symbol &&
        values.decimalPlaces === row.original.decimalPlaces)
    ) {
      table?.setEditingRow(null)
    } else {
      setTable(table)
      updateCurrency(row.original.id, values, row.index, () => {})
    }
  }
  const renderRowActionsCell = ({ table, row }) => {
    if (Object.keys(row.original).length > 0) {
      return row?.id === table.getState().editingRow?.id ? (
        <div className={Styles.RowActions}>
          <Tooltip title={t('CountriesPage.Cancel')}>
            <IconButton
              onClick={() => {
                table?.setEditingRow(null)
              }}
            >
              <IFsvg.CloseBtn height={24} width={24} fill={Colors.primary} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('CountriesPage.Save')}>
            <IconDivIFToolTip>
              <IFTooltipIconsLoading
                isLoading={
                  updateCurrencyRequestState === RequestState.LOADING
                    ? true
                    : false
                }
                Icon={IFsvg.DoneBtn}
                FilledIcon={IFsvg.DoneBtn}
                size={24}
                animationDisabled={false}
                onClick={() => {
                  handleSaveCurrency(table, row)
                }}
              />
            </IconDivIFToolTip>
          </Tooltip>
        </div>
      ) : (
        <Box className={Styles.RowActions}>
          {editingAdminRole?.includes(
            InfinityEnums.AdminPermissions.CAN_EDIT_COUNTRIES,
          ) && (
            <Tooltip title={t('CountriesPage.Edit')}>
              <IconButton onClick={() => handleEdit({ row, table })}>
                <EditIcon sx={{ color: Colors.gray }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )
    }
    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={() => {
            handleRetry()
          }}
        />
      )
    }
  }
  const handleEdit = ({ row, table }) => {
    const selectedRow = Object.values(currencyCodes).find(
      (currency) => currency.Code === row.original.code,
    )
    currencyValue.current = selectedRow
    setUpdateCurrencyRequestState(RequestState.UNINITIALIZED)
    table?.setEditingRow(row)
  }

  return (
    <>
      <IFTable
        columns={currenciesTableColumns}
        data={currencies}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        paginationOffset={paginationOffset}
        enableEditing={true}
        filters={currenciesFilters}
        showSkeleton={
          (fetchCurrenciesListRequestState === RequestState.LOADING &&
            paginationOffset === 0) ||
          (fetchCurrenciesListRequestState !== RequestState.SUCCEEDED &&
            !paginationOffset)
        }
        tableMaxHeight={tablesHeight - 16}
        tableSetHeight={'100%'}
        tableRequestState={fetchCurrenciesListRequestState}
        renderRowActionsCell={renderRowActionsCell}
      />
    </>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchCurrenciesList: (filter, offset, shouldShowError) =>
      dispatch(
        CountriesActions.fetchCurrenciesList(filter, offset, shouldShowError),
      ),
    setUpdateCurrencyRequestState: (requestState) =>
      dispatch(CountriesActions.setUpdateCurrencyRequestState(requestState)),
    updateCurrency: (currencyId, newCurrency, index, onResponse) =>
      dispatch(
        CountriesActions.updateCurrency(
          currencyId,
          newCurrency,
          index,
          onResponse,
        ),
      ),
  }
}
const mapStateToProps = (state) => ({
  currencies: CountriesSelectors.getCurrenciesList(state),
  currenciesFilters: CountriesSelectors.getCurrenciesFilters(state),
  fetchCurrenciesListRequestState:
    CountriesSelectors.getFetchCurrenciesListRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  paginationOffset: CountriesSelectors.getCurrenciesListOffset(state),
  updateCurrencyRequestState:
    CountriesSelectors.getUpdateCurrencyRequestState(state),
})
export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesList)
