import IFFIlterType from 'Enums/IFFilterType'
import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  filterText: '',
  fetchUsersListRequestState: RequestState.UNINITIALIZED,
  fetchUserTransactionListRequestState: RequestState.UNINITIALIZED,
  fetchUserPaymentSessionListRequestState: RequestState.UNINITIALIZED,
  paginationOffset: 0,
  transactionListPaginationOffset: 0,
  paymentSessionListPaginationOffset: 0,
  userSortingAsc: false,
  defaultUserFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['Active', 'Inactive'],
      initialValue: [],
      value: [],
    },
  ],
  usersFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['Active', 'Inactive'],
      initialValue: [],
      value: [],
    },
  ],
  transactionsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Type',
      data: ['addMoney', 'charge', 'refund'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
  paymentsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Status',
      data: ['pending', 'success', 'fail'],
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
  ],
  users: [],
  usersCount: 0,
  fetchUserListCountRequestState: RequestState.UNINITIALIZED,
  addCurrentCardRequestState: RequestState.UNINITIALIZED,
  currentUser: {
    id: '',
    chargingTokens: [],
    paymentCards: [],
    balance: 0,
    selectedCountry: '',
    isActive: true,
    name: '',
    phone: '',
    transactions: [],
    email: '',
    paymentSessions: [],
  },
  fetchUserDetailsRequestState: RequestState.UNINITIALIZED,
  selectedUserIndex: -1,
  reverseChargeTransactionRequestState: RequestState.UNINITIALIZED,
  points: [],
  fetchUserPointsListRequestState: RequestState.UNINITIALIZED,
  pointsListPaginationOffset: 0,
  fetchAdminActionsRequestState: RequestState.UNINITIALIZED,
  adminActions: [],
  adminActionsPaginationOffset: 0,
  adminActionsFilters: [
    {
      type: IFFIlterType.KEYWORD,
      title: null,
      data: null,
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.DATE_RANGE,
      title: 'Date',
      initialValue: [],
      value: [],
    },
    {
      type: IFFIlterType.CHECK,
      title: 'Action',
      data: ['Create', 'Edit', 'Delete', 'Activate', 'Deactivate'],
      initialValue: [],
      value: [],
    },
  ],
}
