/**
 * Selectors let us factorize logic that queries the state.
 *
 * Selectors can be used in sagas or components to avoid duplicating that logic.
 *
 * Writing selectors is optional as it is not always necessary, we provide a simple example below.
 */

const isLoginLoading = (state) => {
  return state.secure.authState.loginLoading
}

const getUser = (state) => {
  return state.secure.authState.user
}

const getToken = (state) => {
  return state.secure.authState.token
}

const isLoggedIn = (state) => {
  return state.secure.authState.token && state.secure.authState.user
}

const getRefreshToken = (state) => {
  return state.secure.authState.refreshToken
}

const getEditingAdminRole = (state) => {
  return state.secure.authState.editingAdminRole
}
const getChangingPasswordRequestState = (state) => {
  return state.secure.authState.ChangingPasswordRequestState
}
const getChangingUserNameRequestState = (state) => {
  return state.secure.authState.ChangingUserNameRequestState
}
const getAdminDetailsRequestState = (state) => {
  return state.secure.authState.fetchAdminDetailsRequestState
}
const getAdminActionList = (state) => {
  return state.secure.authState.adminActions
}

const getAdminActionsFilters = (state) => {
  return state.secure.authState.adminActionsFilters
}

const getfetchAdminActionsRequestState = (state) => {
  return state.secure.authState.fetchAdminActionsRequestState
}
const getAdminActionsListOffset = (state) => {
  return state.secure.authState.adminActionPaginationOffset
}
const getResetPasswordVerificationRequestState = (state) => {
  return state.secure.authState.resetPasswordVerificationRequestState
}

const getResetPasswordEmailID = (state) => {
  return state.secure.authState.resetPasswordEmailID
}
const getResetPasswordRequestState = (state) => {
  return state.secure.authState.resetPasswordRequestState
}

const AuthSelectors = {
  getUser,
  isLoginLoading,
  getToken,
  getRefreshToken,
  isLoggedIn,
  getEditingAdminRole,
  getChangingPasswordRequestState,
  getAdminDetailsRequestState,
  getChangingUserNameRequestState,
  getAdminActionList,
  getAdminActionsFilters,
  getfetchAdminActionsRequestState,
  getAdminActionsListOffset,
  getResetPasswordVerificationRequestState,
  getResetPasswordEmailID,
  getResetPasswordRequestState,
}

export default AuthSelectors
