var base64 = require('base-64')

export const encodeObjectToBase64 = (object) => {
  const jsonString = JSON.stringify(object)
  const utf8Bytes = new TextEncoder().encode(jsonString)
  const byteString = String.fromCharCode(...utf8Bytes)
  return base64.encode(byteString)
}

export const decodeBase64ToObject = (base64Str) => {
  const decodedString = base64.decode(base64Str)
  const utf8Bytes = new Uint8Array(
    decodedString.split('').map((char) => char.charCodeAt(0)),
  )
  const jsonString = new TextDecoder().decode(utf8Bytes)
  return JSON.parse(jsonString)
}
