import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Styles from './TariffListItem.module.css'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import { useState } from 'react'
import { DoNotDisturbOn, AddCircle } from '@mui/icons-material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { isPropsMatch } from 'Utils/PropsMatch'
import styled from 'styled-components'
import InfoIcon from '@mui/icons-material/Info'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.TariffListItem.TooltipShadow}`,
    maxWidth: 'none',
  },
}))

const TariffListItem = ({
  _id,
  subscriptionName,
  subscriptionTime,
  tariffName,
  currentType,
  moneyTariff,
  pointsTariff,
  maxPower,
  energyConsumed,
  isEditing,
  onIconClick = () => {},
  onEnergyConsumedChange = () => {},
  hasPoints,
}) => {
  const { t } = useTranslation()
  const [isRemoveIcon, setIsRemoveIcon] = useState(false)
  const [energyConsumedEdit, setEnergyConsumedEdit] = useState(
    Number(energyConsumed),
  )

  const textFieldStyling = {
    '& .MuiInputBase-root::before': {
      borderBottom: `1px solid ${
        isRemoveIcon ? Colors.TariffListItem.DetailsGreyedOut : 'inherit'
      }`,
    },
    '& .MuiInputBase-root:hover::before': {
      borderBottom: `1px solid ${
        isRemoveIcon ? Colors.TariffListItem.DetailsGreyedOut : Colors.primary
      } !important`,
    },
    '& .MuiInputBase-root::after': {
      borderBottom: `2px solid ${
        isRemoveIcon ? Colors.TariffListItem.gray : Colors.primary
      }`,
    },
    '& .MuiInputBase-root.Mui-focused::after': {
      borderBottom: `2px solid ${
        isRemoveIcon ? Colors.TariffListItem.DetailsGreyedOut : Colors.primary
      } !important`,
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      color: isRemoveIcon ? Colors.TariffListItem.DetailsGreyedOut : 'inherit',
    },
  }

  useEffect(() => {
    if (!isEditing) setIsRemoveIcon(false)
  }, [isEditing])

  return (
    <div className={Styles.Container}>
      <div className={Styles.NameContainer}>
        <div
          className={Styles.SubscriptionNameContainer}
          style={{
            backgroundColor: isRemoveIcon
              ? Colors.TariffListItem.NameGreyedOut
              : Colors.primary,
          }}
        >
          <IFText style={{ color: Colors.white }} className={Styles.NameText}>
            {subscriptionName}
          </IFText>
        </div>
        <div
          className={Styles.TariffName}
          style={{
            border: `1.5px solid ${
              isRemoveIcon
                ? Colors.TariffListItem.NameGreyedOut
                : Colors.primary
            }`,
            borderLeftColor: isRemoveIcon ? 'transparent' : '',
          }}
        >
          <IFText
            style={
              isRemoveIcon
                ? { color: Colors.TariffListItem.DetailsGreyedOut }
                : { color: Colors.text }
            }
            className={Styles.NameText}
          >
            {tariffName}
          </IFText>
        </div>
        <div className={Styles.TooltipContainer}>
          <HtmlTooltip
            title={
              <div className={Styles.ToolTipWrapper}>
                <div className={Styles.RowContainer}>
                  <div
                    className={Styles.LeftColumnContainer}
                    style={{ color: Colors.text }}
                  >
                    <IFText className={Styles.ToolTipTitleText}>
                      {t('Tariffs.Time')}
                    </IFText>
                  </div>
                  <div
                    className={Styles.ToolTipSubtitleText}
                    style={{ color: Colors.text }}
                  >
                    <IFText>{subscriptionTime}</IFText>
                  </div>
                </div>
                <div className={Styles.RowContainer}>
                  <div
                    className={Styles.LeftColumnContainer}
                    style={{ color: Colors.text }}
                  >
                    <IFText className={Styles.ToolTipTitleText}>
                      {t('Tariffs.MaxPower')}
                    </IFText>
                  </div>
                  <div
                    className={Styles.ToolTipSubtitleText}
                    style={{ color: Colors.text }}
                  >
                    <IFText>{`${maxPower ? maxPower : 0} kW`}</IFText>
                  </div>
                </div>
              </div>
            }
            placement="right-start"
          >
            <InfoIcon
              style={
                isRemoveIcon
                  ? { color: Colors.TariffListItem.gray }
                  : { color: Colors.TariffListItem.Tooltip }
              }
              className={Styles.InfoIcon}
            />
          </HtmlTooltip>
        </div>
      </div>

      <div className={Styles.FieldContainer}>
        <IFText
          style={
            isRemoveIcon
              ? { color: Colors.TariffListItem.DetailsGreyedOut }
              : { color: Colors.text }
          }
        >
          {currentType}
        </IFText>
      </div>
      <div className={Styles.FieldContainer}>
        <IFText
          style={
            isRemoveIcon
              ? { color: Colors.TariffListItem.DetailsGreyedOut }
              : { color: Colors.text }
          }
        >
          {moneyTariff}
        </IFText>
      </div>
      {hasPoints && (
        <div className={Styles.FieldContainer}>
          <IFText
            style={
              isRemoveIcon
                ? { color: Colors.TariffListItem.DetailsGreyedOut }
                : { color: Colors.text }
            }
          >
            {pointsTariff}
          </IFText>
        </div>
      )}

      {isEditing && (
        <div className={Styles.EnergyConsumptionContainer}>
          <TextField
            id={`energyConsumptionId`}
            value={energyConsumedEdit}
            onChange={(e) => {
              if (Number(e.target.value) < 0) return
              setEnergyConsumedEdit(Number(e.target.value))
              onEnergyConsumedChange(_id, Number(e.target.value))
            }}
            variant="standard"
            sx={textFieldStyling}
            type="number"
            inputProps={{
              step: 'any',
              pattern: '[0-9]*[.,]?[0-9]*',
            }}
          />
        </div>
      )}

      {isEditing ? (
        <div
          className={Styles.IconContainer}
          onClick={() => {
            setIsRemoveIcon((prev) => {
              onIconClick(_id, !prev)
              return !prev
            })
          }}
        >
          {isRemoveIcon ? (
            <AddCircle
              className={Styles.Icon}
              style={{
                color: Colors.TariffListItem.AddIcon,
              }}
            />
          ) : (
            <DoNotDisturbOn
              className={Styles.Icon}
              style={{
                color: Colors.TariffListItem.RemoveIcon,
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}

TariffListItem.propTypes = {
  _id: PropTypes.string,
  subscriptionName: PropTypes.string,
  subscriptionTime: PropTypes.string,
  tariffName: PropTypes.string,
  currentType: PropTypes.number,
  moneyTariff: PropTypes.number,
  pointsTariff: PropTypes.number,
  maxPower: PropTypes.number,
  energyConsumed: PropTypes.number,
  isEditing: PropTypes.bool,
  onIconClick: PropTypes.func,
  onEnergyConsumedChange: PropTypes.func,
  hasPoints: PropTypes.bool,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    '_id',
    'subscriptionName',
    'subscriptionTime',
    'tariffName',
    'currentType',
    'moneyTariff',
    'pointsTariff',
    'maxPower',
    'energyConsumed',
    'isEditing',
    'onIconClick',
    'onEnergyConsumedChange',
    'hasPoints',
  ])
}
export default React.memo(TariffListItem, shouldSkipRender)
