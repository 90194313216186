const getFetchAppChargingTokensRequestState = (state) => {
  return state.main.appChargingTokenState.fetchAppChargingTokensRequestState
}
const getAppChargingTokens = (state) => {
  return state.main.appChargingTokenState.appChargingTokens
}
const getPaginationOffset = (state) => {
  return state.main.appChargingTokenState.paginationOffset
}

const getAppChargingTokenFilter = (state) => {
  return state.main.appChargingTokenState.appChargingTokenFilter
}
const getEditAppChargingTokenRequestState = (state) => {
  return state.main.appChargingTokenState.editAppChargingTokenRequestState
}
const getDeleteAppChargingTokenRequestState = (state) => {
  return state.main.appChargingTokenState.deleteAppChargingTokenRequestState
}
const getCreateAppChargingTokenRequestState = (state) => {
  return state.main.appChargingTokenState.createAppChargingTokenRequestState
}
const getCreateAppChargingTokenBulkRequestState = (state) => {
  return state.main.appChargingTokenState.createAppChargingTokenBulkRequestState
}
const getDownloadAppChargingTokenSampleFileRequestState = (state) => {
  return state.main.appChargingTokenState
    .downloadAppChargingTokenSampleFileRequestState
}
const getAppChargingTokensCountRequestState = (state) => {
  return state.main.appChargingTokenState.getAppChargingTokensCountRequestState
}
const getAppChargingTokenCount = (state) => {
  return state.main.appChargingTokenState.count
}
const AppChargingTokenSelectors = {
  getFetchAppChargingTokensRequestState,
  getAppChargingTokens,
  getPaginationOffset,
  getAppChargingTokenFilter,
  getEditAppChargingTokenRequestState,
  getDeleteAppChargingTokenRequestState,
  getCreateAppChargingTokenRequestState,
  getCreateAppChargingTokenBulkRequestState,
  getDownloadAppChargingTokenSampleFileRequestState,
  getAppChargingTokensCountRequestState,
  getAppChargingTokenCount,
}

export default AppChargingTokenSelectors
