import styles from './FundTransactionSessionDetail.module.css'
import { IFButton, IFText, IFDialog } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import { useRef } from 'react'
import PropTypes from 'prop-types'

const FundTransactionSessionDetail = ({
  row,
  onRefundClick,
  isRefundDisabled,
  editingAdminRole,
}) => {
  const showDialogHandler = useRef()
  const { t } = useTranslation()

  const canViewRefundTransaction = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_REFUND,
  )
  const refundText = row.original.isRefunded
    ? t('FundTransactionListItem.refunded')
    : t('FundTransactionListItem.notRefunded')
  const PaymentsDetails = [
    {
      key: 'Amount',
      value: row.original.isCredit
        ? `+${row.original.country?.currency?.code} ${Number(
            row.original.amount,
          ).toFixed(2)}`
        : `-${row.original.country?.currency?.code} ${Number(
            row.original.amount,
          ).toFixed(2)}`,
      translationKey: 'FundTransactionDetails.General.amount',
    },
    {
      key: 'isCredit',
      value: row.original.isCredit ? 'True' : 'False',
      translationKey: 'FundTransactionDetails.General.credit',
    },
    {
      key: 'isRefunded',
      value: row.original.isRefunded ? 'True' : 'False',
      translationKey: 'FundTransactionDetails.General.refunded',
    },
    {
      key: 'status',
      value: row.original.status,
      translationKey: 'FundTransactionDetails.General.status',
    },
  ]
  const QNBDetailItems = [
    {
      key: 'issuer',
      value: row.original.paymentIssuer,
      translationKey: 'FundTransactionDetails.General.issuer',
    },
    {
      key: 'fees',
      value: `${row.original.details?.fees} ${row.original.country?.currency?.code}`,
      translationKey: 'FundTransactionDetails.Qnb.fees',
    },
    {
      key: 'orderID',
      value: row.original.details?.orderId,
      translationKey: 'FundTransactionDetails.Qnb.orderId',
    },
    {
      key: 'paymentID',
      value: row.original.details?._id,
      translationKey: 'FundTransactionDetails.Qnb.paymentId',
    },
  ]
  const InternalDetailItems = [
    {
      key: 'issuer',
      value: row.original.paymentIssuer,
      translationKey: 'FundTransactionDetails.General.issuer',
    },
    {
      key: 'adminId',
      value: row.original.details?.adminId,
      translationKey: 'FundTransactionDetails.Internal.adminId',
    },
  ]
  const FawryDetailItems = [
    {
      key: 'issuer',
      value: row.original.paymentIssuer,
      translationKey: 'FundTransactionDetails.General.issuer',
    },
  ]
  const PaymentIssuerList =
    row.original.paymentIssuer === 'Internal'
      ? InternalDetailItems
      : row.original.paymentIssuer === 'QNB'
      ? QNBDetailItems
      : FawryDetailItems

  return (
    <div
      className={styles.TransactionDetails}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.Line}>
        <div
          style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
          className={styles.Divider}
        />
        <div
          style={{ background: Colors.DividerColor }}
          className={styles.Circle}
        />
      </div>
      <div className={styles.Details}>
        <div className={styles.ChargingSessionDetails}>
          <IFText className={styles.TransactionTitle}>
            {t('FundTransactionDetails.General.title')}
          </IFText>
          <div className={styles.AllDetails}>
            {PaymentsDetails.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  <IFText className={styles.DetailText}>{item.value}</IFText>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.ChargingSessionDetails}>
          <IFText className={styles.TransactionTitle}>
            {t('FundTransactionDetails.title')}
          </IFText>
          <div className={styles.AllDetails}>
            {PaymentIssuerList.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  <IFText className={styles.DetailText}>{item.value}</IFText>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {row.original.type === 'refund' ? (
        <div className={styles.RefundButtonContainer} />
      ) : (
        <>
          {canViewRefundTransaction && (
            <div className={styles.RefundButtonContainer}>
              <IFButton
                color={Colors.red}
                size="sm"
                isFill={false}
                isDead={isRefundDisabled}
                isLoading={false}
                text={refundText}
                onClick={(e) => {
                  e.stopPropagation()
                  showDialogHandler?.current?.show()
                }}
                className={styles.refundButton}
              />
              <IFDialog
                ref={showDialogHandler}
                open={false}
                maxWidth={'xs'}
                fullWidth={true}
                title={t('ChargingTransactionListItem.confirmRefundTitle')}
                bodyText={t('ChargingTransactionListItem.confirmRefund')}
                buttonAcceptonClick={onRefundClick}
                buttonCancelColor={Colors.UserPageCancel}
                buttonAcceptColor={Colors.UserPageDeactivateButton}
                buttonAcceptText={t('ChargingTransactionListItem.refundButton')}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

FundTransactionSessionDetail.propTypes = {
  row: PropTypes.object,
  onRefundClick: PropTypes.func,
  isRefundDisabled: PropTypes.bool,
  editingAdminRole: PropTypes.array,
}

export default FundTransactionSessionDetail
