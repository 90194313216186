import Styles from './AddForm.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { Form, Formik, Field, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { TextField, FormGroup, MenuItem } from '@mui/material'
import { object, string } from 'yup'
import { IFLoadingIndicator, IFButton, IFText } from 'Components'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { styled as muiStyled } from '@mui/system'
import ReactCountryFlag from 'react-country-flag'
import CountriesSelectors from 'Stores/Countries-Currencies/Selectors'
import CountriesActions from 'Stores/Countries-Currencies/Actions'
import RequestState from 'Enums/RequestState'

const Countries = require('world-countries/countries.json')

const StyledTextField = muiStyled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: Colors.grey,
      borderWidth: 1,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
      borderWidth: 1,
    },
  },
})

const initialValues = {
  countryName: '',
  countryCode: '',
  countryCurrency: '',
  countryBounds: '',
}

const FormContainer = styled.div`
  background-color: ${Colors.white};
`

const ErrorContainer = styled.div`
  color: ${Colors.red};
`

const AddCountryForm = ({
  onSubmitForm,
  addCountryRequestState,
  fetchAvailableCurrencies,
  availableCurrencies,
  fetchCreateCountryBounds,
  createCountryBounds,
  createCountryBoundsRequestState,
  availableCurrenciesRequestState,
}) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState()
  useEffect(() => {
    fetchAvailableCurrencies()
  }, [])
  return (
    <FormContainer className={Styles.FormWrappingDiv}>
      <div>
        <Formik
          validationSchema={object({
            countryName: Yup.string().required(t('CountriesPage.Required')),
            countryCode: string().required(t('CountriesPage.Required')),
            countryCurrency: string().required(t('CountriesPage.Required')),
            countryBounds: Yup.array()
              .of(string().required(t('CountriesPage.Required')))
              .required(t('CountriesPage.Required')),
          })}
          initialValues={initialValues}
          onSubmit={(values) => {
            const currency = availableCurrencies.find(
              (currency) => currency.name === values.countryCurrency,
            )
            const updatedValues = {
              ...values,
              countryName: selected.name.common,
              countryCurrency: currency.id,
            }
            onSubmitForm(updatedValues)
          }}
        >
          {({ values, errors, setFieldValue }) => {
            useEffect(() => {
              setFieldValue('countryBounds', createCountryBounds)
            }, [createCountryBounds])
            return (
              <Form>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="countryName">
                    {t('CountriesPage.CountryNameForm')}
                  </label>
                  <Field
                    className={Styles.Input}
                    id="standard-select-currency"
                    select
                    fullWidth
                    as={StyledTextField}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      const { value } = event.target
                      const country = Countries.find(
                        (country) => country.cca2 === value,
                      )
                      setSelected(country)
                      setFieldValue('countryName', value)
                      setFieldValue('countryCode', country.cca2)
                      fetchCreateCountryBounds(country.name.common)
                    }}
                    size="small"
                    name="countryName"
                    sx={{
                      '& .MuiInputBase-root.Mui-focused': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiSelect-select:focus': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiInputBase-root:hover::before': {
                        borderBottom: `1px solid ${Colors.primary} !important`,
                      },
                      '& .MuiInputBase-root.Mui-focused::after': {
                        borderBottom: `2px solid ${Colors.primary} !important`,
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 250,
                            maxWidth: 250,
                            overflow: 'scroll',
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      },
                    }}
                  >
                    {Countries.map((country) => (
                      <MenuItem key={country.cca2} value={country.cca2}>
                        <div className={Styles.DropDownItem}>
                          <ReactCountryFlag
                            className={Styles.FlagItem}
                            countryCode={country.cca2}
                            svg
                          />
                          <IFText className={Styles.DropDownText}>
                            {country.name.common}
                          </IFText>
                        </div>
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="countryName" />
                  </ErrorContainer>
                </FormGroup>
                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="countryCode">
                    {t('CountriesPage.CountryCodeForm')}
                  </label>
                  <TextField
                    disabled={true}
                    className={Styles.Input}
                    fullWidth
                    value={values.countryCode}
                    name="countryCode"
                    size="small"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        cursor: 'default !important',
                      },
                    }}
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="countryCode" />
                  </ErrorContainer>
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="countryBounds">
                    {t('CountriesPage.CountryBoundsForm')}
                  </label>
                  <TextField
                    type="text"
                    disabled={true}
                    fullWidth
                    name="countryBounds"
                    size="small"
                    value={values.countryBounds}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-input.Mui-disabled': {
                        cursor: 'default !important',
                      },
                    }}
                  />
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="countryBounds" />
                  </ErrorContainer>
                </FormGroup>

                <FormGroup className={Styles.InputWrappingDiv}>
                  <label htmlFor="countryCurrency">
                    {t('CountriesPage.CountryCurrencyForm')}
                  </label>
                  <Field
                    id="standard-select-currency"
                    select
                    variant="outlined"
                    fullWidth
                    name="countryCurrency"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    className={Styles.Input}
                    as={StyledTextField}
                    sx={{
                      '& .MuiInputBase-root.Mui-focused': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiSelect-select:focus': {
                        backgroundColor: 'transparent',
                      },
                      '& .MuiInputBase-root:hover::before': {
                        borderBottom: `1px solid ${Colors.primary} !important`,
                      },
                      '& .MuiInputBase-root.Mui-focused::after': {
                        borderBottom: `2px solid ${Colors.primary} !important`,
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: 250,
                            maxWidth: 250,
                            overflow: 'scroll',
                          },
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      },
                    }}
                  >
                    {availableCurrencies?.map((currency) => (
                      <MenuItem key={currency.name} value={currency.name}>
                        <IFText className={Styles.DropDownText}>
                          {currency.name}
                        </IFText>
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorContainer className={Styles.ErrorStyling}>
                    <ErrorMessage name="countryCurrency" />
                  </ErrorContainer>
                </FormGroup>

                <div className={Styles.ButtonDiv}>
                  <IFButton
                    className={Styles.ButtonWrappingDiv}
                    type="submit"
                    color={Colors.primary}
                    isDead={
                      !(
                        values.countryName &&
                        values.countryCode &&
                        values.countryCurrency &&
                        values.countryBounds.length === 4
                      ) ||
                      errors.countryName ||
                      errors.countryCode ||
                      errors.countryCurrency ||
                      errors.countryBounds ||
                      createCountryBoundsRequestState ===
                        RequestState.LOADING ||
                      availableCurrenciesRequestState === RequestState.LOADING
                    }
                    isFill={true}
                    isLoading={false}
                    text={
                      addCountryRequestState === RequestState.LOADING ? (
                        <div className={Styles.LoadingIndicator}>
                          <IFLoadingIndicator size={'2em'} />
                        </div>
                      ) : (
                        t('CountriesPage.Submit')
                      )
                    }
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </FormContainer>
  )
}

const mapStateToProps = (state) => ({
  createCountryBounds: CountriesSelectors.getCreateCountryBounds(state),
  createCountryBoundsRequestState:
    CountriesSelectors.getFetchCreateCountryBoundstRequestState(state),
  availableCurrencies: CountriesSelectors.getAvailableCurrencies(state),
  availableCurrenciesRequestState:
    CountriesSelectors.getAvailableCurrenciesRequestState(state),
  addCountryRequestState: CountriesSelectors.getAddCountryRequestState(state),
})
function mapDispatchToProps(dispatch) {
  return {
    fetchCreateCountryBounds: (countryName) =>
      dispatch(CountriesActions.fetchCreateCountryBounds(countryName)),
    fetchAvailableCurrencies: () =>
      dispatch(CountriesActions.fetchAvailableCurrencies()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCountryForm)
