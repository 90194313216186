import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchRoles: null,
  fetchRolesLoading: null,
  fetchRolesSuccess: ['roles'],
  fetchRolesError: ['error'],

  fetchRolesWithAdmins: ['offset', 'shouldShowError'],
  fetchRolesWithAdminsLoading: null,
  fetchRolesWithAdminsSuccess: ['offset', 'rolesWithAdmins', 'nextOffset'],
  fetchRolesWithAdminsError: ['error'],

  fetchRolesCount: null,
  fetchRolesCountLoading: null,
  fetchRolesCountSuccess: ['count'],
  fetchRolesCountFail: ['error'],

  updateRole: ['roleId', 'newRoleName', 'newRolePermissions'],
  updateRoleLoading: ['roleId'],
  updateRoleSuccess: ['role'],
  updateRoleFail: ['roleId'],

  createRole: ['role'],
  createRoleLoading: null,
  createRoleSuccess: ['role'],
  createRoleFail: null,

  deleteRole: ['roleId'],
  deleteRoleLoading: null,
  deleteRoleSuccess: ['roleId'],
  deleteRoleFail: ['error'],

  fetchRoleAdminList: ['roleId', 'offset'],
  fetchRoleAdminListLoading: null,
  fetchRoleAdminListSuccess: ['offset', 'admins', 'nextOffset'],
  fetchRoleAdminListFail: null,

  clearAssignedRoleAdmins: null,
  clearRoles: null,
})

export const RoleTypes = Types
export default Creators
