import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'

const startYear = 2021
const currentYear = new Date().getFullYear()
const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) =>
  (startYear + i).toString(),
)

export const INITIAL_STATE = {
  metrics: {},
  fetchDashboardRequestState: RequestState.UNINITIALIZED,
  dashboardFilters: [
    {
      type: IFFIlterType.SELECT,
      title: 'Year',
      data: years,
      initialValue: '',
      value: '',
      canClear: true,
      field: 'Date',
    },
  ],
}
