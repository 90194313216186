import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  AdminList,
  RoleList,
  AdminPageTabs,
  AdminInvitationsList,
} from 'Containers'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import RoleActions from 'Stores/Role/Actions'
import RequestState from 'Enums/RequestState'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import Styles from './AdminPage.module.css'
import { useTranslation } from 'react-i18next'
import Colors from 'Theme/Colors'
import RoleSelectors from 'Stores/Role/Selectors'
import CreateRole from './CreateRoleModal'
import InviteAdminModal from './InviteAdminModal'
import AuthSelectors from 'Stores/Auth/Selectors'
import AdminPermissions from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import LoadingBar from 'react-top-loading-bar'
import history from 'history/browser'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'
import AuthActions from 'Stores/Auth/Actions'

const AdminPage = ({
  fetchAdminList,
  fetchAdminListRequestState,
  adminsFilters,
  roles,
  AdminCount,
  RoleCount,
  fetchAdminsCount,
  fetchRoleCount,
  createRole,
  createRoleRequestState,
  loggedInAdmin,
  fetchRoles,
  admins,
  clearAdmins,
  inviteAdmin,
  inviteAdminRequestState,
  fetchInviteAdminList,
  inviteAdmins,
  fetchAdminsInvitationsCount,
  adminsInvitationsCount,
  fetchAdminsCountRequestState,
  fetchRolesCountRequestState,
  fetchRolesWithAdmins,
  rolesWithAdmins,
  fetchEditingAdminRole,
  editingAdminRole,
  clearRoles,
  defaultAdminsFilters,
  fetchInviteAdminListRequestState,
  fetchRolesWithAdminsRequestState,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const tabParam = parseInt(searchParams.get('tab'), 10)
    return !isNaN(tabParam) &&
      (tabParam === 0 || tabParam === 1 || tabParam === 2)
      ? tabParam
      : 0
  })

  const [firstLoad, setFirstLoad] = useState(true)
  const createRoleModalRef = useRef()
  const inviteAdminModalRef = useRef()
  const canEditRoles = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_CREATE_ROLES,
  )
  const canInviteAdmin = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_INVITE_ADMIN,
  )
  const [progress, setProgress] = useState(0)
  const roletablesRef = useRef(null)
  const admintablesRef = useRef(null)
  const invitationtablesRef = useRef(null)
  const [tablesHeight, setTablesHeight] = useState(0)
  const [roleTablesHeight, setRoleTablesHeight] = useState(0)
  const [invitationTablesHeight, setInvitationTablesHeight] = useState(0)
  const adminFilterRef = useRef(null)
  const [fetchAdminListLoadingState, setFetchAdminListLoadingState] =
    useState(true)
  useEffect(() => {
    if (
      fetchAdminListRequestState === RequestState.SUCCEEDED ||
      fetchInviteAdminListRequestState === RequestState.SUCCEEDED ||
      fetchRolesWithAdminsRequestState === RequestState.SUCCEEDED
    ) {
      setFetchAdminListLoadingState(false)
    }
  }, [
    fetchAdminListRequestState,
    fetchInviteAdminListRequestState,
    fetchRolesWithAdminsRequestState,
  ])
  useEffect(() => {
    if (selectedTabIndex === 0) {
      fetchRoles()
      fetchAdminsCount()
      fetchAdminList(0)
      fetchEditingAdminRole()
      clearRoles()
    } else if (selectedTabIndex === 1) {
      fetchInviteAdminList(0)
      clearRoles()
    } else if (selectedTabIndex === 2) {
      fetchRolesWithAdmins(0)
    }
  }, [selectedTabIndex])

  useEffect(() => {
    if (fetchAdminListRequestState === RequestState.SUCCEEDED && firstLoad) {
      setFirstLoad(false)
    }
  }, [fetchAdminListRequestState, fetchAdminsCountRequestState, firstLoad])

  useEffect(() => {
    fetchAdminsCount()
    fetchRoleCount()
    fetchAdminsInvitationsCount()
  }, [])

  useEffect(() => {
    if (
      firstLoad &&
      fetchAdminListRequestState === RequestState.LOADING &&
      progress === 0
    ) {
      setProgress(progress + 10)
    }

    if (firstLoad && fetchAdminListRequestState === RequestState.SUCCEEDED) {
      setProgress(100)
    }
    if (
      firstLoad &&
      fetchAdminListRequestState !== RequestState.LOADING &&
      fetchAdminListRequestState !== RequestState.UNINITIALIZED &&
      fetchAdminListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchAdminListRequestState])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('tab', selectedTabIndex)

    history.push({
      pathname: '/admin',
      search: searchParams.toString(),
    })
  }, [selectedTabIndex])

  const handleTabChange = (newValue) => {
    clearAdmins()
    if (newValue === 0) {
      history.push({
        pathname: '/admin',
        search: `?filter=${encodeObjectToBase64(defaultAdminsFilters)}`,
      })
    }
    setSelectedTabIndex(newValue)
  }

  const handleFilterChange = (newFilter) => {
    history.push({
      pathname: '/admin',
      search: `?filter=${encodeObjectToBase64(newFilter)}`,
    })
    fetchAdminList(0)
    fetchAdminsCount()
  }

  const handleCreateNewRoleClick = () => {
    createRoleModalRef.current.show()
  }
  const handleInviteAdminClick = () => {
    inviteAdminModalRef.current.show()
  }
  useEffect(() => {
    if (createRoleRequestState === RequestState.SUCCEEDED) {
      if (createRoleModalRef.current) {
        createRoleModalRef.current.dismiss()
      }
    }
  }, [createRoleRequestState])

  useEffect(() => {
    if (inviteAdminRequestState === RequestState.SUCCEEDED) {
      if (inviteAdminModalRef.current) {
        inviteAdminModalRef.current.dismiss()
      }
    }
  }, [inviteAdminRequestState])

  const onSubmitCreateRoleHandler = (role) => {
    createRole(role)
  }

  const { t } = useTranslation()

  const onSubmitInviteAdminHandler = (inviteAdminData) => {
    inviteAdmin(inviteAdminData)
  }

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (admintablesRef.current) {
      resizeObserver.observe(admintablesRef.current)
    }

    return () => {
      if (admintablesRef.current) {
        resizeObserver.unobserve(admintablesRef.current)
      }
    }
  })

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setRoleTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (roletablesRef.current) {
      resizeObserver.observe(roletablesRef.current)
    }

    return () => {
      if (roletablesRef.current) {
        resizeObserver.unobserve(roletablesRef.current)
      }
    }
  })
  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setInvitationTablesHeight(height)
      }
    }

    const resizeObserver = new ResizeObserver(handleResize)

    if (invitationtablesRef.current) {
      resizeObserver.observe(invitationtablesRef.current)
    }

    return () => {
      if (invitationtablesRef.current) {
        resizeObserver.unobserve(invitationtablesRef.current)
      }
    }
  })

  return (
    <div className={Styles.Wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div
        className={Styles.AdminsContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.TabButtonDiv}>
          <AdminPageTabs
            onValueChange={handleTabChange}
            initialIndex={selectedTabIndex}
            isLoading={
              fetchAdminListLoadingState &&
              (fetchAdminListRequestState === RequestState.LOADING ||
                fetchAdminsCountRequestState === RequestState.LOADING)
            }
            adminFilter={adminsFilters}
            AdminCount={AdminCount}
            fetchListRequestState={
              fetchAdminsCountRequestState ||
              fetchAdminsInvitationsCount ||
              fetchRolesCountRequestState
            }
            RoleCount={RoleCount}
            InvitationCount={adminsInvitationsCount}
          />
          {(canEditRoles || canInviteAdmin) && (
            <div className={Styles.InviteAdminDiv}>
              {firstLoad &&
              fetchAdminListRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <>
                  {selectedTabIndex !== 2 && canInviteAdmin && (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('AdminPage.InviteAdminButton')}
                      className={Styles.InviteAdminButton}
                      onClick={handleInviteAdminClick}
                    />
                  )}
                  {selectedTabIndex === 2 && canEditRoles && (
                    <IFButton
                      size="sm"
                      color={Colors.primary}
                      isFill={false}
                      isLoading={false}
                      text={t('AdminPage.NewRole')}
                      className={Styles.InviteAdminButton}
                      onClick={handleCreateNewRoleClick}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        {selectedTabIndex === 0 && (
          <div className={Styles.AdminFilterDiv}>
            <IFFilter
              ref={adminFilterRef}
              onFilterChange={(newFilter) => handleFilterChange(newFilter)}
              filters={adminsFilters}
              textFieldPlaceholder={t('AdminPage.AdminFilterPlaceholder')}
              isLoading={fetchAdminListLoadingState}
            />
          </div>
        )}

        <Scrollbars className={Styles.ScrollParent}>
          {selectedTabIndex === 0 ? (
            <div className={Styles.Tables} ref={admintablesRef}>
              <AdminList
                tablesHeight={tablesHeight}
                admins={admins}
                roles={roles}
                loggedInAdmin={loggedInAdmin}
              />
            </div>
          ) : selectedTabIndex === 1 ? (
            <div className={Styles.Tables} ref={invitationtablesRef}>
              <AdminInvitationsList
                tablesHeight={invitationTablesHeight}
                inviteAdmins={inviteAdmins}
                loggedInAdmin={loggedInAdmin}
              />
            </div>
          ) : (
            <div className={Styles.Tables} ref={roletablesRef}>
              <RoleList
                roles={rolesWithAdmins}
                tablesHeight={roleTablesHeight}
                loggedInAdmin={loggedInAdmin}
              />
            </div>
          )}
        </Scrollbars>
        <IFModal title={t('AdminPage.CreateNewRole')} ref={createRoleModalRef}>
          <CreateRole
            onSubmitForm={(values) => onSubmitCreateRoleHandler(values)}
          />
        </IFModal>

        <IFModal title={t('AdminPage.InviteAdmin')} ref={inviteAdminModalRef}>
          <InviteAdminModal
            roles={roles}
            onSubmitForm={(values) => onSubmitInviteAdminHandler(values)}
          />
        </IFModal>
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAdminList: (offset, shouldShowError) =>
      dispatch(AdminActions.fetchAdminList(offset, shouldShowError)),
    fetchAdminsCount: () => dispatch(AdminActions.fetchAdminsCount()),
    fetchRoleCount: () => dispatch(RoleActions.fetchRolesCount()),
    createRole: (role) => dispatch(RoleActions.createRole(role)),
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
    clearAdmins: () => dispatch(AdminActions.clearAdmins()),
    inviteAdmin: (inviteAdminData) =>
      dispatch(AdminActions.inviteAdmin(inviteAdminData)),
    fetchInviteAdminList: (offset, shouldShowError) =>
      dispatch(AdminActions.fetchInviteAdminList(offset, shouldShowError)),
    fetchAdminsInvitationsCount: () =>
      dispatch(AdminActions.fetchAdminsInvitationsCount()),
    fetchRolesWithAdmins: (offset, shouldShowError) =>
      dispatch(RoleActions.fetchRolesWithAdmins(offset, shouldShowError)),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
    clearRoles: () => dispatch(RoleActions.clearRoles()),
    fetchRolesCountRequestState: () =>
      dispatch(RoleActions.fetchRolesCountRequestState()),
  }
}

const mapStateToProps = (state) => ({
  roles: RoleSelectors.getRoles(state),
  admins: AdminSelectors.getAdminList(state),
  inviteAdmins: AdminSelectors.getInviteAdmins(state),
  AdminCount: AdminSelectors.getAdminsCount(state),
  RoleCount: RoleSelectors.getRolesCount(state),
  loggedInAdmin: AuthSelectors.getUser(state),
  fetchAdminListRequestState:
    AdminSelectors.getfetchAdminListRequestState(state),
  adminsFilters: AdminSelectors.getAdminsFilters(state),
  createRoleRequestState: RoleSelectors.getCreateRoleRequestState(state),
  paginationOffset: AdminSelectors.getAdminListPaginationOffset(state),
  inviteAdminRequestState: AdminSelectors.getInviteAdminRequestState(state),
  adminsInvitationsCount: AdminSelectors.getAdminsInvitationsCount(state),
  fetchAdminsCountRequestState:
    AdminSelectors.getfetchAdminsCountRequestState(state),
  rolesWithAdmins: RoleSelectors.getRolesWithAdmins(state),
  rolesPaginationOffset: RoleSelectors.getRolesPaginationOffset(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  defaultAdminsFilters: AdminSelectors.getDefaultAdminsFilters(state),
  fetchRolesWithAdminsRequestState:
    RoleSelectors.getfetchRolesWithAdminsRequestState(state),
  fetchInviteAdminListRequestState:
    AdminSelectors.getFetchInviteAdminListRequestState(state),
})

AdminPage.propTypes = {
  fetchAdminList: PropTypes.func,
  fetchAdminListRequestState: PropTypes.number,
  adminsFilters: PropTypes.object,
  roles: PropTypes.array,
  AdminCount: PropTypes.number,
  RoleCount: PropTypes.number,
  fetchAdminsCount: PropTypes.func,
  fetchRoleCount: PropTypes.func,
  createRole: PropTypes.func,
  createRoleRequestState: PropTypes.number,
  loggedInAdmin: PropTypes.object,
  fetchRoles: PropTypes.func,
  admins: PropTypes.array,
  clearAdmins: PropTypes.func,
  inviteAdmin: PropTypes.func,
  inviteAdminRequestState: PropTypes.number,
  fetchInviteAdminList: PropTypes.func,
  inviteAdmins: PropTypes.array,
  fetchAdminsInvitationsCount: PropTypes.func,
  adminsInvitationsCount: PropTypes.number,
  admins: PropTypes.array,
  clearAdmins: PropTypes.func,
  paginationOffset: PropTypes.number,
  fetchAdminsCountRequestState: PropTypes.number,
  fetchRolesWithAdmins: PropTypes.func,
  rolesWithAdmins: PropTypes.array,
  rolesPaginationOffset: PropTypes.number,
  fetchEditingAdminRole: PropTypes.func,
  editingAdminRole: PropTypes.array,
  clearRoles: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
