import React, { useState, useRef, useEffect } from 'react'
import Styles from './ReverseTransactionModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { TextField, MenuItem } from '@mui/material'
import InfinityEnums from 'Enums/InfinityEnums'
import { IFButton, IFDialog, IFText } from 'Components'
import { TariffList } from 'Containers'
import TransactionActions from 'Stores/Transaction/Actions'
import TransactionSelectors from 'Stores/Transaction/Selectors'
import { connect } from 'react-redux'
import { formatTime } from 'Utils/StringFunctions'

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

const formatAllSubscriptions = (subscriptions, hasPoints) => {
  const tariffMap = new Map()

  subscriptions.forEach((subscription) => {
    subscription.timeSlots.tariffs.forEach((tariff) => {
      const key = `${tariff._id}`
      const timeRange = `${formatTime(
        new Date(subscription.timeSlots.startTime),
      )} to ${formatTime(new Date(subscription.timeSlots.endTime))}`

      if (tariffMap.has(key)) {
        const existingTariff = tariffMap.get(key)
        existingTariff.timeRanges.push(timeRange)
      } else {
        tariffMap.set(key, {
          name: `${subscription.name}.${tariff.name}`,
          _id: {
            tariffId: tariff._id,
            subId: subscription._id,
          },
          subscriptionName: subscription.name,
          timeRanges: [timeRange],
          tariffName: tariff.name,
          currentType: tariff.currentType,
          moneyTariff: tariff.pricePerWh,
          pointsTariff: tariff.pointsPerWh,
          maxPower: tariff.maxPower || 0,
          energyConsumed: 0,
          isEditing: false,
          onIconClick: () => {},
          hasPoints: hasPoints,
        })
      }
    })
  })

  return Array.from(tariffMap.values()).map((tariff) => ({
    ...tariff,
    subscriptionTime: tariff.timeRanges.join(', '),
  }))
}

const formatSubscriptions = (subscriptions) => {
  return subscriptions?.map((sub) => {
    const timeSlots = sub.subscription.timeSlots.filter((slot) =>
      slot.tariffs.includes(sub.tariff._id),
    )

    const formattedTimes = timeSlots
      .map((slot) => {
        const startTime = new Date(slot.startTime)
        const endTime = new Date(slot.endTime)
        return `${formatTime(startTime)} to ${formatTime(endTime)}`
      })
      .join(', ')

    return {
      _id: {
        tariffId: sub.tariff._id,
        subId: sub.subscription._id,
      },
      subscriptionName: sub.subscription.name,
      subscriptionTime: formattedTimes || null,
      tariffName: sub.tariff.name,
      currentType: sub.tariff.currentType,
      moneyTariff: sub.tariff.pricePerWh,
      pointsTariff: sub.tariff.pointsPerWh,
      maxPower: sub.tariff.maxPower,
      energyConsumed: sub.consumption,
    }
  })
}

const ReverseTransactionModal = ({
  onSubmit,
  isReverseTransactionLoading,
  subscriptions,
  fetchAllSubscriptions,
  allSubscriptions,
  hasPoints,
  countryId,
}) => {
  const { t } = useTranslation()
  const [allSubscriptionsFormatted, setAllSubscriptionsFormatted] = useState([])
  const [subscriptionsFormatted, setSubscriptionsFormatted] = useState([])
  const [isDead, setIsDead] = useState(false)
  const [finalTariffs, setFinalTariffs] = useState({})

  useEffect(() => {
    fetchAllSubscriptions(countryId)
  }, [])

  useEffect(() => {
    setAllSubscriptionsFormatted(
      formatAllSubscriptions(allSubscriptions, hasPoints),
    )
  }, [allSubscriptions])

  useEffect(() => {
    setSubscriptionsFormatted(formatSubscriptions(subscriptions))
  }, [subscriptions])

  const [reverseType, setReverseType] = useState('')
  const confirmReverseDialog = useRef()
  const tariffListRef = useRef()

  const onChange = (value) => {
    setIsDead(value)
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.FieldsWrapper}>
        <div className={Styles.TextFieldContainer}>
          <CssTextField
            name="reverseType"
            className={Styles.Input}
            variant="outlined"
            size="small"
            as={CssTextField}
            InputLabelProps={{ shrink: true }}
            value={reverseType}
            select
            label={t('ReverseTransactionModal.ReverseTransactionType')}
            onChange={(data) => {
              setReverseType(data.target.value)
            }}
          >
            {Object.values(InfinityEnums.TransactionReverseType).map(
              (type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ),
            )}
          </CssTextField>
        </div>
        <div
          className={
            reverseType === InfinityEnums.TransactionReverseType.CORRECT
              ? Styles.ShowContainer
              : Styles.HideContainer
          }
        >
          <div className={Styles.TariffContainer}>
            <div className={Styles.HeadersContainer}>
              <div className={Styles.SubscriptionsContainer}>
                <IFText className={Styles.HeaderText}>
                  {t('Tariffs.Subscriptions')}
                </IFText>
              </div>
              <div className={Styles.HeaderContainer}>
                <IFText className={Styles.HeaderText}>
                  {t('Tariffs.CurrentType')}
                </IFText>
              </div>
              <div className={Styles.HeaderContainer}>
                <IFText className={Styles.HeaderText}>
                  {t('Tariffs.MoneyTariff')}
                </IFText>
              </div>
              {hasPoints && (
                <div className={Styles.HeaderContainer}>
                  <IFText className={Styles.HeaderText}>
                    {t('Tariffs.PointsTariff')}
                  </IFText>
                </div>
              )}
              <div className={Styles.EnergyConsumptionContainer}>
                <IFText className={Styles.HeaderText}>
                  {t('Tariffs.EnergyConsumed')}
                </IFText>
              </div>
              <div className={Styles.IconContainer} />
            </div>

            <div style={{ marginTop: '0.5rem', height: '100%' }}>
              <TariffList
                ref={tariffListRef}
                displayedList={subscriptionsFormatted}
                dropdownList={allSubscriptionsFormatted}
                isEditing={true}
                onSubmit={(value) => setFinalTariffs(value)}
                onDataChange={(value) => onChange(value)}
                hasPoints={hasPoints}
              />
            </div>
          </div>
        </div>

        <div className={Styles.ButtonContainer}>
          <IFButton
            className={Styles.SubmitButton}
            type="submit"
            color={Colors.primary}
            isDead={
              reverseType === InfinityEnums.TransactionReverseType.REVERSE
                ? false
                : !isDead && !isReverseTransactionLoading
            }
            isFill={true}
            isLoading={false}
            text={t('ReverseTransactionModal.SubmitText')}
            onClick={() => {
              tariffListRef.current.handleSubmit()
              confirmReverseDialog.current.show()
            }}
          />
        </div>
      </div>
      <IFDialog
        ref={confirmReverseDialog}
        style={{ margin: '15em' }}
        open={false}
        title={
          reverseType === InfinityEnums.TransactionReverseType.CORRECT
            ? t('ReverseTransactionModal.ConfirmDialogTitleCorrect')
            : t('ReverseTransactionModal.ConfirmDialogTitleReverse')
        }
        bodyText={
          reverseType === InfinityEnums.TransactionReverseType.CORRECT
            ? t('ReverseTransactionModal.ConfirmDialogSubtitleCorrect')
            : t('ReverseTransactionModal.ConfirmDialogSubtitleReverse')
        }
        buttonAcceptonClick={() => {
          onSubmit({
            tariffs: finalTariffs,
            reverseType: reverseType,
          })
        }}
        buttonCancelColor={Colors.red}
        buttonAcceptColor={Colors.primary}
        buttonAcceptText={t('ReverseTransactionModal.ConfirmDialogButton')}
        buttonClassName={Styles.Button}
        isLoading={isReverseTransactionLoading}
        dismissOnAccept={false}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  allSubscriptions: TransactionSelectors.getAllSubscriptions(state),
})

function mapDispatchToProps(dispatch) {
  return {
    fetchAllSubscriptions: (countryId) =>
      dispatch(TransactionActions.fetchAllSubscriptions(countryId)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReverseTransactionModal)
