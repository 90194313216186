import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  IFTable,
  IFText,
  IFAvatarList,
  IFsvg,
  IFModal,
  IFDialog,
  IFButton,
} from 'Components'
import RoleSelectors from 'Stores/Role/Selectors'
import RoleActions from 'Stores/Role/Actions'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import { Colors } from 'Theme'
import Styles from './RoleList.module.css'
import { Menu, MenuItem, Fade, Tooltip, IconButton } from '@mui/material'
import { capitalizeFirstLetter } from '../../Utils/StringFunctions'
import RoleDetails from 'Containers/RoleDetails/RoleDetails'
import ReportIcon from '@mui/icons-material/Report'
import AuthSelectors from 'Stores/Auth/Selectors'
import AdminPermissions from 'Enums/InfinityEnums'
import AuthActions from 'Stores/Auth/Actions'
import moment from 'moment'

const RoleList = ({
  roles,
  loggedInAdmin,
  tablesHeight,
  rolesPaginationOffset,
  fetchRolesWithAdmins,
  fetchRolesWithAdminsRequestState,
  deleteRole,
  deleteRoleRequestState,
  clearAssignedRoleAdmins,
  editingAdminRole,
  fetchEditingAdminRole,
}) => {
  const [failedRequest, setFailedRequest] = useState(false)
  const { t } = useTranslation()
  const modalRef = useRef()
  const deleteModalRef = useRef()
  const [menuState, setMenuState] = useState({ open: false, role: null })
  const [selectedRole, setSelectedRole] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [registeredCountries, setRegisteredCountries] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })
  const canEditRoles = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_EDIT_ROLES,
  )

  useEffect(() => {
    if (loggedInAdmin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
    }
  }, [loggedInAdmin])

  useEffect(() => {
    setColumnVisibility({
      country: registeredCountries,
    })
  }, [registeredCountries])
  const handleClick = (event, role) => {
    setMenuState({ open: true, role: role })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setMenuState({ open: false, role: null })
    setAnchorEl(null)
  }

  const handleMenuItemClick = () => {
    if (menuState.role) {
      setSelectedRole(menuState.role)
      modalRef.current.show()
      handleClose()
    }
  }

  const onDeleteClickHandler = () => {
    if (menuState.role) {
      setSelectedRole(menuState.role)
      deleteModalRef.current.show()
      handleClose()
    }
  }
  const handleDeleteRole = () => {
    if (selectedRole) {
      deleteRole(selectedRole._id)
    }
  }
  useEffect(() => {
    if (deleteRoleRequestState === RequestState.SUCCEEDED) {
      deleteModalRef.current.dismiss()
    }
  }, [deleteRoleRequestState])

  const customDialogContentStyle = {
    height: '600px',
    width: '1150px',
  }

  const loadMoreData = () => {
    if (
      fetchRolesWithAdminsRequestState === RequestState.LOADING ||
      rolesPaginationOffset === null
    )
      return
    fetchRolesWithAdmins(rolesPaginationOffset, failedRequest)
  }
  useEffect(() => {
    if (
      fetchRolesWithAdminsRequestState === RequestState.ERROR_0_NETWORK ||
      fetchRolesWithAdminsRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchRolesWithAdminsRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchRolesWithAdminsRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchRolesWithAdminsRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchRolesWithAdminsRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchRolesWithAdminsRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchRolesWithAdminsRequestState])

  const handleRetry = () => {
    fetchRolesWithAdmins(rolesPaginationOffset)
  }

  const roleTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header:
          fetchRolesWithAdminsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Name')
          ),
        Cell: ({ row }) => {
          return row.index < roles.length ? (
            <IFText>{capitalizeFirstLetter(row.original.name)}</IFText>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'admins',
        header:
          fetchRolesWithAdminsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="55px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Admins')
          ),
        Cell: ({ row }) => {
          return row.index < roles.length ? (
            <div>
              <IFAvatarList
                admins={row.original.admins}
                totalAdmins={row.original.adminsCount}
                avatarSize={27}
                maxDisplayed={3}
              />
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'permissions',
        header:
          fetchRolesWithAdminsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="55px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.Permissions')
          ),
        Cell: ({ row }) => {
          return row.index < roles.length ? (
            <IFText>{row.original.permissionsCount}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'country',
        header:
          fetchRolesWithAdminsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('RoleList.Country')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < roles.length ? (
            <IFText>{row.original.country.name}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'updatedOn',
        header:
          fetchRolesWithAdminsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
              style={{ Left: '40px' }}
            />
          ) : (
            t('RoleList.UpdatedOn')
          ),
        Cell: ({ row, index }) => {
          return row.index < roles.length ? (
            <div className={Styles.UpdateOnRoleColumn}>
              <div className={Styles.UpdateOnRoleTable}>
                <IFText>
                  {moment(row.original.updatedAt).format('MMM DD, YYYY LTS')}
                </IFText>
              </div>

              <div>
                <Tooltip title={t('RoleList.MoreInfo')}>
                  <IconButton
                    onClick={(event) => handleClick(event, row.original)}
                  >
                    <IFsvg.MoreInfo
                      className={Styles.MoreInfo}
                      height={24}
                      width={24}
                      fill={Colors.primary}
                    />
                  </IconButton>
                </Tooltip>

                {menuState.open && menuState.role === row.original && (
                  <Menu
                    id={`simple-menu-${index}`}
                    anchorEl={anchorEl}
                    keepMounted
                    open={menuState.open && menuState.role === row.original}
                    onClose={handleClose}
                    elevation={2}
                    TransitionComponent={Fade}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: 'transparent !important',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: `${Colors.HoverDeactivate} !important`,
                        },
                      }}
                      onClick={handleMenuItemClick}
                    >
                      <IFText
                        style={{
                          color: Colors.primary,
                        }}
                      >
                        {t('RoleList.MoreInfo')}
                      </IFText>
                    </MenuItem>

                    {row.original.adminsCount == 0 && canEditRoles ? (
                      <MenuItem
                        sx={{
                          backgroundColor: 'transparent !important',
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: `${Colors.HoverDeactivate} !important`,
                          },
                        }}
                        onClick={onDeleteClickHandler}
                      >
                        <IFText
                          style={{
                            color: Colors.red,
                          }}
                        >
                          {t('RoleList.deleteModalConfirm')}
                        </IFText>
                      </MenuItem>
                    ) : null}
                  </Menu>
                )}
              </div>
            </div>
          ) : failedRequest ? (
            <IFButton
              size="sm"
              color={Colors.red}
              text={t('IFTable.TryAgain')}
              onClick={handleRetry}
            />
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [
      handleClick,
      handleClose,
      handleMenuItemClick,
      t,
      menuState,
      fetchRolesWithAdminsRequestState,
      failedRequest,
    ],
  )

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )
  return (
    <>
      <IFTable
        columns={roleTableColumns}
        data={roles}
        hideColumnQuery={columnVisibility}
        paginationOffset={rolesPaginationOffset}
        fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        tableMaxHeight={tablesHeight}
        tableSetHeight={'100%'}
        showSkeleton={
          fetchRolesWithAdminsRequestState === RequestState.LOADING ||
          (fetchRolesWithAdminsRequestState !== RequestState.SUCCEEDED &&
            rolesPaginationOffset === 0)
        }
        tableRequestState={fetchRolesWithAdminsRequestState}
      />

      <IFModal
        ref={modalRef}
        title={t('RoleDetails.RoleDetails')}
        maxWidth={60}
        dialogContentStyle={customDialogContentStyle}
        className={Styles.RoleDetailsModal}
        onDismiss={() => {
          clearAssignedRoleAdmins()
        }}
      >
        <RoleDetails
          role={selectedRole}
          isLoading={fetchRolesWithAdminsRequestState === RequestState.LOADING}
        />
      </IFModal>

      <IFDialog
        ref={deleteModalRef}
        style={{ margin: '15em' }}
        open={false}
        title={t('RoleList.deleteModalTitle')}
        bodyText={t('RoleList.deleteModalText')}
        buttonAcceptonClick={handleDeleteRole}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('RoleList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteRoleRequestState === RequestState.LOADING}
        dismissOnAccept={false}
      />
    </>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchRolesWithAdmins: (offset, shouldShowError) =>
      dispatch(RoleActions.fetchRolesWithAdmins(offset, shouldShowError)),
    deleteRole: (roleId) => dispatch(RoleActions.deleteRole(roleId)),
    clearAssignedRoleAdmins: () =>
      dispatch(RoleActions.clearAssignedRoleAdmins()),
    fetchEditingAdminRole: () => dispatch(AuthActions.fetchEditingAdminRole()),
  }
}

const mapStateToProps = (state) => ({
  rolesPaginationOffset: RoleSelectors.getRolesPaginationOffset(state),
  fetchRolesWithAdminsRequestState:
    RoleSelectors.getfetchRolesWithAdminsRequestState(state),
  deleteRoleRequestState: RoleSelectors.getDeleteRoleRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})

RoleList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object),
  loggedInAdmin: PropTypes.object,
  tablesHeight: PropTypes.number,
  rolesPaginationOffset: PropTypes.number,
  fetchRolesWithAdmins: PropTypes.func,
  fetchRolesWithAdminsRequestState: PropTypes.number,
  deleteRole: PropTypes.func,
  deleteRoleRequestState: PropTypes.number,
  clearAssignedRoleAdmins: PropTypes.func,
  editingAdminRole: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleList)
