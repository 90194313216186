import RequestState from 'Enums/RequestState'
/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
  appChargingTokenDesigns: [],
  allAppChargingTokenDesigns: [],
  fetchAppChargingTokenDesignsState: RequestState.UNINITIALIZED,
  fetchAllAppChargingTokenDesignsRequestState: RequestState.UNINITIALIZED,
  addAppChargingTokenDesignRequestState: RequestState.UNINITIALIZED,
  editAppChargingTokenDesignRequestState: RequestState.UNINITIALIZED,
  getAppChargingTokenCountRequestState: RequestState.UNINITIALIZED,
  deleteAppChargingTokenDesignRequestState: RequestState.UNINITIALIZED,
  paginationOffset: 0,
  count: 0,
}
