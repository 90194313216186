import { INITIAL_STATE } from './initialState'
import { AppChargingTokenTypes } from './Actions'
import { createReducer } from 'reduxsauce'
import RequestState from 'Enums/RequestState'
import IFFIlterType from 'Enums/IFFilterType'
import history from 'history/browser'
import { encodeObjectToBase64 } from '../../Utils/Base64Functions'
import InfinityEnums from 'Enums/InfinityEnums'

export const fetchAppChargingTokensLoading = (state) => {
  return {
    ...state,
    fetchAppChargingTokensRequestState: RequestState.LOADING,
  }
}

export const fetchAppChargingTokensSuccess = (
  state,
  { offset, appChargingTokens, nextOffset, filterObject },
) => {
  const onAppChargingTokenPage =
    window.location.pathname
      .toLocaleLowerCase()
      .indexOf('appchargingtokens') !== -1 ||
    history.location.pathname
      .toLocaleLowerCase()
      .indexOf('appchargingtokens') !== -1

  if (onAppChargingTokenPage) {
    if (offset === 0) {
      history.push({
        search: `?filter=${encodeObjectToBase64(filterObject)}&tab=${0}`,
      })
      return {
        ...state,
        appChargingTokens: appChargingTokens.map((appChargingTokens) => ({
          ...appChargingTokens,
        })),
        fetchAppChargingTokensRequestState: RequestState.SUCCEEDED,
        paginationOffset: nextOffset,
        appChargingTokenFilter: filterObject,
      }
    } else {
      return {
        ...state,
        appChargingTokens: [
          ...state.appChargingTokens,
          ...appChargingTokens.map((appChargingTokens) => ({
            ...appChargingTokens,
          })),
        ],
        fetchAppChargingTokensRequestState: RequestState.SUCCEEDED,
        paginationOffset: nextOffset,
      }
    }
  } else {
    return {
      ...state,
      fetchAppChargingTokensRequestState: RequestState.UNINITIALIZED,
    }
  }
}

export const fetchAppChargingTokensError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAppChargingTokensRequestState: requestState,
  }
}

export const editAppChargingTokenLoading = (state) => {
  return {
    ...state,
    editAppChargingTokenRequestState: RequestState.LOADING,
  }
}

export const editAppChargingTokenSuccess = (state, { appChargingToken }) => {
  const updatedAppChragingToken = state.appChargingTokens.map(
    (oldAppChargingToken) => {
      return oldAppChargingToken._id ===
        appChargingToken.updatedAppChargingToken._id
        ? {
            ...oldAppChargingToken,
            uid: appChargingToken.updatedAppChargingToken.uid,
            visualNumber: appChargingToken.updatedAppChargingToken.visualNumber,
            design: appChargingToken.updatedAppChargingToken.design,
            reference: appChargingToken.updatedAppChargingToken.reference,
            parentId: appChargingToken.updatedAppChargingToken.parentId,
            voucherCode: appChargingToken.updatedAppChargingToken.voucherCode,
          }
        : oldAppChargingToken
    },
  )
  return {
    ...state,
    appChargingTokens: updatedAppChragingToken,
    editAppChargingTokenRequestState: RequestState.SUCCEEDED,
  }
}

export const editAppChargingTokenError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    editAppChargingTokenRequestState: requestState,
  }
}

export const deleteAppChargingTokenLoading = (state) => {
  return {
    ...state,
    deleteAppChargingTokenRequestState: RequestState.LOADING,
  }
}
export const deleteAppChargingTokenSuccess = (
  state,
  { appChargingTokenId },
) => {
  const updatedAppChargingTokenList = state.appChargingTokens.filter(
    (appChargingToken) => appChargingToken._id !== appChargingTokenId,
  )
  return {
    ...state,
    appChargingTokens: updatedAppChargingTokenList,
    deleteAppChargingTokenRequestState: RequestState.SUCCEEDED,
    count: state.count - 1 < 0 ? 0 : state.count - 1,
  }
}

export const deleteAppChargingTokenError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteAppChargingTokenRequestState: requestState,
  }
}

export const clearAppChargingTokens = (state) => {
  return {
    ...state,
    appChargingTokens: [],
    fetchAppChargingTokensRequestState: RequestState.UNINITIALIZED,
  }
}
export const setDeleteAppChargingTokenRequestState = (state, { newState }) => {
  return {
    ...state,
    deleteAppChargingTokenRequestState: newState,
  }
}

export const createAppChargingTokenLoading = (state) => {
  return {
    ...state,
    createAppChargingTokenRequestState: RequestState.LOADING,
  }
}

export const createAppChargingTokenSuccess = (state, { appChargingToken }) => {
  return {
    ...state,
    appChargingTokens: [appChargingToken, ...state.appChargingTokens],
    createAppChargingTokenRequestState: RequestState.SUCCEEDED,
    count: state.count + 1,
  }
}

export const createAppChargingTokenError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createAppChargingTokenRequestState: requestState,
  }
}

export const createAppChargingTokenBulkLoading = (state) => {
  return {
    ...state,
    createAppChargingTokenBulkRequestState: RequestState.LOADING,
  }
}
export const createAppChargingTokenBulkSuccess = (
  state,
  { appChargingTokens },
) => {
  return {
    ...state,
    appChargingTokens: [
      ...appChargingTokens,
      ...(state.appChargingTokens || []),
    ],
    createAppChargingTokenBulkRequestState: RequestState.SUCCEEDED,
    count: state.count + appChargingTokens.length,
  }
}
export const createAppChargingTokenBulkError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createAppChargingTokenBulkRequestState: requestState,
  }
}

export const downloadAppChargingTokenSampleFileLoading = (state) => {
  return {
    ...state,
    downloadAppChargingTokenSampleFileRequestState: RequestState.LOADING,
  }
}

export const downloadAppChargingTokenSampleFileSuccess = (state) => {
  return {
    ...state,
    downloadAppChargingTokenSampleFileRequestState: RequestState.SUCCEEDED,
  }
}

export const downloadAppChargingTokenSampleFileError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    downloadAppChargingTokenSampleFileRequestState: requestState,
  }
}
export const getAppChargingTokensCountLoading = (state) => {
  return {
    ...state,
    getAppChargingTokensCountRequestState: RequestState.LOADING,
  }
}
export const getAppChargingTokensCountSuccess = (state, { count }) => {
  return {
    ...state,
    count: count,
    getAppChargingTokensCountRequestState: RequestState.SUCCEEDED,
  }
}
export const getAppChargingTokensCountError = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    getAppChargingTokensCountRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AppChargingTokenTypes.FETCH_APP_CHARGING_TOKENS_LOADING]:
    fetchAppChargingTokensLoading,
  [AppChargingTokenTypes.FETCH_APP_CHARGING_TOKENS_SUCCESS]:
    fetchAppChargingTokensSuccess,
  [AppChargingTokenTypes.FETCH_APP_CHARGING_TOKENS_ERROR]:
    fetchAppChargingTokensError,
  [AppChargingTokenTypes.EDIT_APP_CHARGING_TOKEN_LOADING]:
    editAppChargingTokenLoading,
  [AppChargingTokenTypes.EDIT_APP_CHARGING_TOKEN_SUCCESS]:
    editAppChargingTokenSuccess,
  [AppChargingTokenTypes.EDIT_APP_CHARGING_TOKEN_ERROR]:
    editAppChargingTokenError,
  [AppChargingTokenTypes.DELETE_APP_CHARGING_TOKEN_LOADING]:
    deleteAppChargingTokenLoading,
  [AppChargingTokenTypes.DELETE_APP_CHARGING_TOKEN_SUCCESS]:
    deleteAppChargingTokenSuccess,
  [AppChargingTokenTypes.DELETE_APP_CHARGING_TOKEN_ERROR]:
    deleteAppChargingTokenError,
  [AppChargingTokenTypes.CLEAR_APP_CHARGING_TOKENS]: clearAppChargingTokens,
  [AppChargingTokenTypes.SET_DELETE_APP_CHARGING_TOKEN_REQUEST_STATE]:
    setDeleteAppChargingTokenRequestState,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_LOADING]:
    createAppChargingTokenLoading,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_SUCCESS]:
    createAppChargingTokenSuccess,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_ERROR]:
    createAppChargingTokenError,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_BULK_LOADING]:
    createAppChargingTokenBulkLoading,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_BULK_SUCCESS]:
    createAppChargingTokenBulkSuccess,
  [AppChargingTokenTypes.CREATE_APP_CHARGING_TOKEN_BULK_ERROR]:
    createAppChargingTokenBulkError,
  [AppChargingTokenTypes.DOWNLOAD_APP_CHARGING_TOKEN_SAMPLE_FILE_LOADING]:
    downloadAppChargingTokenSampleFileLoading,
  [AppChargingTokenTypes.DOWNLOAD_APP_CHARGING_TOKEN_SAMPLE_FILE_SUCCESS]:
    downloadAppChargingTokenSampleFileSuccess,
  [AppChargingTokenTypes.DOWNLOAD_APP_CHARGING_TOKEN_SAMPLE_FILE_ERROR]:
    downloadAppChargingTokenSampleFileError,
  [AppChargingTokenTypes.GET_APP_CHARGING_TOKENS_COUNT_LOADING]:
    getAppChargingTokensCountLoading,
  [AppChargingTokenTypes.GET_APP_CHARGING_TOKENS_COUNT_SUCCESS]:
    getAppChargingTokensCountSuccess,
  [AppChargingTokenTypes.GET_APP_CHARGING_TOKENS_COUNT_ERROR]:
    getAppChargingTokensCountError,
})
