import { call, put, spawn } from 'redux-saga/effects'
import {
  countriesApiService,
  currenciesApiService,
  handleError,
  handleSuccess,
} from 'Services/ApiService'
import CountriesActions from 'Stores/Countries-Currencies/Actions'
import { encodeObjectToBase64 } from 'Utils/Base64Functions'
import i18n from 'i18next'

export function* fetchCountriesList({ filter, offset, shouldShowError }) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (offset === 0) {
      yield spawn(fetchCountriesListCount, filter)
      yield put(CountriesActions.fetchCountriesListLoading())
    }
    const { data } = yield call(countriesApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      CountriesActions.fetchCountriesListSuccess(
        offset,
        data.countries,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(CountriesActions.fetchCountriesListFail(e))
    if (!shouldShowError) handleError(e)
  }
}
export function* fetchCountriesListCount(filter) {
  yield put(CountriesActions.fetchCountriesListCountLoading())
  try {
    const { data } = yield call(countriesApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(
      CountriesActions.fetchCountriesListCountSuccess(data.countriesCount),
    )
  } catch (e) {
    yield put(CountriesActions.fetchCountriesListCountFail(e))
    handleError(e)
  }
}

export function* fetchCurrenciesList({ filter, offset, shouldShowError }) {
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    if (offset === 0) {
      yield put(CountriesActions.fetchCurrenciesListLoading())
    }
    const { data } = yield call(currenciesApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    yield put(
      CountriesActions.fetchCurrenciesListSuccess(
        offset,
        data.currencies,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(CountriesActions.fetchCurrenciesListFail(e))
    if (!shouldShowError) handleError(e)
  }
}
export function* fetchCurrenciesListCount({ filter }) {
  yield put(CountriesActions.fetchCurrenciesListCountLoading())
  try {
    if (filter != null) {
      filter = encodeObjectToBase64(filter)
    }
    const { data } = yield call(currenciesApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(
      CountriesActions.fetchCurrenciesListCountSuccess(data.currenciesCount),
    )
  } catch (e) {
    yield put(CountriesActions.fetchCurrenciesListCountFail(e))
    handleError(e)
  }
}
export function* fetchCountryBounds({ countryName }) {
  yield put(CountriesActions.fetchCountryBoundsLoading())
  const url = 'https://nominatim.openstreetmap.org/search'
  const params = new URLSearchParams({
    q: countryName,
    format: 'json',
    polygon_geojson: 0,
  })
  try {
    const response = yield call(fetch, `${url}?${params.toString()}`)
    if (response.ok) {
      const data = yield response.json()
      if (data && data.length > 0) {
        const boundingBox = data[0].boundingbox
        //south, north, west, east
        const boundsArray = [
          parseFloat(parseFloat(boundingBox[0]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[1]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[2]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[3]).toFixed(2)),
        ]
        yield put(CountriesActions.fetchCountryBoundsSuccess(boundsArray))
      }
    }
  } catch (error) {
    yield put(CountriesActions.fetchCountryBoundsFail(error.message))
    handleError(error)
  }
}
export function* updateCountry({ countryId, newCountry, index, onResponse }) {
  try {
    yield put(CountriesActions.updateCountryLoading())
    const { data } = yield call(countriesApiService.patch, `/${countryId}`, {
      ...newCountry,
    })
    yield put(CountriesActions.updateCountrySuccess(index, data))
    onResponse(index)
  } catch (e) {
    yield put(CountriesActions.updateCountryFail(e))
    onResponse(index)
    handleError(e)
  }
}
export function* fetchAvailableCurrencies() {
  try {
    yield put(CountriesActions.fetchAvailableCurrenciesLoading())
    const { data } = yield call(currenciesApiService.get, '/all')
    yield put(CountriesActions.fetchAvailableCurrenciesSuccess(data.currencies))
  } catch (e) {
    yield put(CountriesActions.fetchAvailableCurrenciesFail(e))
    handleError(e)
  }
}
export function* fetchCreateCountryBounds({ countryName }) {
  yield put(CountriesActions.fetchCreateCountryBoundsLoading())
  const url = 'https://nominatim.openstreetmap.org/search'
  const params = new URLSearchParams({
    q: countryName,
    format: 'json',
    polygon_geojson: 0,
  })
  try {
    const response = yield call(fetch, `${url}?${params.toString()}`)
    if (response.ok) {
      const data = yield response.json()
      if (data && data.length > 0) {
        const boundingBox = data[0].boundingbox
        //south, north, west, east
        const boundsArray = [
          parseFloat(parseFloat(boundingBox[0]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[1]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[2]).toFixed(2)),
          parseFloat(parseFloat(boundingBox[3]).toFixed(2)),
        ]
        yield put(CountriesActions.fetchCreateCountryBoundsSuccess(boundsArray))
      }
    }
  } catch (error) {
    yield put(CountriesActions.fetchCreateCountryBoundsFail(error.message))
    handleError(error)
  }
}
export function* addCountry({ country }) {
  try {
    yield put(CountriesActions.addCountryLoading())
    const { data } = yield call(countriesApiService.post, '/', {
      name: country.countryName,
      code: country.countryCode,
      currency: country.countryCurrency,
      bounds: country.countryBounds,
    })
    yield put(CountriesActions.addCountrySuccess(data.country))
    handleSuccess(i18n.t('CountriesPage.AddCountrySuccessMessage'))
  } catch (e) {
    yield put(CountriesActions.addCountryFail(e))
    handleError(e)
  }
}
export function* updateCurrency({
  currencyId,
  newCurrency,
  index,
  onResponse,
}) {
  try {
    yield put(CountriesActions.updateCurrencyLoading())
    const { data } = yield call(currenciesApiService.patch, `/${currencyId}`, {
      ...newCurrency,
    })
    yield put(CountriesActions.updateCurrencySuccess(index, data))
    onResponse(index)
  } catch (e) {
    yield put(CountriesActions.updateCurrencyFail(e))
    onResponse(index)
    handleError(e)
  }
}
export function* addCurrency({ currency }) {
  try {
    yield put(CountriesActions.addCurrencyLoading())
    const { data } = yield call(currenciesApiService.post, '/', {
      name: currency.currencyName,
      code: currency.currencyCode,
      symbol: currency.currencySymbol,
      decimalPlaces: currency.currencyDecimalPlaces,
    })
    yield put(CountriesActions.addCurrencySuccess(data.currency))
    handleSuccess(i18n.t('CountriesPage.AddCurrencySuccessMessage'))
  } catch (e) {
    yield put(CountriesActions.addCurrencyFail(e))
    handleError(e)
  }
}
