import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css'
import {
  Query,
  Builder,
  Utils as QbUtils,
} from '@react-awesome-query-builder/mui'
import { Box } from '@mui/material'
function queryToTree(query, config) {
  const generateRuleId = () => QbUtils.uuid()
  function convertRule(field, value, isNot = false) {
    const fieldConfig = config.fields[field]
    if (!fieldConfig) {
      throw new Error(`Field "${field}" is not defined in the configuration.`)
    }
    let operator, valueType
    if (field === 'isActive') {
      let boolVal = value === true ? true : false
      return {
        type: 'rule',
        id: generateRuleId(),
        properties: {
          field,
          operator: 'equal',
          value: [boolVal],
          valueSrc: ['value'],
          valueType: ['boolean'],
          not: isNot,
        },
      }
    }
    if (typeof value === 'object' && value !== null) {
      if ('$not' in value) {
        return convertRule(field, value.$not, true)
      }
    }
    if (fieldConfig.type === 'multiselect') {
      if (value && value.hasOwnProperty('$in')) {
        value = Array.isArray(value.$in) ? [value.$in] : [[value.$in]]
        operator =
          value.length > 0 ? 'multiselect_contains' : 'multiselect_not_contains'
      } else if (value && value.hasOwnProperty('$ne') && value.$ne === null) {
        operator = 'is_not_null'
        value = [[]]
      } else {
        if (value === null) {
          operator = 'is_null'
          value = [[]]
        } else if (value === undefined) {
          operator = 'is_not_null'
          value = [[]]
        } else if (Array.isArray(value) && value.length === 1) {
          operator = 'multiselect_equals'
          value = [value]
        } else if (value && !Array.isArray(value)) {
          operator = 'multiselect_equals'
          value = [[value]]
        } else {
          operator = 'multiselect_not_equals'
          value = [[]]
        }
      }
      valueType = 'multiselect'
    } else if (fieldConfig.type === 'text') {
      if (typeof value === 'object' && value !== null) {
        if ('$not' in value) {
          let notCondition = value.$not
          if (typeof notCondition === 'object' && '$regex' in notCondition) {
            let regexValue = notCondition.$regex
            if (typeof regexValue === 'string' && regexValue.trim() !== '') {
              if (regexValue.startsWith(`'^`)) {
                operator = 'not_starts_with'
              } else if (regexValue.endsWith(`$'`)) {
                operator = 'not_ends_with'
              } else {
                operator = 'not_like'
              }
            }
            value = [
              regexValue.replace(/['/^/$/"]/g, '').replace(/[\/\\]/g, ''),
            ]
          } else if (
            typeof notCondition === 'string' &&
            notCondition.trim() !== ''
          ) {
            operator = 'not_equal'
            value = [
              notCondition.replace(/['/^/$/"]/g, '').replace(/[\/\\]/g, ''),
            ]
          }
        } else if ('$regex' in value) {
          let regexValue = value.$regex
          if (typeof regexValue === 'string' && regexValue.trim() !== '') {
            if (regexValue.startsWith(`'^`)) {
              operator = 'starts_with'
            } else if (regexValue.endsWith(`$'`)) {
              operator = 'ends_with'
            } else {
              operator = 'like'
            }
          }
          value = [regexValue.replace(/['/^/$/"]/g, '').replace(/[\/\\]/g, '')]
        } else if ('$ne' in value) {
          operator = 'not_equal'
          value = [value.$ne.replace(/['/^/$/"]/g, '').replace(/[\/\\]/g, '')]
        }
      } else if (typeof value === 'string' && value.trim() !== '') {
        if (value.includes('$ne')) {
          operator = 'not_equal'
          value = [value.replace(/[$ne:'"]/g, '').replace(/[\/\\]/g, '')]
        } else {
          operator = 'equal'
          value = [value.replace(/[']/g, '').replace(/[\/\\]/g, '')]
        }
      }
      valueType = 'text'
    } else if (fieldConfig.type === 'date') {
      const { mappedOperator, mappedValue } = mapDateOperator(value)
      operator = mappedOperator
      value = mappedValue
      valueType = 'date'
    } else if (typeof value === 'object' && value !== null) {
      const operatorKey = Object.keys(value)[0]
      operator = mapOperator(operatorKey, fieldConfig.type)
      value = value[operatorKey]
      valueType = mapValueType(fieldConfig.type)
    } else {
      operator = 'select_equals'
      valueType = mapValueType(fieldConfig.type)
    }
    return {
      type: 'rule',
      id: generateRuleId(),
      properties: {
        field,
        operator,
        value: Array.isArray(value) ? value : [value],
        valueSrc: ['value'],
        valueType: [valueType],
        not: isNot,
      },
    }
  }
  function mapOperator(operatorKey, fieldType) {
    const operatorMapping = {
      $gt: 'greater',
      $lt: 'less',
      $gte: 'greater_or_equal',
      $lte: 'less_or_equal',
      $eq:
        fieldType === 'select' || fieldType === 'multiselect'
          ? 'select_equals'
          : 'equal',
      $ne:
        fieldType === 'select' || fieldType === 'multiselect'
          ? 'select_not_equals'
          : 'not_equal',
    }
    return operatorMapping[operatorKey] || 'equal'
  }
  function mapDateOperator(value) {
    if (typeof value === 'object' && value !== null) {
      const operatorKey = Object.keys(value)[0]
      let dateValue = value[operatorKey]
      if (typeof dateValue === 'string') {
        let localDate = new Date(dateValue)
        localDate.setHours(0, 0, 0, 0)
        dateValue = localDate.toISOString()
      }
      const operatorMapping = {
        $eq: 'equal',
        $ne: 'not_equal',
        $gt: 'greater',
        $lt: 'less',
        $gte: 'greater_or_equal',
        $lte: 'less_or_equal',
      }
      return {
        mappedOperator: operatorMapping[operatorKey] || 'equal',
        mappedValue: [dateValue],
      }
    }
    return { mappedOperator: 'equal', mappedValue: [value] }
  }
  function mapValueType(fieldType) {
    const typeMapping = {
      text: 'text',
      select: 'select',
      multiselect: 'multiselect',
      date: 'date',
      boolean: 'boolean',
    }
    return typeMapping[fieldType] || 'text'
  }
  function parseLogicalOperators(
    query,
    parentCombinator = null,
    isNotGroup = false,
  ) {
    const children = []

    if (Array.isArray(query)) {
      query.forEach((item) => {
        children.push(parseLogicalOperators(item, parentCombinator, isNotGroup))
      })
    } else {
      for (const [key, value] of Object.entries(query)) {
        if (key === '$or' || key === '$and') {
          const newCombinator = key === '$and' ? 'AND' : 'OR'
          const groupChildren = []

          if (
            Array.isArray(value) &&
            value.every((v) => typeof v === 'object')
          ) {
            value.forEach((subQuery) => {
              if (Object.keys(subQuery).length > 1) {
                const andGroupChildren = Object.entries(subQuery).map(
                  ([subField, subValue]) => convertRule(subField, subValue),
                )

                groupChildren.push({
                  id: generateRuleId(),
                  type: 'group',
                  properties: { conjunction: 'AND' },
                  children1: Object.fromEntries(
                    andGroupChildren.map((child) => [child.id, child]),
                  ),
                })
              } else {
                groupChildren.push(
                  parseLogicalOperators(subQuery, newCombinator, false),
                )
              }
            })
          }

          const group = {
            id: generateRuleId(),
            type: 'group',
            properties: { conjunction: newCombinator, not: isNotGroup },
            children1: Object.fromEntries(
              groupChildren.map((child) => [child.id, child]),
            ),
          }

          children.push(group)
        } else if (key === '$not') {
          const parsedNot = parseLogicalOperators(value, parentCombinator, true)
          if (parsedNot.type === 'group') {
            parsedNot.properties.not = true
            children.push(parsedNot)
          } else {
            children.push({
              id: generateRuleId(),
              type: 'group',
              properties: { conjunction: parentCombinator || 'AND', not: true },
              children1: { [parsedNot.id]: parsedNot },
            })
          }
        } else {
          children.push(convertRule(key, value, isNotGroup))
        }
      }
    }

    if (children.length === 1) {
      return children[0]
    }

    return {
      id: generateRuleId(),
      type: 'group',
      properties: { conjunction: parentCombinator || 'AND', not: isNotGroup },
      children1: Object.fromEntries(children.map((child) => [child.id, child])),
    }
  }

  const parsedTree = parseLogicalOperators(query)
  const tree =
    parsedTree.type === 'group'
      ? parsedTree
      : {
          id: generateRuleId(),
          type: 'group',
          properties: { conjunction: 'AND' },
          children1: {
            [parsedTree.id]: parsedTree,
          },
        }
  return tree
}
const StyledQueryBuilder = styled(Box)`
  .group.group-or-rule {
    background-color: transparent !important;
    border: none !important;
    position: relative; /* Ensure child elements align correctly */
  }
  .group.group-or-rule .group--header {
    background-color: transparent !important;
  }
  .group.group-or-rule .group--children {
    border: none !important;
  }
  /* Hide all action buttons by default */
  .MuiButtonBase-root.MuiIconButton-root,
  .group--actions .group--addRule,
  .group--actions .group--addGroup {
    width: 20px !important; /* Set consistent size */
    height: 20px !important;
    right: 0px;
    opacity: 1; /* Hide buttons */
  }
  /* Set width to 100px for MuiButton */
  .MuiButtonBase-root.MuiButton-root.MuiButton-text {
    width: 100px !important;
    min-width: 100px !important; /* Ensures width doesn't shrink */
  }
  /* Set font size to 14px for input elements, autocomplete, and select components */
  .MuiInputBase-root,
  .MuiAutocomplete-input,
  .MuiAutocomplete-option,
  .MuiSelect-root {
    font-size: 14px !important;
  }
  /* Set specific font size for MuiAutocomplete-option */
  .MuiAutocomplete-option {
    font-size: 14px !important;
  }
  /* Add any other necessary styling */
  .css-1hxb263-MuiButtonBase-root-MuiIconButton-root {
    color: red;
  }
`
const StyledBox = styled(Box)`
  background-color: transparent !important;
  border: none !important;
  font-size: 14px !important;
`
class DemoQueryBuilder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tree: QbUtils.loadTree(props.initTree),
      config: props.config,
      query: {},
    }
  }
  render = () => (
    <StyledQueryBuilder>
      <StyledBox mb={3}>
        <Query
          {...this.state.config}
          value={this.state.tree}
          onChange={this.onChange}
          renderBuilder={this.renderBuilder}
        />
      </StyledBox>
    </StyledQueryBuilder>
  )
  renderBuilder = (props) => <Builder {...props} />
  onChange = (immutableTree, config) => {
    this.setState({ tree: immutableTree })
    const formattedQuery = QbUtils.mongodbFormat(immutableTree, config)
    if (this.props.onQueryChange) {
      this.props.onQueryChange(formattedQuery || {})
    }
    if (formattedQuery) {
      this.setState({ query: formattedQuery })
      if (this.props.onQueryChange) {
        this.props.onQueryChange(formattedQuery)
      }
    }
  }
}
const QueryBuilder = ({ config, initTree, onQueryChange }) => {
  const initialTree = queryToTree(initTree, config)
  return (
    <DemoQueryBuilder
      config={config}
      initTree={initialTree}
      onQueryChange={onQueryChange}
    />
  )
}
QueryBuilder.propTypes = {
  config: PropTypes.object,
  initTree: PropTypes.object,
  onQueryChange: PropTypes.func,
}
export default QueryBuilder
