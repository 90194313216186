import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import AdminSelectors from 'Stores/Admin/Selectors'
import AdminActions from 'Stores/Admin/Actions'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  IFAvatar,
  IFTable,
  IFText,
  IFsvg,
  IFTooltipIconsLoading,
  IFButton,
} from 'Components'
import { Colors } from 'Theme'
import Styles from './AdminList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Skeleton from '@mui/material/Skeleton'
import {
  MenuItem,
  Select,
  FormControl,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material'
import IFDialog from 'Components/IFDialog/IFDialog'
import { capitalizeFirstLetter, formatDate } from '../../Utils/StringFunctions'
import ReportIcon from '@mui/icons-material/Report'
import RoleActions from 'Stores/Role/Actions'
import EditIcon from '@mui/icons-material/Edit'
import AdminPermissions from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import moment from 'moment'

const StyledSelect = styled(Select)`
  && {
    font-family: 'ProximaNova';
    font-size: 14px;
    color: ${Colors.textColor};
    height: 30px;
    padding: 8px 8px;
  }
`

const AdminList = ({
  admins,
  adminFilters,
  fetchAdminList,
  paginationOffset,
  fetchAdminListRequestState,
  deleteAdmin,
  roles,
  updateAdminRole,
  updateAdminRoleRequestState,
  deleteAdminRequestState,
  loggedInAdmin,
  tablesHeight,
  editingAdminRole,
  setUpdateAdminRoleRequestState,
}) => {
  const { t } = useTranslation()
  const confirmDeleteDialog = useRef()

  const [newRole, setNewRole] = useState('')
  const [deleteAdminId, setDeleteAdminId] = useState(null)

  const [registeredCountries, setRegisteredCountries] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({
    country: false,
  })
  const canEditAdmin = editingAdminRole.includes(
    AdminPermissions.AdminPermissions.CAN_EDIT_ADMINS,
  )
  const [table, setTable] = useState()

  useEffect(() => {
    if (loggedInAdmin?.registeredCountries?.length > 1) {
      setRegisteredCountries(true)
    }
  }, [loggedInAdmin])

  useEffect(() => {
    setColumnVisibility({
      country: registeredCountries,
    })
  }, [registeredCountries])
  const handleEdit = ({ row, table }) => {
    setUpdateAdminRoleRequestState(RequestState.UNINITIALIZED)
    table?.setEditingRow(row)
  }
  useEffect(() => {
    if (deleteAdminRequestState === RequestState.SUCCEEDED) {
      confirmDeleteDialog.current.dismiss()
    }
  }, [deleteAdminRequestState])

  const handleDeleteAdmin = () => {
    deleteAdmin(deleteAdminId)

    const adminIndex = admins.findIndex((admin) => admin.id === deleteAdminId)
    setDeleteAdminId(null)
  }

  const onDeleteClickHandler = (index) => {
    setDeleteAdminId(admins[index].id)
    confirmDeleteDialog.current.show()
  }

  const loadMoreData = () => {
    if (
      fetchAdminListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchAdminList(paginationOffset, failedRequest)
  }
  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchAdminListRequestState === RequestState.ERROR_0_NETWORK ||
      fetchAdminListRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchAdminListRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchAdminListRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchAdminListRequestState])
  const handleRetry = () => {
    fetchAdminList(paginationOffset)
  }
  const adminTableColumns = useMemo(
    () => [
      {
        accessorKey: 'name',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING ||
          paginationOffset === 0 ? (
            <div className={Styles.SkeletonDiv}>
              <Skeleton
                variant="text"
                width="110px"
                animation="none"
                height={20}
                style={{ Left: '40px' }}
              />
            </div>
          ) : (
            <div className={Styles.HeaderAdminName}>{t('AdminList.Name')}</div>
          ),
        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div className={Styles.AdminNameColumn}>
              <div className={Styles.AdminNameAvatar}>
                <IFAvatar
                  imageURL={row.original.avatarURL}
                  name={row.original.name}
                  size={40}
                />
              </div>
              <div className={Styles.AdminNameString}>
                <IFText>{capitalizeFirstLetter(row.original.name)}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'email',
        size: 220,
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING ||
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Email')}</IFText>
          ),
        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div>
              <div>
                <IFText>{capitalizeFirstLetter(row.original.email)}</IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'country',
        size: 120,
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING ||
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Country')}</IFText>
          ),
        Cell: ({ row }) => {
          if (
            row.index < admins.length &&
            row.original.registeredCountries.length > 0
          ) {
            const countryCodes = row.original.registeredCountries
              .map((country) => country.name)
              .join(', ')
            return (
              <div>
                <IFText>{countryCodes}</IFText>
              </div>
            )
          } else if (failedRequest) {
            return <></>
          } else {
            return (
              <Skeleton
                variant="text"
                width="60%"
                animation="none"
                height={20}
              />
            )
          }
        },
      },
      {
        accessorKey: 'createdOn',
        enableEditing: false,
        header:
          fetchAdminListRequestState === RequestState.LOADING ||
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <div className={Styles.AdminColumnCreatedOn}>
              {t('AdminList.CreatedOn')}
            </div>
          ),

        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div className={Styles.AdminColumnCreatedOn}>
              <IFText>
                {moment(row.original.createdAt).format('MMM DD, YYYY LTS')}
              </IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },

      {
        accessorKey: 'role',
        header:
          fetchAdminListRequestState === RequestState.LOADING ||
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            <IFText>{t('AdminList.Role')}</IFText>
          ),
        muiTableBodyCellProps: {
          sx: {
            overflow: 'visible',
          },
        },
        muiEditTextFieldProps: ({ row }) => {
          const [newRole, setNewRole] = useState(row.original.role.id)
          return {
            type: 'select',
            select: true,
            value: newRole,
            onChange: (event) => {
              setNewRole(event.target.value)
            },
            children: roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            )),
            sx: {
              backgroundColor: 'transparent',
              '& .MuiInputBase-root': {
                color: 'black',
                '&:hover::before': {
                  borderBottom: `1px solid ${Colors.primary} !important`,
                },
                '&.Mui-focused::after': {
                  borderBottom: `2px solid ${Colors.primary} !important`,
                },
              },
              '& .MuiSelect-select': {
                backgroundColor: 'transparent',
                '&:focus': {
                  backgroundColor: 'transparent',
                },
              },
              '& .MuiPaper-root': {
                backgroundColor: 'white',
              },
            },
          }
        },

        Cell: ({ row }) => {
          return row.index < admins.length ? (
            <div>
              <IFText>{row.original.role.name}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],
    [
      admins,
      fetchAdminListRequestState,
      newRole,
      roles,
      updateAdminRoleRequestState,
      failedRequest,
    ],
  )
  const handleSaveRole = (table, row, values) => {
    const roleId =
      typeof values.role === 'object' ? values.role.id : values.role
    if (!roleId || roleId === row.original.role.id) {
      table?.setEditingRow(null)
      return
    }
    setTable(table)
    updateAdminRole(row.original.id, roleId)
  }
  useEffect(() => {
    if (updateAdminRoleRequestState === RequestState.SUCCEEDED) {
      table?.setEditingRow(null)
    }
  }, [updateAdminRoleRequestState])

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )

  const renderRowActions = ({ table, row }) => {
    const isCurrentAdmin = row.original.id === loggedInAdmin.id
    if (Object.keys(row.original).length > 0 && !isCurrentAdmin) {
      return (
        <Box>
          {row?.id === table.getState().editingRow?.id ? (
            <div className={Styles.AdminTableIcons}>
              <Tooltip title={t('AdminList.deleteModalCancel')}>
                <IconButton
                  onClick={() => {
                    table?.setEditingRow(null)
                  }}
                >
                  <IFsvg.CloseBtn
                    height={24}
                    width={24}
                    fill={Colors.primary}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('AdminList.Save')}>
                <div className={Styles.CustomBackGround}>
                  <IFTooltipIconsLoading
                    isLoading={
                      updateAdminRoleRequestState === RequestState.LOADING
                        ? true
                        : false
                    }
                    Icon={IFsvg.DoneBtn}
                    FilledIcon={IFsvg.DoneBtn}
                    size={26}
                    animationDisabled={false}
                    onClick={() => {
                      const values = row.getAllCells().reduce((acc, cell) => {
                        acc[cell.column.id] = cell.getValue()
                        return acc
                      }, {})
                      handleSaveRole(table, row, values)
                    }}
                  />
                </div>
              </Tooltip>

              <Tooltip title={t('AdminList.deleteModalConfirm')}>
                <IconButton
                  onClick={() => {
                    onDeleteClickHandler(row.index)
                  }}
                >
                  <div
                    className={Styles.DeleteIcon}
                    style={{ color: Colors.AdminListDeleteIcon }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </div>
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className={Styles.AdminTableIcons}>
              <Tooltip title={t('AdminList.Edit')}>
                <IconButton onClick={() => handleEdit({ row, table })}>
                  <EditIcon sx={{ color: Colors.gray }} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Box>
      )
    }

    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={handleRetry}
        />
      )
    }
  }
  return (
    <div>
      {((fetchAdminListRequestState !== RequestState.SUCCEEDED &&
        !paginationOffset) ||
        admins) && (
        <>
          <IFTable
            columns={adminTableColumns}
            data={admins}
            fetchMoreOnBottomReached={fetchMoreOnBottomReached}
            paginationOffset={paginationOffset}
            filters={adminFilters}
            showSkeleton={
              fetchAdminListRequestState === RequestState.LOADING ||
              (fetchAdminListRequestState !== RequestState.SUCCEEDED &&
                paginationOffset === 0)
            }
            tableMaxHeight={tablesHeight}
            tableSetHeight={'100%'}
            hideColumnQuery={columnVisibility}
            tableRequestState={fetchAdminListRequestState}
            enableEditing={canEditAdmin}
            renderRowActions={canEditAdmin && renderRowActions}
          />
        </>
      )}
      <IFDialog
        ref={confirmDeleteDialog}
        style={{ margin: '15em' }}
        open={false}
        title={t('AdminList.deleteModalTitle')}
        bodyText={t('AdminList.deleteModalText')}
        buttonAcceptonClick={handleDeleteAdmin}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('AdminList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteAdminRequestState === RequestState.LOADING}
        dismissOnAccept={false}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateAdminRole: (adminId, newRoleValue) =>
      dispatch(AdminActions.updateAdminRole(adminId, newRoleValue)),
    deleteAdmin: (adminId) => dispatch(AdminActions.deleteAdmin(adminId)),
    fetchAdminList: (offset, shouldShowError) =>
      dispatch(AdminActions.fetchAdminList(offset, shouldShowError)),
    fetchRoles: () => dispatch(RoleActions.fetchRoles()),
    setUpdateAdminRoleRequestState: (newState) =>
      dispatch(AdminActions.setUpdateAdminRoleRequestState(newState)),
  }
}
const mapStateToProps = (state) => ({
  fetchAdminListRequestState:
    AdminSelectors.getfetchAdminListRequestState(state),
  paginationOffset: AdminSelectors.getAdminListPaginationOffset(state),
  adminFilters: AdminSelectors.getAdminsFilters(state),
  updateAdminRoleRequestState:
    AdminSelectors.getupdateAdminRoleRequestState(state),
  deleteAdminRequestState: AdminSelectors.getDeleteAdminRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
})
AdminList.propTypes = {
  updateAdminRole: PropTypes.func,
  deleteAdmin: PropTypes.func,
  fetchAdminList: PropTypes.func,
  fetchAdminListRequestState: PropTypes.number,
  paginationOffset: PropTypes.number,
  adminFilters: PropTypes.object,
  deleteAdminRequestState: PropTypes.number,
  fetchRoles: PropTypes.func,
  updateAdminRoleRequestState: PropTypes.number,
  loggedInAdmin: PropTypes.object,
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminList)
