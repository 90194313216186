import { IFText, IFSkeleton } from 'Components'
import Styles from './DashboardCard.module.css'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { formatNumber } from '../../Utils/Calculations'
const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 16px;
  flex-direction: column;
`

const IconContainer = styled.div`
  background-color: ${Colors.filterBackground};
  border: 1px solid ${Colors.filterBackground};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DashboardCard = ({
  title,
  data,
  metric,
  icon,
  height,
  width,
  isLoading,
}) => {
  const formattedData = formatNumber(
    parseFloat(data).toFixed(0),
  ).toLocaleString()
  return (
    <div style={{ height: height, width: width }}>
      {isLoading ? (
        <Card>
          <IconContainer>
            <IFSkeleton variant="circular" width="30px" height="30px" />
          </IconContainer>

          <div className={Styles.Data}>
            <IFSkeleton
              variant="text"
              width="140px"
              height="16px"
              style={{ marginTop: 44 }}
            />
            <IFSkeleton
              className={Styles.Count}
              variant="text"
              width="140px"
              height="16px"
              style={{ marginTop: 8 }}
            />
          </div>
        </Card>
      ) : (
        <Card style={{ height: height, width: width }}>
          <IconContainer>
            <img className={Styles.plugicon} src={icon} alt="" />
          </IconContainer>

          <div className={Styles.Data}>
            <IFText className={Styles.Title}>{title}</IFText>
            <IFText style={{ color: Colors.text }} className={Styles.Count}>
              {metric ? `${metric} ${formattedData}` : formattedData}
            </IFText>
          </div>
        </Card>
      )}
    </div>
  )
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.number,
  metric: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  icon: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default DashboardCard
