import React from 'react'
import styled from 'styled-components'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import Styles from './DashboardList.module.css'
import { IFText, IFAvatar, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'Utils/Calculations'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`

const TableRow = styled.div`
  display: flex;
  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.CardShadow};
  }
`

const TableHeaderRow = styled.div`
  display: flex;
  background-color: ${Colors.ListBackground};
`

const DashboardList = ({
  data,
  title,
  keyProp,
  labelProp,
  isAvatar,
  isLoading,
}) => {
  const { t } = useTranslation()

  let category = ''
  if (isAvatar) category = t('Dashboard.ListEnergyConsumption')
  else category = t('Dashboard.ListRedeemCount')

  // Preprocess data to ensure at least 5 rows with default values
  const preprocessData = (data) => {
    const processedData = [...data]
    while (processedData.length < 5) {
      processedData.push({ [keyProp]: '-', [labelProp]: '-' })
    }
    return processedData
  }

  const displayData = preprocessData(data)

  return (
    <div style={{ height: '100%' }}>
      {isLoading ? (
        <Card>
          <div className={Styles.Header}>
            <IFSkeleton variant="text" width="150px" height="22px" />
          </div>

          <div className={Styles.Table}>
            <TableHeaderRow>
              <div className={Styles.LeftTableHeader}>
                <IFSkeleton variant="text" width="100px" height="20px" />
              </div>
              <div className={Styles.RightTableHeader}>
                <IFSkeleton variant="text" width="100px" height="20px" />
              </div>
            </TableHeaderRow>

            {displayData.map((item, index) => (
              <TableRow key={index}>
                <div className={Styles.LeftTableCell}>
                  {isAvatar ? (
                    <div className={Styles.UserDetails}>
                      <div className={Styles.TableInfo}>
                        <IFSkeleton
                          variant="text"
                          width="130px"
                          height="16px"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.TableInfo}>
                      <IFSkeleton variant="text" width="130px" height="16px" />
                    </div>
                  )}
                </div>
                <div className={Styles.RightTableCell}>
                  <IFSkeleton variant="text" width="130px" height="16px" />
                </div>
              </TableRow>
            ))}
          </div>
        </Card>
      ) : (
        <Card>
          <div className={Styles.Header}>
            <IFText className={Styles.Title}>{title}</IFText>
          </div>

          <div className={Styles.Table}>
            <TableHeaderRow>
              <div className={Styles.LeftTableHeader}>
                <IFText
                  style={{
                    color: Colors.text,
                    fontSize: 14,
                  }}
                >
                  {keyProp}
                </IFText>
              </div>
              <div className={Styles.RightTableHeader}>
                <IFText
                  style={{
                    color: Colors.text,
                    fontSize: 12,
                  }}
                >
                  {category}
                </IFText>
              </div>
            </TableHeaderRow>

            {displayData.map((item, index) => (
              <TableRow key={index}>
                <div className={Styles.LeftTableCell}>
                  {isAvatar ? (
                    <div className={Styles.UserDetails}>
                      <div>
                        <IFAvatar
                          name={item[keyProp]}
                          size={30}
                          className={Styles.Avatar}
                        />
                      </div>
                      <div className={Styles.TableInfo}>
                        <IFText className={Styles.NameText}>
                          {item[keyProp]}
                        </IFText>
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.TableInfo}>
                      <IFText>{item[keyProp]}</IFText>
                    </div>
                  )}
                </div>
                <div className={Styles.RightTableCell}>
                  <IFText
                    style={{
                      color: Colors.text,
                    }}
                  >
                    {formatNumber(item[labelProp])}
                  </IFText>
                </div>
              </TableRow>
            ))}
          </div>
        </Card>
      )}
    </div>
  )
}

DashboardList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  keyProp: PropTypes.string,
  labelProp: PropTypes.string,
  isAvatar: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default DashboardList
