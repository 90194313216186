import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts'
import styled from 'styled-components'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import { IFText, IFSkeleton } from 'Components'
import { useTranslation } from 'react-i18next'
import Styles from './HorizontalBarchart.module.css'

const Card = styled.div`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.CardShadow};
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: column;
`

const BlurOverlayText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
  font-weight: 600;
  background-color: ${Colors.white};
  padding: 10px;
  border-radius: 5px;
  z-index: 1;
`
const HorizontalBarchart = ({
  values,
  labels,
  title,
  chartColors,
  isLoading,
}) => {
  const { t } = useTranslation()

  const chartData = labels.map((label, index) => ({
    name: label,
    value: values[index],
    color: chartColors[index % chartColors.length],
  }))
  const isNoData = values.every((value) => value === 0)
  return (
    <div>
      {isLoading ? (
        <Card>
          <IFSkeleton variant="text" width="150px" height="16px" />
          <div
            className={Styles.ChartContainer}
            style={{ position: 'relative' }}
          >
            <IFSkeleton
              variant="rectangular"
              height="100px"
              style={{ marginTop: 16 }}
            />
          </div>
        </Card>
      ) : (
        <Card>
          <IFText className={Styles.Title} style={{ color: Colors.text }}>
            {title}
          </IFText>
          <div
            className={Styles.ChartContainer}
            style={{ position: 'relative' }}
          >
            {isNoData ? (
              <div>
                <BlurOverlayText>
                  <IFText>{t('TransactionsPage.ChartNoData')}</IFText>
                </BlurOverlayText>
                <ResponsiveContainer width="100%" height={111}>
                  <BarChart data={[]} layout="vertical">
                    <XAxis type="number" hide />
                    <YAxis type="category" hide />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <ResponsiveContainer width="100%" height={111}>
                <BarChart data={chartData} layout="vertical" fontSize={12}>
                  <XAxis type="number" hide />
                  <YAxis type="category" dataKey="name" hide={false} />
                  <Tooltip />
                  <Bar dataKey="value">
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

HorizontalBarchart.propTypes = {
  values: PropTypes.array,
  labels: PropTypes.array,
  title: PropTypes.string,
  chartColors: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default HorizontalBarchart
