import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchAppChargingTokenDesigns: ['offset'],
  fetchAppChargingTokenDesignsLoading: null,
  fetchAppChargingTokenDesignsSuccess: [
    'offset',
    'appChargingTokenDesigns',
    'nextOffset',
  ],
  fetchAppChargingTokenDesignsError: ['error'],

  fetchAllAppChargingTokenDesigns: null,
  fetchAllAppChargingTokenDesignsLoading: null,
  fetchAllAppChargingTokenDesignsSuccess: ['appChargingTokenDesigns'],
  fetchAllAppChargingTokenDesignsError: ['error'],

  addAppChargingTokenDesign: ['design'],
  addAppChargingTokenDesignLoading: null,
  addAppChargingTokenDesignSuccess: ['design'],
  addAppChargingTokenDesignError: ['error'],

  editAppChargingTokenDesign: ['id', 'design'],
  editAppChargingTokenDesignLoading: null,
  editAppChargingTokenDesignSuccess: ['design'],
  editAppChargingTokenDesignError: ['error'],

  getAppChargingTokenDesignsCount: null,
  getAppChargingTokenDesignsCountLoading: null,
  getAppChargingTokenDesignsCountSuccess: ['appChargingTokenCount'],
  getAppChargingTokenDesignsCountError: ['error'],

  deleteAppChargingTokenDesign: ['id'],
  deleteAppChargingTokenDesignLoading: null,
  deleteAppChargingTokenDesignSuccess: ['id'],
  deleteAppChargingTokenDesignError: ['error'],

  clearDesigns: null,
})

export const AppChargingTokenDesignTypes = Types
export default Creators
