const getAdminList = (state) => {
  return state.main.adminState.admins
}

const getFilteredAdminList = (state) => {
  let result = []
  if (state.main.adminState.admins) {
    for (let i = 0; i < state.main.adminState.admins.length; i++) {
      if (
        state.main.adminState.admins[i].name
          .toLowerCase()
          .includes(state.main.adminState.filterText.toLowerCase())
      ) {
        result.push(state.main.adminState.admins[i])
      }
    }
  }
  return result
}
const getAdminsFilters = (state) => {
  return state.main.adminState.adminsFilters
}

const getFilterText = (state) => {
  return state.main.adminState.filterText
}

const getfetchAdminListRequestState = (state) => {
  return state.main.adminState.fetchAdminListRequestState
}

const getupdateAdminRoleRequestState = (state) => {
  return state.main.adminState.updateAdminRoleRequestState
}

const getAdminListPaginationOffset = (state) => {
  return state.main.adminState.paginationOffset
}
const getDeleteAdminRequestState = (state) => {
  return state.main.adminState.deleteAdminRequestState
}
const getAdminsCount = (state) => {
  return state.main.adminState.count
}
const getfetchAdminsCountRequestState = (state) => {
  return state.main.adminState.fetchAdminsCountRequestState
}
const getInviteAdmins = (state) => {
  return state.main.adminState.inviteAdmins
}
const getInviteAdminStatus = (state) => {
  return state.main.adminState.inviteAdminStatus
}
const getInviteAdminRequestState = (state) => {
  return state.main.adminState.inviteAdminRequestState
}
const getFetchInviteAdminListRequestState = (state) => {
  return state.main.adminState.fetchInviteAdminListRequestState
}
const getDeleteInviteAdminRequestState = (state) => {
  return state.main.adminState.deleteInviteAdminRequestState
}
const getSendAdminEmailRequestState = (state) => {
  return state.main.adminState.sendAdminEmailRequestState
}
const getFetchInviteAdminRequestState = (state) => {
  return state.main.adminState.fetchInviteAdminRequestState
}
const getInviteAdmin = (state) => {
  return state.main.adminState.inviteAdmin
}

const getCreateAdminRequestState = (state) => {
  return state.main.adminState.createAdminRequestState
}
const getFetchAdminsInvitationsCountRequestState = (state) => {
  return state.main.adminState.fetchAdminsInvitationsCountRequestState
}
const getAdminsInvitationsCount = (state) => {
  return state.main.adminState.adminsInvitationsCount
}
const getDefaultAdminsFilters = (state) => {
  return state.main.adminState.defaultAdminsFilters
}
const getInviteAdminListPaginationOffset = (state) => {
  return state.main.adminState.inviteAdminPaginationOffset
}

const AdminSelectors = {
  getFilterText,
  getAdminList,
  getAdminsFilters,
  getFilteredAdminList,
  getfetchAdminListRequestState,
  getupdateAdminRoleRequestState,
  getAdminListPaginationOffset,
  getDeleteAdminRequestState,
  getfetchAdminsCountRequestState,
  getAdminsCount,
  getInviteAdmins,
  getInviteAdminStatus,
  getInviteAdminRequestState,
  getFetchInviteAdminListRequestState,
  getDeleteInviteAdminRequestState,
  getSendAdminEmailRequestState,
  getFetchInviteAdminRequestState,
  getInviteAdmin,
  getCreateAdminRequestState,
  getFetchAdminsInvitationsCountRequestState,
  getAdminsInvitationsCount,
  getDefaultAdminsFilters,
  getInviteAdminListPaginationOffset,
}

export default AdminSelectors
